export enum DepositType {
  NOT_YET_SELECTED = 'NOT_YET_SELECTED',
  ONE_TIME = 'ONE_TIME',
  RUV = 'RUV'
}

export enum RentDepositDeliveryOption {
  LANDLORD = 'LANDLORD',
  PROPERTY_SEARCHER = 'PROPERTY_SEARCHER'
}

export enum RentDepositLandlordOption {
  LEGAL_PERSON = 'LEGAL_PERSON',
  NATURAL_PERSON = 'NATURAL_PERSON'
}

export enum RentDepositPaymentOption {
  MONTHLY = 'MONTHLY',
  YEARLY = 'YEARLY'
}

export enum RuvApplianceStatus {
  REQUEST_FAILED = 'REQUEST_FAILED',
  APPROVED = 'APPROVED',
  DECLINED = 'DECLINED',
  UNDER_REVISION = 'UNDER_REVISION',
  TECHNICAL_PROBLEM = 'TECHNICAL_PROBLEM'
}
