// eslint-disable-next-line @typescript-eslint/ban-types
export function checkExifOrientation(file: Blob, callback: Function) {
  if (!file || !Object.keys(file).length) {
    return -1;
  }

  const reader = new FileReader();

  reader.onload = (e: ProgressEvent) => {
    const view = new DataView((e.target as any).result);
    if (view.getUint16(0, false) !== 0xffd8) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return callback(-2);
    }

    const length = view.byteLength;
    let offset = 2;

    while (offset < length) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      if (view.getUint16(offset + 2, false) <= 8) return callback(-1);
      const marker = view.getUint16(offset, false);
      offset += 2;
      if (marker === 0xffe1) {
        if (view.getUint32((offset += 2), false) !== 0x45786966) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-return
          return callback(-1);
        }

        const little = view.getUint16((offset += 6), false) === 0x4949;
        offset += view.getUint32(offset + 4, little);
        const tags = view.getUint16(offset, little);
        offset += 2;
        for (let i = 0; i < tags; i++) {
          if (view.getUint16(offset + i * 12, little) === 0x0112) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return
            return callback(view.getUint16(offset + i * 12 + 8, little));
          }
        }
        // tslint:disable-next-line
      } else if ((marker & 0xff00) !== 0xff00) {
        break;
      } else {
        offset += view.getUint16(offset, false);
      }
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return callback(-1);
  };
  reader.readAsArrayBuffer(file);
}
