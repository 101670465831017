<div class="ad-block-toasts-container">
  @for (toast of toasts; track toast.id) {
    <div
      class="ad-block-toast"
      [@inOut]="animate"
      (@inOut.done)="onAnimationEnd($event)"
      (click)="toastClick(toast)"
    >
      <div class="ad-block-toast__content">
        <div class="ad-block-toast__message-container">
          <span
            class="ad-block-toast__message"
            [innerHTML]="toast.message"
          ></span>
        </div>
      </div>
    </div>
  }
</div>
