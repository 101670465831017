enum BadgeColorEnum {
  NEUTRAL = 'neutral',
  NEUTRAL_LIGHT = 'neutral-light',
  NEUTRAL_LIGHT_DARK = 'neutral-light-dark',

  PRIMARY_ACCENT_LIGHT = 'primary-accent-light',
  PRIMARY_ACCENT = 'primary-accent',
  PRIMARY_ACCENT_DARK = 'primary-accent-dark',
  SECONDARY_ACCENT = 'secondary-accent',

  WHITE = 'white',
  SECONDARY_TEXT = 'secondary-text',

  LIGHT_BLUE = 'light-blue',
  LIGHT_GREEN = 'light-green',
  LIGHT_RED = 'light-red',

  STATE_100 = 'state-100',
  STATE_300 = 'state-300',
  STATE_500 = 'state-500',
  STATE_700 = 'state-700',
  STATE_900 = 'state-900',

  CONTACT_GUEST = 'contact-guest',
  CONTACT_EXCLUSIVE = 'contact-exclusive',
  CONTACT_TENANT = 'contact-tenant',
  CONTACT_OFFLINE = 'contact-offline',
  CONTACT_ANONYMOUS = 'contact-anonymous',
  CONTACT_MANUAL_OR_SELF = 'contact-manual-or-self',

  PROPERTY_BROKER_WORKING = 'property-broker-working',

  DIGITAL_CONTRACT_NECT = 'digital-contract-nect',
  DIGITAL_CONTRACT_BANK = 'digital-contract-bank',
  DIGITAL_CONTRACT_IDENTITY = 'digital-contract-identity',

  TICKET_STATUS_OPEN = 'ticket-status-open',
  TICKET_STATUS_WAITING_FOR_OTHERS = 'ticket-status-waiting-for-others',
  TICKET_STATUS_CLOSED = 'ticket-status-closed',
  TICKET_STATUS_CANCELLED = 'ticket-status-cancelled',
  TICKET_STATUS_IN_PROGRESS = 'ticket-status-in-progress',

  RESIDENT_BLOCKED = 'resident-blocked'
}

enum BadgeSizeEnum {
  SMALL = 'small',
  NORMAL = 'normal'
}

enum BadgeBorderRadiusEnum {
  SMALL = 'small',
  BIG = 'big'
}

export { BadgeColorEnum, BadgeSizeEnum, BadgeBorderRadiusEnum };
