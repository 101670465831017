export enum PropertySearcherDocumentTypes {
  CREDIT_REPORT = 'CREDIT_REPORT',
  INCOME_STATEMENT = 'INCOME_STATEMENT',
  WB_CERTIFICATE = 'WB_CERTIFICATE',
  ADDITIONAL_DOCUMENTS = 'ADDITIONAL_DOCUMENTS',
  IMG = 'IMG',
  PDF = 'PDF',
  SHARED_DOCUMENT = 'SHARED_DOCUMENT',
  MAILATTACHMENTS = 'MAILATTACHMENTS'
}
