export const customerDefinedFieldDefinitions = `
  id
  type
  label
  order
`;

export const customerDefinedDefinitions = `
  customerDefinedFieldDefinitions {
    ${customerDefinedFieldDefinitions}
  }
`;

export const customerDefinedValues = `
  customerDefinedFieldValues {
    definition {
      ${customerDefinedFieldDefinitions}
    }
    value {
      type
      value
    }
  }
`;
