import { Injectable } from '@angular/core';
import { AddonType } from '@ui/shared/models';

@Injectable()
export class PermissionService {
  #permissions: string[] = [];
  #bookedAddons: AddonType[] = [];

  public setPermissions(permissions: string[]) {
    this.#permissions = permissions;
  }

  public getPermissions(): ReadonlyArray<string> {
    return this.#permissions;
  }

  public hasPermission(permission: string) {
    return this.#permissions.indexOf(permission) > -1;
  }

  public setBookedAddons(addons: AddonType[]) {
    this.#bookedAddons = addons;
  }

  public hasAddon(addon: AddonType) {
    return this.#bookedAddons.indexOf(addon) > -1;
  }
}
