import { Attachment } from './attachment.model';
import { ContentSharingInput } from './customer-portal';
import { TargetGroup } from './faq.model';

export interface NewsOverview {
  id?: number;
  uuid?: string;
  data?: NewsData;
  targetGroups?: TargetGroup;
  publishedAt?: string;
  publishedUntil?: string;
  sharing?: ContentSharingInput;
  sendNotification?: boolean;
}

export interface NewsData {
  title?: string;
  text?: string;
  excerpt?: string;
  href?: string;
  image?: Attachment;
  imageUrl?: string;
  showInHeader?: boolean;
}

export interface NewsSearchOptions {
  page: number;
  size: number;
}

export enum NewsNav {
  NEWS = 'news'
}

export interface NewsBulkDeleteInput {
  contentDeleteIds: number[];
}
