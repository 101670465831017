<div class="row">
  <app-card
    class="col-sm-6 col-md-12 col-xl-6 mt-3 mt-sm-0 mb-md-3 mb-xl-0 text-center"
  >
    <img
      src="/assets/images/digitalContract/qes/qes_mobileid_qr_code.svg"
      height="150"
      class="mb-3"
    />
    <p>{{ 'qes.mobileid.scan_qr_code_l' | translate }}</p>
  </app-card>
  <app-card
    class="col-sm-6 col-md-12 col-xl-6 mt-3 mt-sm-0 mb-md-3 mb-xl-0 text-center"
  >
    <img
      src="/assets/images/digitalContract/qes/qes_mobileid_phone.svg"
      height="150"
      class="mb-3"
    />
    <p>{{ 'qes.mobileid.follow_steps_in_app_l' | translate }}</p>
  </app-card>
  <app-card
    class="col-12 col-sm-6 col-md-12 col-xl-6 mt-4 mt-md-0 mt-xl-4 text-center"
  >
    <img
      src="/assets/images/digitalContract/qes/qes_mobileid_check.svg"
      height="150"
      class="mb-3"
    />
    <p>{{ 'qes.mobileid.redirect_l' | translate }}</p>
  </app-card>
</div>
