<app-modal>
  <div class="modal-m" [formGroup]="form">
    <app-modal-content>
      <div class="header-menu">
        <div class="attachment__details">
          <app-simple-text-input
            formControlName="title"
            class="big"
          ></app-simple-text-input>
          @if (attachment?.size) {
            <p class="default-s attachment-details__size">
              {{ attachment?.size | appFileSize }}
            </p>
          }
        </div>
        <div class="attachment__actions">
          <app-button [type]="'light-bordered'" (clickEvent)="rotate()">{{
            'general.rotate_a' | translate
          }}</app-button>
          <div class="remove-attachment-button">
            <svg-icon
              src="/assets/images/icons/icon-bin.svg"
              [applyClass]="true"
              [svgStyle]="{ 'width.px': 15 }"
              class="secondary-svg-color path rect"
              (click)="remove()"
            ></svg-icon>
          </div>
        </div>
      </div>
      <div class="attachment__image-container">
        @if (!loading) {
          <img
            [src]="imgSrc || ''"
            alt=""
            class="attachment__preview"
            [ngClass]="'rotate-' + attachment.rotate"
          />
        }
        @if (loading) {
          <app-loading-spinner class="loading-indicator"></app-loading-spinner>
        }
      </div>
    </app-modal-content>
    <app-modal-footer>
      <div class="modal-footer">
        <app-button [type]="'light-bordered'" (clickEvent)="cancel()">{{
          'CANCEL_A' | translate
        }}</app-button>
        <app-button (clickEvent)="save()">{{
          'general.save_a' | translate
        }}</app-button>
      </div>
    </app-modal-footer>
  </div>
</app-modal>
