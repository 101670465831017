<div class="password">
  @if (showLabel) {
    <div
      class="password-label__container d-flex flex-row justify-content-between"
    >
      <app-form-field-label class="col-5 p0">{{
        label | translate
      }}</app-form-field-label>
    </div>
  }
  <div class="password-input">
    <input
      #input
      appInput
      type="password"
      class="form-control"
      [required]="required"
      [placeholder]="placeholder"
      [formControl]="password"
      [class.password-required__box--visible]="boxVisible"
      (focus)="showBox()"
      (focusout)="hideBox()"
    />
    <span class="password__eye" (click)="showPassword()">
      @if (passwordInput?.nativeElement?.type === 'password') {
        <img
          class="password-show"
          src="/assets/images/icons/icon-eye-black.svg"
        />
      }
      @if (passwordInput?.nativeElement?.type === 'text') {
        <img
          class="password-hide"
          src="/assets/images/icons/icon-eye-closed-black.svg"
        />
      }
    </span>
  </div>
  @if (showRequiredBox) {
    <div
      class="password-required__box mt10"
      [class.d-block]="boxVisible"
      [class.d-none]="!boxVisible"
      [class.password-required__box--has-errors]="
        password?.value.length > 0 && password?.invalid
      "
      [class.password-required__box--is-valid]="password?.valid"
    >
      <div
        class="password-required__box-title d-flex flex-row align-items-center mb10"
      >
        <svg-icon
          src="/assets/images/icons/icon-lock.svg"
          [applyClass]="true"
          [svgStyle]="{ 'height.px': 32.62, 'width.px': 25.77 }"
          class="primary-text-svg-color rect path"
          [class.negative-svg-color]="
            password?.value.length > 0 && password?.invalid
          "
          [class.green-svg-color]="password.valid && password.value.length > 0"
        ></svg-icon>
        <p
          class="m0 pl15"
          [class.password-required__box-title--has-errors]="
            password?.value.length > 0 && password?.invalid
          "
          [class.password-required__box-title--is-valid]="password?.valid"
        >
          {{ 'password.required.info_l' | translate }}
        </p>
      </div>
      <div class="password-required__box-labels">
        <label
          class="col p0 d-flex flex-row align-items-center"
          [ngClass]="password.hasError('minlength') ? 'negative' : 'positive'"
        >
          @if (password.value.length === 0) {
            <span class="password-required__box-empty-check mr7"></span>
          }
          @if (password.value.length > 0) {
            <svg-icon
              [src]="getIcon(password.hasError('minlength'))"
              [applyClass]="true"
              [svgStyle]="{ 'height.px': 12, 'width.px': 12 }"
              class="circle polyline line"
              [class.green-svg-color]="!password.hasError('minlength')"
              [class.negative-svg-color]="password.hasError('minlength')"
            ></svg-icon>
          }
          <span
            [class.password-required__box-label--has-errors]="
              password.value.length > 0 && password.hasError('minlength')
            "
            [class.password-required__box-label--is-valid]="
              password.value.length > 0 && !password.hasError('minlength')
            "
            >{{ 'password.min_8_characters_l' | translate }}</span
          >
        </label>
        <label
          class="col p0 d-flex flex-row align-items-center"
          [ngClass]="password.hasError('hasNumber') ? 'negative' : 'positive'"
        >
          @if (password.value.length === 0) {
            <span class="password-required__box-empty-check mr7"></span>
          }
          @if (password.value.length > 0) {
            <svg-icon
              [src]="getIcon(password.hasError('hasNumber'))"
              [applyClass]="true"
              [svgStyle]="{ 'height.px': 12, 'width.px': 12 }"
              class="circle polyline line"
              [class.green-svg-color]="!password.hasError('hasNumber')"
              [class.negative-svg-color]="password.hasError('hasNumber')"
            ></svg-icon>
          }
          <span
            [class.password-required__box-label--has-errors]="
              password.value.length > 0 && password.hasError('hasNumber')
            "
            [class.password-required__box-label--is-valid]="
              password.value.length > 0 && !password.hasError('hasNumber')
            "
            >{{ 'password.at_least_contain_1_number_l' | translate }}</span
          >
        </label>
        <label
          class="col p0 d-flex flex-row align-items-center"
          [ngClass]="
            password.hasError('hasCapitalCase') &&
            password.hasError('hasSmallCase')
              ? 'negative'
              : 'positive'
          "
        >
          @if (password.value.length === 0) {
            <span class="password-required__box-empty-check mr7"></span>
          }
          @if (password.value.length > 0) {
            <svg-icon
              [src]="
                getIcon(
                  password.hasError('hasCapitalCase') ||
                    password.hasError('hasSmallCase')
                )
              "
              [applyClass]="true"
              [svgStyle]="{ 'height.px': 12, 'width.px': 12 }"
              class="circle polyline line"
              [class.green-svg-color]="
                !password.hasError('hasCapitalCase') &&
                !password.hasError('hasSmallCase')
              "
              [class.negative-svg-color]="
                password.hasError('hasCapitalCase') ||
                password.hasError('hasSmallCase')
              "
            ></svg-icon>
          }
          <span
            [class.password-required__box-label--has-errors]="
              (password.value.length > 0 &&
                password.hasError('hasCapitalCase')) ||
              password.hasError('hasSmallCase')
            "
            [class.password-required__box-label--is-valid]="
              password.value.length > 0 &&
              !password.hasError('hasCapitalCase') &&
              !password.hasError('hasSmallCase')
            "
            >{{ 'password.capital_and_lower_case_char_l' | translate }}</span
          >
        </label>
      </div>
    </div>
  }
</div>
