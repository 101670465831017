export enum QesMethods {
  ROBO_IDENT = 'ROBO_IDENT',
  BANK = 'BANK',
  VIDEO = 'VIDEO',
  E_ID = 'E_ID'
}

export enum QESMethodsBadgeText {
  ROBO_IDENT = 'digital_contract.qes_method_selfie_badge_text_l',
  BANK = 'digital_contract.qes_method_bank_badge_text_l',
  VIDEO = 'digital_contract.qes_method_video_badge_text_l',
  E_ID = 'digital_contract.qes_method_id_badge_text_l'
}
