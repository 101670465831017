import { SalesLegalText } from './sales-legal-text.model';
import { Property } from './property.model';

export type PropertySearcherHistoryEventType =
  | ApplicationEventType
  | ProposalEventType
  | 'APPLIED'
  | 'ACCEPTED'
  | 'VIEWING';

export enum ProposalEventType {
  PROPOSAL_CREATED = 'PROPOSAL_CREATED',
  PROPOSAL_MANUALLY_CREATED = 'PROPOSAL_MANUALLY_CREATED',
  PROPOSAL_DENIED_BY_LL = 'PROPOSAL_DENIED_BY_LL',
  PROPOSAL_OFFERED = 'PROPOSAL_OFFERED',
  PROPOSAL_AUTO_OFFERED = 'PROPOSAL_AUTO_OFFERED',
  PROPOSAL_DENIED_BY_PS = 'PROPOSAL_DENIED_BY_PS',
  PROPOSAL_ACCEPTED = 'PROPOSAL_ACCEPTED',
  PS_NO_REPLY = 'PS_NO_REPLY',
  PDF_PRINTED = 'PDF_PRINTED'
}

export enum ApplicationEventType {
  DK_LEVEL_CHANGED = 'DK_LEVEL_CHANGED',
  ACCEPTED_AS_TENANT = 'ACCEPTED_AS_TENANT',
  ACCEPTED_AS_BUYER = 'ACCEPTED_AS_BUYER',
  APPLICATION_DELETED = 'APPLICATION_DELETED',
  APPLICATION_ACCEPTED = 'APPLICATION_ACCEPTED',
  APPLICATION_ACCEPTED_PRE_TENANT = 'APPLICATION_ACCEPTED_PRE_TENANT',
  APPLICATION_ACCEPTED_EXCLUSIVELY = 'APPLICATION_ACCEPTED_EXCLUSIVELY',
  APPLICATION_AUTO_ACCEPTED = 'APPLICATION_AUTO_ACCEPTED',
  APPLICATION_REJECTED = 'APPLICATION_REJECTED',
  APPLICATION_UNREJECTED = 'APPLICATION_UNREJECTED',
  APPOINTMENT_CHANGED = 'APPOINTMENT_CHANGED',
  APPOINTMENT_CANCELED_BY_PS = 'APPOINTMENT_CANCELED_BY_PS',
  APPOINTMENT_CANCELED_BY_LL = 'APPOINTMENT_CANCELED_BY_LL',
  APPOINTMENT_ACCEPTED = 'APPOINTMENT_ACCEPTED',
  ALL_APPOINTMENTS_DECLINED_BY_PS = 'ALL_APPOINTMENTS_DECLINED_BY_PS',
  PROFILE_DATA_CHANGED = 'PROFILE_DATA_CHANGED',
  INTENT_GIVEN = 'INTENT_GIVEN',
  NO_INTENT_GIVEN = 'NO_INTENT_GIVEN',
  ASKED_FOR_INTENT = 'ASKED_FOR_INTENT',
  ASKED_FOR_SD = 'ASKED_FOR_SD',
  SD_CREATED = 'SD_CREATED',
  SD_UPDATED = 'SD_UPDATED',
  APPLIED_POOL = 'APPLIED_POOL',
  APPLIED_EXTERNAL = 'APPLIED_EXTERNAL',
  APPLIED_BY_LL = 'APPLIED_BY_LL',
  PROFILE_CHANGED = 'PROFILE_CHANGED',
  PS_NO_REPLY = 'PS_NO_REPLY',
  MESSAGE_SENT_BY_PS = 'MESSAGE_SENT_BY_PS',
  MESSAGE_SENT_BY_LL = 'MESSAGE_SENT_BY_LL',
  PDF_PRINTED = 'PDF_PRINTED',
  LEGAL_CONFIRMATION = 'LEGAL_CONFIRMATION',
  EXPOSE_EMAIL_SENT = 'EXPOSE_EMAIL_SENT',
  PROPERTY_RENTAL_PROCESS_RESET = 'PROPERTY_RENTAL_PROCESS_RESET'
}

export interface PropertySearcherHistory {
  type?: PropertySearcherHistoryEventType;
  created?: Date;
  propertyId?: string;
  externalId?: string;
  propertyData?: Property;
  differences?: Differences[];
  fileHistory?: FileHistory;
  portal?: string;
  propertyDeleted?: boolean;
  reason?: RefusalReason;
  dkApprovalLevel?: string;
  editorBean?: EditorBean;
  salesLegalTexts?: SalesLegalText[];
}

export interface RefusalReason {
  reasonType: string;
  otherReason: string;
}

export interface Differences {
  fieldName?: string;
  value?: string;
  prevValue?: string;
}

export interface FileHistory {
  removedFiles: [any];
  changedFiles: [any];
}

export interface EditorBean {
  id?: number;
  name?: string;
  firstName?: string;
  email?: string;
}
