enum ButtonTypeEnum {
  PRIMARY = 'primary',
  PRIMARY_INVERTED = 'primary-inverted',
  SECONDARY = 'secondary',
  POSITIVE = 'positive',
  YELLOW = 'yellow',
  NEGATIVE = 'negative',
  SNIPPET = 'snippet',
  EMPTY_LIGHT = 'empty-light',
  CONTEXT_MENU = 'context-menu',
  LIGHT_BORDERED = 'light-bordered',
  DESCRIPTION = 'description',
  LINK = 'link',
  NEUTRAL = 'neutral'
}

enum ButtonBorderRadiusEnum {
  NONE = 'none',
  SMALL = 'small',
  BIG = 'big'
}

enum ButtonSizeEnum {
  LARGE = 'large',
  MEDIUM = 'medium',
  SMALL = 'small'
}

export { ButtonTypeEnum, ButtonBorderRadiusEnum, ButtonSizeEnum };
