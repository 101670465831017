import { CustomerSettings } from './branding.model';
import { IconTypeEnum } from './enums';
import { AddonType } from './products';

export enum NavigationItemStateEnum {
  WARNING = 'warning'
}

export interface BaseNavigationItem {
  name: string;
  label: string;
  link?: string;
  icon?: IconTypeEnum;
  hide?: boolean;
  hideOnSingleAddonFn?: (addonsType: AddonType[]) => boolean;
  hideOnCustomerSettings?: (settings: CustomerSettings) => boolean;
  redirectLink?: string;
  withPermission?: string;
  notificationCount?: number;
  state?: NavigationItemStateEnum;
  children?: BaseNavigationItem[];
}
