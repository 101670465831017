import { gql } from 'apollo-angular';

export const locationSearchFields = `
  features {
    geometry {
      coordinates
    }
    properties {
      countrycode
      name
      street
      type
      postcode
      city
      district
      country
      locality
      housenumber
      state
    }
  }
`;

export const locationSearchQuery = gql`
  query searchLocations($input: String) {
    searchLocations(input: $input) {
      ${locationSearchFields}
    }
  }
`;

export interface LocationSearchResultEntryProperties {
  name?: string;
  street?: string;
  type?: string;
  locality?: string;
  district?: string;
  country?: string;
  countrycode?: string;
  postcode?: string;
  city?: string;
  housenumber?: string;
  state?: string;
}

export interface LocationSearchResultEntry {
  geometry?: { coordinates: number[] };
  properties?: LocationSearchResultEntryProperties;
  type?: string;
}
export type LocationSearchResultEntries = LocationSearchResultEntry[];

export interface LocationSearchResult {
  searchLocations: {
    features: LocationSearchResultEntries;
  };
}
