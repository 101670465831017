import {
  ChangeDetectionStrategy,
  Component,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { RootQuestionContainerModel, ContactTag } from '@ui/shared/models';
import { FormsModule } from '@angular/forms';
import { CheckComponent } from '../../form/controls/check/check.component';
import { HierarchicalQuestionsDisplayRootComponent } from '../hierarchical-questions-display-root/hierarchical-questions-display-root.component';

@Component({
  selector: 'app-available-hierarchical-question',
  templateUrl: './available-hierarchical-question.component.html',
  styleUrls: ['./available-hierarchical-question.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    HierarchicalQuestionsDisplayRootComponent,
    CheckComponent,
    FormsModule
  ]
})
export class AvailableHierarchicalQuestionComponent {
  @Input() hierarchicalQuestion: RootQuestionContainerModel;
  @Input() tags: ContactTag[];
  @Output() selectedChange = new EventEmitter<boolean>();
  public selected = false;

  public onSelectedChange(value: boolean) {
    this.selectedChange.emit(value);
  }
}
