export const RegexTypes = {
  TEXT: '[a-zA-ZäÄöÖüÜ\\ßàáéè\\-\\.\\, ]*',
  PROPERTY_NAME: '[a-zA-Z0-9äÄöÖüÜß\\-,.:() ]*',
  PHONE: '[0-9\\+\\- /()]+',
  STREET: '[a-zA-ZäÄöÖüÜD0-9\\ß\\-\\.\\, ]*',
  STREETNUMBER: '^[^\\-][0-9a-zA-Z\\-\\.\\,]*',
  ZIPCODE: '[a-zA-Z0-9 ]*',
  CITY: '[a-zA-ZäÄöÖüÜ\\ß\\- ]*',
  REGION: '[a-zA-ZäÄöÖüÜ\\ß\\- \\.\\,\\(\\) ]*',
  COUNTRY: '[a-zA-ZäÄöÖüÜ\\ß\\-  ]*',
  MULTIPLYFACTOR: '[0-9]+(.[0-9]+)?',
  NUMBERS: '[0-9]*',
  DOMAIN: '[a-zA-Z0-9 ]*',
  TIME: '^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$',
  DIGITAL_CONTRACT_FIELD_LABEL: '[a-zA-Z0-9]*',
  URL: /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=]+/i,
  INTERNATIONAL_PHONE:
    /\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)|(\d*)/g,
  INTERNATIONAL_PHONE_CONTRACTS:
    // With https://immomio.atlassian.net/browse/ART-8291 the BE will use the same RegEx
    '\\+(9[976]\\d|8[987530]\\d|6[987]\\d|5[90]\\d|42\\d|3[875]\\d|2[98654321]\\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)(?!0+)\\d{1,14}$',
  SPECIAL_CHARACTERS: /[^\w\s]/gi,
  ONLY_TWO_DECIMALS: /^\d+\.?\d{0,2}$/,
  ALPHA_NUMERICS: '[A-Z0-9äÄöÖüÜß]*',
  FILE_AND_FOLDER_NAMES: '[\\w\\.\\-\\_\\ \\öäüÖÄÜß]+'
};
