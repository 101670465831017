import { Injectable, inject } from '@angular/core';
import {
  NgbModal,
  NgbModalRef,
  NgbModalOptions
} from '@ng-bootstrap/ng-bootstrap';

import { Observable, Subject } from 'rxjs';
import { take } from 'rxjs/operators';

import { FreshworksService } from 'libs/infrastructure/browser';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { RejectionDialogComponent } from './rejection-dialog/rejection-dialog.component';

export interface ModalOptions extends NgbModalOptions {
  data?: { [key: string]: any };
}

export class ModalRef<_T, R = any> extends NgbModalRef {
  onClose: () => Observable<R>;
  onDismiss: () => Observable<any>;
}

@Injectable()
export class ModalService {
  private ngbModal = inject(NgbModal);
  private freshworksService = inject(FreshworksService, { optional: true });

  open<T, R = any>(content: any, options: ModalOptions = {}) {
    const freshworksWidgetsVisibilityOnOpening =
      this.freshworksService?.getWidgetsVisibility();

    // hide Freshworks widgets if they are visible at the moment:
    if (freshworksWidgetsVisibilityOnOpening)
      this.freshworksService?.hideWidgets();

    const onCloseSubject = new Subject<R>();
    const onDismissSubject = new Subject();

    const modalRef = this.ngbModal.open(content, options) as ModalRef<T>;

    if (modalRef && modalRef.componentInstance && options.data) {
      Object.assign(modalRef.componentInstance, options.data);
    }

    modalRef.onClose = () => onCloseSubject.asObservable().pipe(take(1));
    modalRef.onDismiss = () => onDismissSubject.asObservable().pipe(take(1));

    modalRef.result
      .then((result: R) => {
        onCloseSubject.next(result);
        onCloseSubject.complete();
      })
      .catch((reason: any) => {
        onDismissSubject.next(reason);
        onDismissSubject.complete();
      })
      .finally(() => {
        // restore visibility of Freshworks widgets if they were visible when opening the modal:
        if (freshworksWidgetsVisibilityOnOpening)
          this.freshworksService?.showWidgets();
      });

    return modalRef;
  }

  /***
   * Fix for Angular Bug related to creating dynamic views in lifecycle hooks.
   * Doing that causes ExpressionHasBeenChangedAfterItWasChecked errors.
   * Using setTimeout is simple and acceptable workaround for this.
   * See https://github.com/angular/angular/issues/15634 for more details.
   * Use it only in lifecycle hooks (ngOnInit, ngOnChanges and so on)
   * For every normal case, use normal .open() method
   */
  promisifyOpen<T, R = any>(
    content: any,
    options: ModalOptions = {}
  ): Promise<ModalRef<T, R>> {
    return new Promise(resolve =>
      setTimeout(() => resolve(this.open(content, options)))
    );
  }

  openConfirmation(options: ModalOptions = {}) {
    return this.open<ConfirmationDialogComponent>(
      ConfirmationDialogComponent,
      options
    );
  }

  openAcknowledgement(options: ModalOptions = {}) {
    return this.openConfirmation({
      ...options,
      data: {
        ...options.data,
        acknowledge: true
      }
    });
  }

  openRejection(options: ModalOptions = {}) {
    return this.open<RejectionDialogComponent>(
      RejectionDialogComponent,
      options
    );
  }
}
