export enum ChartType {
  BAR = 'bar',
  LINE = 'line',
  PIE = 'pie'
}

export enum ChartConfigType {
  SINGLE = 'SET',
  SERIES = 'SERIES'
}

export enum ChartInterval {
  HOUR = 'h',
  DAY = 'd',
  WEEK = 'w',
  MONTH = 'M',
  QUARTER = 'q',
  YEAR = 'y'
}
