import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

import { distinctUntilChanged, filter, take } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';

import { MessengerState } from 'libs/infrastructure/base-state/messenger/messenger.reducer';
import { getUnreadMessageCount } from 'libs/infrastructure/base-state/messenger/messenger.selector';

@Injectable({
  providedIn: 'root'
})
export class MessengerNotificationService {
  private route = inject(ActivatedRoute);
  private router = inject(Router);
  private store = inject<Store<MessengerState>>(Store);
  private translateService = inject(TranslateService);
  private documentRef = inject(DOCUMENT);

  private _unreadCount: BehaviorSubject<number>;

  public get unreadCount$(): Observable<number> {
    return this._unreadCount.asObservable().pipe(distinctUntilChanged());
  }

  public init(): void {
    this._unreadCount = new BehaviorSubject<number>(0);

    this.router.events
      .pipe(filter(event => !!(event instanceof NavigationEnd)))
      .subscribe(() => {
        this.setUnreadCountInTitle(this._unreadCount.getValue());
      });

    this.store
      .select(getUnreadMessageCount)
      .pipe(distinctUntilChanged())
      .subscribe(count => {
        this._unreadCount.next(count);
        this.setUnreadCountInTitle(count);
      });
  }

  public complete(): void {
    this._unreadCount.complete();
  }

  private setUnreadCountInTitle(count: number) {
    let route = this.route.firstChild;
    let child = route;
    while (child) {
      if (child.firstChild) {
        child = child.firstChild;
        route = child;
      } else {
        child = null;
      }
    }
    route.data.pipe(take(1)).subscribe(data => {
      const currentRoute = (data.title as string) || 'navigation.main_page';
      const currentRouteKey = this.translateService.instant(
        currentRoute
      ) as string;
      if (count === 0) {
        this.documentRef.title = currentRouteKey;
      } else if (count === 1) {
        this.documentRef.title = `${currentRouteKey} ${<string>(
          this.translateService.instant('messenger.page_title_w_count_single_l')
        )}`;
      } else if (count > 1) {
        this.documentRef.title = `${currentRouteKey} ${<string>(
          this.translateService.instant(
            'messenger.page_title_w_count_multi_l',
            { count }
          )
        )}`;
      }
    });
  }
}
