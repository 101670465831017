import { Action } from '@ngrx/store';

import { Constants } from '@ui/shared/models';

export const FETCH_CONSTANTS = '[Constants] Fetch Constants';
export const FETCH_CONSTANTS_SUCCESS = '[Constants] Fetch Constants Success';
export const FETCH_CONSTANTS_FAIL = '[Constants] Fetch Constants Fails';

export class FetchConstants implements Action {
  readonly type = FETCH_CONSTANTS;
}

export class FetchConstantsSuccess implements Action {
  readonly type = FETCH_CONSTANTS_SUCCESS;
  constructor(public data: Constants) {}
}

export class FetchConstantsFail implements Action {
  readonly type = FETCH_CONSTANTS_FAIL;
  constructor(public error: any) {}
}

export type ConstantsActions =
  | FetchConstants
  | FetchConstantsFail
  | FetchConstantsSuccess;
