import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  inject
} from '@angular/core';
import { CustomerLocation, SearchCity } from '@ui/shared/models';
import {
  LoadCityDistricts,
  SearchForCities,
  SearchProfilesState,
  getSearchCities,
  isCitySearching
} from 'libs/infrastructure/base-state';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import {
  ControlContainer,
  FormGroup,
  FormsModule,
  ReactiveFormsModule
} from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgClass, AsyncPipe } from '@angular/common';
import { AppInputDirective } from '../../form/controls/input/input.directive';
import { AutoCompleteFieldComponent } from '../../../molecules/form/controls/auto-complete-field/auto-complete-field.component';
import { FormFieldLabelComponent } from '../../form/form-field/form-field-label/form-field-label.component';
import { FormFieldComponent } from '../../form/form-field/form-field.component';

@Component({
  selector: 'app-city-autocomplete',
  templateUrl: './city-autocomplete.component.html',
  styleUrls: ['./city-autocomplete.component.scss'],
  standalone: true,
  imports: [
    FormFieldComponent,
    FormFieldLabelComponent,
    AutoCompleteFieldComponent,
    AppInputDirective,
    FormsModule,
    ReactiveFormsModule,
    NgClass,
    AsyncPipe,
    TranslateModule
  ]
})
export class CityAutocompleteComponent implements OnInit {
  private store = inject<Store<SearchProfilesState>>(Store);
  private controlContainer = inject(ControlContainer);

  @Input() disableCityEdit: boolean;
  @Input() country: CustomerLocation = CustomerLocation.DE;
  @Output() cityChange = new EventEmitter();
  public cityGroup: FormGroup;
  public citiesSelector$: Observable<SearchCity[]>;
  public loading = false;
  public isLoading$: Observable<boolean>;

  ngOnInit(): void {
    this.cityGroup = <FormGroup>this.controlContainer.control;
    this.citiesSelector$ = this.store.select(getSearchCities);
    this.isLoading$ = this.store.select(isCitySearching);

    this.patchValues();
  }

  public onSelect(value: SearchCity) {
    this.cityGroup.patchValue(value, { emitEvent: false });
    this.cityChange.emit();
    this.store.dispatch(new LoadCityDistricts(value.id));
  }

  public onNewInput(term: string) {
    this.store.dispatch(new SearchForCities(this.country, term));
  }

  private patchValues() {
    const { name, id } = this.cityGroup.value;

    if (name) {
      this.store.dispatch(new SearchForCities(this.country, name));
      this.store.dispatch(new LoadCityDistricts(id));
    }
  }
}
