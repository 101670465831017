import { Address } from '../address.model';
import { UviEventConsumptionType } from './uvi-event-consumption-type.enum';
import { UviEventSendingProcessType } from './uvi-event-sending-process-type.enum';

export interface UviEvent {
  id: string;
  billingUnitMscId: string;
  residentialUnitMscId: string;
  masterDataContractExternalId: string;
  periodBegin: Date;
  periodEnd: Date;
  consumptionServiceTypes: UviEventConsumptionType[];
  sendingProcessType: UviEventSendingProcessType;
  sendingDate: Date;
  address: Address;
  contactFullName: string;
  uviDataDelivered: boolean;
  errorMessage: string;
}

export interface UviEventLog {
  id: string;
  icon: string;
  eventType: string;
  timestamp: Date;
}

export interface UviControlStat {
  total: number;
  totalViaApp: number;
  totalViaLetter: number;
  deliveredViaApp: number;
  deliveredViaLetter: number;
  errorPdfGeneration: number;
  errorLetterSending: number;
  noDataAvailable: number;
}

export enum UviConsumptionDelivery {
  DELIVERED_VIA_APP = 'DELIVERED_VIA_APP',
  DELIVERED_VIA_LETTER = 'DELIVERED_VIA_LETTER',
  ERROR_PDF_GENERATION = 'ERROR_PDF_GENERATION',
  ERROR_LETTER_SENDING = 'ERROR_LETTER_SENDING',
  NO_DATA_AVAILABLE = 'NO_DATA_AVAILABLE'
}
