import { Component, Input } from '@angular/core';
import { NgClass, NgTemplateOutlet } from '@angular/common';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss'],
  standalone: true,
  imports: [RouterLink, NgClass, NgTemplateOutlet]
})
export class MessageComponent {
  @Input() messageType: string;
  @Input() redirectLink: string;
}
