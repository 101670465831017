import { Action } from '@ngrx/store';

export const SHOW_INFO = '[Notification] Show Info';
export const SHOW_ERROR = '[Notification] Show Error';
export const SHOW_ACKNOWLEDGE = '[Notification] Show Acknowledge';

export class ShowInfo implements Action {
  readonly type = SHOW_INFO;

  constructor(
    public message: string,
    public interpolateParams?: Record<string, unknown>
  ) {}
}

export class ShowError implements Action {
  readonly type = SHOW_ERROR;

  constructor(
    public message: string,
    public interpolateParams?: Record<string, unknown>
  ) {}
}

export class ShowInfoDialog implements Action {
  readonly type = SHOW_ACKNOWLEDGE;

  constructor(
    public titleMessage: string,
    public message: string,
    public okButtonMessage: string
  ) {}
}

export type notificationActions = ShowInfo | ShowError | ShowInfoDialog;
