<div class="list-controls d-flex flex-md-row flex-column col-12 p0">
  <app-pagination
    [pageSize]="pageSize"
    [collectionSize]="totalCount"
    [page]="page"
    (pageChange)="onPageChange($event)"
  >
  </app-pagination>
  @if (totalCount > 10 && !hidePageSizeSelection) {
    <div class="size-selection ms-md-auto">
      <app-page-size-selection
        [pageSize]="pageSize"
        [selectableSizes]="selectableSizes"
        (sizeChange)="onSizeChange($event)"
      >
      </app-page-size-selection>
    </div>
  }
</div>
