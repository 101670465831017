import { Injectable } from '@angular/core';
import { District, LandlordDistrictGroupBean } from '@ui/shared/models';

@Injectable()
export class DistrictHandlingService {
  // to add count for districts, so it's easier to loop on them in the templated
  private _districtIndex = 0;

  public cityPacketViewPayload(packets: LandlordDistrictGroupBean[]) {
    return packets.map(packet => {
      const citiesMap = new Map<string, any[]>();
      const citiesDistrictsMap = new Map<string, District[]>();
      const { id, name, districts, children, country } = packet;
      this.groupChildrenByCity(children, citiesMap, citiesDistrictsMap);
      this.groupDistrictsByCity(districts, citiesMap, citiesDistrictsMap);

      return {
        id,
        name,
        citiesMap,
        citiesDistrictsMap,
        country
      };
    });
  }

  private groupDistrictsByCity(
    districts: District[],
    map: Map<string, any[]>,
    citiesDistrictsMap: Map<string, District[]>
  ) {
    districts.forEach(district => {
      const { cityName, cityId, name, id } = district;
      if (map.has(cityName)) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        map.get(cityName)[0].districts.push({ id, name });
        citiesDistrictsMap.get(cityId).push({ id, name });
      } else {
        map.set(cityName, [
          {
            cityId,
            districts: [{ id, name }],
            districtIndex: this._districtIndex++
          }
        ]);
        citiesDistrictsMap.set(cityId, [{ id, name }]);
      }
    });
  }

  private groupChildrenByCity(
    children: LandlordDistrictGroupBean[],
    map: Map<string, any[]>,
    citiesDistrictsMap: Map<string, District[]>
  ) {
    children.forEach(child => {
      const cityName = child.districts[0].cityName;
      const cityId = child.districts[0].cityId;

      const districts = child.districts.map(({ id, name }) => ({ id, name }));
      if (map.has(cityName)) {
        map.get(cityName).push({
          cityId,
          groupedDistrictsName: child.name,
          districts,
          districtIndex: this._districtIndex++
        });
        citiesDistrictsMap
          .get(cityId)
          .push(...districts.map(({ id, name }) => ({ id, name })));
      } else {
        map.set(cityName, [
          {
            cityId,
            groupedDistrictsName: child.name,
            districts,
            districtIndex: this._districtIndex++
          }
        ]);
        citiesDistrictsMap.set(cityId, [
          ...districts.map(({ id, name }) => ({ id, name }))
        ]);
      }
    });
  }
}
