import moment from 'moment';

export function getDaysRange(
  start: Date,
  end: Date,
  format: string,
  locale: string
) {
  const arr = [];
  let dt = moment(start);
  while (dt.toDate() <= end) {
    arr.push(moment(dt).locale(locale).format(format));
    dt = moment(dt).add(1, 'd');
  }
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return arr;
}
