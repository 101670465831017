import { Component, forwardRef, OnInit, inject } from '@angular/core';
import {
  ControlValueAccessor,
  FormBuilder,
  FormGroup,
  NG_VALUE_ACCESSOR,
  FormsModule,
  ReactiveFormsModule
} from '@angular/forms';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { UserSettingsConversationConfig } from 'libs/components/legacy/messenger/model/interface';
import { TranslateModule } from '@ngx-translate/core';
import { CheckboxComponent } from '../../../../form/controls/checkbox/checkbox.component';
import { FormFieldComponent } from '../../../../form/form-field/form-field.component';

@UntilDestroy()
@Component({
  selector: 'app-conversation-general-config-form',
  templateUrl: './conversation-general-config-form.component.html',
  styleUrls: ['./conversation-general-config-form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ConversationGeneralConfigFormComponent),
      multi: true
    }
  ],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    FormFieldComponent,
    CheckboxComponent,
    TranslateModule
  ]
})
export class ConversationGeneralConfigFormComponent
  implements OnInit, ControlValueAccessor
{
  private fb = inject(FormBuilder);

  public form: FormGroup;
  public value: UserSettingsConversationConfig;

  private onChange = (value: unknown) => value;
  private onTouch = () => null;

  public ngOnInit(): void {
    this.form = this.fb.group({
      notifyWhenReceivingMessage: [false]
    });

    this.form.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((value: UserSettingsConversationConfig) => {
        this.value = value;
        this.onChange(this.value);
        this.onTouch();
      });
  }

  public registerOnChange(fn) {
    this.onChange = fn;
  }

  public registerOnTouched(fn) {
    this.onTouch = fn;
  }

  public writeValue(value: UserSettingsConversationConfig) {
    this.form.patchValue(value);
    this.value = value;
  }
}
