export interface SSOParams {
  id_token: string;
  access_token: string;
  state: string;
  error?: string;
  [key: string]: any;
}

export interface IDToken {
  nonce: string;
  [key: string]: any;
}

export enum SocialLoginProvider {
  GOOGLE = 'GOOGLE',
  APPLE = 'APPLE'
}
