<form [formGroup]="form" class="hierarchical-questions card">
  <div class="hierarchical-questions__sub-questions card-body">
    @switch (hierarchicalQuestion.data.type) {
      @case ('SELECTION') {
        <app-hierarchical-question-selection
          [formControl]="answerIdsControl"
          [hierarchicalQuestion]="hierarchicalQuestion"
          [currentLanguage]="currentLanguage"
          [defaultLanguage]="defaultLanguage"
          (ngModelChange)="onAnswerChanged($event)"
        ></app-hierarchical-question-selection>
      }
      @default {
        <app-hierarchical-question-input
          [disabled]="isProcessing"
          [hierarchicalQuestion]="hierarchicalQuestion"
          [formControl]="responseControl"
          [currentLanguage]="currentLanguage"
          [defaultLanguage]="defaultLanguage"
          (ngModelChange)="onAnswerChanged($event)"
        ></app-hierarchical-question-input>
      }
    }
    @if (hierarchicalQuestion.data.idType === 'MEMBERSHIP') {
      <div class="dk-info__container">
        <app-badge
          [color]="BadgeColorEnum.PRIMARY_ACCENT"
          [icon]="IconTypeEnum.Info"
        >
          {{ 'general.data_privacy_notice_l' | translate }}
        </app-badge>
        <p class="mt10 mb0">
          {{ 'question.membership.privacy_notice_l' | translate }}
        </p>
      </div>
    }
    @if (hierarchicalQuestion.data.commentAllowed) {
      <div formGroupName="userResponse">
        <app-form-field>
          <app-form-field-label>
            {{
              hierarchicalQuestion.data.commentHint[currentLanguage] ||
                hierarchicalQuestion.data.commentHint[defaultLanguage]
            }}
          </app-form-field-label>
          <textarea
            appInput
            count="true"
            maxValue="1000"
            formControlName="comment"
            class="form-control"
          ></textarea>
        </app-form-field>
      </div>
    }
  </div>
</form>
