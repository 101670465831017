import { BadgeColorEnum } from 'libs/components/atoms/badge/badge.enum';

export enum PropertyProposalStatus {
  DENIEDBYPS = 'DENIEDBYPS',
  DENIEDBYLL = 'DENIEDBYLL',
  PROSPECT = 'PROSPECT',
  OFFERED = 'OFFERED',
  AUTO_OFFERED = 'AUTO_OFFERED',
  NO_REPLY = 'NO_REPLY'
}

type PropertyProposalStatusColorMapping = {
  [key in PropertyProposalStatus]: BadgeColorEnum;
};
export const PROPERTY_PROPOSAL_STATUS_COLORS: PropertyProposalStatusColorMapping =
  {
    [PropertyProposalStatus.DENIEDBYPS]: BadgeColorEnum.NEUTRAL,
    [PropertyProposalStatus.DENIEDBYLL]: BadgeColorEnum.STATE_900,
    [PropertyProposalStatus.PROSPECT]: BadgeColorEnum.STATE_100,
    [PropertyProposalStatus.OFFERED]: BadgeColorEnum.STATE_500,
    [PropertyProposalStatus.AUTO_OFFERED]: BadgeColorEnum.STATE_500,
    [PropertyProposalStatus.NO_REPLY]: BadgeColorEnum.STATE_500
  };

export enum ActivePropertyProposalStatus {
  PROSPECT = 'PROSPECT',
  OFFERED = 'OFFERED',
  DENIEDBYPS = 'DENIEDBYPS'
}

export enum PropertyApplicationStatus {
  ACCEPTED = 'ACCEPTED',
  ALL_APPOINTMENTS_DECLINED = 'ALL_APPOINTMENTS_DECLINED',
  ASK_FOR_INTENT = 'ASK_FOR_INTENT',
  ATTENDING_TO_VIEWING = 'ATTENDING_TO_VIEWING',
  AUTO_ACCEPTED = 'AUTO_ACCEPTED',
  AUTO_REJECTED = 'AUTO_REJECTED',
  DECLARE_INTENT = 'DECLARE_INTENT',
  DELETED = 'DELETED',
  DELETED_BY_PS = 'DELETED_BY_PS',
  DENIEDBYLL = 'DENIEDBYLL',
  DELETED_RECENTLY = 'DELETED_RECENTLY',
  INTENT = 'INTENT',
  INVITED_TO_VIEWING = 'INVITED_TO_VIEWING',
  NEW_MATCH = 'NEW_MATCH',
  NO_INTENT = 'NO_INTENT',
  NO_OPEN_SLOTS = 'NO_OPEN_SLOTS',
  NO_REPLY = 'NO_REPLY',
  PRE_TENANT_VIEWING = 'PRE_TENANT_VIEWING',
  REJECTED = 'REJECTED',
  SET_INTENT = 'SET_INTENT',
  TENANT = 'TENANT',
  UNANSWERED = 'UNANSWERED',
  WAITING_FOR_LANDLORD = 'WAITING_FOR_LANDLORD'
}

export const filteredOutFromList = [
  'DELETED_BY_PS',
  'DELETED_RECENTLY',
  'DELETED'
];

export enum PropertySearcherFilterTypes {
  WBS = 'WBS',
  URGENCY_CERTIFICATE = 'URGENCY_CERTIFICATE',
  URGENCY_CONFIRMATION = 'URGENCY_CONFIRMATION',
  SIXTH_PARAGRAPH_SECOND_SUPPORT_PATH = 'SIXTH_PARAGRAPH_SECOND_SUPPORT_PATH',
  WBS_100 = 'WBS_100',
  WBS_140 = 'WBS_140',
  WBS_160 = 'WBS_160',
  WBS_180 = 'WBS_180',
  WBS_240 = 'WBS_240',
  WBS_SPECIAL_HOUSING_NEEDS = 'WBS_SPECIAL_HOUSING_NEEDS',
  ANONYMOUS = 'ANONYMOUS',
  GUEST = 'GUEST',
  MAIN = 'MAIN',
  OFFLINE = 'OFFLINE',
  LEVEL2INSPECT = 'LEVEL2INSPECT',
  UNVERIFIED = 'UNVERIFIED',
  LEVEL1VERIFIED = 'LEVEL1VERIFIED',
  LEVEL2VERIFIED = 'LEVEL2VERIFIED',
  PROCESSED = 'PROCESSED',
  UNPROCESSED = 'UNPROCESSED'
}

export enum AnswerOverviewType {
  ANSWERED_POSITIVE = 'ANSWERED_POSITIVE',
  ANSWERED_MIXED = 'ANSWERED_MIXED',
  ANSWERED_NEGATIVE = 'ANSWERED_NEGATIVE',
  NOT_ANSWERED = 'NOT_ANSWERED',
  ANSWERS_MISSING = 'ANSWERS_MISSING'
}

export enum PropertySearchersTypes {
  APPLICANTS = 'APPLICANTS',
  PROSPECTS = 'PROSPECTS'
}

export enum PropertySearcherType {
  APPLICATION = 'APPLICATION',
  PROPOSAL = 'PROPOSAL'
}
