import { Component, Input } from '@angular/core';

import { AvailableLanguageCodesEnum } from '@ui/shared/models';
import {
  ImageSources,
  ImageSrcSet,
  ImageBorderRadiusEnum
} from 'libs/components/atoms/image/image.model';
import { UpperCasePipe } from '@angular/common';
import { ImageComponent } from '../image.component';

@Component({
  selector: 'app-locale-flag',
  templateUrl: './locale-flag.component.html',
  styleUrls: ['./locale-flag.component.scss'],
  standalone: true,
  imports: [ImageComponent, UpperCasePipe]
})
export class LocaleFlagComponent {
  @Input() languageCode = AvailableLanguageCodesEnum.DE;

  public ImageBorderRadiusEnum = ImageBorderRadiusEnum;

  public getFlagImagePath(fileName: string): string {
    return `/assets/images/flags/${fileName}.png`;
  }

  public getFlagImageSources(flagCode: string): ImageSources {
    const srcSet: ImageSrcSet = {};
    for (let i = 0; i < 3; i++) {
      const pixelDensity = i + 1;
      srcSet[`x${pixelDensity}`] = `${this.getFlagImagePath(
        `${flagCode}${i !== 0 ? `@${pixelDensity}x` : ''}`
      )}`;
    }
    return [{ srcSet }];
  }
}
