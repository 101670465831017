import { Injectable, InjectionToken, inject } from '@angular/core';

import { AuthToken } from 'libs/infrastructure/keycloak-authentication-module/model';
import { LocalStorageService } from '../storage/local-storage.service';

export const TOKEN_STORAGE_KEY = new InjectionToken<string>(
  'TOKEN_STORAGE_KEY'
);

@Injectable()
export class AuthTokenService {
  private storageService = inject(LocalStorageService);
  private tokenStorageKey = inject(TOKEN_STORAGE_KEY);

  public setToken(token: AuthToken) {
    return this.storageService.setItem(this.tokenStorageKey, token);
  }

  public getToken(): AuthToken {
    return (
      this.storageService.getItem(this.tokenStorageKey) || ({} as AuthToken)
    );
  }

  public removeToken() {
    return this.storageService.removeItem(this.tokenStorageKey);
  }
}
