<div class="hint-container">
  <ng-template #hintContent>
    <ng-content></ng-content>
  </ng-template>
  <i
    [ngbTooltip]="hintContent"
    [placement]="[placement, 'auto']"
    [container]="container"
  >
    <img src="/assets/images/icons/icon-question.svg" class="question-mark" />
  </i>
</div>
