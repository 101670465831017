export interface UserPermissionRole {
  id: string;
  name: string;
  userPermissionRoleType: UserPermissionRoleType;
  userPermissions: UserPermission[];
}

export interface UserPermissionListFilter {
  page?: number;
  size?: number;
  sortBy?: UserRoleListSortByFields;
  sortOrder?: 'ASC' | 'DESC';
}

export interface UPCReturnType {
  permissionRoles: {
    nodes: UserPermissionRole[];
  };
}

export enum UserPermissionRoleType {
  SYSTEM = 'SYSTEM',
  CUSTOMER = 'CUSTOMER'
}

export enum UserPermission {
  DASHBOARD = 'DASHBOARD',
  OBJECTS = 'OBJECTS',
  PROJECTS = 'PROJECTS',
  APPOINTMENTS = 'APPOINTMENTS',
  CONTRACTS = 'CONTRACTS',
  SCHUFA = 'SCHUFA',
  CUSTOMER_PORTAL = 'CUSTOMER_PORTAL',
  VMP_CONTACTS = 'VMP_CONTACTS',
  RESIDENT_CONTACTS = 'RESIDENT_CONTACTS',
  MESSENGER = 'MESSENGER',
  REPORTING = 'REPORTING',
  ADMINISTRATION = 'ADMINISTRATION',
  UVI_CONTROL = 'UVI_CONTROL'
}

export enum UserRoleListSortByFields {
  NAME = 'NAME',
  USER_PERMISSION_ROLE_TYPE = 'USER_PERMISSION_ROLE_TYPE',
  UPDATED = 'UPDATED',
  CREATED = 'CREATED'
}
