export interface FaqOverview {
  id?: number;
  uuid?: string;
  data?: FaqData;
  targetGroups?: TargetGroup;
  children?: FaqOverview;
  parentId?: number;
}

export interface TargetGroup {
  type?: string;
  administrationUnitId?: number;
}

export interface FaqData {
  title?: string;
  text?: string;
  href?: string;
}

export interface FaqInput {
  data?: FaqData;
  targetGroups?: TargetGroup[];
  parentId?: number;
}

export enum FaqNav {
  FAQS = 'faqs',
  FAQ = 'faq'
}

export interface ContentBulkDeleteInput {
  contentDeleteIds: number[];
}
