export interface CustomerFilter {
  page?: number;
  searchTerm?: string;
}

export interface ContentSharingInput {
  shareWithAll?: boolean;
  shareWith?: ContentSharingItemInput[];
}

export interface ContentSharingItem {
  id: string;
  displayName: string;
  combinedExternalId: string;
}

export interface ContentSharingItemInput extends ContentSharingItem {
  shareType: ShareType;
}

export enum ShareType {
  ADMINISTRATION_UNIT = 'ADMINISTRATION_UNIT',
  CONTACT = 'CONTACT'
}

export enum CustomerPortalTab {
  TICKETS = 'TICKETS',
  ANNOUNCEMENTS = 'ANNOUNCEMENTS',
  OFFERS = 'OFFERS',
  NEWS = 'NEWS',
  FAQS = 'FAQS',
  DOCUMENTS = 'DOCUMENTS',
  WOBAU_FANCARD = 'WOBAU_FANCARD',
  CSV_EXPORT = 'EXPORT',
  CSV_EXPORT_DOCUMENTS_DATA = 'EXPORT_DOCUMENTS_DATA',
  ABG_FAQS = 'ABG_FAQS',
  SERVICES = 'SERVICES'
}

export enum CustomerPortalTitle {
  TICKETS = 'customer-portal.page.tasks',
  ANNOUNCEMENTS = 'customer-portal.page.announcements',
  OFFERS = 'customer-portal.page.offers',
  NEWS = 'customer-portal.page.news',
  FAQS = 'customer-portal.page.faqs',
  DOCUMENTS = 'customer-portal.page.documents',
  WOBAU_FANCARD = 'customer-portal.page.wobau_fancard',
  CSV_EXPORT = 'customer-portal.page.export',
  CSV_EXPORT_DOCUMENTS_DATA = 'customer-portal.page.documents_export',
  ABG_FAQS = 'customer-portal.page.abg_faqs',
  SERVICES = 'customer-portal.page.services'
}
