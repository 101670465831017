export enum CustomQuestionType {
  BOOLEAN = 'boolean',
  SELECT = 'select',
  MULTISELECT = 'multiselect',
  RANGE = 'range'
}
export enum CustomQuestionSubType {
  RANGE_DATE = 'range_date',
  RANGE_VALUE = 'range_value'
}

export enum HierarchicalQuestionType {
  SELECTION = 'SELECTION',
  INPUT_TEXT = 'INPUT_TEXT',
  INPUT_DATE = 'INPUT_DATE',
  RANGE_NUMBER = 'RANGE_NUMBER',
  RANGE_DATE = 'RANGE_DATE'
}

export enum HierarchicalQuestionDisplayType {
  BOOLEAN = 'BOOLEAN',
  SELECT = 'SELECT',
  MULTISELECT = 'MULTISELECT',
  RANGE = 'RANGE'
}

export enum HierarchicalQuestionAnswerId {
  BASE = 'BASE',
  INPUT = 'INPUT',
  BIRTHDATE = 'BIRTHDATE',
  SELECTION = 'SELECTION',
  RANGED = 'RANGED',
  RANGED_MAIN = 'RANGED_MAIN',
  RANGED_UPPER_BOUND = 'RANGED_UPPER_BOUND',
  RANGED_LOWER_BOUND = 'RANGED_LOWER_BOUND',
  MEMBERSHIP_TRUE = 'MEMBERSHIP_TRUE',
  MEMBERSHIP_FALSE = 'MEMBERSHIP_FALSE',
  INTENDING_INCREASE_FLAT_SIZE = 'INTENDING_INCREASE_FLAT_SIZE',
  INTENDING_DECREASE_FLAT_SIZE = 'INTENDING_DECREASE_FLAT_SIZE',
  INTENDING_PROGRAM = 'INTENDING_PROGRAM'
}

export enum DefaultQuestionType {
  MEMBERSHIP = 'MEMBERSHIP'
}

export enum HierarchicalQuestionIdType {
  BIRTHDATE = 'BIRTHDATE'
}

export enum HierarchicalQuestionScoreType {
  UPPER_BOUND = 'UPPER_BOUND',
  LOWER_BOUND = 'LOWER_BOUND',
  MIDDLE = 'MIDDLE'
}

export enum DefaultQuestionAnswerIdType {
  BASE = 'BASE',
  INPUT = 'INPUT',
  BIRTHDATE = 'BIRTHDATE',
  RANGED = 'RANGED',
  RANGED_MAIN = 'RANGED_MAIN',
  RANGED_UPPER_BOUND = 'RANGED_UPPER_BOUND',
  RANGED_LOWER_BOUND = 'RANGED_LOWER_BOUND',
  SELECTION = 'SELECTION',
  MEMBERSHIP_TRUE = 'MEMBERSHIP_TRUE',
  MEMBERSHIP_FALSE = 'MEMBERSHIP_FALSE',
  INTENDING_INCREASE_FLAT_SIZE = 'INTENDING_INCREASE_FLAT_SIZE',
  INTENDING_DECREASE_FLAT_SIZE = 'INTENDING_DECREASE_FLAT_SIZE',
  INTENDING_PROGRAM = 'INTENDING_PROGRAM'
}

export enum ScoreType {
  NOT_ANSWERED = 'NOT_ANSWERED',
  ANSWERS_MISSING = 'ANSWERS_MISSING',
  ANSWERED_POSITIVE = 'ANSWERED_POSITIVE',
  ANSWERED_NEGATIVE = 'ANSWERED_NEGATIVE',
  ANSWERED_MIXED = 'ANSWERED_MIXED'
}

export enum HierarchicalQuestionActionType {
  TAG = 'TAG'
}

export enum EditTaskType {
  CREATE = 'CREATE',
  EDIT = 'EDIT',
  DELETE = 'DELETE'
}
