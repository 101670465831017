import { Component, EventEmitter, Input, Output } from '@angular/core';

import { LandlordUser, PropertySearcherUser } from '@ui/shared/models';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ElevationDirective } from 'libs/directives';
import { ImageSizePipe } from '../../../pipes/image-size.pipe';
import { AvatarComponent } from '../avatar/avatar.component';
import { LogoComponent } from '../logo/logo.component';
import { ButtonComponent } from '../../atoms/button/button.component';

@UntilDestroy()
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  standalone: true,
  imports: [
    ButtonComponent,
    LogoComponent,
    AvatarComponent,
    ImageSizePipe,
    ElevationDirective
  ]
})
export class HeaderComponent {
  @Input() user: LandlordUser | PropertySearcherUser;
  @Input() navigationOpen: boolean;
  @Input() profileSettingsOpen: boolean;
  @Output() navigationToggleClick = new EventEmitter<Event>();
  @Output() profileSettingsToggleClick = new EventEmitter<Event>();

  public onNavigationToggleClick(event: Event) {
    this.navigationToggleClick.emit(event);
  }

  public onProfileSettingsToggleClick(event: Event) {
    this.profileSettingsToggleClick.emit(event);
  }
}
