<app-modal>
  <div class="modal-xs">
    <app-modal-content>
      <div class="modal-body confirmation-dialog__body">
        <p class="title-m confirmation-dialog__title">
          {{ titleMessage | translate: { value: titleValue } }}
        </p>
        @if (!innerHTML) {
          <p class="default-s confirmation-dialog__message">
            {{ message | translate: { value: messageValue } }}
          </p>
        } @else {
          <p
            class="default-s confirmation-dialog__message"
            [innerHTML]="message | translate: { value: messageValue }"
          ></p>
        }
        @if (isProspectOfflineSales) {
          <p class="default-s confirmation-dialog__message mt-4">
            {{
              'offline_sales_prospect.confirmation.terms_and_condition.message'
                | translate
            }}
          </p>
        }
      </div>
    </app-modal-content>

    <app-modal-footer>
      <div class="modal-footer confirmation-dialog__footer">
        @if (!acknowledge) {
          <app-button (clickEvent)="cancelAction()" [type]="'light-bordered'">{{
            cancelButtonMessage | translate
          }}</app-button>
        }
        <app-button (clickEvent)="okAction()">{{
          okButtonMessage | translate
        }}</app-button>
      </div>
    </app-modal-footer>
  </div>
</app-modal>
