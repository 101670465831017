import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomQuestion, RootQuestion } from '@ui/shared/models';
import { TranslateModule } from '@ngx-translate/core';
import { HierarchicalRootQuestionFormComponent } from '../hierarchical-root-question-form/hierarchical-root-question-form.component';
import { CustomQuestionsFormComponent } from '../custom-questions-form/custom-questions-form.component';

@Component({
  selector: 'app-custom-questions-modal-content',
  templateUrl: './custom-questions-modal-content.component.html',
  styleUrls: ['./custom-questions-modal-content.component.scss'],
  standalone: true,
  imports: [
    CustomQuestionsFormComponent,
    FormsModule,
    ReactiveFormsModule,
    HierarchicalRootQuestionFormComponent,
    TranslateModule
  ]
})
export class CustomQuestionsModalContentComponent {
  @Input() control = new FormControl();
  @Input() hierarchicalRootQuestionControl = new FormControl();
  @Input() isProcessing: boolean;
  @Input() hierarchicalRootQuestions: RootQuestion[];
  @Input() customQuestions: CustomQuestion[];
  @Input() hierarchicalQuestionErrorMessage: string;
  @Output() formValidityChange = new EventEmitter<boolean>();
  @Output() hierarchicalFormValidityChange = new EventEmitter<boolean>();

  public onFormValidityChange(value: boolean) {
    this.formValidityChange.emit(value);
  }

  public onHierarchicalFormValidityChange(value: boolean) {
    this.hierarchicalFormValidityChange.emit(value);
  }
}
