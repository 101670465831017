<div class="attachment_msg">
  <app-attachments-list
    [showDownload]="showDownload"
    [disableDownload]="disableDownload"
    [blockDownload]="blockDownload"
    [isDocument]="isDocument"
    [attachments]="attachments"
    (download)="onDownload($event)"
    (preview)="onPreview($event)"
  ></app-attachments-list>
</div>
