import { Constructor, Attachment } from '@ui/shared/models';

import { isString } from 'libs/utils';

export interface AttachmentComponentHelper {
  attachment: Attachment;
  name: string;
  fileType: string;
  fileIconName: string;
  hasFile: boolean;
}

export type AttachmentComponentHelperCtor =
  Constructor<AttachmentComponentHelper>;

export function mixinAttachmentHelper<TBase extends Constructor>(
  Base: TBase
): AttachmentComponentHelperCtor & TBase {
  return class extends Base {
    public attachment: Attachment;

    public get name() {
      return (
        this.attachment.title ||
        this.attachment.name ||
        (this.attachment.identifier &&
          `${this.attachment.identifier}.${this.attachment.extension}`) ||
        'attachment.default_name_placeholder_l'
      );
    }

    get fileType() {
      if (this.attachment && isString(this.attachment.extension)) {
        return this.attachment.extension.toUpperCase();
      }

      const type =
        (this.attachment && this.attachment.type) ||
        (this.attachment && this.attachment.file && this.attachment.file.type);

      const typeParts = (type || '').split('/');

      return typeParts[typeParts.length - 1].toUpperCase();
    }

    get fileIconName() {
      return `fa-file-${this.fileType.toLowerCase()}-o`;
    }

    get hasFile() {
      return (
        this.attachment.file instanceof Blob || this.attachment instanceof Blob
      );
    }

    constructor(...args: any[]) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      super(...args);
    }
  };
}
