import {
  AvailableLanguageCodesEnum,
  Dictionary,
  Language
} from '@ui/shared/models';

export const languages: Dictionary<Language> = {
  de: {
    code: AvailableLanguageCodesEnum.DE,
    name: 'german',
    extendedLocale: 'de_DE',
    label: 'general.german',
    nativeLabel: 'Deutsch'
  },
  en: {
    code: AvailableLanguageCodesEnum.EN,
    name: 'english',
    extendedLocale: 'en_US',
    label: 'general.english',
    nativeLabel: 'English'
  }
};
