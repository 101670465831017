export enum HomepageModuleType {
  CARD = 'CARD',
  LIST = 'LIST'
}

export interface HomepageModule {
  id?: string;
  name?: string;
  type?: HomepageModuleType;
  externalToken?: string;
  url?: string;
  iframeSnippet?: string;
  svgCount?: number;
}

export interface HomepageModules {
  homepageModules: {
    nodes: HomepageModule[];
  };
}
