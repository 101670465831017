import { UserPermission } from 'landlord/screens/account/manage/models';

export const isPropertyManager = (permissions: UserPermission[]) =>
  permissions.length === 1 && permissions.includes(UserPermission.APPOINTMENTS);

export const isAgent = (permissions: UserPermission[]) =>
  permissions.length > 1 &&
  !permissions.includes(UserPermission.ADMINISTRATION);

export const isAdmin = (permissions: UserPermission[]) =>
  permissions.includes(UserPermission.ADMINISTRATION);
