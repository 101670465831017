import {
  NgbDateParserFormatter,
  NgbDateStruct
} from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment';
import { Injectable } from '@angular/core';

@Injectable()
export class DateParserFormatter extends NgbDateParserFormatter {
  private dateFormat = 'DD.MM.YYYY';

  parse(dateString: string): NgbDateStruct {
    const date = moment(dateString, this.dateFormat);

    return dateString && date.isValid()
      ? { year: date.year(), month: date.month() + 1, day: date.date() }
      : null;
  }

  format(date: NgbDateStruct): string {
    return date
      ? moment(
          `${date.day}.${date.month}.${date.year}`,
          this.dateFormat
        ).format(this.dateFormat)
      : null;
  }
}
