export function onScroll(data: Event): boolean {
  const target = (data?.target as HTMLElement)?.scrollTop
    ? (data?.target as HTMLElement)
    : ((data?.target as HTMLDocument)?.scrollingElement as HTMLElement);

  if (target) {
    const pos = target.scrollTop;
    const threshold = 15;
    return pos >= threshold;
  }
}
