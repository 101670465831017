import { isEqual, cloneDeep } from 'lodash-es';

export const removeArrayDuplicates = <T>(array: T[], key: string) => {
  const set = new Set();
  return array.filter(obj => {
    if (!set.has(obj[key])) {
      set.add(obj[key]);
      return true;
    }
    return false;
  });
};

export const removeArrayDuplicatesPrimitive = <T>(array: T[]) => {
  return [...new Set(array)];
};

export const getArrayDuplicates = <T>(array: T[], key: string) => {
  const set = new Set();
  return array.filter(obj => {
    if (!set.has(obj[key])) {
      set.add(obj[key]);
      return false;
    }
    return true;
  });
};

/**
 * The arrays are now cloned, because read only arrays could not be sorted
 * @param array
 * @param array2
 * @param ignoreOrder
 */
export const arraysEqual = <T>(array: T[], array2: T[], ignoreOrder = true) => {
  return ignoreOrder
    ? isEqual([...array].sort(), [...array2].sort())
    : isEqual([...array], [...array2]);
};

export const areObjectsEqual = <T>(object: T, object2: T) => {
  return isEqual(object, object2);
};

export const arrayHasPrimitiveValues = (array: unknown[]): boolean => {
  if (!array) {
    throw new Error('please pass an array');
  }

  return !(array[0] instanceof Object);
};

export const deepCloneArray = <T>(array: T[]) => {
  return cloneDeep(array);
};
