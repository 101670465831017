export enum HousingPermissionType {
  // Bundesweit
  WBS = 'WBS',
  SIXTH_PARAGRAPH_SECOND_SUPPORT_PATH = 'SIXTH_PARAGRAPH_SECOND_SUPPORT_PATH',

  // Hamburg
  URGENCY_CERTIFICATE = 'URGENCY_CERTIFICATE', // shared with Berlin
  URGENCY_CONFIRMATION = 'URGENCY_CONFIRMATION', // shared with Berlin
  HAMBURG_SIXTEENTH_PARAGRAPH = 'HAMBURG_SIXTEENTH_PARAGRAPH',

  // Berlin
  WBS_100 = 'WBS_100',
  WBS_140 = 'WBS_140',
  WBS_160 = 'WBS_160',
  WBS_180 = 'WBS_180',
  WBS_240 = 'WBS_240',
  WBS_SPECIAL_HOUSING_NEEDS = 'WBS_SPECIAL_HOUSING_NEEDS',

  // Bayern
  BAVARIA_EOF_INCOME_GROUP_1 = 'BAVARIA_EOF_INCOME_GROUP_1',
  BAVARIA_EOF_INCOME_GROUP_2 = 'BAVARIA_EOF_INCOME_GROUP_2',
  BAVARIA_EOF_INCOME_GROUP_3 = 'BAVARIA_EOF_INCOME_GROUP_3',

  // Schleswig-Holstein
  SCHLESWIG_HOLSTEIN_EIGHTH_PARAGRAPH_STANDARD = 'SCHLESWIG_HOLSTEIN_EIGHTH_PARAGRAPH_STANDARD',
  SCHLESWIG_HOLSTEIN_EIGHTH_PARAGRAPH_PLUS_20 = 'SCHLESWIG_HOLSTEIN_EIGHTH_PARAGRAPH_PLUS_20',
  SCHLESWIG_HOLSTEIN_EIGHTH_PARAGRAPH_PLUS_40 = 'SCHLESWIG_HOLSTEIN_EIGHTH_PARAGRAPH_PLUS_40',
  SCHLESWIG_HOLSTEIN_EIGHTY_EIGHTH_PARAGRAPH_D_2 = 'SCHLESWIG_HOLSTEIN_EIGHTY_EIGHTH_PARAGRAPH_D_2',

  // Baden-Württemberg
  BADEN_WUERTTEMBERG_WOSU_EMERGENCY_CERTIFICATE = 'BADEN_WUERTTEMBERG_WOSU_EMERGENCY_CERTIFICATE'
}
