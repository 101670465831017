import { Theme, ThemePropertyKeys } from 'libs/infrastructure/theme/symbols';
import { ThemeUrls } from 'libs/infrastructure/theme/theme-urls';
import {
  getColorPrimaryAccentLight,
  getColorSecondaryAccentLight,
  getColorDark,
  getColorLight,
  getColorUltraLight,
  getHexColorWithAlpha,
  getContrastingBWColor,
  adjustColorLightness
} from 'libs/infrastructure/theme/theme.utils';

const themeColorPrimaryAccent = '#3486EF';
const themeColorPrimaryAccentLight = getColorPrimaryAccentLight(
  themeColorPrimaryAccent
);
const themeColorPrimaryAccentDark = getColorDark(themeColorPrimaryAccent);

const themeColorSecondaryAccent = '#193F70';
const themeColorSecondaryAccentLight = getColorSecondaryAccentLight(
  themeColorSecondaryAccent
);
const themeColorSecondaryAccentLighter = adjustColorLightness(
  themeColorSecondaryAccent,
  0.93
);

const themeColorSecondaryAccentDark = getColorDark(themeColorSecondaryAccent);

const themeColorNeutral = '#CED2D5';
const themeColorState100 = '#5FBF89';
const themeColorState200 = '#59a2d3';
const themeColorState500 = '#f7c976';
// the following color should actually be #ED5D57 instead,
// but #DC6965 has less saturation and therefore looks better when being adjusted in terms of lightness
const themeColorState900 = '#DC6965';

export const ImmomioTheme: Theme = {
  name: ThemeUrls.IMMOMIO,
  logo: {
    url: '/assets/images/logos/logo-immomio-main.svg',
    title: 'Immomio'
  },
  iTPLogo: {
    url: '/assets/images/logos/Immomio_Logo_Kooperation.svg',
    title: 'Immomio Kooperation'
  },

  favicon: { url: '/assets/images/favicons/favicon.svg' },
  faviconBig: { url: '/assets/images/favicons/favicon-big.png' },
  pageTitle: 'Immomio',
  properties: {
    [ThemePropertyKeys.THEME_COLOR_APP_BACKGROUND]: '#F5F8FF',
    [ThemePropertyKeys.THEME_COLOR_CARD_BACKGROUND]: '#FFFFFF',
    [ThemePropertyKeys.THEME_COLOR_MENU_BACKGROUND]: '#050B13',

    [ThemePropertyKeys.THEME_COLOR_PRIMARY_ACCENT]: themeColorPrimaryAccent,
    [ThemePropertyKeys.THEME_COLOR_PRIMARY_ACCENT_BW_CONTRAST]:
      getContrastingBWColor(themeColorPrimaryAccent),
    [ThemePropertyKeys.THEME_COLOR_PRIMARY_ACCENT_LIGHT]:
      themeColorPrimaryAccentLight,
    [ThemePropertyKeys.THEME_COLOR_PRIMARY_ACCENT_LIGHT_BW_CONTRAST]:
      getContrastingBWColor(themeColorPrimaryAccentLight),
    [ThemePropertyKeys.THEME_COLOR_PRIMARY_ACCENT_DARK]:
      themeColorPrimaryAccentDark,
    [ThemePropertyKeys.THEME_COLOR_PRIMARY_ACCENT_DARK_BW_CONTRAST]:
      getContrastingBWColor(themeColorPrimaryAccentDark),

    [ThemePropertyKeys.THEME_COLOR_SECONDARY_ACCENT]: themeColorSecondaryAccent,
    [ThemePropertyKeys.THEME_COLOR_SECONDARY_ACCENT_BW_CONTRAST]:
      getContrastingBWColor(themeColorSecondaryAccent),
    [ThemePropertyKeys.THEME_COLOR_SECONDARY_ACCENT_LIGHT]:
      themeColorSecondaryAccentLight,
    [ThemePropertyKeys.THEME_COLOR_SECONDARY_ACCENT_LIGHT_BW_CONTRAST]:
      getContrastingBWColor(themeColorSecondaryAccentLight),
    [ThemePropertyKeys.THEME_COLOR_SECONDARY_ACCENT_LIGHTER]:
      themeColorSecondaryAccentLighter,
    [ThemePropertyKeys.THEME_COLOR_SECONDARY_ACCENT_LIGHTER_BW_CONTRAST]:
      getContrastingBWColor(themeColorSecondaryAccentLighter),
    [ThemePropertyKeys.THEME_COLOR_SECONDARY_ACCENT_DARK]:
      themeColorSecondaryAccentDark,
    [ThemePropertyKeys.THEME_COLOR_SECONDARY_ACCENT_DARK_BW_CONTRAST]:
      getContrastingBWColor(themeColorSecondaryAccentDark),

    [ThemePropertyKeys.THEME_COLOR_PRIMARY_TEXT]: '#050B13',
    [ThemePropertyKeys.THEME_COLOR_SECONDARY_TEXT]: '#7C8592',
    [ThemePropertyKeys.THEME_COLOR_BUTTON_TEXT]: '#FFFFFF',

    [ThemePropertyKeys.THEME_COLOR_STATE_100_ULTRA_LIGHT]:
      getColorUltraLight(themeColorState100),
    [ThemePropertyKeys.THEME_COLOR_STATE_100_LIGHT]:
      getColorLight(themeColorState100),
    [ThemePropertyKeys.THEME_COLOR_STATE_100]: themeColorState100,
    [ThemePropertyKeys.THEME_COLOR_STATE_100_DARK]:
      getColorDark(themeColorState100),

    [ThemePropertyKeys.THEME_COLOR_STATE_200_ULTRA_LIGHT]:
      getColorUltraLight(themeColorState200),
    [ThemePropertyKeys.THEME_COLOR_STATE_200_LIGHT]:
      getColorLight(themeColorState200),
    [ThemePropertyKeys.THEME_COLOR_STATE_200]: themeColorState200,
    [ThemePropertyKeys.THEME_COLOR_STATE_200_DARK]:
      getColorDark(themeColorState200),

    [ThemePropertyKeys.THEME_COLOR_STATE_300]: '#80E5AC',

    [ThemePropertyKeys.THEME_COLOR_STATE_500_ULTRA_LIGHT]:
      getColorUltraLight(themeColorState500),
    [ThemePropertyKeys.THEME_COLOR_STATE_500_LIGHT]:
      getColorLight(themeColorState500),
    [ThemePropertyKeys.THEME_COLOR_STATE_500]: themeColorState500,
    [ThemePropertyKeys.THEME_COLOR_STATE_500_DARK]:
      getColorDark(themeColorState500),

    [ThemePropertyKeys.THEME_COLOR_STATE_700]: '#E07F5D',
    [ThemePropertyKeys.THEME_COLOR_STATE_900_ULTRA_LIGHT]:
      getColorUltraLight(themeColorState900),
    [ThemePropertyKeys.THEME_COLOR_STATE_900_LIGHT]:
      getColorLight(themeColorState900),
    [ThemePropertyKeys.THEME_COLOR_STATE_900]: themeColorState900,
    [ThemePropertyKeys.THEME_COLOR_STATE_900_DARK]:
      getColorDark(themeColorState900),

    [ThemePropertyKeys.THEME_COLOR_NEUTRAL]: themeColorNeutral,
    [ThemePropertyKeys.THEME_COLOR_NEUTRAL_ALPHA_50]: getHexColorWithAlpha(
      themeColorNeutral,
      0.5
    )
  }
};
