import { APP_BASE_HREF, registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import {
  inject,
  InjectionToken,
  LOCALE_ID,
  ModuleWithProviders,
  NgModule
} from '@angular/core';
import { provideEffects } from '@ngrx/effects';
import { provideState, Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';

import { Apollo } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';

import { MessengerFacade } from 'libs/components/legacy/messenger/services';

import { UserConversionService } from '../services/user-conversion.service';
import { AuthTokenService, TOKEN_STORAGE_KEY } from './authentication';
import { BaseState, effects, reducers } from './base-state';
import {
  DOCUMENT_REF,
  NavigatorService,
  PageTitleService,
  ScrollerDirective,
  ScrollerService,
  ViewportSizeDetectorService,
  WINDOW_REF
} from './browser';
import { ClipboardService } from './clipboard';
import { CONSTANTS_LOADER, ConstantsGuard } from './constants';
import { DownloadService } from './download/download.service';
import { DownloadUrlGeneratorService } from './download-url-generator';
import { FileUploadService } from './file/file-upload.service';
import {
  ApolloCacheConfig,
  ApolloFactory,
  ApolloHttpConfig
} from './gql-client';
import {
  DEFAULT_TRANSLATIONS_PATH,
  I18nService,
  languages,
  LOCALE_CONFIG,
  TranslationsGuard
} from './i18n';
import { InfrastructureConfig } from './infrastructure-config.model';
import { INFRASTRUCTURE_CONFIG } from './infrastructure-config.token';
import { NotificationService } from './notification';
import {
  PermissionGuard,
  PermissionResolver,
  PermissionService
} from './permission';
import {
  RoutingDetectorService,
  SearchParamsSerializerService
} from './router';
import {
  CookieService,
  InMemoryStorageService,
  LocalStorageService,
  SessionStorageService
} from './storage';

const APOLLO_HTTP_CONFIG = new InjectionToken<ApolloHttpConfig>(
  'APOLLO_HTTP_CONFIG'
);
const APOLLO_HTTP_RESIDENT_CONFIG = new InjectionToken<ApolloHttpConfig>(
  'APOLLO_HTTP_RESIDENT_CONFIG'
);

const APOLLO_CACHE_CONFIG = new InjectionToken<ApolloCacheConfig>(
  'APOLLO_CACHE_CONFIG'
);

function apolloHttpConfigFactory(config: InfrastructureConfig) {
  return {
    uri: config.environment.graphql_server_uri,
    wsUri: config.environment.graphql_ws_server_uri,
    authHeaderName: config.environment.auth_header_name
  };
}

function apolloHttpResidentConfigFactory(config: InfrastructureConfig) {
  return {
    uri: config.environment.graphql_resident_server_uri,
    authHeaderName: config.environment.auth_header_name
  };
}

function getCurrentLocale(
  config: InfrastructureConfig,
  windowRef: Window = window
) {
  const availableCodes = config.languages.availableLanguagesByCode;
  const defaultCode = config.languages.defaultLanguageCode;

  const hrefLocale = windowRef.location.pathname.split('/')[1];

  return (
    availableCodes.find(code => code === hrefLocale.toString().toLowerCase()) ||
    windowRef.localStorage.getItem(config.storageKeys.localeKey) ||
    defaultCode
  );
}

function localeConfigFactory(
  config: InfrastructureConfig,
  windowRef: Window = window
) {
  return {
    localeKey: config.storageKeys.localeKey,
    currentLocale: getCurrentLocale(config, windowRef)
  };
}

function localeIdFactory(
  config: InfrastructureConfig,
  windowRef: Window = window
) {
  return getCurrentLocale(config, windowRef);
}

function baseHrefFactory(
  config: InfrastructureConfig,
  windowRef: Window = window
) {
  return '/' + getCurrentLocale(config, windowRef);
}

function defaultTranslationsPathFactory(config: InfrastructureConfig) {
  return config.defaultTranslationsPath || '/assets/i18n/translations.json';
}

function windowFactory() {
  return window;
}

function documentFactory() {
  return document;
}

@NgModule({
  exports: [ScrollerDirective],
  imports: [TranslateModule, ScrollerDirective],
  providers: [
    MessengerFacade,
    provideState('infrastructure', reducers),
    provideEffects(effects)
  ]
})
export class InfrastructureModule {
  public static forRoot(
    config: InfrastructureConfig
  ): ModuleWithProviders<InfrastructureModule> {
    /**
     * The reason for using factories is that AoT compiler will complain about any
     * computations here. Even simple assignment will throw an error, therefore
     * we need to postpone creating values for those tokens and let Angular call
     * their factories.
     */
    return {
      ngModule: InfrastructureModule,
      providers: [
        { provide: INFRASTRUCTURE_CONFIG, useValue: config },
        { provide: TOKEN_STORAGE_KEY, useValue: config.storageKeys.tokenKey },
        {
          provide: APOLLO_HTTP_CONFIG,
          useFactory: apolloHttpConfigFactory,
          deps: [INFRASTRUCTURE_CONFIG]
        },
        {
          provide: APOLLO_HTTP_RESIDENT_CONFIG,
          useFactory: apolloHttpResidentConfigFactory,
          deps: [INFRASTRUCTURE_CONFIG]
        },
        { provide: APOLLO_CACHE_CONFIG, useValue: config.apolloCacheIds || {} },
        { provide: WINDOW_REF, useFactory: windowFactory },
        {
          provide: LOCALE_CONFIG,
          useFactory: localeConfigFactory,
          deps: [INFRASTRUCTURE_CONFIG, WINDOW_REF]
        },
        {
          provide: LOCALE_ID,
          useFactory: localeIdFactory,
          deps: [INFRASTRUCTURE_CONFIG, WINDOW_REF]
        },
        {
          provide: APP_BASE_HREF,
          useFactory: baseHrefFactory,
          deps: [INFRASTRUCTURE_CONFIG, WINDOW_REF]
        },
        { provide: DOCUMENT_REF, useFactory: documentFactory },
        {
          provide: DEFAULT_TRANSLATIONS_PATH,
          useFactory: defaultTranslationsPathFactory,
          deps: [INFRASTRUCTURE_CONFIG]
        },
        Apollo,
        HttpLink,
        LocalStorageService,
        SessionStorageService,
        InMemoryStorageService,
        AuthTokenService,
        ViewportSizeDetectorService,
        PageTitleService,
        I18nService,
        TranslationsGuard,
        DownloadUrlGeneratorService,
        DownloadService,
        ConstantsGuard,
        RoutingDetectorService,
        SearchParamsSerializerService,
        NotificationService,
        ClipboardService,
        FileUploadService,
        PermissionService,
        PermissionGuard,
        PermissionResolver,
        ScrollerService,
        CookieService,
        NavigatorService,
        UserConversionService
      ]
    };
  }

  constructor() {
    const apollo = inject(Apollo);
    const httpLink = inject(HttpLink);
    const authTokenService = inject(AuthTokenService);
    const store = inject<Store<BaseState>>(Store);
    const localStorage = inject(LocalStorageService);
    const localeConfig = inject(LOCALE_CONFIG);
    const httpConfig = inject(APOLLO_HTTP_CONFIG);
    const httpResidentConfig = inject(APOLLO_HTTP_RESIDENT_CONFIG);
    const constantsLoader = inject(CONSTANTS_LOADER, { optional: true });

    if (!constantsLoader) {
      console.warn(
        'ConstantsLoader is not defined! App will not be able to load constants.'
      );
    }

    registerLocaleData(localeDe, languages.de.code);

    const apolloConfig = ApolloFactory.getApolloConfig(
      httpLink,
      authTokenService,
      store,
      httpConfig,
      localStorage
    );

    const residentConfig = ApolloFactory.getApolloResidentConfig(
      httpLink,
      authTokenService,
      store,
      httpResidentConfig
    );

    apollo.create(apolloConfig);
    apollo.create(residentConfig, 'resident');
    localStorage.setItem(localeConfig.localeKey, localeConfig.currentLocale);
  }
}
