import { Action } from '@ngrx/store';
import { NavigationExtras, NavigationEnd, Params } from '@angular/router';

export const GO = '[Router] Go';
export const BACK = '[Router] Back';
export const FORWARD = '[Router] Forward';
export const UPDATE_QUERY_PARAMS = '[Router] Update Query Params';

export const ROUTE_CHANGE = '[Router] Route Change';

export class Go implements Action {
  readonly type = GO;

  constructor(
    public payload: {
      path: any[];
      query?: unknown;
      extras?: NavigationExtras;
    }
  ) {}
}

export class Back implements Action {
  readonly type = BACK;
}

export class Forward implements Action {
  readonly type = FORWARD;
}

export class UpdateQueryParams implements Action {
  readonly type = UPDATE_QUERY_PARAMS;

  constructor(public params: Params) {}
}

export class RouteChange implements Action {
  readonly type = ROUTE_CHANGE;

  constructor(
    public previous: NavigationEnd,
    public current: NavigationEnd
  ) {}
}

export type Actions = Go | Back | Forward | UpdateQueryParams | RouteChange;
