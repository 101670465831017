export enum Icons {
  Add = 'add',
  AirCondition = 'air-condition',
  Archive = 'archive',
  AreaSize = 'area-size',
  Armchair = 'armchair',
  ArrowDown = 'arrow-down',
  ArrowDropdown = 'arrow-dropdown',
  ArrowLeft = 'arrow-left',
  ArrowRight = 'arrow-right',
  ArrowUp = 'arrow-up',
  Assistant = 'assistant',
  Attention = 'attention',
  Attic = 'attic',
  Balcony = 'balcony',
  BarChart = 'bar-chart',
  BarrierFree = 'barrier-free',
  Basement = 'basement',
  Bathtub = 'bathtub',
  Bed = 'bed',
  Bell = 'bell',
  Bidet = 'bidet',
  Bike = 'bike',
  Blinders = 'blinders',
  Bling = 'bling',
  Board = 'board',
  Bubbletub = 'bubbletub',
  Building = 'building',
  Bulb = 'bulb',
  BurgerMenuExpand = 'burger-menu-expand',
  BurgerMenuReduce = 'burger-menu-reduce',
  BurgerMenu = 'burger-menu',
  Bus = 'bus',
  Calendar = 'calendar',
  Celebrations = 'celebrations',
  ChartBar = 'chart-bar',
  ChartLine = 'chart-line',
  ChartPie = 'chart-pie',
  ChatTemplate = 'chat-template',
  CheckFramed = 'check-framed',
  Check = 'check',
  ChevronDown = 'chevron-down',
  ChevronLeft = 'chevron-left',
  ChevronRight = 'chevron-right',
  ChevronUp = 'chevron-up',
  Clipboard = 'clipboard',
  Clock = 'clock',
  Close = 'close',
  Coin = 'coin',
  Coins = 'coins',
  Commentary = 'commentary',
  Compass = 'compass',
  Computer = 'computer',
  Contacts = 'contacts',
  ContextMenuOutlined = 'context-menu-outlined',
  Context = 'context',
  Contracts = 'contracts',
  Cookie = 'cookie',
  Copy = 'copy',
  Crane = 'crane',
  Crosshair = 'crosshair',
  Crown = 'crown',
  Dashboard = 'dashboard',
  Data = 'data',
  Delete = 'delete',
  DetachedLinkFilled = 'detached-link-filled',
  Discover = 'discover',
  Document = 'document',
  Door = 'door',
  Download = 'download',
  Edit = 'edit',
  Editor = 'editor',
  Elevator = 'elevator',
  EnergyCertificate = 'energy-certificate',
  Expand = 'expand',
  Export = 'export',
  Extinguisher = 'extinguisher',
  EyeClosed = 'eye-closed',
  EyeOpen = 'eye-open',
  Feedback = 'feedback',
  Filter = 'filter',
  Fire = 'fire',
  Fireplace = 'fireplace',
  Flashes = 'flashes',
  Floor = 'floor',
  FolderOutlined = 'folder-outlined',
  Folder = 'folder',
  Food = 'food',
  Furniture = 'furniture',
  Gallery = 'gallery',
  Garage = 'garage',
  Garden = 'garden',
  GasContainer = 'gas-container',
  Globe = 'globe',
  HalfRooms = 'half-rooms',
  Hammer = 'hammer',
  Handicapped = 'handicapped',
  HealthCare = 'health-care',
  HeatingCosts = 'heating-costs',
  Heating = 'heating',
  Height = 'height',
  HighVoltage = 'high-voltage',
  Highway = 'highway',
  History = 'history',
  Hospital = 'hospital',
  HouseDoor = 'house-door',
  HouseType = 'house-type',
  Info = 'info',
  Intercom = 'intercom',
  InternetCable = 'internet-cable',
  Key = 'key',
  Keyboard = 'keyboard',
  Kitchen = 'kitchen',
  LaundryRoom = 'laundry-room',
  Laundry = 'laundry',
  Law = 'law',
  Level = 'level',
  Lift = 'lift',
  LightBulb = 'light-bulb',
  LightningOutlined = 'lightning-outlined',
  Lightning = 'lightning',
  LinesChart = 'lines-chart',
  Link = 'link',
  Linkedin = 'linkedin',
  List = 'list',
  LocationOutlined = 'location-outlined',
  Location = 'location',
  LockClosed = 'lock-closed',
  LockOpen = 'lock-open',
  Loggia = 'loggia',
  Logout = 'logout',
  Mail = 'mail',
  Marketing = 'marketing',
  Masonry = 'masonry',
  Messenger = 'messenger',
  Minus = 'minus',
  Mobile = 'mobile',
  MoneyBag = 'money-bag',
  MoneyPig = 'money-pig',
  Monument = 'monument',
  MoreMoney = 'more-money',
  MovingBox = 'moving-box',
  News = 'news',
  Notes = 'notes',
  Object = 'object',
  Offer = 'offer',
  Office = 'office',
  PaintBrush = 'paint-brush',
  PaintRoller = 'paint-roller',
  PaperClip = 'paper-clip',
  PaperPlane = 'paper-plane',
  Parking = 'parking',
  PartyCannon = 'party-cannon',
  Pdf = 'pdf',
  Pet = 'pet',
  Phone = 'phone',
  PieChart = 'pie-chart',
  PiggyBank = 'piggy-bank',
  Pipe = 'pipe',
  Plane = 'plane',
  Planning = 'planning',
  Plug = 'plug',
  Plus = 'plus',
  Pool = 'pool',
  Publish = 'publish',
  Question = 'question',
  Redirect = 'redirect',
  Refresh = 'refresh',
  Remove = 'remove',
  Reporting = 'reporting',
  Reset = 'reset',
  Restaurants = 'restaurants',
  Restoration = 'restoration',
  Rooms = 'rooms',
  Save = 'save',
  School = 'school',
  SearchProfile = 'search-profile',
  Search = 'search',
  SelfDisclosure = 'self-disclosure',
  Senior = 'senior',
  Settings = 'settings',
  Share = 'share',
  Shower = 'shower',
  Sorting = 'sorting',
  SpinnerDots = 'spinner-dots',
  SpinnerFilled = 'spinner-filled',
  Stairs = 'stairs',
  Stairwell = 'stairwell',
  StarEmpty = 'star-empty',
  StarFilled = 'star-filled',
  StarHalf = 'star-half',
  StoreRoom = 'store-room',
  Store = 'store',
  Sun = 'sun',
  Terrace = 'terrace',
  TextEditor = 'text-editor',
  Ticket = 'ticket',
  Toilet = 'toilet',
  Train = 'train',
  Tv = 'tv',
  Upload = 'upload',
  Urinal = 'urinal',
  User = 'user',
  UtilityRoom = 'utility-room',
  UviHeating = 'uvi_heating',
  Warning = 'warning',
  WashingMachine = 'washing-machine',
  WashingRoom = 'washing-room',
  Water = 'water',
  Wbs = 'wbs',
  Width = 'width',
  Window = 'window',
  Xing = 'xing',
  Zap = 'zap',
  Zaps = 'zaps',
  Zoom = 'zoom'
}
