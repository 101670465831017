import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { Elevation, ElevationDirective, ElevationType } from 'libs/directives';
import { buildBEMClassNamesByGivenBaseClassAndFlags } from 'libs/utils';
import { RippleDirective } from 'libs/directives/ripple/ripple.directive';
import {
  CardBorderStyleEnum,
  CardPaddingEnum,
  CardTypeEnum
} from './card.enum';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ElevationDirective, RippleDirective]
})
export class CardComponent {
  @Input() type = CardTypeEnum.DEFAULT;
  @Input() borderStyle = CardBorderStyleEnum.DEFAULT;
  @Input() elevation: ElevationType = Elevation.ZERO;
  @Input() padding: CardPaddingEnum = CardPaddingEnum.DEFAULT;
  @Input() clickable = false;
  @Input() borderRadius = true;
  @Input() disabled = false;
  @Input() overflowVisible = false;

  @Output() clickEvent = new EventEmitter();

  public CardBorderStyleEnum = CardBorderStyleEnum;

  public rippleColor: string;

  public baseClass = 'card';

  public click(): void {
    this.clickEvent.emit();
  }

  public getClassName(): string {
    this.rippleColor =
      this.type === CardTypeEnum.DEFAULT
        ? 'rgba(235, 242, 255, 0.50)'
        : 'rgba(255, 255, 255, 0.50)';

    return buildBEMClassNamesByGivenBaseClassAndFlags(this.baseClass, {
      [`type-${this.type}`]: !!this.type,
      [`border-style-${this.borderStyle}`]: !!this.borderStyle,
      [`border-radius-none`]: !this.borderRadius,
      [`padding-${this.padding}`]: !!this.padding,
      [`overflow-visible`]: this.overflowVisible,
      clickable: this.clickable,
      disabled: this.disabled
    });
  }
}
