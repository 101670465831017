import { Component, Input } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MessageComponent } from '../message/message.component';
import { AppInputDirective } from '../form/controls/input/input.directive';
import { FormFieldLabelComponent } from '../form/form-field/form-field-label/form-field-label.component';
import { FormFieldComponent } from '../form/form-field/form-field.component';

@Component({
  selector: 'app-invoice-payment-method',
  templateUrl: './invoice-payment-method.component.html',
  styleUrls: ['./invoice-payment-method.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    FormFieldComponent,
    FormFieldLabelComponent,
    AppInputDirective,
    MessageComponent,
    TranslateModule
  ]
})
export class InvoicePaymentMethodComponent {
  @Input() form: FormGroup;
  public error: string | boolean;

  public get taxIdControlValue() {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.form.get('taxId').value;
  }

  public get invoiceEmailControlValue() {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.form.get('invoiceEmail').value;
  }

  public validate() {
    return {
      taxId: this.taxIdControlValue || '',
      invoiceEmail: this.invoiceEmailControlValue,
      error: this.error
    };
  }
}
