import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  inject
} from '@angular/core';

import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { matchControlValidatorFactory } from 'libs/components/legacy/form';

import { ChangePasswordPayload } from '@ui/shared/models';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonComponent } from '../../atoms/button/button.component';
import { PasswordComponent } from '../form/controls/password/password.component';
import { FormFieldComponent } from '../form/form-field/form-field.component';

@Component({
  selector: 'app-set-password-form',
  templateUrl: './set-password-form.component.html',
  styleUrls: ['./set-password-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    FormFieldComponent,
    PasswordComponent,
    ButtonComponent,
    TranslateModule
  ]
})
export class SetPasswordFormComponent {
  private formBuilder = inject(FormBuilder);

  @Input() ctaButtonLabel: string;
  @Input() isSaving: boolean;
  @Output() submitPassword = new EventEmitter<ChangePasswordPayload>();

  public formData: ChangePasswordPayload = {
    password: '',
    confirmedPassword: ''
  };

  public form = this.formBuilder.group({
    password: ['', matchControlValidatorFactory('confirmedPassword', true)],
    confirmedPassword: ['', matchControlValidatorFactory('password', true)]
  });

  public setPassword(): void {
    this.submitPassword.emit(this.form.value as ChangePasswordPayload);
  }
}
