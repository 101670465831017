<div class="custom-question-field__content">
  <app-form-field>
    @if (hierarchicalQuestion?.data?.title && currentLanguage) {
      <app-form-field-label [showRequiredMarker]="true">
        {{
          hierarchicalQuestion.data.title[currentLanguage] ||
            hierarchicalQuestion.data.title[defaultLanguage] | translate
        }}
      </app-form-field-label>
    }
    <app-flat-select
      [items]="options"
      [(ngModel)]="value"
      required
      [multiple]="hierarchicalQuestion.data?.maxAnswers > 1"
      [currentLanguage]="currentLanguage"
      [defaultLanguage]="defaultLanguage"
    ></app-flat-select>
  </app-form-field>
</div>
