import { SystemTag } from '@ui/shared/models';

export interface TagConfig {
  label?: string;
  color: TagColor;
  tooltip?: string;
}

interface TagConfigs {
  [key: string]: TagConfig;
}

export enum TagColor {
  GUEST = 'guest',
  EXCLUSIVE = 'exclusive',
  OFFLINE = 'offline',
  ANONYMOUS = 'anonymous',
  TENANT = 'tenant',
  SHARED = 'shared',
  PERSONAL = 'personal',
  SALES_PROSPECT = 'sales_prospect',
  MEMBER = 'member',
  DYNAMIC = 'dynamic',
  PROJECT = 'project',
  SELF_REGISTERED = 'self_registered',
  MERGE_CASE = 'merge_case'
}

export const TAG_CONFIGS: TagConfigs = {
  [SystemTag.GUEST]: {
    label: 'tag.guest_label_l',
    color: TagColor.GUEST,
    tooltip: 'tag.guest_tooltip'
  },
  [SystemTag.EXCLUSIVE]: {
    label: 'tag.itp_label_l',
    color: TagColor.EXCLUSIVE,
    tooltip: 'tag.itp_tooltip'
  },
  [SystemTag.OFFLINE]: {
    label: 'tag.offline_label_l',
    color: TagColor.OFFLINE,
    tooltip: 'tag.offline_tooltip'
  },
  [SystemTag.TENANT]: {
    label: 'tag.tenant_label_l',
    color: TagColor.TENANT,
    tooltip: 'tag.tenant_tooltip'
  },
  [SystemTag.ANONYMOUS]: {
    label: 'tag.anonymous_label_l',
    color: TagColor.ANONYMOUS,
    tooltip: 'tag.anonymous_tooltip'
  },
  [SystemTag.SALES_PROSPECT]: {
    label: 'tag.sales_prospect_label_l',
    color: TagColor.SALES_PROSPECT,
    tooltip: 'tag.sales_prospect_tooltip'
  },
  [SystemTag.BUYER]: {
    label: 'tag.buyer_label_l',
    color: TagColor.TENANT,
    tooltip: 'tag.buyer_tooltip'
  },
  [SystemTag.MEMBER]: {
    label: 'tag.member_label_l',
    color: TagColor.MEMBER,
    tooltip: 'tag.member_tooltip'
  },
  [SystemTag.SELF_REGISTERED]: {
    label: 'tag.self_registered_label_l',
    color: TagColor.SELF_REGISTERED,
    tooltip: 'tag.self_registered_tooltip'
  },
  [SystemTag.MERGE_CASE]: {
    label: 'tag.merge_case_label_l',
    color: TagColor.MERGE_CASE,
    tooltip: 'tag.merge_case_tooltip'
  },
  [TagColor.SHARED]: {
    color: TagColor.SHARED
  },
  [TagColor.PERSONAL]: {
    color: TagColor.PERSONAL
  },
  [TagColor.PROJECT]: {
    color: TagColor.PROJECT
  },
  [TagColor.DYNAMIC]: {
    color: TagColor.DYNAMIC
  }
};
