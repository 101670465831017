import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PerformanceBannerConfig } from '@ui/shared/models';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-performance-warning-banner',
  templateUrl: './performance-warning-banner.component.html',
  styleUrls: ['./performance-warning-banner.component.scss'],
  standalone: true,
  imports: [TranslateModule]
})
export class PerformanceWarningBannerComponent {
  @Input() confirmed: boolean;
  @Input() config: PerformanceBannerConfig;
  @Output() confirm = new EventEmitter<void>();

  onClose() {
    this.confirm.emit();
  }
}
