import { AdministrationUnit } from './administration-unit.model';
import { Attachment } from './attachment.model';
import { PropertyFollowup } from './property.model';

export enum ProjectStatus {
  PLANNING = 'PLANNING',
  ANNOUNCEMENT = 'ANNOUNCEMENT',
  MARKETING = 'MARKETING',
  RENTED = 'RENTED'
}

export interface ProjectData {
  titleImage?: Attachment;
  name?: string;
  slogan?: string;
  description?: string;
  availableFrom?: string;
  houseEntrances?: string;
}

export interface Project {
  id?: number;
  externalId?: string;
  data?: ProjectData;
  status?: ProjectStatus;
  flatType?: string;
  addedProperties?: number;
  containedProperties?: number;
  propertyInformation?: PropertyInformation;
  applyProcessInformation?: ProjectApplyProcessInformation;
  administrationUnit?: AdministrationUnit;
  key?: string;
  registrationUrl?: string;
  landingPageUrl?: string;
}

export interface ProjectTotalRange {
  from?: number;
  to?: number;
}

export interface PropertyInformation {
  countInformation?: ProjectCountInformation;
  housingInformation?: ProjectObjectInformation;
  commercialInformation?: ProjectObjectInformation;
  parkingInformation?: ProjectObjectInformation;
}

export interface ProjectCountInformation {
  totalRent?: ProjectTotalRange;
  size?: ProjectTotalRange;
  rooms?: ProjectTotalRange;
}

export interface ProjectObjectInformation {
  totalCount?: number;
  rentedCount?: number;
  state?: string;
}

export interface ProjectApplyProcessInformation {
  prospects?: number;
  applicants?: number;
}

export interface ProjectPropertyReservation {
  propertyId: string;
  followup: PropertyFollowup;
}
