import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';

import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';

import * as fromBaseState from 'libs/infrastructure/base-state';
import { PermissionService } from './permission.service';

export interface PermissionRouteData {
  permission: string;
  accessDeniedRedirect: string;
}

@Injectable()
export class PermissionGuard {
  private permissionService = inject(PermissionService);
  private store = inject<Store<fromBaseState.AppState>>(Store);

  canActivate(route: ActivatedRouteSnapshot) {
    const data = route.data as PermissionRouteData;

    if (!data.permission) {
      return of(true);
    }

    return of(this.permissionService.hasPermission(data.permission)).pipe(
      withLatestFrom(this.store.select(fromBaseState.getRouterState)),
      map(([hasAccess, routerState]) => {
        if (!hasAccess && routerState.navigationId === 1) {
          this.store.dispatch(
            new fromBaseState.Go({
              path: [data.accessDeniedRedirect || '/']
            })
          );

          return false;
        }

        return hasAccess;
      })
    );
  }
}
