import { createReducer, on } from '@ngrx/store';
import { DistrictForAddress } from '@ui/shared/models';
import { ActionState, ActionStateCreator } from 'libs/state-utils';
import * as fromActions from './district.actions';

export interface DistrictState {
  districts: DistrictForAddress[];
  districtsActionState: ActionState;
}

export const initialState: DistrictState = {
  districts: null,
  districtsActionState: ActionStateCreator.create()
};

export const reducer = createReducer(
  initialState,

  on(fromActions.loadDistrictsByAddress, state => ({
    ...state,
    districtsActionState: ActionStateCreator.onStart()
  })),

  on(fromActions.loadDistrictsByAddressSuccess, (state, { data }) => ({
    ...state,
    districts: data,
    districtsActionState: ActionStateCreator.onSuccess()
  })),

  on(fromActions.loadDistrictsByAddressFailure, (state, { error }) => ({
    ...state,
    districtsActionState: ActionStateCreator.onError(error)
  })),

  on(fromActions.resetDistricts, () => ({
    ...initialState
  }))
);

export const getDistricts = (state: DistrictState) => state.districts;
export const getDistrictsIsLoading = (state: DistrictState) =>
  state.districtsActionState.pending;
