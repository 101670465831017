export enum ConstantsTypes {
  BUILDING_CONDITION_TYPES = 'buildingConditionTypes',
  CERTIFICATE_CREATION_DATES = 'certificateCreationDates',
  COUNTRY_CODES = 'countryCodes',
  CREDENTIAL_PROPERTIES = 'credentialProperties',
  EMPLOYMENT_TYPES = 'employmentTypes',
  ENERGY_CERTIFICATE_TYPES = 'energyCertificateTypes',
  EXTRA_DATA = 'extraData',
  FILE_TYPES = 'fileTypes',
  FLAT_TYPES = 'flatTypes',
  GENDER_TYPES = 'genderTypes',
  GROUND_TYPES = 'groundTypes',
  HEATER_FIRING_TYPES = 'heaterFiringTypes',
  HEATER_TYPES = 'heaterTypes',
  HOUSEHOLD_TYPES = 'householdTypes',
  LANDLORD_CUSTOMER_PREFERENCES = 'landlordCustomerPreferences',
  LANDLORD_CUSTOMER_SIZES = 'landlordCustomerSizes',
  LANDLORD_CUSTOMER_TYPES = 'landlordCustomerTypes',
  LANDLORD_USERTYPES = 'landlordUsertypes',
  LANDLORD_USER_PREFERENCES = 'landlordUserPreferences',
  LOGIN_METHODS = 'loginMethods',
  MAIL_TEMPLATES = 'mailTemplates',
  PERSONAL_STATUSES = 'personalStatuses',
  PRODUCT_BASKET_PROPERTIES = 'productBasketProperties',
  PROPERTY_PORTAL_STATES = 'propertyState',
  PROPERTY_PORTAL_STATES_PATCH = 'propertyPortalStates',
  PROPERTY_WRITE_PROTECTIONS = 'propertyWriteProtections',
  JOB_STATES = 'jobStates',
  SCHUFA_REPORT_TYPES = 'schufaReportTypes',
  HOUSING_PERMISSION_TYPES = 'housingPermissionTypes',
  S = 's',
  TITLES = 'titles'
}
