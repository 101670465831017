import { ConversationLabel } from 'libs/components/legacy/messenger/model/interface';
import { BadgeColorEnum } from 'libs/components/atoms/badge/badge.enum';

export const chatBadgeColor = {
  [ConversationLabel.APPLICATION]: {
    color: BadgeColorEnum.NEUTRAL,
    text: 'messenger.conversation_label_application_l'
  },
  [ConversationLabel.DAMAGE]: {
    color: BadgeColorEnum.NEUTRAL,
    text: 'messenger.conversation_label_damage_l'
  },
  [ConversationLabel.CONCERN]: {
    color: BadgeColorEnum.NEUTRAL,
    text: 'messenger.conversation_label_concern_l'
  },
  [ConversationLabel.TICKET_STATUS_OPEN]: {
    color: BadgeColorEnum.TICKET_STATUS_OPEN,
    text: 'messenger.conversation_label_ticket_open_l'
  },
  [ConversationLabel.TICKET_STATUS_CANCELLED]: {
    color: BadgeColorEnum.TICKET_STATUS_CANCELLED,
    text: 'messenger.conversation_label_ticket_cancelled_l'
  },
  [ConversationLabel.TICKET_STATUS_CLOSED]: {
    color: BadgeColorEnum.TICKET_STATUS_CLOSED,
    text: 'messenger.conversation_label_ticket_closed_l'
  },
  [ConversationLabel.TICKET_STATUS_IN_PROGRESS]: {
    color: BadgeColorEnum.TICKET_STATUS_IN_PROGRESS,
    text: 'messenger.conversation_label_ticket_in_progress_l'
  },
  [ConversationLabel.TICKET_STATUS_WAITING_FOR_OTHERS]: {
    color: BadgeColorEnum.TICKET_STATUS_WAITING_FOR_OTHERS,
    text: 'messenger.conversation_label_ticket_waiting_for_others_l'
  }
};
