import { Address } from './address.model';
import { Attachment } from './attachment.model';
import { ContractCustomerSettings } from './digital-contract';
import { CustomerLocation } from './enums';
import { DkApprovalLevel } from './property-searcher.model';
import { RegistrationLinkObjectTypes } from './registration-link.model';
import { SalesLegalText } from './sales-legal-text.model';
import { LandlordDistrictGroupBean } from './search-profile.model';

export interface BrandingTheme {
  name?: string;
  primaryColor?: string;
  secondaryColor?: string;
  primaryTextColor?: string;
  secondaryTextColor?: string;
  buttonTextColor?: string;
  backgroundColor?: string;
  cardBackgroundColor?: string;
  menuBarBackgroundColor?: string;
  menuBarTextColorActive?: string;
  menuBarTextColorInactive?: string;
  active?: boolean;

  // temporary
  logo?: Attachment;
  favicon?: Attachment;
  customerId?: string;
  logoRedirectUrl?: string;
  allowUserToLeaveItp?: boolean;
  itpBooked?: boolean;
  onlyShowConfiguredCityPartsToUser?: boolean;
  tokenId?: string;
  customerName?: string;
  objectTypes?: RegistrationLinkObjectTypes;
  districtGroup?: LandlordDistrictGroupBean;
}

export interface SimpleCustomerBranding {
  name: string;
  logo: string;
}

export interface CustomerSettings {
  id?: string;
  logo?: Attachment;
  favicon?: Attachment;
  residentAppBranding?: ResidentAppBranding;
  logoRedirectUrl?: string;
  brandingThemes?: BrandingTheme[];
  themeUrl?: string;
  mailConfig?: MailConfig;
  searchUntilIntervalWeeks?: number;
  internalPoolReturnUrl?: string;
  applicationArchive?: TimedSettings;
  deleteDkLevelAfterRenting?: boolean;
  allowContinueAsGuest?: boolean;
  obscurePropertySearcherScore?: boolean;
  dkLevelCustomerSettings?: DKLevelCustomerSettings;
  conversationConfig?: ConversationConfig;
  noteCommentSettings?: NoteCommentSettings;
  blocklistCustomerSettings?: BlocklistCustomerSettings;
  useThemeForLandlord?: boolean;
  automatedRentalProcessConfig?: AutomatedRentalCustomerSettings;
  applicantDownloadExposeAllowed?: boolean;
  propertySettings?: PropertySettings;
  usePropertyVersioning?: boolean;
  crosscheckSettings?: CrosscheckSettings;
  priosetCustomerSettings?: PriosetCustomerSettings;
  onlyShowConfiguredCityPartsToUser?: boolean;
  itpSettings?: InternalTenantPoolSettings;
  smartDepositSettings?: SmartDepositSettings;
  ruvDepositSettings?: RuvDepositSettings;
  tagSettings?: TagSettings;
  contractCustomerSettings?: ContractCustomerSettings;
  salesLegalTextSettings?: SalesLegalText[];
  residentAppCardSettings?: ResidentAppCardSettings;
  depositSettings?: DepositSettings;
  halfRommsEnabled?: boolean;
  noteDeleteSettings?: TimedSettings;
  askForViewingAppointmentAttendance?: boolean;
  rentedOutPropertiesDeletionConfig?: RentedOutPropertiesDeletionConfig;
  dataPrivacyUrl?: string;
  residentAppSettings?: ResidentAppSettings;
  projectCustomerSettings?: ProjectCustomerSettings;
  internationalizationSettings?: InternationalizationSettings;
  paymentModuleSettings?: PaymentModuleSettings;
  customerIdent?: string;
  defaultObjectTypes?: RegistrationLinkObjectTypes;
  defaultDistrictGroupId?: string;
  contactSettings?: ContactSettings;
  contactMergeSettings?: SmartContactMergeSettings;
  externalPricing?: boolean;
  applicantSettings?: ApplicantSettings;
  allowInvitingAnonymousApplicants?: boolean;
  unansweredApplicantNotificationSettings?: TimedSettings; // used as input
}

export interface ApplicantSettings {
  allowInvitingAnonymousApplicants: boolean;
  unansweredApplicantNotificationSettings?: TimedSettings;
}

export interface ContactSettings {
  smartContactMergeSettings?: SmartContactMergeSettings;
  contactsDeletionConfig?: ContactsDeletionConfig;
}

export interface SmartContactMergeSettings {
  active: boolean;
}

export interface ContactsDeletionConfig {
  userOwned?: DeletionConfig;
  landlordOwned?: DeletionConfig;
  manuallyCreated?: DeletionConfig;
  automaticallyCreated?: DeletionConfig;
}

export interface DeletionConfig extends TimedSettings {
  contactsDeletionActive: boolean;
  contactsDeletionUnit: string;
  contactsDeletionUnitAmount: number;
  contactsSearchProfileEndDateAdjustable: boolean;
}

export interface ResidentAppSettings {
  residentAppSystemType: ResidentAppSystemType;
  appClientName: string;
  appRegisterPath: string;
}

export interface ResidentAppCardSettings {
  headline: string;
  description: string;
}

export interface TagSettings {
  personalLabelsEnabled: boolean;
  membershipEnabled: boolean;
}

export interface RuvDepositSettings {
  allowRuvDeposit: boolean;
  address: Address;
  thirdPartyObjects?: boolean;
}

export interface SmartDepositSettings {
  allowSmartDeposit: boolean;
  hasThirdPartyObjects?: boolean;
  enabledByDefault?: boolean;
}

export interface TimedSettings {
  unit?: string;
  amount?: number;
  enabled?: boolean;
}

export interface Level2Settings {
  active: boolean;
  useBirthdate: boolean;
  useEmail: boolean;
  useName: boolean;
  usePhone: boolean;
}

export interface InternalTenantPoolSettings {
  allowUserToLeaveItp?: boolean;
  addExclusiveLabelForWhitelabelLogins?: boolean;
  dmvCard?: boolean;
  shopCard?: boolean;
  schufaCard?: boolean;
  milesCard?: boolean;
  wechselPilotCard?: boolean;
  whitelabel?: boolean;
  sendRegisterMail?: boolean;
  showCreditScreeningDocumentUploadArea?: boolean;
  movingChecklist?: boolean;
  movingAuction?: boolean;
  informalLanguage?: boolean;
}

export interface CrosscheckSettings {
  level2Settings: Level2Settings;
}

export interface AutomatedRentalCustomerSettings {
  onlyCreationByTemplateAllowed: boolean;
}

export interface BlocklistCustomerSettings {
  blockedEmails: string[];
}

export interface ConversationConfig {
  agentsAccessOnlyOwnConversations: boolean;
}

export interface NoteCommentSettings {
  ratingEnabled: boolean;
  commentsEnabled: boolean;
}

export interface DKLevelCustomerSettings {
  intentNeededBeforeDk2?: boolean;
  nameLevel?: DkApprovalLevel;
  scoringLevel?: DkApprovalLevel;
  portraitLevel?: DkApprovalLevel;
  unmaskMember?: boolean;
}

export interface MailConfig {
  senderAddressPrefix: string;
  senderNamePrefix: string;
}

export interface PreviewTheme {
  logo?: Attachment;
  theme?: BrandingTheme;
}

export type ResidentAppBranding = {
  residentAppLogo?: Attachment;
};

export interface CustomerSettingsInput {
  customerID?: string;
  brandingThemes?: BrandingTheme[];
  logoRedirectUrl?: string;
  logo?: Attachment;
  customerSettingsID?: string;
  themeUrl?: string;
  mailConfig?: MailConfig;
  useThemeForLandlord?: boolean;
  favicon?: Attachment;
  residentAppBranding?: ResidentAppBranding;
}

export interface DepositSettings {
  formula?: string;
  entryPrice?: number;
}

export interface RentedOutPropertiesDeletionConfig {
  timeUnit?: string;
  unitAmount?: number;
  active?: boolean;
  agentManualDeletionAllowed?: boolean;
}

export interface PropertySettings {
  depositSettings?: DepositSettings;
  halfRoomsEnabled?: boolean;
  noteDeleteSettings?: TimedSettings;
  askForViewingAppointmentAttendance?: boolean;
  rentedOutPropertiesDeletionConfig?: RentedOutPropertiesDeletionConfig;
}

export interface PriosetCustomerSettings {
  changeOnlyByTemplateAllowed: boolean;
}

export interface ProjectCustomerSettings {
  allowToAssignAdministrationUnits?: boolean;
  allowToCreatePropertyTemplates?: boolean;
  allowToDeleteProjects?: boolean;
}

export interface InternationalizationSettings {
  countries?: CustomerLocation[];
  defaultCountry?: CustomerLocation;
  itpCountries?: string[];
}

export enum CustomerSettingsPath {
  WHITELABEL = 'whitelabel',
  TAG = 'tag',
  PROSPECT_PORTAL = 'prospectPortal',
  PROPERTY = 'property',
  PRIVACY = 'privacy',
  PRIOSET = 'prioset',
  DISTRICTS = 'districts',
  DIGITAL_SIGNATURE = 'digitalSignature',
  BLOCK_LIST = 'blocklist',
  AUTOMATED_RENTAL_PROCESS = 'automatedRentalProcess',
  PROPERTY_IMPORT = 'propertyImport',
  SALES_LEGAL_TEXT = 'salesLegalText',
  RESIDENT_APP_CARD = 'residentAppCard',
  PROJECT_SETTINGS = 'project',
  GENERAL = 'general',
  PAYMENT_MODULE = 'paymentModule',
  IMPORT_SETTINGS = 'importSettings',
  CONTACT_MERGE_SETTINGS = 'contactMergeSettings',
  UNANSWERED_APPLICANT_NOTIFICATION_SETTINGS = 'unansweredApplicantNotificationSettings'
}

export enum ResidentAppSystemType {
  SAAS = 'SAAS',
  ON_PREMISE = 'ON_PREMISE'
}

export enum PaymentMethods {
  SEPA = 'SEPA',
  PAYPAL = 'PAYPAL',
  BANK_WIRE = 'BANK_WIRE'
}

export type PaymentModuleSettings = {
  allowedPaymentMethods?: PaymentMethods[];
};
