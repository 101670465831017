import {
  trigger,
  transition,
  style,
  animate,
  state,
  animateChild,
  group,
  query
} from '@angular/animations';

const defaultTimingFunction = 'cubic-bezier(.35,0,.25,1)';

const fadeDuration = 300; // in ms
const slideDuration = 300; // in ms

export enum SlideAnimationStateEnum {
  OPEN = 'leftOpen',
  CLOSED_LEFT = 'closedLeft',
  CLOSED_RIGHT = 'closedRight',
  CLOSED_LEFT_WITH_OFFSET = 'closedLeftWithOffset',
  CLOSED_RIGHT_WITH_OFFSET = 'closedRightWithOffset'
}

export enum FadeAnimationStateEnum {
  SHOW = 'show',
  HIDE = 'hide'
}

export enum CollapseXAnimationStateEnum {
  OPEN = 'open',
  CLOSED = 'closed'
}

export const fadeOnEnterLeaveAnimation = trigger('fadeOnEnterLeave', [
  transition(':enter', [
    style({ opacity: 0 }),
    animate(`${fadeDuration}ms`, style({ opacity: 1 }))
  ]),
  transition(':leave', [
    style({ opacity: 1 }),
    animate(`${fadeDuration}ms`, style({ opacity: 0 }))
  ])
]);

export const fadeAnimation = trigger('fade', [
  state(FadeAnimationStateEnum.SHOW, style({ opacity: 1 })),
  state(FadeAnimationStateEnum.HIDE, style({ opacity: 0 })),
  transition(
    `${FadeAnimationStateEnum.SHOW} => ${FadeAnimationStateEnum.HIDE}`,
    animate(`${fadeDuration}ms ${defaultTimingFunction}`)
  ),
  transition(
    `${FadeAnimationStateEnum.HIDE} => ${FadeAnimationStateEnum.SHOW}`,
    animate(`${fadeDuration}ms ${defaultTimingFunction}`)
  )
]);

// same as fadeAnimation but with delayed hide => show to be timed with an outer slide:
export const innerFadeAnimation = trigger('innerFade', [
  state(FadeAnimationStateEnum.SHOW, style({ opacity: 1 })),
  state(FadeAnimationStateEnum.HIDE, style({ opacity: 0 })),
  transition(
    `${FadeAnimationStateEnum.SHOW} => ${FadeAnimationStateEnum.HIDE}`,
    animate(`${fadeDuration}ms ${defaultTimingFunction}`)
  ),
  transition(
    `${FadeAnimationStateEnum.HIDE} => ${FadeAnimationStateEnum.SHOW}`,
    animate(`${fadeDuration}ms ${slideDuration}ms ${defaultTimingFunction}`)
  )
]);

export const collapseXAnimation = trigger('collapseX', [
  state(CollapseXAnimationStateEnum.OPEN, style({ width: '{{width}}' }), {
    params: { width: '100%' }
  }),
  state(CollapseXAnimationStateEnum.CLOSED, style({ width: '{{width}}' }), {
    params: { width: '0' }
  }),
  transition(
    '* <=> *',
    group([
      query('@innerFade', animateChild(), { optional: true }),
      animate(`${slideDuration}ms ${defaultTimingFunction}`)
    ])
  )
]);

export const slideAnimation = trigger('slide', [
  state(SlideAnimationStateEnum.OPEN, style({ transform: 'translateX(0)' })),
  state(
    SlideAnimationStateEnum.CLOSED_LEFT,
    style({ transform: 'translateX(-100%)' })
  ),
  state(
    SlideAnimationStateEnum.CLOSED_RIGHT,
    style({ transform: 'translateX(100%)' })
  ),
  state(
    SlideAnimationStateEnum.CLOSED_LEFT_WITH_OFFSET,
    style({ transform: 'translateX(calc(-100% - {{offset}}px))' }),
    { params: { offset: 0 } }
  ),
  state(
    SlideAnimationStateEnum.CLOSED_RIGHT_WITH_OFFSET,
    style({ transform: 'translateX(calc(100% + {{offset}}px))' }),
    { params: { offset: 0 } }
  ),
  transition(
    '* <=> *',
    group([
      query('@innerFade', animateChild(), { optional: true }),
      animate(`${slideDuration}ms ${defaultTimingFunction}`)
    ])
  )
]);
