import { Attachment } from './attachment.model';

export interface FancardOffersOverview {
  offerId?: string;
  uuid?: string;
  title?: string;
  showInHeader?: boolean;
  image?: Attachment;
  imageUrl?: string;
  textContent?: string;
  fancardOfferType?: FancardOfferType;
  fancardOfferCategory?: string;
  href?: string;
  publishedAt?: string;
  publishedUntil?: string;
  _links?: FancardAdditionalProps;
}

export interface FancardAdditionalProp {
  href: string;
  hreflang: string;
  title: string;
  type: string;
  deprecation: string;
  profile: string;
  name: string;
  templated: boolean;
}

export interface FancardAdditionalProps {
  additionalProp1: FancardAdditionalProp;
  additionalProp2: FancardAdditionalProp;
  additionalProp3: FancardAdditionalProp;
}

export interface FancardTargetGroup {
  type: FancardTargetGroupType;
  administrationUnitId: number;
}

export interface FancardOffersInput {
  publishedAt?: string | Date;
  publishedUntil?: string | Date;
  targetGroups: FancardTargetGroup[];
}

export enum FancardTargetGroupType {
  TENANT,
  OWNER,
  ADMINISTRATION_UNIT
}

export enum FancardOfferType {
  PERMANENT = 'permanent',
  DEAL = 'deal',
  GENERAL = 'general'
}

export interface FancardOfferFiles {
  document: Attachment;
}

export interface FancardBulkDeleteInput {
  contentDeleteIds: string[];
}
