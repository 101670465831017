import {
  ChangeDetectorRef,
  Directive,
  ElementRef,
  HostListener,
  Input,
  inject
} from '@angular/core';
@Directive({
  selector: '[appFocusFormError]',
  standalone: true
})
export class FocusFormErrorDirective {
  private element = inject<ElementRef<HTMLElement>>(ElementRef);
  cdr = inject(ChangeDetectorRef);

  @Input() prioritizeInputElements = true;
  @Input() invalidClassSelector = 'form [formcontrolname].ng-invalid';

  @HostListener('ngSubmit') onSubmit() {
    this.scrollToFirstInvalidControl();
  }

  private scrollToFirstInvalidControl() {
    const inputList: NodeListOf<HTMLElement> =
      this.element.nativeElement.querySelectorAll(this.invalidClassSelector);
    if (inputList.length > 0) {
      let inputs = Array.from(inputList);
      if (this.prioritizeInputElements) {
        inputs = this.sortInputsByType(inputs);
      }
      const firstInvalidControl = inputs[0];
      this.scroll(firstInvalidControl);
      this.setFocusOnInvalid(inputs);
    }
    this.cdr.markForCheck();
  }

  private scroll(invalidControl: HTMLElement) {
    const scrollIntoViewFn = () => {
      invalidControl.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      });
    };
    setTimeout(scrollIntoViewFn, 0);
  }

  private setFocusOnInvalid(inputs: HTMLElement[]) {
    inputs.reverse().forEach(element => element.focus());
  }

  private sortInputsByType(inputs: HTMLElement[]): HTMLElement[] {
    return inputs.sort((a, b) => {
      if (a.tagName === 'INPUT' && b.tagName === 'INPUT') return 0;
      if (a.tagName === 'INPUT') return -1;
      return 1;
    });
  }
}
