import { ConditionId } from './condition-id.model';
import { CustomQuestion, RootQuestionContainerModel } from './custom-questions';
import { Customer } from './customer.model';
import { HousingPermission } from './housing-permission.model';
import { CustomQuestionAssociation } from './property.model';
import { Residents } from './residents.model';
import { SearchPeriod } from './search-period.model';

export interface BoundaryValue {
  value: number;
  lowerBound: number;
  upperBound: number;
}

export interface ListValue {
  value: number;
  choice: string[];
}

export interface NumberValue {
  value: number;
  knockout: boolean;
}

export interface BooleanValue {
  value: boolean;
  knockout: boolean;
}

export interface BoundryValueContainer {
  value: BoundaryValue;
  knockout: boolean;
}

export interface ListValueContainer {
  value: ListValue;
  knockout: boolean;
}

export interface PriosetData {
  age?: BoundryValueContainer;
  employmentType?: ListValueContainer;
  monthlyIncome?: BoundryValueContainer;
  children?: NumberValue;
  smoking?: NumberValue;
  animals?: NumberValue;
  householdType?: ListValueContainer;
  conditions?: PriosetCondition[];
  editableConditions?: ConditionId[];
}

export interface Prioset {
  customQuestions?: CustomQuestion[];
  hierarchicalQuestions?: RootQuestionContainerModel[];
  customQuestionAssociations?: CustomQuestionAssociation[];
  created?: Date;
  customer?: Customer;
  data?: PriosetData;
  description?: string;
  id?: string;
  locked?: boolean;
  name?: string;
  template?: boolean;
  updated?: Date;
  sizeOfProperties?: number;
  customerID?: string;
}

export interface Priosets {
  own?: Prioset[];
  preset?: Prioset[];
  ownAdditional?: Prioset[];
}

export interface PriosetCondition {
  value?: boolean;
  importance?: number;
  knockout?: boolean;
  data?: ConditionData;
}

export type ConditionData = HousingPermission | SearchPeriod | Residents;

export const getEmptyPrioset = () => ({
  id: '',
  name: '',
  description: '',
  template: false,
  customQuestions: [],
  customQuestionAssociations: [],
  data: {
    age: {
      value: {
        value: 0,
        lowerBound: 0,
        upperBound: 1
      },
      knockout: false
    },
    employmentType: {
      value: {
        value: 0,
        choice: []
      },
      knockout: false
    },
    monthlyIncome: {
      value: {
        value: 0,
        lowerBound: 1,
        upperBound: 4
      },
      knockout: false
    },
    children: {
      value: 0,
      knockout: false
    },
    smoking: {
      value: 0,
      knockout: false
    },
    animals: {
      value: 0,
      knockout: false
    },
    householdType: {
      value: {
        value: 0,
        choice: []
      },
      knockout: false
    },
    conditions: [...emptyConditions]
  }
});

export const defaultHousingCondition = {
  value: false,
  importance: 10,
  knockout: true,
  data: {
    conditionId: ConditionId.HOUSING_PERMISSION,
    housingPermissionTypes: [],
    amountPeopleLowerBound: 0,
    amountPeopleUpperBound: 0
  }
};

export const withHousingCondition = {
  ...defaultHousingCondition,
  value: true
};

export const defaultSearchPeriodCondition = {
  value: false,
  importance: 0,
  knockout: false,
  data: {
    conditionId: ConditionId.SEARCH_PERIOD,
    months: 6
  }
};

export const defaultResidentsCondition = (lower = 0, upper = 5) => ({
  value: false,
  importance: 0,
  knockout: false,
  data: {
    conditionId: ConditionId.RESIDENTS,
    amountPeopleLowerBound: lower,
    amountPeopleUpperBound: upper
  }
});

export const emptyConditions = [
  defaultHousingCondition,
  defaultSearchPeriodCondition,
  defaultResidentsCondition()
];
