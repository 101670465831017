import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';

import { Elevation, ElevationDirective, ElevationType } from 'libs/directives';
import { buildBEMClassNamesByGivenBaseClassAndFlags } from 'libs/utils';

import { IconTypeEnum } from '@ui/shared/models';
import { NgClass } from '@angular/common';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import {
  BadgeBorderRadiusEnum,
  BadgeColorEnum,
  BadgeSizeEnum
} from './badge.enum';

@Component({
  selector: 'app-badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgbTooltip, NgClass, ElevationDirective]
})
export class BadgeComponent {
  @Input() tooltipText = '';
  @Input() borderColor = '';
  @Input() borderType = '';
  @Input() color = BadgeColorEnum.NEUTRAL;
  @Input() size = BadgeSizeEnum.NORMAL;
  @Input() borderRadius = BadgeBorderRadiusEnum.SMALL;

  @Input() clickable = false;
  @Input() elevation: ElevationType = Elevation.ZERO;
  @Input() withCloseButton = false;
  @Input() uppercase = true;
  @Input() fontBold = false;

  @Input() icon: IconTypeEnum = null;

  @Output() clickEvent = new EventEmitter<MouseEvent>();
  @Output() closeEvent = new EventEmitter<MouseEvent>();

  public baseClass = 'badge';

  public getClassName(): string {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-return
    return buildBEMClassNamesByGivenBaseClassAndFlags(this.baseClass, {
      [`color-${this.color}`]: !!this.color,
      [`size-${this.size}`]: !!this.size,
      [`border-radius-${this.borderRadius}`]: !!this.borderRadius,
      [`border-color-${this.borderColor}`]: !!this.borderColor,
      [`border-style-${this.borderType}`]: !!this.borderRadius,
      clickable: this.clickable
    });
  }

  public badgeClick(event: MouseEvent): void {
    if (this.clickable) {
      this.clickEvent.emit(event);
    }
  }

  public closeButtonClick(event: MouseEvent): void {
    if (this.withCloseButton) {
      this.closeEvent.emit(event);
    }
  }
}
