<div
  class="messenger__chat-area"
  #chatContainerArea
  (dragenter)="onDragOverFile($event)"
  (dragleave)="onDragOverFile($event)"
>
  @if (isMobile || isTablet) {
    <div
      class="mobile-chat-nav d-flex justify-content-between flexbox-column ios-padding-left ios-padding-right"
    >
      <app-button
        class="mobile-nav-item"
        (clickEvent)="setActivePanel(paneTypes.CONTACT_LIST)"
      >
        <img src="/assets/images/icons/icon-navigation-left.svg" />
        <span class="pl5">{{ 'messenger.return_a' | translate }}</span>
      </app-button>
      @if (isPropertySearcher) {
        <app-button
          class="mobile-nav-item"
          (clickEvent)="setActivePanel(paneTypes.INFO)"
        >
          {{ 'messenger.show_flat_info_a' | translate }}
          <span class="pl5"
            ><img src="/assets/images/icons/icon-navigation-right.svg"
          /></span>
        </app-button>
      }
      @if (isLandlord) {
        <app-button
          class="mobile-nav-item"
          (clickEvent)="setActivePanel(paneTypes.INFO)"
          [iconRight]="'arrow-right'"
        >
          {{ 'messenger.show_property_searcher_info_a' | translate }}
        </app-button>
      }
    </div>
  }
  <div
    #chatHistory
    class="messenger__chat-history ios-padding-left ios-padding-right"
    [ngClass]="{
      p0: isMobile || isTablet,
      'mobile-chat': isMobile,
      'tablet-chat': isTablet,
      'property-searcher__chat': isPropertySearcher
    }"
  >
    @if (activeConversation) {
      <div class="messenger__chat-header d-flex justify-content-end">
        @if (showMessageTemplates) {
          <form [formGroup]="selectTemplateForm">
            <app-dropdown-select
              [items]="templates"
              [placement]="'bottom-right'"
              [itemValueKey]="'id'"
              [disabled]="templates?.length === 0"
              (scrollBottom)="scrollingToBottom()"
              formControlName="template"
            >
              <div class="chat-template-dropdown-btn" dropdown-button>
                <svg-icon
                  src="/assets/images/icons/Icon_ChatTemplate.svg"
                  [applyClass]="true"
                  [svgStyle]="{ 'width.px': 20 }"
                  class="main-svg-color path rect"
                ></svg-icon>
              </div>
              <ng-template let-item>{{ item.title | translate }}</ng-template>
            </app-dropdown-select>
          </form>
        }
        @if (allowAttachmentUpload) {
          <app-button
            class="secondary file-upload__link"
            [disabled]="!canAnswer || isApplicationBlocked"
            (clickEvent)="selectFile()"
            [type]="'light-bordered'"
          >
            <div class="icon icon--paper-clip"></div>
          </app-button>
        }
      </div>
    }
    <ng-scrollbar
      class="messenger__chat-history-content"
      [class.heightHide]="!activeConversation"
      [orientation]="'vertical'"
      [visibility]="'hover'"
      (scroll)="scrolled($event)"
    >
      @if (messagesActionState.pending) {
        <div class="loading-area">
          <app-loading-spinner></app-loading-spinner>
        </div>
      }
      @for (message of messages; track message; let i = $index) {
        <app-chat-text-message
          [sender]="sender"
          [message]="message"
          [groupCssClass]="groupMessages(i)"
          [activeConversation]="activeConversation"
          [isPropertySearcher]="isPropertySearcher"
          [isLandlord]="isLandlord"
          (download)="onDownload($event)"
          (preview)="onPreview($event)"
          (createMessageAsTemplate)="onCreateMessageAsTemplate($event)"
        ></app-chat-text-message>
      }
      @if (activeConversation && isLandlord && !messages) {
        <app-no-content
          headline="messages.no_message_l"
          message="message.send_message_now_l"
          [hideImage]="true"
        ></app-no-content>
      }
    </ng-scrollbar>
    @if (!activeConversation) {
      <app-no-content
        headline="messages.select_conversation_title_l"
        message="message.select_conversation_message_l"
        [hideImage]="true"
      ></app-no-content>
    }
  </div>
  @if (activeConversation) {
    <div
      #chatInput
      class="type_msg"
      [class.mobile-chat]="isMobile"
      [class.tablet-chat]="isTablet"
    >
      <form [formGroup]="form">
        @if (allowAttachmentUpload) {
          <app-form-field
            [ngClass]="{
              visible: dragEvent || attachmentControl.value,
              hidden: !dragEvent && !attachmentControl.value
            }"
          >
            <app-form-field-label
              >{{ 'messenger.attachment_l' | translate }}
            </app-form-field-label>
            <app-attachments
              [showRemove]="true"
              [accept]="acceptedFileTypes"
              [size]="documentsMaxSize"
              (remove)="hideUploadArea()"
              formControlName="attachments"
            ></app-attachments>
          </app-form-field>
        }
        <div
          class="input-container d-flex justify-content-between ios-padding-left ios-padding-right"
        >
          <app-form-field class="inline col-10 col-md-11 col-xl-11 p0 p-md-0">
            <textarea
              appInput
              rows="1"
              cols="100"
              type="text"
              name="chatInput"
              [placeholder]="'forms.type_message' | translate"
              formControlName="message"
              class="form-control"
              (keypress)="onSendMessage($event)"
            >
            </textarea>
          </app-form-field>
          <app-button
            class="default msg_send_btn"
            [disabled]="
              !canAnswer ||
              isApplicationBlocked ||
              areMessageAndAttachmentsEmpty
            "
            (clickEvent)="onSendMessage()"
          >
            <div class="icon icon--arrow-right"></div>
          </app-button>
          <app-file-upload
            class="file-upload__input"
            #fileInput
            [size]="documentsMaxSize"
            [accept]="acceptedFileTypes"
            (changeFileUpload)="onChange($event)"
          ></app-file-upload>
        </div>
      </form>
    </div>
  }
</div>
