export const imprintLink = {
  url: 'https://www.mieter.immomio.com/impressum',
  name: 'general.imprint'
};

export const termsLink = {
  url: 'https://www.mieter.immomio.com/agb',
  name: 'general.terms'
};

export const dataPrivacyLink = {
  url: 'https://www.mieter.immomio.com/datenschutz',
  name: 'general.data_privacy'
};

export const impressum_agb_links_config = {
  links: [termsLink, dataPrivacyLink, imprintLink]
};
