import { Injectable, inject } from '@angular/core';

import { gql } from 'apollo-angular';
import { Apollo } from 'apollo-angular';

import { map, take } from 'rxjs/operators';
import { BasketValue, GetBasketValuePayload } from '@ui/shared/models';

@Injectable()
export class BasketCreatorService {
  private apollo = inject(Apollo);

  getBasketValue(payload: GetBasketValuePayload) {
    const getBasketValueQuery = gql`
      query getBasketValue($payload: GetBasketValueInput) {
        getBasketValue(input: $payload) {
          basketID
          priceNet
          postDiscountPriceNet
          priceGross
          postDiscountPriceGross
          currency
        }
      }
    `;

    return this.apollo
      .query<{ getBasketValue: BasketValue }>({
        query: getBasketValueQuery,
        variables: { payload },
        fetchPolicy: 'no-cache'
      })
      .pipe(
        map(result => result.data.getBasketValue),
        take(1)
      );
  }
}
