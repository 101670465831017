import { UserPermission } from 'landlord/screens/account/manage/models';
import { Address } from './address.model';
import { Attachment } from './attachment.model';
import { BrandingTheme } from './branding.model';
import { CustomQuestionScore, QuestionContainer } from './custom-questions';
import { EmploymentType, PropertyApplicationStatus } from './enums';
import { HousingPermission } from './housing-permission.model';
import { Note } from './note.model';
import { Profile } from './profile.model';
import { PropertyBean } from './property.model';
import { SalesLegalText } from './sales-legal-text.model';
import {
  LandlordDistrictGroupBean,
  SearchProfile
} from './search-profile.model';
import { SystemTag } from './system-tag.model';
import { ContactTag } from './tag.model';

export enum DkApprovalLevel {
  DK0 = 'DK0',
  DK1 = 'DK1',
  DK2 = 'DK2',
  DK3 = 'DK3'
}

export enum UpdateStatus {
  NEW = 'NEW',
  UPDATED = 'UPDATED',
  SEEN = 'SEEN'
}

export enum UserType {
  MAIN = 'MAIN',
  ANONYMOUS = 'ANONYMOUS',
  GUEST = 'GUEST',
  SALES_ANONYMOUS = 'SALES_ANONYMOUS'
}

export enum UserProfileType {
  OFFLINE = 'OFFLINE',
  REGISTERED = 'REGISTERED',
  UNREGISTERED = 'UNREGISTERED'
}

export interface PropertySearcher {
  id?: string;
  isProspect?: boolean;
  created?: Date;
  updated?: Date;
  propertyId?: string;
  property?: PropertyBean;
  user?: PropertySearcherUser;
  score?: number;
  customQuestionScore?: CustomQuestionScore;
  portal?: string;
  text?: string;
  status?: string;
  applicantStatus?: PropertyApplicationStatus;
  upcomingAppointmentDate?: Date;
  upcomingAppointmentId?: string;
  attendingViewing?: boolean;
  new?: boolean;
  note?: Note;
  hasComments?: boolean;
  acceptedAppointment?: boolean;
  applicationBlocked?: boolean;
  upcomingAppointmentAcceptanceId?: string;
  dkApprovalLevel?: DkApprovalLevel;
  searchingSince?: Date;
  updateStatus?: UpdateStatus;
  askedForIntent?: boolean;
  favourite?: boolean;
  questionContainer?: QuestionContainer;
  propertyTenantUserId?: string;
  userAllowedToSendMessage?: boolean;
  conversationId?: string;
  inInternalPool?: boolean;
  attendingExclusiveViewing?: boolean;
  applicationConversationStatus?: ApplicationConversationStatus;
  appointmentSlotsAvailable?: boolean;
  contactUserProfileStatistics?: ContactUserProfileStatistics;
  deleted?:
    | PropertyApplicationStatus.DELETED
    | PropertyApplicationStatus.DELETED_RECENTLY;
  deletedByPs?: Date;
  downloadExposeAllowed?: boolean;
  downloadExposeUrl?: string;
  searchProfileBean?: SearchProfile;
  systemTags?: SystemTag[];
  archivedByCustomer?: boolean;
  tags?: ContactTag[];
  salesLegalTexts?: SalesLegalText[];
}

export interface PropertySearcherUser {
  id?: string;
  created?: Date;
  updated?: Date;
  anonymous?: boolean;
  lastLogin?: Date;
  deleted?: Date;
  email?: string;
  profile?: PropertySearcherUserProfile;
  address?: Address;
  enabled?: boolean;
  expired?: boolean;
  locked?: boolean;
  type?: string; // anon, guest, main
  usertype?: string; // registered, unregistered, offline
  permissions?: UserPermission[];
  status?: string;
  accountNonExpired?: boolean;
  accountNonLocked?: boolean;
  credentialsNonExpired?: boolean;
  username?: string;
  new?: boolean;
  emailVerified?: boolean;
  profileCompleteness?: number;
  searchUntil?: string;
  comments?: any[];
  rating?: number;
  applications?: any;
  internalPoolReturnUrl?: string;
  internalPoolCustomerName?: string;
  inInternalPool?: boolean;
  customerBranding?: CustomerBranding;
  offlineUser?: boolean;
  districtGroup?: LandlordDistrictGroupBean;
  onlyShowConfiguredCityPartsToUser?: boolean;
}

export interface PropertySearcherUserBean {
  id?: string;
  email?: string;
  type?: string;
  prospectOptIn?: string;
  lastLogin?: string;
  searchUntil?: string;
  deleted?: Date;
}

export interface GuestUserProfile {
  address: Address;
  data: PropertySearcherUserProfile;
  email: string;
  id: string;
  type: string;
  usertype: string;
  itpCustomerName?: string;
}

export interface ContactUserProfileStatistics {
  id: string;
  offeredProposals: number;
  openOfferedProposals: number;
  declinedOfferedProposals: number;
  upcomingViewingAppointments: number;
  activeApplications: number;
}

export interface CustomerBranding {
  theme: BrandingTheme;
  logo: Attachment;
  favicon: Attachment;
  logoRedirectUrl?: string;
  itpSettings?: ITPSettings;

  iTPLogo?: Attachment;
  customerId?: string;
  pageTitle?: string;
  countries?: string[];
}

export interface ITPSettings {
  dmvCard: boolean;
  shopCard: boolean;
  schufaCard: boolean;
  milesCard: boolean;
  wechselPilotCard: boolean;
  whitelabel: boolean;
  allowUserToLeaveItp: boolean;
  showCreditScreeningDocumentUploadArea: boolean;
  movingChecklist: boolean;
  movingAuction: boolean;
  informalLanguage: boolean;
}

export interface UserProfileAdditionalInformation {
  animals?: boolean;
  bailment?: boolean;
  music?: boolean;
  housingPermission?: HousingPermission;
}

export interface PropertySearcherUserProfile extends Profile {
  dateOfBirth?: string;
  moveInDate?: string;
  householdType?: string;
  personalStatus?: string;
  residents?: number;
  profession?: {
    type?: EmploymentType;
    subType?: string;
    income?: number;
  };
  creditScreening?: {
    available?: boolean;
  };
  law?: {
    noRentArrears?: boolean;
    noPoliceRecord?: boolean;
    noTenancyLawConflicts?: boolean;
    informationTrueAndComplete?: boolean;
  };
  additionalInformation?: UserProfileAdditionalInformation;
  attachments?: Attachment[];
  furtherInformation?: string;
  guarantorExist?: boolean;

  creditScore?: Attachment[];
  incomeProof?: Attachment[];
  wbsDocument?: Attachment[];
  otherDocuments?: Attachment[];
}

export interface TenantInfo {
  userId?: string;
  name: string;
  firstName: string;
}

export enum ApplicationConversationStatus {
  NO_CONVERSATION = 'NO_CONVERSATION',
  HAS_CONVERSATION = 'HAS_CONVERSATION',
  HAS_NEW_MESSAGE = 'HAS_NEW_MESSAGE'
}
