import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';

import { BaseNavigationItem as NavigationItem } from '@ui/shared/models';
import { TranslateModule } from '@ngx-translate/core';
import { RouterLinkActive, RouterLink } from '@angular/router';

@Component({
  selector: 'app-navigation-item-v2',
  templateUrl: './navigation-item-v2.component.html',
  styleUrls: ['./navigation-item-v2.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [RouterLinkActive, RouterLink, TranslateModule]
})
export class NavigationItemV2Component {
  @Input() item: NavigationItem;
  @Input() exact: true;
  @Output() trackEvent = new EventEmitter<string>();

  public onTrackEvent(event?: string) {
    this.trackEvent.emit(event);
  }
}
