export enum UploadFileType {
  PDF = 'PDF',
  IMG = 'IMG',
  ZIP = 'ZIP',
  ICS = 'ICS',
  CSV = 'CSV',
  XLSX = 'XLSX',
  XML = 'XML'
}

export enum UploadDocumentType {
  FLOOR_PLAN = 'FLOOR_PLAN',
  VIDEO = 'VIDEO',
  ENERGY_CERTIFICATE = 'ENERGY_CERTIFICATE',
  WB_CERTIFICATE = 'WB_CERTIFICATE',
  CREDIT_REPORT = 'CREDIT_REPORT',
  SELF_ASSESSMENT = 'SELF_ASSESSMENT',
  INCOME_STATEMENT = 'INCOME_STATEMENT',
  PROOF_OF_PAYMENT = 'PROOF_OF_PAYMENT',
  MAILATTACHMENTS = 'MAILATTACHMENTS',
  SHARED_DOCUMENT = 'SHARED_DOCUMENT',
  RESIDENT_CONTENT = 'RESIDENT_CONTENT',
  RESIDENT_CONTENT_IMG = 'RESIDENT_CONTENT_IMG',
  DOCUMENT_MANAGEMENT = 'DOCUMENT_MANAGEMENT'
}
