import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

import { MessengerFilter } from 'libs/components/legacy/messenger/model/interface';

@Injectable()
export class MessengerFilterService {
  private filter: MessengerFilter = {};
  private _filter$ = new BehaviorSubject<MessengerFilter>({});

  get propertyId() {
    return this.filter.propertyId;
  }

  get agents() {
    return this.filter.agents;
  }

  get search() {
    return this.filter.search;
  }

  get page() {
    return this.filter.page;
  }

  get size() {
    return this.filter.size;
  }

  public init(filters?: MessengerFilter) {
    const { agents, conversationExists, propertyId } = filters || {};
    this.filter.propertyId = propertyId;
    this.filter.agents = agents || [];
    this.filter.search = null;
    this.filter.page = 0;
    this.filter.size = 9;
    this.filter.conversationExists = conversationExists;
    this.emit();
  }

  public setFilter(filter: MessengerFilter) {
    this.filter = { ...filter };
    this.emit();
  }

  public updateFilter(filter: MessengerFilter) {
    this.filter = { ...this.filter, ...filter };
    this.emit();
  }

  public reset(keptFilters: MessengerFilter = {}) {
    this.filter = {
      agents: this.filter.agents,
      propertyId: null,
      search: null,
      page: 0,
      size: 9,
      conversationExists: undefined,
      ...keptFilters
    };
    this.emit();
  }

  public setPropertyId(id: string) {
    this.filter.propertyId = id;
    this.emit();
  }

  public setPage(page: number) {
    this.filter.page = page;
    this.emit();
  }

  public setSize(size: number) {
    this.filter.size = size;
    this.emit();
  }

  private emit() {
    const result = {};
    Object.keys(this.filter).forEach(key => {
      if (this.filter[key] !== undefined && this.filter[key] !== null) {
        result[key] = this.filter[key];
      }
    });
    this._filter$.next(result);
  }

  public getFilter() {
    return this._filter$.asObservable().pipe(distinctUntilChanged());
  }
}
