import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output
} from '@angular/core';

import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  District,
  PropertyType,
  SearchProfile,
  SearchProfileType
} from '@ui/shared/models';
import { groupBy } from 'libs/utils/group-by';
import moment from 'moment';
import { BadgeColorEnum } from 'libs/components/atoms/badge/badge.enum';
import {
  isPropertyTypeCommercial,
  isPropertyTypeFlat,
  isSalesObject,
  isValueNullOrUndefined
} from 'libs/utils';
import { TranslateModule } from '@ngx-translate/core';
import { DecimalPipe, CurrencyPipe, KeyValuePipe } from '@angular/common';
import { SvgIconComponent } from 'angular-svg-icon';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { SortPipe } from '../../../../pipes/sort.pipe';
import { DateTimePipe } from '../../../../pipes/date-time.pipe';
import { AddressPipe } from '../../../../pipes/address.pipe';
import { ContextMenuItemComponent } from '../../../molecules/context-menu/context-menu-item/context-menu-item.component';
import { ContextMenuComponent } from '../../../molecules/context-menu/context-menu.component';
import { SlideToggleComponent } from '../../form/controls/slide-toggle/slide-toggle.component';
import { BadgeComponent } from '../../../atoms/badge/badge.component';

@Component({
  selector: 'app-search-profile-info',
  templateUrl: './search-profile-info.component.html',
  styleUrls: ['./search-profile-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    BadgeComponent,
    SlideToggleComponent,
    FormsModule,
    ReactiveFormsModule,
    NgbTooltip,
    ContextMenuComponent,
    ContextMenuItemComponent,
    SvgIconComponent,
    DecimalPipe,
    CurrencyPipe,
    KeyValuePipe,
    TranslateModule,
    AddressPipe,
    DateTimePipe,
    SortPipe
  ]
})
export class SearchProfileInfoComponent implements OnInit, OnChanges {
  @Input() searchProfile: SearchProfile;
  @Input() small = false;
  @Input() showToggleOption = true;
  @Input() hideMenu = false;
  @Input() showMarketingTypeBadge: boolean;
  @Output() editProfile = new EventEmitter<SearchProfile>();
  @Output() deleteProfile = new EventEmitter<string>();
  @Output() toggleProfile = new EventEmitter<string>();

  public isProfileActiveControl: FormControl;
  public districtsMap: Map<string, District[]>;
  public badgeColor = BadgeColorEnum;

  public get typeDistrict() {
    return SearchProfileType.DISTRICT;
  }

  public get typeRadius() {
    return SearchProfileType.RADIUS;
  }

  public get isProfileOutdated() {
    return moment().isAfter(this.searchProfile.durationEndDate);
  }

  public get isFlat() {
    return isPropertyTypeFlat(this.searchProfile?.propertyType as PropertyType);
  }

  public get isCommercial() {
    return isPropertyTypeCommercial(
      this.searchProfile?.propertyType as PropertyType
    );
  }

  public get isSalesObject() {
    return isSalesObject(this.searchProfile?.marketingType);
  }

  public get upperBoundRent() {
    return isValueNullOrUndefined(
      this.searchProfile.upperBoundRent || this.searchProfile.upperBoundBaseRent
    )
      ? Infinity
      : this.searchProfile.upperBoundRent ||
          this.searchProfile.upperBoundBaseRent;
  }

  public get priceUpperBoundSales() {
    return isValueNullOrUndefined(this.searchProfile.salesData?.priceUpperBound)
      ? Infinity
      : this.searchProfile.salesData?.priceUpperBound;
  }

  public get searchProfileCities() {
    return !this.searchProfile.districts?.length
      ? this.searchProfile.address.city
      : [
          ...groupBy(
            this.searchProfile?.districts || [],
            (d: District) => d.cityName
          )?.keys()
        ].join(' ,');
  }

  public get isInfinity() {
    return (
      (this.isSalesObject ? this.priceUpperBoundSales : this.upperBoundRent) ===
      Infinity
    );
  }

  public get isProjectSearchProfile(): boolean {
    return this.searchProfile?.type === SearchProfileType.PROJECT;
  }

  public ngOnInit() {
    this.isProfileActiveControl = new FormControl(
      !this.searchProfile.deactivated
    );
  }

  public ngOnChanges() {
    this.computeDistrictMap();
  }

  public getMappedDistrictNames(district: District[]) {
    return district.map(d => d.name)?.join(', ');
  }

  public onEditProfile() {
    this.editProfile.emit(this.searchProfile);
  }

  public onDeleteProfile() {
    this.deleteProfile.emit(this.searchProfile.id);
  }

  public onToggleProfile() {
    this.toggleProfile.emit(this.searchProfile.id);
  }

  public computeDistrictMap() {
    this.districtsMap = groupBy(
      this.searchProfile.districts,
      (d: District) => d.cityName
    );
  }
}
