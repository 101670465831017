import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  inject
} from '@angular/core';
import {
  ControlContainer,
  FormControl,
  FormGroup,
  Validators,
  FormsModule,
  ReactiveFormsModule
} from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { merge, Observable } from 'rxjs';

import {
  Address,
  COUNTRY_OPTIONS,
  CustomerLocation,
  CustomerSettings,
  InternationalizationSettings
} from '@ui/shared/models';
import { AddressFormConfig, DistrictForAddress } from '@ui/shared/models';
import { debounceTime, filter, switchMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import {
  DistrictState,
  getDistricts,
  getDistrictsIsLoading,
  loadDistrictsByAddress,
  resetDistricts
} from 'libs/infrastructure/base-state/district';
import { LocationSearchResultEntry } from 'libs/queries/location-search.queries';
import { TranslateModule } from '@ngx-translate/core';
import { LockableDataComponent } from '../lockable-data/lockable-data.component';
import { AutoCompleteFieldComponent } from '../../molecules/form/controls/auto-complete-field/auto-complete-field.component';
import { AppInputDirective } from '../form/controls/input/input.directive';
import { LocationSearchFieldComponent } from '../../molecules/form/controls/location-search-field/location-search-field.component';
import { DropdownSelectComponent } from '../form/controls/dropdown-select/dropdown-select.component';
import { FormFieldComponent } from '../form/form-field/form-field.component';
import { HintComponent } from '../hint/hint.component';
import { FormFieldLabelComponent } from '../form/form-field/form-field-label/form-field-label.component';
@UntilDestroy()
@Component({
  selector: 'app-address-form',
  templateUrl: './address-form.component.html',
  styleUrls: ['./address-form.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    FormFieldLabelComponent,
    HintComponent,
    FormFieldComponent,
    DropdownSelectComponent,
    LocationSearchFieldComponent,
    AppInputDirective,
    AutoCompleteFieldComponent,
    LockableDataComponent,
    TranslateModule
  ]
})
export class AddressFormComponent implements OnInit, OnDestroy {
  private store = inject<Store<DistrictState>>(Store);
  private controlContainer = inject(ControlContainer);

  public districtSelector$: Observable<DistrictForAddress[]>;
  public districtSelectorIsLoading$: Observable<boolean>;
  public inputWatcher$: Observable<string>;
  public addressForm: FormGroup;
  public countrySettings: InternationalizationSettings;
  public countryList = COUNTRY_OPTIONS;
  public useLocationSearchField = true;
  public districtsLoading = false;
  @Input() useStreetForSearch = false;
  @Input() label: string;
  @Input() showLabel = true;
  @Input() showBottomLabel = false;
  @Input() required = false;
  @Input() config: AddressFormConfig = {
    countryName: false,
    prefillFirstCountry: false,
    readOnlyCountryName: false,
    countryNameToolTip: '',
    useCustomerSettings: false,
    showCountry: false,
    appendCountryToSearch: false,
    preventPatchingCountryOnInit: false
  };
  @Input() readOnly = false;
  @Input() showRegion = true;
  @Input() showDistricts = true;
  @Input() locked = false;
  @Input() dropdownToggleOnFullWidth: boolean;
  @Input() customerSettings: CustomerSettings;
  @Input() resetValuesOnCountryChange = false;
  @Output() districtSelect = new EventEmitter<string>();

  public get zipCodeControl() {
    return this.addressForm.get('zipCode') as FormControl;
  }

  public get districtControl() {
    return this.addressForm.get('district') as FormControl;
  }

  private get cityControl() {
    return this.addressForm.get('city');
  }

  private get streetControl() {
    return this.addressForm.get('street');
  }

  private get houseNumberControl() {
    return this.addressForm.get('houseNumber');
  }

  private get countryControl() {
    return this.addressForm.get('country');
  }

  private countriesMap = new Map([
    ['DE', 5],
    ['AT', 4]
  ]);

  public ngOnInit() {
    this.addressForm = <FormGroup>this.controlContainer?.control;

    if (this.showDistricts) {
      this.districtSelectorIsLoading$ = this.store.select(
        getDistrictsIsLoading
      );

      this.districtSelectorIsLoading$
        .pipe(untilDestroyed(this))
        .subscribe(loading => (this.districtsLoading = loading));

      this.districtSelector$ = this.districtSelectorIsLoading$.pipe(
        filter(loading => !loading),
        switchMap(() => this.store.select(getDistricts))
      );

      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      this.inputWatcher$ = merge(
        this.zipCodeControl.valueChanges,
        this.cityControl.valueChanges,
        this.streetControl.valueChanges,
        this.houseNumberControl.valueChanges,
        this.countryControl?.valueChanges
      ).pipe(
        debounceTime(500),
        filter(() => this.allControlsAreValidAndNonEmpty())
      );
    }
    this.countryControl?.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((value: string) => {
        /* If we are appending non AT/DE country to search, we don't want to use the location search field
           because it will not return any results for non AT/DE countries, instead we use standard input field
         */
        if (
          ![CustomerLocation.DE, CustomerLocation.AT].includes(
            value as CustomerLocation
          ) &&
          this.config.appendCountryToSearch
        ) {
          this.useLocationSearchField = false;
        } else {
          this.useLocationSearchField = true;
        }

        if (this.countriesMap.has(value)) {
          const validators = [
            Validators.pattern(`^[0-9]{${this.countriesMap.get(value)}}$`)
          ];
          if (this.zipCodeControl.hasValidator(Validators.required)) {
            validators.push(Validators.required);
          }
          this.zipCodeControl.setValidators(validators);
        } else {
          this.zipCodeControl.clearValidators();
        }

        this.zipCodeControl.updateValueAndValidity({ emitEvent: false });
      });

    this.countrySettings = this.customerSettings?.internationalizationSettings;

    if (this.config?.useCustomerSettings) {
      this.countryList = this.countryList?.filter(item =>
        this.countrySettings?.countries.includes(
          item?.value as CustomerLocation
        )
      );
    }

    if (
      !this.countryControl?.value &&
      !this.config.preventPatchingCountryOnInit
    ) {
      this.patchCountryControl();
    }

    if (this.config?.prefillFirstCountry && !this.countryControl.value) {
      this.countryControl?.patchValue(this.countryList[0]?.value);
    }
  }

  public ngOnDestroy() {
    this.store.dispatch(resetDistricts());
  }

  public onNewInput(term: string) {
    if (!term) return;

    const { zipCode, street, city, houseNumber, country }: Address = this
      .addressForm.value as Address;

    this.store.dispatch(
      loadDistrictsByAddress({
        address: {
          zipCode,
          street,
          city,
          houseNumber,
          country
        }
      })
    );
  }

  public onSelect(value: DistrictForAddress) {
    this.districtControl.patchValue(value.name);
    this.addressForm.patchValue(
      {
        city: value.cityName,
        region: value.stateName
      },
      { emitEvent: false }
    );

    this.addressForm.get('region').setErrors(null);

    this.districtSelect.emit(value.id);
  }

  public onLocationSearchSelect(value: LocationSearchResultEntry) {
    this.addressForm.patchValue({
      city: value.properties.city,
      street: value.properties.street,
      zipCode: value.properties.postcode,
      houseNumber: value.properties.housenumber,
      country: this.config?.countryName
        ? value.properties.country
        : value.properties.countrycode
          ? value.properties.countrycode
          : CustomerLocation.DE,
      region: value.properties.state
    });
  }

  private allControlsAreValidAndNonEmpty() {
    return (
      this.zipCodeControl.valid &&
      !!this.zipCodeControl.value &&
      this.cityControl.valid &&
      !!this.cityControl.value &&
      this.streetControl.valid &&
      !!this.streetControl.value &&
      this.houseNumberControl.valid &&
      !!this.houseNumberControl.value &&
      this.countryControl?.valid &&
      !!this.countryControl?.value
    );
  }

  private patchCountryControl(): void {
    let patchValue: string;
    if (this.config?.useCustomerSettings) {
      patchValue = this.countrySettings?.defaultCountry;
    } else if (this.config?.showCountry) {
      patchValue = this.countryControl?.value as string;
    } else {
      patchValue = CustomerLocation.DE;
    }

    this.countryControl?.patchValue(patchValue || CustomerLocation.DE);
  }

  public onSelectCountry(country: string): void {
    if (
      country !== this.countryControl?.value &&
      this.resetValuesOnCountryChange
    ) {
      this.addressForm.reset({
        country: this.countryControl?.value,
        emitEvent: false
      });
    }
  }
}
