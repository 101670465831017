<div class="row col">
  @if (hierarchicalQuestion.data?.title && currentLanguage) {
    <app-form-field-label [showRequiredMarker]="true">
      {{
        hierarchicalQuestion.data?.title[currentLanguage] ||
          hierarchicalQuestion.data?.title[defaultLanguage] | translate
      }}
    </app-form-field-label>
  }
</div>
<div class="row">
  <div class="col-12">
    @if (isDate || isRangeDate) {
      <app-form-field>
        <app-date
          [(ngModel)]="value"
          [startDate]="isFutureDate ? startDateFutureStruct : startDateStruct"
          [maxDate]="isFutureDate ? maxDateFutureStruct : maxDateStruct"
          [minDate]="minDateStruct"
        ></app-date>
      </app-form-field>
    }
    @if (isText) {
      <app-form-field>
        <input
          type="text"
          required
          appInput
          [placeholder]="'custom_questions.type_answer_l' | translate"
          [(ngModel)]="value"
          class="form-control"
      /></app-form-field>
    }
    @if (isRangeNumber) {
      <app-form-field>
        <input
          type="number"
          required
          appInput
          [placeholder]="'custom_questions.type_answer_l' | translate"
          [(ngModel)]="value"
          class="form-control"
      /></app-form-field>
    }
  </div>
</div>
