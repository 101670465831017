import { inject, Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { CONSTANTS_LOADER } from '../../constants/constants-loader-service.token';

import * as fromActions from './constants.actions';

@Injectable()
export class ConstantsEffects {
  private actions$ = inject(Actions);
  private constantsLoader = inject(CONSTANTS_LOADER, { optional: true });

  fetchConstants$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromActions.FetchConstants>(fromActions.FETCH_CONSTANTS),
      switchMap(() =>
        this.constantsLoader.load().pipe(
          map(result => new fromActions.FetchConstantsSuccess(result)),
          catchError(err =>
            of(
              new fromActions.FetchConstantsFail(
                err ? err.message : 'Unexpected error'
              )
            )
          )
        )
      )
    )
  );
}
