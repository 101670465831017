import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  SimpleChanges
} from '@angular/core';

export enum AvatarSizeEnum {
  TINY = 'tiny',
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
  MAX = 'max'
}

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true
})
export class AvatarComponent implements OnChanges {
  @Input() imgSrc = '';
  @Input() name = '';
  @Input() size = AvatarSizeEnum.MAX;
  @Input() hideImage = false;
  @Input() rounded = false;

  public hasLoadError = false;
  public avatarSizeEnum = AvatarSizeEnum;

  public get userInitials() {
    const fullname = this.name.split(' ');
    const name = [fullname[0], fullname[fullname.length - 1]];

    return name.map(part => part && part[0] && part[0].toUpperCase()).join('');
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (
      changes.imgSrc &&
      changes.imgSrc.currentValue !== changes.imgSrc.previousValue
    ) {
      this.hasLoadError = false;
    }
  }

  public onLoadError() {
    this.hasLoadError = true;
  }
}
