import {
  IconTypeEnum,
  MarketingType,
  MarketingWorkflowState,
  MarketingWorkflowStateFilter,
  NameValue,
  PropertyType
} from '@ui/shared/models';

export const isRentalObject = (marketingType: MarketingType): boolean => {
  return marketingType === MarketingType.RENT;
};

export const isSalesObject = (marketingType: MarketingType): boolean => {
  return marketingType === MarketingType.SALES;
};

export const isPropertyTypeFlat = (propertyType: PropertyType): boolean => {
  return propertyType === PropertyType.FLAT;
};

export const isPropertyTypeGarage = (propertyType: PropertyType): boolean => {
  return propertyType === PropertyType.GARAGE;
};

export const isPropertyTypeCommercial = (
  propertyType: PropertyType
): boolean => {
  return propertyType === PropertyType.COMMERCIAL;
};

export const isPropertyTypeWithDistrict = (
  propertyType: PropertyType
): boolean => {
  return [PropertyType.COMMERCIAL, PropertyType.FLAT].includes(propertyType);
};

export const getPropertyTypeIcon = (type: PropertyType): string => {
  if (isPropertyTypeFlat(type)) {
    return IconTypeEnum.HouseType;
  } else if (isPropertyTypeGarage(type)) {
    return IconTypeEnum.Garage;
  } else {
    return IconTypeEnum.Building;
  }
};

export const generateObjectPhases = (
  hasSalesObjectAccess: boolean,
  showStateNew: boolean,
  showLastPhase = true
) => {
  const prefix = 'MARKETING_WORKFLOW_STATE.';
  const suffix = '_L';
  return Object.keys(MarketingWorkflowState).reduce((acc: NameValue[], key) => {
    if (key === MarketingWorkflowState.SOLD && !hasSalesObjectAccess) {
      return acc;
    }
    if (key === MarketingWorkflowState.NEW && !showStateNew) {
      /*
       NEW is filtered out in the marketing workflow state since it is not a valid state for manual change
       NEW is shown in the marketing workflow state when used in the marketing workflow state filter
      */
      return acc;
    }
    if (
      (key === MarketingWorkflowState.SOLD ||
        key === MarketingWorkflowState.RENTED_OUT) &&
      !showLastPhase
    ) {
      return acc;
    }
    acc.push({
      name: prefix + key + suffix,
      value: MarketingWorkflowState[key]
    });
    return acc;
  }, []);
};

export const generateObjectPhaseFilters = () => {
  const prefix = 'MARKETING_WORKFLOW_STATE.';
  const suffix = '_L';
  return Object.keys(MarketingWorkflowStateFilter).reduce(
    (acc: NameValue[], key) => {
      acc.push({
        name: prefix + key + suffix,
        value: MarketingWorkflowStateFilter[key]
      });
      return acc;
    },
    []
  );
};
