import { Component, Input } from '@angular/core';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-hint',
  templateUrl: './hint.component.html',
  styleUrls: ['./hint.component.scss'],
  standalone: true,
  imports: [NgbTooltip]
})
export class HintComponent {
  @Input() placement = 'top';
  @Input() container?: string;
}
