import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  contentChild,
  ContentChild,
  HostBinding,
  inject,
  Input,
  OnInit,
  QueryList,
  ViewEncapsulation
} from '@angular/core';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateModule } from '@ngx-translate/core';
import { NgTemplateOutlet } from '@angular/common';
import { AppClickAreaDirective } from '../controls/input/click-area.directive';
import { AppFormFieldControl } from './form-field-control/form-field-control';

import { FormFieldLabelComponent } from './form-field-label/form-field-label.component';
import { FormFieldErrorComponent } from './form-field-error/form-field-error.component';

import { getErrorMessage } from './form-field-error/error-message';

@UntilDestroy()
@Component({
  selector: 'app-form-field',
  templateUrl: './form-field.component.html',
  styleUrls: ['./form-field.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    AppClickAreaDirective,
    NgTemplateOutlet,
    FormFieldErrorComponent,
    FormFieldLabelComponent,
    TranslateModule
  ]
})
export class FormFieldComponent implements OnInit, AfterContentInit {
  private changeDetectorRef = inject(ChangeDetectorRef);

  @Input() label = '';
  @Input() labelBelowInput = false;
  @Input() markCompleted = false;
  @Input() disableErrorMessages = false;
  @Input() disabled = false;
  @Input() disabledClickArea = false;

  @ContentChild(AppFormFieldControl, { static: true })
  fieldControl: AppFormFieldControl<any>;
  projectedLabelChild = contentChild(FormFieldLabelComponent);
  @ContentChild(FormFieldErrorComponent, { static: true })
  projectedErrorChildren: QueryList<FormFieldErrorComponent>;
  @HostBinding('class.app-form-field') private mainClass = true;
  public errors: string[] = [];
  public showRequiredMarker: boolean;
  public controlId: string;

  ngOnInit() {
    this.validateInputChild();
    this.fieldControl.stateChanges.pipe(untilDestroyed(this)).subscribe(() => {
      if (!this.hasProjectedErrorChildren()) {
        const errorsMap = this.fieldControl.errors;
        this.errors = errorsMap
          ? // eslint-disable-next-line @typescript-eslint/no-unsafe-return
            Object.keys(errorsMap).map(key => getErrorMessage(key))
          : [];
      }
      this.changeDetectorRef.markForCheck();
    });
  }

  ngAfterContentInit() {
    this.showRequiredMarker =
      this.fieldControl?.required ||
      this.projectedLabelChild()?.showRequiredMarker;
    this.controlId = this.fieldControl?.id;

    if (this.projectedLabelChild()) {
      this.projectedLabelChild().showRequiredMarker = this.showRequiredMarker;
      this.projectedLabelChild().idFor = this.controlId;
    }
  }

  private hasProjectedErrorChildren() {
    return this.projectedErrorChildren?.length > 0;
  }

  private validateInputChild() {
    if (!this.fieldControl)
      throw Error('fieldControl missing in app-form-field');
  }
}
