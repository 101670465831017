<div class="custom-question-form__content">
  <div class="uppercase-m semibold mb16">
    {{ 'custom_questions.question_info_l' | translate }}
  </div>

  <form [formGroup]="form">
    <div class="custom-question-form__section">
      <div class="mb16">
        <app-form-field>
          <app-form-field-label>{{
            'custom_questions.question_name_l' | translate
          }}</app-form-field-label>
          <input
            appInput
            appAutofocus
            required
            [readonly]="readonly"
            [placeholder]="'custom_questions.type_question_name_l' | translate"
            formControlName="title"
            class="form-control"
          />
        </app-form-field>
      </div>

      <div class="mb16">
        <app-form-field>
          <app-checkbox
            [disabled]="readonly"
            [label]="'custom_questions.is_scoring_enabled_l' | translate"
            formControlName="scoring"
          ></app-checkbox>
        </app-form-field>
      </div>

      <div class="mb16">
        <div class="d-flex align-items-center justify-content-between">
          <div>
            <app-form-field-label
              >{{
                'custom_questions.select_answer_type_l' | translate
              }}
              *</app-form-field-label
            >
          </div>
          <app-hint placement="left" class="pl6">{{
            'custom_questions.select_type_hint' | translate
          }}</app-hint>
        </div>
        <div class="custom-question-form__types-select">
          <app-dropdown-select
            [items]="types"
            formControlName="type"
            [disabled]="readonly"
            class="custom-question-form__type-option"
          >
            <div dropdown-button>
              {{ 'custom_questions.choose_question_l' | translate }}
            </div>
            <ng-template let-item>{{ item.name | translate }}</ng-template>
          </app-dropdown-select>
          @if (isRange) {
            <span
              class="default-s"
              [innerHTML]="
                'custom_question.range_value_description_l' | translate
              "
            ></span>
          }
          @if (isRange) {
            <app-radio-group [isUnselectable]="false" formControlName="subType">
              @for (type of rangeTypes; track type) {
                <app-radio-button
                  [disabled]="readonly"
                  [value]="type.value"
                  class="custom-question-form__range-option-type col p0"
                >
                  {{ type.name | translate }}
                </app-radio-button>
              }
            </app-radio-group>
          }
        </div>
      </div>

      @if (isBoolean) {
        <div class="custom-question-form__options mb16">
          <div class="custom-question-form__option">
            <app-form-field-label
              >{{
                'custom_questions.preferred_answer_l' | translate
              }}
              *</app-form-field-label
            >
            <app-radio-group
              required
              [disabled]="readonly"
              formControlName="desiredBooleanAnswer"
            >
              <app-radio-button
                #radioButon
                [disabled]="readonly"
                class="custom-question-form__preferred-answer radio-group boolean mb4"
                [value]="true"
              >
                {{ 'general.yes_l' | translate }}
              </app-radio-button>
              <app-radio-button
                #radioButon
                [disabled]="readonly"
                class="custom-question-form__preferred-answer radio-group boolean mb4"
                [value]="false"
              >
                {{ 'general.no_l' | translate }}
              </app-radio-button>
            </app-radio-group>
          </div>
        </div>
      }
      @if (isSelect) {
        <div class="custom-question-form__options" formArrayName="options">
          @for (option of options.controls; track option; let i = $index) {
            <div class="custom-question-form__option mb16">
              <div
                class="custom-question-form__option-content"
                [formGroupName]="i"
              >
                <app-form-field>
                  <div
                    class="d-flex justify-content-between align-items-center custom-question-form__option-title"
                  >
                    <app-form-field-label>
                      {{ 'custom_questions.option_l' | translate }}
                      {{ i | appAlphaIndex }}
                    </app-form-field-label>
                    @if (!readonly) {
                      <div class="d-flex option-title__delete">
                        <svg-icon
                          src="/assets/images/icons/icon-bin.svg"
                          [applyClass]="true"
                          [svgStyle]="{ 'width.px': 30 }"
                          class="secondary-svg-color path rect"
                          (click)="removeOption(i)"
                        ></svg-icon>
                      </div>
                    }
                  </div>
                  <input
                    appInput
                    appAutofocus
                    required
                    [readonly]="readonly || isBoolean"
                    [placeholder]="'custom_questions.type_answer_l' | translate"
                    formControlName="name"
                    class="form-control mb8"
                  />
                </app-form-field>
                <div
                  class="d-flex custom-question-form__preferred-answer"
                  [ngClass]="{ disabled: readonly }"
                  (click)="preferredAnswerClick(option)"
                >
                  <app-check
                    formControlName="desired"
                    [forceDisabled]="readonly"
                  ></app-check>
                  <div class="custom-question-form__preferred-answer-label">
                    {{ 'custom_questions.preferred_answer_l' | translate }}
                  </div>
                </div>
                @if (
                  hasRangedOptions(option) && isRange && isDesiredOption(i)
                ) {
                  <div
                    class="custom-question-form__ranged-option-types"
                    formGroupName="rangedOptions"
                  >
                    <div class="d-flex">
                      <div
                        class="custom-question-form__ranged-option-min col p0 pr15"
                        formGroupName="min"
                      >
                        <div
                          class="d-flex custom-question-form__preferred-answer radio-group mb10"
                          [ngClass]="{ disabled: readonly }"
                          (click)="preferredAnswerClick(option, 'min')"
                        >
                          <app-check
                            formControlName="desired"
                            [forceDisabled]="readonly"
                          ></app-check>
                          <div
                            class="custom-question-form__preferred-answer-label"
                          >
                            {{
                              'custom_questions.prefered_range_answer_l'
                                | translate
                            }}
                          </div>
                        </div>
                        @if (isRangeDate) {
                          <app-form-field>
                            <app-form-field-label>{{
                              'custom_question.range_min_value_l' | translate
                            }}</app-form-field-label>
                            <app-date
                              [required]="!readonly"
                              [readonly]="readonly"
                              formControlName="value"
                              (keyup)="updateForm(option)"
                            >
                            </app-date>
                          </app-form-field>
                        }
                        @if (isRangeValue) {
                          <app-form-field>
                            <app-form-field-label>{{
                              'custom_question.range_min_value_l' | translate
                            }}</app-form-field-label>
                            <input
                              required
                              type="number"
                              appInput
                              [readonly]="readonly"
                              [placeholder]="
                                'custom_questions.type_answer_l' | translate
                              "
                              formControlName="value"
                              class="form-control"
                              (keyup)="updateForm(option)"
                            />
                          </app-form-field>
                        }
                      </div>
                      <div
                        class="custom-question-form__ranged-option-max col p0"
                        formGroupName="max"
                      >
                        <div
                          class="d-flex custom-question-form__preferred-answer radio-group mb10"
                          [ngClass]="{ disabled: readonly }"
                          (click)="preferredAnswerClick(option, 'max')"
                        >
                          <app-check
                            formControlName="desired"
                            [forceDisabled]="readonly"
                          ></app-check>
                          <div
                            class="custom-question-form__preferred-answer-label"
                          >
                            {{
                              'custom_questions.prefered_range_answer_l'
                                | translate
                            }}
                          </div>
                        </div>
                        @if (isRangeDate) {
                          <app-form-field>
                            <app-form-field-label>{{
                              'custom_question.range_max_value_l' | translate
                            }}</app-form-field-label>
                            <app-date
                              [required]="!readonly"
                              [readonly]="readonly"
                              formControlName="value"
                              (keyup)="updateForm(option)"
                            >
                            </app-date>
                          </app-form-field>
                        }
                        @if (isRangeValue) {
                          <app-form-field>
                            <app-form-field-label>{{
                              'custom_question.range_max_value_l' | translate
                            }}</app-form-field-label>
                            <input
                              type="number"
                              required
                              appInput
                              [readonly]="readonly"
                              [placeholder]="
                                'custom_questions.type_answer_l' | translate
                              "
                              formControlName="value"
                              class="form-control"
                              (keyup)="updateForm(option)"
                            />
                          </app-form-field>
                        }
                      </div>
                    </div>
                  </div>
                }
              </div>
            </div>
          }
          @if (!readonly) {
            <div
              class="custom-question-form__add-option mb10"
              (click)="addOption()"
            >
              <svg-icon
                src="/assets/images/icons/icon-add.svg"
                [applyClass]="true"
                [svgStyle]="{ 'width.px': 15 }"
                class="main-svg-color circle circle-fill mr6"
              ></svg-icon>
              {{ 'custom_questions.add_option_a' | translate }}
            </div>
          }
        </div>
      }

      <div>
        <app-form-field>
          <app-checkbox
            [disabled]="readonly"
            [label]="'custom_questions.allow_comment_answer_l' | translate"
            formControlName="commentAllowed"
          ></app-checkbox>
        </app-form-field>
      </div>

      @if (showCommentHint) {
        <div class="mb16">
          <app-form-field>
            <app-form-field-label>{{
              'custom_questions.comment_title_name_l' | translate
            }}</app-form-field-label>
            <input
              appInput
              [readonly]="readonly"
              [placeholder]="
                'custom_questions.type_default_comment_title' | translate
              "
              formControlName="commentHint"
              class="form-control"
            />
          </app-form-field>
        </div>
      }
      @if (global) {
        <div class="custom-question__score">
          <app-knockout-criteria
            [disabled]="readonly"
            [formControlInput]="getKnockoutInput"
          ></app-knockout-criteria>
          <app-form-field>
            <app-form-field-label>{{
              'custom_questions.global.importance_l' | translate
            }}</app-form-field-label>
            <app-slider
              [min]="0"
              [max]="10"
              [required]="global"
              [disabled]="readonly"
              formControlName="importance"
            >
            </app-slider>
          </app-form-field>
        </div>
      }
      @if (!readonly) {
        <div class="custom-question-form__policies mb16">
          <app-form-field>
            <app-checkbox
              [label]="'custom_questions.accept_policies_m' | translate"
              formControlName="policies"
            ></app-checkbox>
          </app-form-field>
        </div>
      }
    </div>
  </form>
</div>
