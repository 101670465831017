import { createSelector } from '@ngrx/store';

import * as fromReducers from '../reducers';
import * as fromAuthReducers from './auth.reducers';

export const getAuthState = createSelector(
  fromReducers.getBaseState,
  (state: fromReducers.BaseState) => state.auth
);

export const getKeycloakUrl = createSelector(
  getAuthState,
  fromAuthReducers.getKeycloakUrl
);

export const getUserCredentials = createSelector(
  getAuthState,
  fromAuthReducers.getUserCredentials
);

export const getLoginError = createSelector(
  getAuthState,
  fromAuthReducers.getLoginError
);

export const getRequestPasswordResetActionState = createSelector(
  getAuthState,
  fromAuthReducers.getRequestPasswordResetActionState
);

export const getResetPasswordActionState = createSelector(
  getAuthState,
  fromAuthReducers.getResetPasswordActionState
);

export const getEmailVerifiedInCurrentSession = createSelector(
  getAuthState,
  fromAuthReducers.getEmailVerifiedInCurrentSession
);

export const getIsLoading = createSelector(
  getAuthState,
  fromAuthReducers.getIsLoading
);
