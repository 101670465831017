import { gql } from 'apollo-angular';
import { SearchCity, SearchDistrict } from '@ui/shared/models';

export interface SearchForCitiesQueryResponse {
  searchCities: SearchCity[];
}

export interface GetCityDistrictsQueryResponse {
  getDistricts: SearchDistrict[];
}

export const searchForCitiesQuery = gql`
  query searchCities($country: String, $name: String) {
    searchCities(country: $country, name: $name) {
      id
      name
      state
    }
  }
`;

export const getCityDistrictsQuery = gql`
  query getDistricts($cityId: ID!) {
    getDistricts(cityId: $cityId) {
      id
      name
      cityName
    }
  }
`;
