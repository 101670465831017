@if (showV2) {
  <app-modal-v2>
    <div class="modal-m">
      <app-modal-content>
        <div class="modal-header">
          <app-button [type]="'link'" (clickEvent)="cancelAction()">{{
            cancelButtonMessage | translate
          }}</app-button>
        </div>
        <div class="modal-body confirm-reason__body">
          <div class="confirm-reason__scroll-container">
            <p class="title-m confirm-reason__title">
              {{ titleMessage | translate }}
            </p>
            <p class="default-s confirm-reason__message">
              {{ message | translate }}
            </p>
            <app-confirm-reason
              [(reasonTypeModel)]="reasonTypeModel"
              [(otherReasonText)]="otherReasonText"
              [reasons]="reasons"
            >
            </app-confirm-reason>
          </div>
        </div>
      </app-modal-content>
      <app-modal-footer>
        <div class="modal-footer confirm-reason__footer-v2">
          <app-button
            class="col"
            [type]="'negative'"
            [disabled]="isInvalid"
            (clickEvent)="okAction()"
            >{{ okButtonMessage | translate }}</app-button
          >
        </div>
      </app-modal-footer>
    </div>
  </app-modal-v2>
}
@if (!showV2) {
  <app-modal>
    <div class="modal-m">
      <app-modal-content>
        <div class="modal-body confirm-reason__body">
          <p class="title-m confirm-reason__title">
            {{ titleMessage | translate }}
          </p>
          <p class="default-s confirm-reason__message">
            {{ message | translate }}
          </p>
          <app-confirm-reason
            [(reasonTypeModel)]="reasonTypeModel"
            [(otherReasonText)]="otherReasonText"
            [reasons]="reasons"
          >
          </app-confirm-reason>
        </div>
      </app-modal-content>
      <app-modal-footer>
        <div class="modal-footer confirm-reason__footer">
          <app-button [type]="'light-bordered'" (clickEvent)="cancelAction()">{{
            cancelButtonMessage | translate
          }}</app-button>
          <app-button [disabled]="isInvalid" (clickEvent)="okAction()">{{
            okButtonMessage | translate
          }}</app-button>
        </div>
      </app-modal-footer>
    </div>
  </app-modal>
}
