import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  TemplateRef,
  ViewChild
} from '@angular/core';

import {
  Location,
  NgTemplateOutlet,
  PopStateEvent as LocationEvent,
  ViewportScroller
} from '@angular/common';
import { NavigationEnd, Router } from '@angular/router';
import { FormGroup } from '@angular/forms';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { Subject, SubscriptionLike } from 'rxjs';
import { filter } from 'rxjs/operators';

import { BodyModifierClass, BodyService } from 'libs/infrastructure';
import { TranslateModule } from '@ngx-translate/core';
import { ElevationDirective } from 'libs/directives';
import { ButtonComponent } from '../../atoms/button/button.component';
import { WizardStepDirective } from './wizard-step/wizard-step.directive';
import { Step, StepChange } from './models';

import { WizardProgressComponent } from './wizard-progress/wizard-progress.component';

@UntilDestroy()
@Component({
  selector: 'app-wizard',
  templateUrl: './wizard.component.html',
  styleUrls: ['./wizard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    WizardProgressComponent,
    NgTemplateOutlet,
    ButtonComponent,
    TranslateModule,
    ElevationDirective
  ]
})
export class WizardComponent implements OnDestroy, OnInit, AfterViewInit {
  private location = inject(Location);
  private router = inject(Router);
  private viewportScroller = inject(ViewportScroller);
  private bodyService = inject(BodyService);

  @Input() currentForm: FormGroup;
  @Input() steps: Step[];
  @Input() currentStepNumber: number;
  @Input() currentStepValid: boolean;
  @Input() allowCancel = false;
  @Input() allowCancelInAnyStep = false;
  @Input() allowBack = true;
  @Input() isProcessing = false;
  @Input() hideButtonContainer = false;
  @Input() wrapStepContainer = false;
  @Input() hideNextButton = false;
  @Input() floatingTop = true;
  @Input() floatingActions = true;
  @Input() formElement: HTMLFormElement;
  @Input() alwaysEnableButton = false;
  @Output() failedContinue = new EventEmitter();
  @ViewChild('wizardScrollAnchor') wizardScrollAnchor: ElementRef;

  public viewLoaded = false;

  @ContentChildren(WizardStepDirective, { read: TemplateRef })
  stepTemplates: QueryList<TemplateRef<WizardStepDirective>>;

  public stepChange: Subject<StepChange>;
  private locationSubscription: SubscriptionLike;

  /**
   * We are passing currentForm from edit-property.component.ts from LL app
   * as a work around for the ExpressionChangedAfterItHasBeenCheckedError.
   * this.currentForm.valid is true, After create property
   * this.currentForm.valid is false.
   */
  get isValid() {
    return (
      (this.currentForm && this.currentForm.valid) || this.currentStepValid
    );
  }

  ngOnInit() {
    this.stepChange = new Subject<StepChange>();

    this.locationSubscription = this.location.subscribe(location =>
      this.handleLocationChange(location)
    );

    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        untilDestroyed(this)
      )
      .subscribe(() => this.viewportScroller.scrollToPosition([0, 0]));

    this.bodyService.setBodyModifierClass(
      BodyModifierClass.WIZARD_ACTIONS_VISIBLE
    );
  }

  ngAfterViewInit() {
    // We wait for the view to be fully loaded before showing the wizard__actions-container,
    // otherwise edit.property.component will always be scrolled to bottom on init
    this.viewLoaded = true;
  }

  ngOnDestroy() {
    this.stepChange?.complete();
    this.locationSubscription?.unsubscribe();
    this.bodyService.unsetBodyModifierClass(
      BodyModifierClass.WIZARD_ACTIONS_VISIBLE
    );
  }

  next() {
    if (!this.isValid) {
      this.formElement?.dispatchEvent(
        new Event('submit', { cancelable: true })
      );
      this.currentForm.markAllAsTouched();
      this.failedContinue.emit();
    } else {
      this.stepChange.next({ action: 'next' });
      // TODO: check if this causes the invalid jump error
      this.viewportScroller.scrollToPosition([0, 0]);
      (
        this.wizardScrollAnchor.nativeElement as HTMLDivElement
      ).scrollIntoView();
    }
  }

  back() {
    if (!this.allowBack) return;
    this.stepChange.next({ action: 'back' });
    (this.wizardScrollAnchor.nativeElement as HTMLDivElement).scrollIntoView();
  }

  select(step: Step) {
    if (!step.selectable) return;
    this.stepChange.next({ action: 'select', step });
    (this.wizardScrollAnchor.nativeElement as HTMLDivElement).scrollIntoView();
  }

  complete() {
    if (!this.isValid) {
      this.formElement.dispatchEvent(new Event('submit', { cancelable: true }));
      this.currentForm.markAllAsTouched();
      this.failedContinue.emit();
    } else {
      this.stepChange.next({ action: 'complete' });
      (
        this.wizardScrollAnchor.nativeElement as HTMLDivElement
      ).scrollIntoView();
    }
  }

  cancel() {
    if (!this.allowCancel) return;
    this.stepChange.next({ action: 'cancel' });
    (this.wizardScrollAnchor.nativeElement as HTMLDivElement).scrollIntoView();
  }

  private handleLocationChange(location: LocationEvent) {
    const stepName = location.url.split('/').pop();
    const isBeforeCurrent =
      this.steps.findIndex(step => step.name === stepName) <
      this.currentStepNumber - 1;
    const isAfterCurrent =
      this.steps[this.currentStepNumber] &&
      this.steps[this.currentStepNumber].name === stepName;
    this.viewportScroller.scrollToPosition([0, 0]);

    if (isBeforeCurrent) {
      this.back();
    }

    if (isAfterCurrent) {
      this.next();
    }
  }
}
