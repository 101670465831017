export enum TimeType {
  FLEXIBLE = 'FLEXIBLE',
  EXACT = 'EXACT',
  STORAGE = 'STORAGE'
}

export enum MovingAuctionObjectType {
  HOUSE = 'HOUSE',
  APARTMENT = 'APARTMENT'
}

export enum SalutationType {
  MR = 'MR',
  MS = 'MS',
  COMPANY = 'COMPANY'
}

export enum StairwayType {
  STANDARD = 'STANDARD',
  NARROW = 'NARROW',
  WIDE = 'WIDE'
}

export enum DistanceToTransporterType {
  LESS_THAN_20 = 'LESS_THAN_20',
  LESS_THAN_50 = 'LESS_THAN_50',
  GREATER_THAN_50 = 'GREATER_THAN_50'
}

export enum NoParkingZoneType {
  NOT_NECCESSARY = 'NOT_NECCESSARY',
  CUSTOMER = 'CUSTOMER',
  MOVING_COMPANY = 'MOVING_COMPANY'
}

export enum MovingAuctionResponseType {
  CREATED = 'CREATED',
  ACCEPTED = 'ACCEPTED',
  ERROR = 'ERROR'
}
