export enum DigitalContractType {
  RENTAL = 'RENTAL'
}

export enum MappingFieldType {
  SIGNATURE = 'SIGNATURE',
  TEXT = 'TEXT',
  DATE = 'DATE',
  CHECKBOX = 'CHECKBOX'
}

export enum SignatureType {
  AES_MAIL = 'AES_MAIL',
  AES_OFFICE = 'AES_OFFICE',
  ES_MAIL = 'ES_MAIL',
  AES_SMS = 'AES_SMS',
  ES_OFFICE = 'ES_OFFICE',
  QES = 'QES'
}

export enum DigitalContractState {
  FINISHED = 'FINISHED',
  IN_PROGRESS = 'IN_PROGRESS',
  CANCELED = 'CANCELED',
  CONFIGURATION_NEEDED = 'CONFIGURATION_NEEDED',
  PROTECTED = 'PROTECTED'
}

export enum DigitalContractWorkflowState {
  EMBEDDED_SIGNING = 'EMBEDDED_SIGNING',
  AES_CHECK = 'AES_CHECK',
  VERIFY_CODE = 'VERIFY_CODE',
  AES_CODE_OK = 'AES_CODE_OK',
  AES_CODE_RETRY = 'AES_CODE_RETRY',
  AES_CODE_FAILED = 'AES_CODE_FAILED',
  AES_CODE_NOT_ALLOWED = 'USER_NOT_ALLOWED_TO_SUBMIT_AES_CODE',
  QES_CHECK = 'QES_CHECK',
  QES_OK = 'QES_OK'
}

export enum DigitalContractSignerState {
  WAITING = 'WAITING',
  SIGNED = 'SIGNED',
  CANCELED = 'CANCELED',
  INTERNAL_MAIL_SENT = 'INTERNAL_MAIL_SENT',
  INTERNAL_FLAT_VISITED_UPDATED = 'INTERNAL_FLAT_VISITED_UPDATED',
  INTERNAL_ONSITE_DATA_VERIFIED = 'INTERNAL_ONSITE_DATA_VERIFIED',
  INTERNAL_ITP_CODE_VERIFIED = 'INTERNAL_ITP_CODE_VERIFIED',
  DOCUSIGN_CREATED = 'DOCUSIGN_CREATED',
  DOCUSIGN_DELIVERED = 'DOCUSIGN_DELIVERED',
  DOCUSIGN_COMPLETED = 'DOCUSIGN_COMPLETED',
  INTERNAL_SWISSCOM_STARTED = 'INTERNAL_SWISSCOM_STARTED',
  INTERNAL_SWISSCOM_SUCCESS = 'INTERNAL_SWISSCOM_SUCCESS',
  INTERNAL_SWISSCOM_TAC_SUCCESS = 'INTERNAL_SWISSCOM_TAC_SUCCESS'
}

export enum DigitalContractFirstSigner {
  TENANT = 'TENANT',
  LANDLORD = 'LANDLORD'
}

export enum DigitalContractAction {
  DOWNLOAD = 'DOWNLOAD',
  DELETE = 'DELETE',
  EDIT = 'EDIT',
  EDIT_DOCUSIGN = 'EDIT_DOCUSIGN'
}

export enum DocuSignEvent {
  SIGNING_COMPLETE = 'SIGNING_COMPLETE',
  SEND = 'SEND'
}

export enum DigitalContractItpState {
  INIT = 'INIT',
  FAILED = 'FAILED',
  UPLOADED = 'UPLOADED',
  PENDING = 'PENDING',
  ACCEPTED = 'ACCEPTED',
  UNKNOWN = 'UNKNOWN',
  TECHNICAL_ERROR = 'TECHNICAL_ERROR'
}

export enum SchufaVerificationState {
  SUCCESS = 'SUCCESS', // done
  SUCCESS_AFTER_CONFIRMATION = 'SUCCESS_AFTER_CONFIRMATION', // show code input
  SUCCESS_DATA_NEEDS_TO_BE_CONFIRMED = 'SUCCESS_DATA_NEEDS_TO_BE_CONFIRMED', // call verifySchufaData
  DATA_NOT_CORRECT_NEEDS_TO_BE_RESTARTED = 'DATA_NOT_CORRECT_NEEDS_TO_BE_RESTARTED', // restart iban check
  DATA_NOT_CORRECT_AFTER_CONFIRMATION = 'DATA_NOT_CORRECT_AFTER_CONFIRMATION', // PS said IBAN not correct
  CANCEL = 'CANCEL', // Schufa not possible
  ERROR = 'ERROR', // Schufa not reachable: try again later
  MAX_SCHUFA_TRIES_EXCEEDED = 'MAX_SCHUFA_TRIES_EXCEEDED_L', // cancel
  HISTORY_MUST_BE_IN_CORRECT_STATE = 'HISTORY_MUST_BE_IN_CORRECT_STATE_L', // no confirmation required, show code input
  SCHUFA_VERIFICATION_ALREADY_FINISHED = 'SCHUFA_VERIFICATION_ALREADY_FINISHED_L', // show code input (no error msg)
  DATA_NEED_TO_BE_CONFIRMED_L = 'DATA_NEED_TO_BE_CONFIRMED_L' // call verifySchufaData (no error msg)
}
