<div class="logo" (click)="onClick()" [class.c-pointer]="!preventClick">
  @if (!logoExist) {
    @if (!landlordLogo && logo?.url) {
      <img class="logo__image" [src]="logo?.url" [alt]="logo?.title" />
    } @else {
      @if (landlordLogo?.url) {
        <img
          class="logo__image"
          [src]="landlordLogo?.url"
          [alt]="landlordLogo?.title"
        />
      }
    }
  } @else {
    <img
      src="/assets/images/logos/logo-immomio-main.svg"
      class="logo__image"
      alt="Immomio"
    />
  }
</div>
