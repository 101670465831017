export enum TimeInterval {
  MONTH = 'MONTH',
  YEAR = 'YEAR'
}

export enum TimeUnit {
  DAYS = 'DAYS',
  HOURS = 'HOURS',
  MINUTES = 'MINUTES'
}
