import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import {
  MatSliderDragEvent,
  MatSlider,
  MatSliderRangeThumb
} from '@angular/material/slider';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'app-range-slider',
  templateUrl: './range-slider.component.html',
  styleUrl: './range-slider.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatSlider, MatSliderRangeThumb, FormsModule, ReactiveFormsModule]
})
export class RangeSliderComponent {
  minFormControl = input.required<FormControl<number>>();
  maxFormControl = input.required<FormControl<number>>();

  min = input<number>();
  max = input<number>();
  step = input<number>(1);

  minAriaLabel = input<string>();
  maxAriaLabel = input<string>();

  /**
   * When the user stops dragging and leaves the nobs with the cursor,
   * the number bubbles should be hidden
   * @param event
   */
  dragEnd(event: MatSliderDragEvent) {
    event.source._isFocused = false;
  }
}
