import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NameValue } from '@ui/shared/models';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { AppInputDirective } from '../../form/controls/input/input.directive';
import { FormFieldLabelComponent } from '../../form/form-field/form-field-label/form-field-label.component';
import { FormFieldComponent } from '../../form/form-field/form-field.component';
import { RadioButtonComponent } from '../../form/controls/radio/radio-button/radio-button.component';
import { RadioGroupComponent } from '../../form/controls/radio/radio-group/radio-group.component';

@Component({
  selector: 'app-confirm-reason',
  templateUrl: './confirm-reason.component.html',
  styleUrls: ['./confirm-reason.component.scss'],
  standalone: true,
  imports: [
    RadioGroupComponent,
    FormsModule,
    RadioButtonComponent,
    FormFieldComponent,
    FormFieldLabelComponent,
    AppInputDirective,
    TranslateModule
  ]
})
export class ConfirmReasonComponent {
  @Input() reasonTypeModel: string;
  @Input() otherReasonText: string;
  @Input() reasons: NameValue[];

  @Output() reasonTypeModelChange = new EventEmitter<string>();
  @Output() otherReasonTextChange = new EventEmitter<string>();

  public get showText() {
    return this.reasonTypeModel === 'OTHER_REASON';
  }

  public onReasonTypeModelChange(value: string) {
    this.reasonTypeModelChange.emit(value);
  }

  public onOtherReasonTextChange(value: string) {
    this.otherReasonTextChange.emit(value);
  }
}
