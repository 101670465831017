<div class="wizard-progress">
  @for (step of steps; track step.name; let stepIndex = $index) {
    <div
      class="wizard-progress__step"
      [ngClass]="{
        'wizard-progress__step--current': currentStepNumber === stepIndex + 1,
        'wizard-progress__step--previous': currentStepNumber > stepIndex + 1,
        'wizard-progress__step--selectable': step.selectable
      }"
      (click)="onSelectStep(step)"
    >
      <div class="wizard-progress__node"></div>
      <p class="default-s wizard-progress__name">
        {{ step.nameNice | translate }}
      </p>
    </div>
  }
</div>
