import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Input
} from '@angular/core';

import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { AvailableLanguageCodesEnum, Language } from '@ui/shared/models';
import {
  ImageSources,
  ImageSrcSet
} from 'libs/components/atoms/image/image.model';
import { TranslateModule } from '@ngx-translate/core';
import { NgTemplateOutlet, UpperCasePipe } from '@angular/common';
import { LocaleFlagComponent } from '../../atoms/image/locale-flag/locale-flag.component';
import { ButtonComponent } from '../../atoms/button/button.component';
import { ContextMenuItemComponent } from '../context-menu/context-menu-item/context-menu-item.component';
import { ContextMenuComponent } from '../context-menu/context-menu.component';

@Component({
  selector: 'app-lang-picker',
  templateUrl: './lang-picker.component.html',
  styleUrls: ['./lang-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LangPickerComponent),
      multi: true
    }
  ],
  standalone: true,
  imports: [
    ContextMenuComponent,
    NgTemplateOutlet,
    ContextMenuItemComponent,
    ButtonComponent,
    LocaleFlagComponent,
    UpperCasePipe,
    TranslateModule
  ]
})
export class LangPickerComponent implements ControlValueAccessor {
  @Input() availableLanguages: Language[];
  @Input() defaultLanguageCode = AvailableLanguageCodesEnum.DE;
  @Input() showFullLanguageNames = false;
  @Input() ghostButton = false;
  @Input() selectNextLanguageOnClick = false;

  public value: AvailableLanguageCodesEnum;

  private onChange = (value: unknown) => value;
  private onTouch = () => null;

  public onLanguageChange(langCode: AvailableLanguageCodesEnum) {
    if (this.value === langCode) return;

    this.value = langCode;
    this.onChange(this.value);
    this.onTouch();
  }

  public onSelectNextLanguage(langCode: AvailableLanguageCodesEnum) {
    if (!this.selectNextLanguageOnClick) return;

    const nextLangIndex =
      this.availableLanguages.findIndex(
        language => language.code === langCode
      ) + 1;
    const nextLangCode =
      this.availableLanguages[
        nextLangIndex > this.availableLanguages.length - 1 ? 0 : nextLangIndex
      ].code;

    if (nextLangCode !== langCode) this.onLanguageChange(nextLangCode);
  }

  public get selectedLanguage(): Language {
    return (
      this.availableLanguages.find(lang => lang.code === this.value) ||
      this.availableLanguages.find(
        lang => lang.code === this.defaultLanguageCode
      ) ||
      this.availableLanguages[0]
    );
  }

  public getFlagImagePath(fileName: string): string {
    return `/assets/images/flags/${fileName}.png`;
  }

  public getFlagImageSources(flagCode: string): ImageSources {
    const srcSet: ImageSrcSet = {};
    for (let i = 0; i < 3; i++) {
      const pixelDensity = i + 1;
      srcSet[`x${pixelDensity}`] = `${this.getFlagImagePath(
        `${flagCode}${i !== 0 ? `@${pixelDensity}x` : ''}`
      )}`;
    }
    return [{ srcSet }];
  }

  public writeValue(value: AvailableLanguageCodesEnum) {
    this.value = value;
  }

  public registerOnChange(fn) {
    this.onChange = fn;
  }

  public registerOnTouched(fn) {
    this.onTouch = fn;
  }
}
