import { createSelector } from '@ngrx/store';
import * as fromReducers from '../reducers';
import * as fromDistrictsReducer from './district.reducer';

const getDistrictsState = createSelector(
  fromReducers.getBaseState,
  state => state.districts
);

export const getDistricts = createSelector(
  getDistrictsState,
  fromDistrictsReducer.getDistricts
);

export const getDistrictsIsLoading = createSelector(
  getDistrictsState,
  fromDistrictsReducer.getDistrictsIsLoading
);
