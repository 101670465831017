import { Pagination } from './data-access';

export interface BaseTag {
  id: string;
  title: string;
}

export interface ContactTag extends BaseTag {
  tagOwnershipType?: TagOwnershipType;
}

export interface SortedTag {
  name: string;
  value: string;
  type?: TagOwnershipType;
  systemTag?: boolean;
}

export interface TagsResponse {
  tags: ContactTag[];
  pagination: Pagination;
}

export interface PropertiesTagResponse {
  propertiesTags: {
    nodes: PropertiesTag[];
    page: Pagination;
  };
}

export interface PropertiesTag extends BaseTag {
  tagOwnershipType?: TagOwnershipType;
}

export enum TagOwnershipType {
  SHARED = 'SHARED',
  PERSONAL = 'PERSONAL',
  DYNAMIC = 'DYNAMIC'
}

export enum TagTypes {
  CONTACT = 'CONTACT',
  PROPERTY = 'PROPERTY'
}
