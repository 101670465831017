<div
  class="d-flex align-items-center justify-content-between flex-wrap custom-question__details"
>
  @if (isSelect) {
    <div class="d-flex custom-question__options">
      @if (isSingleSelect || isRanged) {
        <div class="mr6">
          {{ 'custom_questions.single_answer_l' | translate }}:
        </div>
      }
      @if (isMultiSelect) {
        <div class="mr6">
          {{ 'custom_questions.multiple_answer_l' | translate }}:
        </div>
      }
      @if (customQuestion?.options.length === 2) {
        <div>
          {{ customQuestion?.options[0].name }} /
          {{ customQuestion?.options[1].name }}
        </div>
      }
      @if (customQuestion?.options.length > 2) {
        <div>
          {{ customQuestion?.options.length }}
          {{ 'custom_questions.options_l' | translate }}
        </div>
      }
    </div>
  }
  @if (isBoolean) {
    <div class="d-flex custom-question__options">
      <div>
        {{ 'general.yes_l' | translate }} / {{ 'general.no_l' | translate }}
      </div>
    </div>
  }
  @if (desiredAnswers.length === 1) {
    <div class="d-flex custom-question__preferred">
      <div class="mr6">
        {{ 'custom_questions.preferred_answer_l' | translate }}:
      </div>
      @if (isSelect) {
        <div>{{ desiredAnswers[0] }}</div>
      }
      @if (isBoolean) {
        <div>
          {{
            (desiredAnswers[0] ? 'general.yes_l' : 'general.no_l') | translate
          }}
        </div>
      }
    </div>
  }
  @if (desiredAnswers.length > 1) {
    <div class="d-flex custom-question__preferred">
      <div class="mr6">
        {{ 'custom_questions.preferred_answers_l' | translate }}:
      </div>
      <div>
        {{ desiredAnswers.length }}
        {{ 'custom_questions.answers_l' | translate }}
      </div>
    </div>
  }
  @if (customQuestion?.global) {
    <div class="d-flex custom-question__preferred">
      <div class="mr6">{{ 'custom_questions.knockout_l' | translate }}:</div>
      <div>{{ customQuestion?.knockout | appHumanizeBoolean }}</div>
    </div>
  }
</div>
