import { Injectable, inject } from '@angular/core';
import {
  BodyModifierClass,
  BodyService
} from 'libs/infrastructure/browser/body';

@Injectable()
export class FreshworksService {
  private bodyService = inject(BodyService);

  public getWidgetsVisibility(): boolean {
    return !this.bodyService.hasBodyModifierClass(
      BodyModifierClass.HIDE_FRESHWORKS_WIDGETS
    );
  }

  public showWidgets(): void {
    this.bodyService.unsetBodyModifierClass(
      BodyModifierClass.HIDE_FRESHWORKS_WIDGETS
    );
  }

  public hideWidgets(): void {
    this.bodyService.setBodyModifierClass(
      BodyModifierClass.HIDE_FRESHWORKS_WIDGETS
    );
  }
}
