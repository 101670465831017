import { Discount } from '../discount.model';
import { Addon } from './addon.model';

export enum SubscriptionPeriod {
  MONTHLY = 'MONTHLY',
  YEARLY = 'YEARLY'
}

export interface Product {
  id?: string;
  subscriptionPeriod?: SubscriptionPeriod;
  addons?: Addon[];
  price?: CalculatedPrices;
  name?: string;
  description?: string;
  created?: Date;
  updated?: Date;
  discounts?: Discount;
}

export interface ProductOverview {
  trial?: boolean;
  product?: Product;
  dueDate?: string;
  nextProduct?: Product;
  discount?: Discount;
  addons?: Addon[];
}

export interface CalculatedPrices {
  weeklyPriceNet?: number;
  monthlyPriceNet?: number;
  yearlyPriceNet?: number;
  postDiscountWeeklyPriceNet?: number;
  postDiscountMonthlyPriceNet?: number;
  postDiscountYearlyPriceNet?: number;
  weeklyPriceGross?: number;
  monthlyPriceGross?: number;
  yearlyPriceGross?: number;
  postDiscountWeeklyPriceGross?: number;
  postDiscountMonthlyPriceGross?: number;
  postDiscountYearlyPriceGross?: number;
  vat?: number;
  setup?: number;
}

export interface AddonPrices {
  price: CalculatedPrices;
  nextPrice: CalculatedPrices;
}
