import {
  Component,
  ElementRef,
  forwardRef,
  Input,
  OnInit,
  ViewChild
} from '@angular/core';
import {
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  NgControl,
  Validators,
  FormsModule,
  ReactiveFormsModule
} from '@angular/forms';
import { AppFormFieldControl } from 'libs/components/legacy/form/form-field/form-field-control/form-field-control';
import { BaseControl } from 'libs/components/legacy/form/controls/base-control';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  matchControlValidatorFactory,
  patternValidator
} from 'libs/components/legacy/form/controls/validation';
import { TranslateModule } from '@ngx-translate/core';
import { NgClass } from '@angular/common';
import { SvgIconComponent } from 'angular-svg-icon';
import { AppInputDirective } from '../input/input.directive';
import { FormFieldLabelComponent } from '../../form-field/form-field-label/form-field-label.component';

@UntilDestroy()
@Component({
  selector: 'app-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PasswordComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => PasswordComponent),
      multi: true
    },
    {
      provide: AppFormFieldControl,
      useExisting: forwardRef(() => PasswordComponent)
    }
  ],
  standalone: true,
  imports: [
    FormFieldLabelComponent,
    AppInputDirective,
    FormsModule,
    ReactiveFormsModule,
    SvgIconComponent,
    NgClass,
    TranslateModule
  ]
})
export class PasswordComponent extends BaseControl<string> implements OnInit {
  @Input() label: string;
  @Input() showLabel: true;
  @Input() showRequiredBox = false;
  @ViewChild(NgControl, { static: true }) ngControl: NgControl;
  @ViewChild('input', { static: true }) passwordInput: ElementRef;

  public password: FormControl;

  private onChange: (args) => any = (): void => null;
  private onTouch: () => any = (): void => null;

  public boxVisible = false;

  public ngOnInit(): void {
    this.password = new FormControl('', {
      validators: Validators.compose([
        Validators.required,
        patternValidator(/\d/, {
          hasNumber: true
        }),
        // check whether the entered password has upper case letter
        patternValidator(/[A-Z]/, {
          hasCapitalCase: true
        }),
        // check whether the entered password has a lower case letter
        patternValidator(/[a-z]/, {
          hasSmallCase: true
        }),
        Validators.minLength(8),
        matchControlValidatorFactory('confirmedPassword', true)
      ])
    });

    this.password.valueChanges
      .pipe(untilDestroyed(this))
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      .subscribe(password => {
        this.value = password;
        this.onChange(password);
        this.onTouch();
      });
  }

  ngAfterViewInit(): void {
    // custom implementation from BaseControl to leave out updateValueAndValidity
    const outerControl = this.injector.get(NgControl).control;
    outerControl.markAsTouched = () => {
      if (!this.ngTouched) {
        this.ngControl?.control.markAsTouched();
        this.ngTouched = true;
      }
    };
  }

  public getIcon(condition: boolean): string {
    return `/assets/images/icons/${
      condition ? 'icon-unverified.svg' : 'icon-bordered-check.svg'
    }`;
  }

  public showPassword(): void {
    if (this.passwordInput.nativeElement.type === 'password') {
      this.passwordInput.nativeElement.type = 'text';
    } else {
      this.passwordInput.nativeElement.type = 'password';
    }
  }

  public showBox(): void {
    this.boxVisible = true;
  }

  public hideBox(): void {
    this.boxVisible = true;
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  public writeValue(obj: any): void {
    this.password.patchValue(obj);
  }

  public validate() {
    return this.password.valid ? null : { passwordInvalid: true };
  }
}
