import { inject, Injectable } from '@angular/core';

import { ActivatedRouteSnapshot } from '@angular/router';

import { PermissionService } from './permission.service';

interface PermissionData {
  permission: string[];
}

@Injectable()
export class PermissionResolver {
  private permissionService = inject(PermissionService);

  resolve(route: ActivatedRouteSnapshot) {
    const routeData = route.data as PermissionData;

    if (!routeData || !routeData.permission) {
      throw new Error(
        `Cannot resolve route ${String(route.url)}: missing permission name`
      );
    }

    const permissions = {};

    routeData.permission.forEach(
      permission =>
        (permissions[permission] =
          this.permissionService.hasPermission(permission))
    );

    return permissions;
  }
}
