import * as fromAppActions from './translation.actions';

export interface TranslationState {
  loaded: boolean;
  loadedInformal: boolean;
  loading: boolean;
  error: string | null;
}

export const initialState: TranslationState = {
  loaded: false,
  loadedInformal: false,
  loading: false,
  error: null
};

export function reducer(
  state: TranslationState = initialState,
  action: fromAppActions.TranslationAction
): TranslationState {
  switch (action.type) {
    case fromAppActions.LOAD_TRANSLATION: {
      return {
        ...state,
        loading: true
      };
    }

    case fromAppActions.LOAD_TRANSLATION_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        loadedInformal: action.informal,
        error: null
      };
    }

    case fromAppActions.LOAD_TRANSLATION_FAIL: {
      return {
        ...state,
        loading: false,
        error: action.error
      };
    }

    case fromAppActions.LOAD_DEFAULT_TRANSLATION: {
      return {
        ...state,
        loaded: true,
        error: null
      };
    }

    default: {
      return state;
    }
  }
}

export const getLoaded = (state: TranslationState) => state.loaded;
export const getLoadedInformal = (state: TranslationState) =>
  state.loadedInformal;
export const getLoading = (state: TranslationState) => state.loading;
export const getError = (state: TranslationState) => state.error;
