import { Injectable } from '@angular/core';

interface InMemoryStorage {
  [key: string]: any;
}

@Injectable()
export class InMemoryStorageService {
  private storage: InMemoryStorage = {};

  public setData<T>(key: string, data: T) {
    this.storage[key] = data;
  }

  public getData<T>(key: string, persist = false) {
    const result: T = this.storage[key];

    if (!persist) {
      this.storage[key] = undefined;
    }

    return result;
  }

  public exists(key: string) {
    return this.storage[key] !== undefined;
  }
}
