import {
  ChangeDetectionStrategy,
  Component,
  computed,
  EventEmitter,
  inject,
  input,
  Input,
  Output
} from '@angular/core';

import moment from 'moment';

import { Conversation } from 'libs/components/legacy/messenger/model/interface';
import { AvatarSizeEnum } from 'libs/components/legacy/avatar/avatar.component';
import { chatBadgeColor } from 'libs/components/legacy/messenger/components/chat-contact-item/chat-information';
import { TranslateModule } from '@ngx-translate/core';
import { BadgeSizeEnum } from 'libs/components/atoms/badge/badge.enum';
import { FullNamePipe } from '../../../../../pipes/full-name.pipe';
import { DateTimePipe } from '../../../../../pipes/date-time.pipe';
import { BadgeComponent } from '../../../../atoms/badge/badge.component';
import { AvatarComponent } from '../../../avatar/avatar.component';

@Component({
  selector: 'app-chat-contact-item',
  templateUrl: './chat-contact-item.component.html',
  styleUrls: ['./chat-contact-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    AvatarComponent,
    BadgeComponent,
    TranslateModule,
    DateTimePipe,
    FullNamePipe
  ]
})
export class ChatContactItemComponent {
  private datePipe = inject(DateTimePipe);

  conversation = input.required<Conversation>();
  @Input() isLandlord: boolean;
  @Input() isPropertySearcher: boolean;
  @Input() activeConversation: Conversation;
  @Output() selectConversation = new EventEmitter<Conversation>();

  timeOfLastMessage = computed(() => {
    const lastMessage = this.conversation()?.lastMessageDate;
    if (this.isToday(lastMessage)) {
      return this.datePipe.transform(lastMessage, {
        withDate: false,
        withTime: true
      });
    }
    if (this.isLastSevenDays(lastMessage)) {
      return this.datePipe.transform(lastMessage, { customPattern: 'dd' });
    }
    if (this.isInThePast(lastMessage)) {
      return this.datePipe.transform(lastMessage, { customPattern: 'MMM DD' });
    }
  });

  protected readonly AvatarSizeEnum = AvatarSizeEnum;
  protected readonly chatBadgeColor = chatBadgeColor;

  public removeBreaks(message: string) {
    if (!message) return;
    let replacedMessage = message;
    replacedMessage = replacedMessage.replace(/<br \/>/gi, ' ');
    replacedMessage = replacedMessage.replace(/<br\/>/gi, ' ');
    return replacedMessage;
  }

  public onSelectConversation(conversation: Conversation) {
    this.selectConversation.emit(conversation);
  }

  public isToday(date: string) {
    return moment().isSame(date, 'days');
  }

  public isLastSevenDays(date: string) {
    return moment().diff(date, 'days') <= 7 && !moment().isSame(date, 'days');
  }

  public isInThePast(date: string) {
    return !this.isToday(date) && !this.isLastSevenDays(date);
  }

  protected readonly BadgeSizeEnum = BadgeSizeEnum;
}
