import { ToastOptions } from './toast-options';

type ToastType = 'success' | 'error' | 'info' | 'warning';

export class Toast {
  id: number;
  timeoutId: number;
  duration: number;
  dismissOnClick: boolean;

  constructor(
    public type: ToastType,
    public message: string,
    options: ToastOptions = {}
  ) {
    this.duration = options.duration || 5000;
    this.dismissOnClick =
      options.dismissOnClick !== undefined ? options.dismissOnClick : true;
  }
}
