import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { SelectComponent } from '../../../form/controls/select/select.component';
import { FormFieldLabelComponent } from '../../../form/form-field/form-field-label/form-field-label.component';
import { FormFieldComponent } from '../../../form/form-field/form-field.component';

@Component({
  selector: 'app-page-size-selection',
  templateUrl: './page-size-selection.component.html',
  styleUrls: ['./page-size-selection.component.scss'],
  standalone: true,
  imports: [
    FormFieldComponent,
    FormFieldLabelComponent,
    SelectComponent,
    FormsModule,
    TranslateModule
  ]
})
export class PageSizeSelectionComponent {
  @Input() pageSize: number;
  @Input() disabled: boolean;
  @Input() forceDisplay: boolean;
  @Input() selectableSizes: number[];

  @Output() sizeChange = new EventEmitter<number>();

  onSizeChange(pageNumber: number) {
    this.sizeChange.emit(pageNumber);
  }
}
