import { ProjectStatus, ApplicantStatus } from '@ui/shared/models';

export interface StatusPipelineConfig {
  tooltip: string;
  stepId?: number;
  label?: string;
  garageLabel?: string;
  commercialLabel?: string;
}

export interface TenantApplicationStatusConfig {
  forceDisplayOfAddress: boolean;
  actionNeeded: boolean;
  isTenant: boolean;
  hide: boolean;
  pipeline: StatusPipelineConfig;
}

type ApplicationStatusConfigs = Record<
  ApplicantStatus,
  TenantApplicationStatusConfig
>;

type ProjectStatusConfigs = Record<
  ProjectStatus,
  TenantApplicationStatusConfig // TODO: adapt project specific variant!
>;

const defaultPipelineStatusConfig: TenantApplicationStatusConfig = {
  forceDisplayOfAddress: false,
  actionNeeded: false,
  isTenant: false,
  hide: false,
  pipeline: {
    tooltip: ''
  }
};

export const unknownApplicationStatusConfig: TenantApplicationStatusConfig = {
  ...defaultPipelineStatusConfig,
  hide: true
};

const newProcessStatusConfig = {
  ...defaultPipelineStatusConfig,
  actionNeeded: true,
  pipeline: {
    stepId: 0,
    tooltip: 'properties.banner.new_match_tooltip_l'
  }
};

export const DEFAULT_APPLICATION_STATUS = ApplicantStatus.NEW_MATCH;
export const APPLICATION_STATUS_CONFIGS: ApplicationStatusConfigs = {
  // Unknown application status
  // TODO: define missing configurations
  [ApplicantStatus.DENIEDBYLL]: unknownApplicationStatusConfig,
  [ApplicantStatus.DENIEDBYPS]: unknownApplicationStatusConfig,
  [ApplicantStatus.NO_REPLY]: unknownApplicationStatusConfig,
  [ApplicantStatus.PROSPECT]: unknownApplicationStatusConfig,
  [ApplicantStatus.UNANSWERED]: unknownApplicationStatusConfig,
  [ApplicantStatus.ACCEPTED]: unknownApplicationStatusConfig,

  // Known application status
  [ApplicantStatus.OFFERED]: newProcessStatusConfig,
  [ApplicantStatus.AUTO_OFFERED]: newProcessStatusConfig,
  [ApplicantStatus.NEW_MATCH]: newProcessStatusConfig,
  [ApplicantStatus.WAITING_FOR_LANDLORD]: {
    ...defaultPipelineStatusConfig,
    pipeline: {
      stepId: 1,
      tooltip: 'properties.banner.waiting_for_landlord_tooltip_l'
    }
  },
  [ApplicantStatus.INVITED_TO_VIEWING]: {
    ...defaultPipelineStatusConfig,
    forceDisplayOfAddress: true,
    actionNeeded: true,
    pipeline: {
      stepId: 2,
      tooltip: 'properties.banner.invited_to_viewing_tooltip_l'
    }
  },
  [ApplicantStatus.NO_OPEN_SLOTS]: {
    ...defaultPipelineStatusConfig,
    forceDisplayOfAddress: true,
    pipeline: {
      stepId: 3,
      label: 'properties.banner.no_open_slots_available_l',
      garageLabel: 'properties.banner.no_open_slots_available_l',
      commercialLabel: 'properties.banner.no_open_slots_available_l',
      tooltip: 'properties.banner.no_open_slots_available_tooltip_l'
    }
  },
  [ApplicantStatus.ATTENDING_TO_VIEWING]: {
    ...defaultPipelineStatusConfig,
    forceDisplayOfAddress: true,
    pipeline: {
      stepId: 3,
      label: 'application.status_pipeline.viewing.label',
      garageLabel: 'application.status_pipeline.viewing.label',
      commercialLabel: 'application.status_pipeline.viewing.label',
      tooltip: 'properties.banner.attending_to_viewing_tooltip_l'
    }
  },
  [ApplicantStatus.ALL_APPOINTMENTS_DECLINED]: {
    ...defaultPipelineStatusConfig,
    forceDisplayOfAddress: true,
    pipeline: {
      stepId: 3,
      label: 'properties.banner.all_appointments_declined_l',
      garageLabel: 'properties.banner.all_appointments_declined_l',
      commercialLabel: 'properties.banner.all_appointments_declined_l',
      tooltip: 'properties.banner.all_appointments_declined_tooltip_l'
    }
  },
  [ApplicantStatus.DECLARE_INTENT]: {
    ...defaultPipelineStatusConfig,
    forceDisplayOfAddress: true,
    actionNeeded: true,
    pipeline: {
      stepId: 4,
      tooltip: 'properties.banner.declare_intent_tooltip_l'
    }
  },
  [ApplicantStatus.INTENT]: {
    ...defaultPipelineStatusConfig,
    forceDisplayOfAddress: true,
    pipeline: {
      tooltip: 'properties.banner.intent_tooltip_l',
      label: 'properties.banner.intent_l',
      garageLabel: 'properties.banner.intent_l',
      commercialLabel: 'properties.banner.intent_l'
    }
  },
  [ApplicantStatus.NO_INTENT]: {
    ...defaultPipelineStatusConfig,
    forceDisplayOfAddress: true,
    hide: true,
    pipeline: {
      tooltip: 'properties.banner.no_intent_tooltip_l',
      label: 'properties.banner.no_intent_l',
      garageLabel: 'properties.banner.no_intent_l',
      commercialLabel: 'properties.banner.no_intent_l'
    }
  },
  [ApplicantStatus.REJECTED]: {
    ...defaultPipelineStatusConfig,
    forceDisplayOfAddress: true,
    hide: true,
    pipeline: {
      tooltip: 'properties.banner.rejected_tooltip_l',
      label: 'properties.banner.rejected_l',
      garageLabel: 'properties.banner.rejected_l',
      commercialLabel: 'properties.banner.rejected_l'
    }
  },
  [ApplicantStatus.TENANT]: {
    ...defaultPipelineStatusConfig,
    forceDisplayOfAddress: true,
    isTenant: true,
    pipeline: {
      tooltip: 'properties.banner.accepted_as_tenant_tooltip_l',
      label: 'properties.banner.accepted_as_tenant_l',
      garageLabel: 'properties.banner.garage.accepted_as_tenant_l',
      commercialLabel: 'properties.banner.commercial.accepted_as_tenant_l'
    }
  }
};

export const DEFAULT_PROJECT_STATUS = ProjectStatus.PLANNING;
export const PROJECT_STATUS_CONFIGS: ProjectStatusConfigs = {
  [ProjectStatus.PLANNING]: {
    ...defaultPipelineStatusConfig,
    pipeline: {
      stepId: 0,
      label: 'project.status_pipeline.planning.label',
      tooltip: 'project.status_pipeline.planning.tooltip'
    }
  },
  [ProjectStatus.ANNOUNCEMENT]: {
    ...defaultPipelineStatusConfig,
    pipeline: {
      stepId: 1,
      label: 'project.status_pipeline.announcement.label',
      tooltip: 'project.status_pipeline.announcement.tooltip'
    }
  },
  [ProjectStatus.MARKETING]: {
    ...defaultPipelineStatusConfig,
    pipeline: {
      stepId: 2,
      label: 'project.status_pipeline.marketing.label',
      tooltip: 'project.status_pipeline.marketing.tooltip'
    }
  },
  [ProjectStatus.RENTED]: {
    ...defaultPipelineStatusConfig,
    pipeline: {
      stepId: 3,
      label: 'project.status_pipeline.rented.label',
      tooltip: 'project.status_pipeline.rented.tooltip'
    }
  }
};
