<app-tag
  class="labels-tag me-2 align-self-center"
  [name]="name"
  [tagType]="tagType"
  [isSystemTag]="isSystemTag"
  [nowrap]="true"
>
  @if (
    readonly
      ? false
      : isSystemTag
        ? (tag | appIsItemInArray: [SystemTag.TENANT, SystemTag.BUYER])
        : tagOwnershipType !== TagOwnershipType.DYNAMIC
  ) {
    <i
      [class]="'icon icon--close c-pointer'"
      icon
      (click)="unassignTag.emit(tag)"
      [ngClass]="
        tag?.ownershipType === 'SHARED' ? 'white-svg-color' : 'black-svg-color'
      "
    ></i>
  }
</app-tag>
