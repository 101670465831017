@for (commentObj of editableComments; track commentObj.id; let i = $index) {
  <div class="single-comment__container">
    <div class="d-flex content__container">
      @if (commentObj?.property?.titleImage) {
        <div class="single-comment__image">
          <img
            src="{{ commentObj?.property?.titleImage?.url }}"
            alt="{{ commentObj?.property?.titleImage?.title }}"
          />
        </div>
      }
      <div class="single-comment__details">
        <div class="row mr0 ml0">
          <div class="col-6 p0">
            <p class="default-m comment-details__name">
              @if (commentObj.fromThirdParty) {
                {{ 'comment.external_comment_l' | translate }}
              } @else {
                {{ commentObj?.agentInfo?.firstName }}&nbsp;{{
                  commentObj?.agentInfo?.name
                }}
              }
            </p>
            <p class="default-s comment-details__excerpt">
              {{ commentObj?.comment }}
            </p>
          </div>
          @if (commentObj?.agentInfo?.id === userId && allowCommentEdit) {
            <div class="col-6 p0 d-flex justify-content-end">
              <app-context-menu>
                <div menu-content>
                  @if (!commentObj.fromThirdParty) {
                    <app-context-menu-item (clickEvent)="editComment(i)">
                      {{ 'comment.edit_a' | translate }}
                    </app-context-menu-item>
                  }
                  <app-context-menu-item
                    (clickEvent)="onDeleteComment(commentObj.id)"
                  >
                    {{ 'comment.remove_a' | translate }}
                  </app-context-menu-item>
                </div>
              </app-context-menu>
            </div>
          }
        </div>
      </div>
    </div>
    @if (commentObj.editMode) {
      <div class="col-12 d-flex p12">
        <input
          appInput
          #editCommentInput
          type="text"
          placeholder="{{ 'comment.edit_placeholder_l' | translate }}"
          [value]="commentObj.comment"
          class="form-control"
        />
        <app-button
          class="secondary ms-2"
          (clickEvent)="cancelEdit(i)"
          [type]="'light-bordered'"
        >
          {{ 'CANCEL_A' | translate }}
        </app-button>
        <app-button
          class="default ms-2"
          [loading]="updatingComment"
          [disabled]="updatingComment || !editCommentInput.value"
          (clickEvent)="onUpdateComment(commentObj, editCommentInput.value)"
        >
          {{ 'comment.update_a' | translate }}
        </app-button>
      </div>
    }
    <hr class="m0" />
    <div class="d-flex content__container">
      <div
        class="d-flex align-items-center single-comment__property_details"
        (click)="onShowProperty(commentObj?.property?.id)"
      >
        @if (commentObj?.property) {
          <p class="default-m property-details__name">
            {{ commentObj?.property?.data?.name }}
          </p>
        }
        @if (commentObj?.property?.externalId) {
          <p class="default-s property-details__external-id">
            {{ 'property.external_id_l' | translate }}
            {{ commentObj?.property?.externalId }}
          </p>
        }
        @if (!commentObj?.property) {
          <p class="default-m property-details__name">
            {{ 'comment.contact_list_l' | translate }}
          </p>
        }
      </div>
      <div class="d-flex align-items-center">
        @if (commentObj.updated) {
          <span class="comment-updated">{{
            'comment.updated_l' | translate
          }}</span>
        }
        <p class="default-s comment-details__date">
          {{ commentObj?.updated || commentObj?.created | appDateTime }}
        </p>
      </div>
    </div>
  </div>
}
