import { ValidationErrors } from '@angular/forms';

import { Subject } from 'rxjs';

export abstract class AppFormFieldControl<T> {
  value: T;
  id: string;
  placeholder: string;
  required: boolean;
  touched: boolean;
  count: boolean;
  counter: number;
  errors: ValidationErrors;
  name?: string;
  type?: string;
  initialType?: string;
  element?: { nativeElement: HTMLElement };
  readonly stateChanges: Subject<void>;
}
