import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-modal-v2',
  templateUrl: './modal-v2.component.html',
  styleUrls: ['./modal-v2.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true
})
export class ModalV2Component {}
