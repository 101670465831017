import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  inject,
  Input
} from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseControl } from 'libs/components/legacy/form';
import {
  AvailableLanguageCodesEnum,
  HierarchicalQuestion,
  HierarchicalQuestionSelectionAnswerData
} from '@ui/shared/models';
import { HierarchicalQuestionService } from 'libs/services';
import { TranslateModule } from '@ngx-translate/core';
import { FlatSelectComponent } from '../../../form/controls/flat-select/flat-select.component';
import { FormFieldLabelComponent } from '../../../form/form-field/form-field-label/form-field-label.component';
import { FormFieldComponent } from '../../../form/form-field/form-field.component';

@Component({
  selector: 'app-hierarchical-question-selection',
  templateUrl: './hierarchical-question-selection.component.html',
  styleUrls: ['./hierarchical-question-selection.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => HierarchicalQuestionSelectionComponent),
      multi: true
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    FormFieldComponent,
    FormFieldLabelComponent,
    FlatSelectComponent,
    FormsModule,
    TranslateModule
  ]
})
export class HierarchicalQuestionSelectionComponent extends BaseControl<any> {
  private hierarchicalQuestionService = inject(HierarchicalQuestionService);

  @Input() hierarchicalQuestion: HierarchicalQuestion;
  @Input() currentLanguage: AvailableLanguageCodesEnum =
    AvailableLanguageCodesEnum.DE;
  @Input() defaultLanguage: AvailableLanguageCodesEnum;

  public writeValue(value: any) {
    if (this.hierarchicalQuestion.data?.maxAnswers > 1) {
      this.value = value ? value : [];
      return;
    }

    this.value = value;
  }

  public get options() {
    return (
      [...this.hierarchicalQuestion.answers]
        .sort(this.hierarchicalQuestionService.sort)
        .map(answer => ({
          name: (answer.data as HierarchicalQuestionSelectionAnswerData).title,
          value: answer.id
        })) || []
    );
  }
}
