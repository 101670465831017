export const messengerNotificationConfig = {
  messenger: {
    loadConversations: {
      success: 'messenger.load_conversation_success_m',
      error: 'messenger.load_conversation_fail_m'
    },
    loadConversationMessages: {
      success: 'messenger.load_conversation_messages_success_m',
      error: 'messenger.load_conversation_messages_fail_m'
    },
    sendMessage: {
      success: 'messenger.send_messages_success_m',
      error: 'messenger.send_messages_fail_m'
    },
    bulkSendMessage: {
      success: 'messenger.bulk_send_messages_success_m',
      error: 'messenger.bulk_send_messages_fail_m'
    },
    config: {
      success: 'messenger.save_messenger_config_m',
      error: 'messenger.save_messenger_config_m'
    },
    findUnreadMessages: {
      error: 'messenger.cant_load_unread_messages_m'
    },
    archiveConversation: {
      success: 'property_searcher.archive_conversation_success_m',
      error: 'property_searcher.archive_conversation_error_m'
    },
    unarchiveConversation: {
      success: 'property_searcher.unarchive_conversation_success_m',
      error: 'property_searcher.unarchive_conversation_error_m'
    }
  },
  template: {
    create: {
      success: 'messenger.create_success_message_m',
      error: 'messenger.create_fail_message_m'
    },
    update: {
      success: 'messenger.update_success_message_m',
      error: 'messenger.update_fail_message_m'
    },
    delete: {
      success: 'messenger.delete_success_message_m',
      error: 'messenger.delete_fail_message_m'
    }
  }
};
