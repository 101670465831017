import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'appIsItemInArray',
  standalone: true
})
export class IsItemInArrayPipe<T> implements PipeTransform {
  transform(item: T, array: T[]): boolean {
    return array.includes(item);
  }
}
