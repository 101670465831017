export enum AvailableLanguageCodesEnum {
  DE = 'de',
  EN = 'en'
}

export type MultiLanguageString = {
  [value in AvailableLanguageCodesEnum]: string;
};

export interface Language {
  code: AvailableLanguageCodesEnum;
  name: string;
  extendedLocale: string;
  label: string;
  nativeLabel: string; // the label written in the resp. language – no i18n needed!
}
