export enum PaymentMethodType {
  INVOICE = 'INVOICE',
  DEFAULT = 'DEFAULT'
}

export class PaymentMethod {
  constructor(
    public id: string,
    public name: string,
    public shortInfo: string,
    public type: PaymentMethodType
  ) {}
}
