<form [formGroup]="addressForm" class="address-form">
  @if (!locked) {
    @if (showLabel) {
      <div class="row align-items-baseline">
        <app-form-field-label [showRequiredMarker]="required && !readOnly">
          {{ label || 'general.address_l' | translate }}
          @if (readOnly) {
            <div class="ms-1">
              <app-hint>
                {{ 'objects.disabled_address_hint_l' | translate }}
              </app-hint>
            </div>
          }
        </app-form-field-label>
      </div>
    }
    @if (
      (countrySettings?.countries?.length > 1 || config.showCountry) &&
      countryControl
    ) {
      <app-form-field class="row mb10">
        <app-dropdown-select
          [items]="countryList"
          class="col-6"
          formControlName="country"
          [disabled]="readOnly"
        >
          <div dropdown-button>
            {{ 'general.address_country_placeholder' | translate }}
          </div>
          <ng-template let-item>
            <div (click)="onSelectCountry(item.value)">
              {{ 'RUVNATIONALITIES_' + item.value | translate }}
            </div>
          </ng-template>
        </app-dropdown-select>
      </app-form-field>
    }
    <div class="row gx-2 mb10">
      @if (useStreetForSearch) {
        <app-form-field class="col-8">
          <app-location-search-field
            [placeholder]="'general.street_name'"
            [required]="required && !readOnly"
            [readOnly]="readOnly"
            [removeLocality]="true"
            (locationData)="onLocationSearchSelect($event)"
            [country]="countryControl?.value"
            [appendCountryToSearch]="config?.appendCountryToSearch"
            formControlName="street"
          ></app-location-search-field>
        </app-form-field>
      }
      @if (!useStreetForSearch) {
        <app-form-field class="col-8">
          <input
            appInput
            type="text"
            [readonly]="readOnly"
            [placeholder]="'general.street_name' | translate"
            formControlName="street"
            class="form-control"
          />
        </app-form-field>
      }
      <app-form-field class="col-4">
        <input
          appInput
          type="text"
          [readonly]="readOnly"
          [placeholder]="'forms.house_number_l' | translate"
          formControlName="houseNumber"
          class="form-control"
        />
      </app-form-field>
    </div>
    <div class="row gx-2 mb10">
      <app-form-field class="col-4">
        <input
          appInput
          type="text"
          [readonly]="readOnly"
          [placeholder]="'general.zip_code_l' | translate"
          formControlName="zipCode"
          class="form-control"
        />
      </app-form-field>
      <app-form-field class="col-8">
        <input
          appInput
          type="text"
          [readonly]="readOnly"
          [placeholder]="'general.city_l' | translate"
          formControlName="city"
          class="form-control"
        />
      </app-form-field>
    </div>
    <div class="row gy-2 mb10">
      @if (showDistricts) {
        <app-form-field>
          <app-auto-complete-field
            [selector$]="districtSelector$"
            [inputWatcher]="inputWatcher$"
            [typeOffset]="1"
            [loading]="districtsLoading"
            (onSelect)="onSelect($event)"
            (onInputChange)="onNewInput($event)"
          >
            <input
              appInput
              type="text"
              autocomplete="none"
              formControlName="district"
              class="form-control"
              [placeholder]="'general.district_l' | translate"
            />
            <ng-template let-item>{{ item.name }}</ng-template>
          </app-auto-complete-field>
        </app-form-field>
      }
      @if (showRegion) {
        <app-form-field>
          <input
            appInput
            type="text"
            [required]="required && !readOnly"
            [readonly]="readOnly"
            [placeholder]="'general.state' | translate"
            formControlName="region"
            class="form-control"
          />
        </app-form-field>
      }
      @if (config?.countryName) {
        <app-form-field>
          <input
            appInput
            type="text"
            [required]="required"
            [readonly]="config?.readOnlyCountryName"
            [placeholder]="'general.country' | translate"
            formControlName="countryName"
            class="form-control"
          />
        </app-form-field>
      }
      @if (config?.countryNameToolTip?.length > 0) {
        <app-hint class="pl5">
          {{ config?.countryNameToolTip | translate }}
        </app-hint>
      }
    </div>
    @if (showBottomLabel) {
      <div class="row label--bottom">
        <app-form-field-label
          [smallText]="true"
          [showRequiredMarker]="required && !readOnly"
        >
          {{ label || 'general.address_l' | translate }}
        </app-form-field-label>
      </div>
    }
  } @else {
    <p>{{ 'general.address_l' | translate }}</p>
    <app-lockable-data [locked]="locked"></app-lockable-data>
  }
</form>
