<div class="list-navigation__container">
  <div class="list-navigation d-flex" [ngClass]="{ disabled: disabled }">
    @for (nav of navConfig; track nav.label; let i = $index) {
      <div
        *appHide="nav.invisible"
        class="nav-item"
        [ngClass]="{
          active: selectedNav === nav.value,
          'nav-item_enabled': !nav.disabled
        }"
        [ngbTooltip]="(nav.disabled ? nav.tooltip : null) | translate"
        (click)="navSelect(nav)"
      >
        <div class="nav-item__name" [ngClass]="{ medium: medium }">
          {{ nav.label | translate }}
        </div>
        @if (totals?.length && totals[i] !== null) {
          <div class="nav-item__count">{{ totals[i] }}</div>
        }
      </div>
    }
  </div>
</div>
