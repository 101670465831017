export enum PriceTypes {
  GROSS = 'GROSS',
  NET = 'NET'
}

export interface Prices {
  postDiscountMonthlyPriceGross?: number;
  postDiscountMonthlyPriceNet?: number;
  postDiscountYearlyPriceGross?: number;
  postDiscountYearlyPriceNet?: number;
  monthlyPriceNet?: number;
  yearlyPriceNet?: number;
  monthlyPriceGross?: number;
  yearlyPriceGross?: number;
  setup?: number;
}
