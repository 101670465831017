<div [class]="getClassName()">
  <ol [class]="getStepListClassName(currentStatus?.pipeline?.showAsFullscreen)">
    @for (
      step of steps;
      track step.label;
      let isFirst = $first;
      let isLast = $last
    ) {
      <li
        [class]="getStepWrapperClassName(isFirst, isLast)"
        [ngbTooltip]="
          (step.isActive || narrowScreen) &&
          !currentStatus?.pipeline?.showAsFullscreen
            ? (step.tooltip | translate)
            : false
        "
        [openDelay]="tooltipOpenDelay"
        [closeDelay]="tooltipCloseDelay"
        placement="bottom auto"
      >
        <div
          [class]="
            getStepItemClassName(isFirst, isLast, step.isActive, step.hasPassed)
          "
        >
          <div [class]="'icon icon--' + step.iconType"></div>
          <span [class]="baseClass + '__step-label'">{{
            step.label | translate
          }}</span>
        </div>
      </li>
    }
  </ol>
  @if (currentStatus?.pipeline?.showAsFullscreen) {
    <div
      [class]="
        getFullscreenStatusClassName(currentStatus.hide, currentStatus.isTenant)
      "
      [ngbTooltip]="currentStatus?.pipeline?.tooltip | translate"
      [openDelay]="tooltipOpenDelay"
      [closeDelay]="tooltipCloseDelay"
      placement="bottom auto"
    >
      @switch (propertyType) {
        @case ('GARAGE') {
          {{ currentStatus?.pipeline?.garageLabel | translate }}
        }
        @case ('COMMERCIAL') {
          {{ currentStatus?.pipeline?.commercialLabel | translate }}
        }
        @default {
          {{ currentStatus?.pipeline?.label | translate }}
        }
      }
    </div>
  }
</div>
