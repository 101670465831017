import { Component, Input, Output, EventEmitter } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-verify-email-banner',
  templateUrl: './verify-email-banner.component.html',
  styleUrls: ['./verify-email-banner.component.scss'],
  standalone: true,
  imports: [TranslateModule]
})
export class VerifyEmailBannerComponent {
  @Input() public emailResent = false;
  @Input() public emailVerified = false;

  @Output() public resend = new EventEmitter<void>();

  public onResend() {
    this.resend.emit();
  }
}
