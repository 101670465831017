import { Injectable } from '@angular/core';

@Injectable()
export class ScrollerService {
  private registeredRoutes: string[] = ['/properties/details'];
  private allRoutesEnabled = false;

  public registerRoute(routePath: string) {
    if (this.registeredRoutes.includes(routePath)) return;

    this.registeredRoutes = [...this.registeredRoutes, routePath];
  }

  public enableAllRoutes() {
    this.allRoutesEnabled = true;
  }

  public isRouteRegistered(routePath: string) {
    return (
      this.allRoutesEnabled ||
      !!this.registeredRoutes.find(registeredRoute =>
        routePath.includes(registeredRoute)
      )
    );
  }

  public registerRoutes(routePaths: string[]) {
    const routesToAdd = routePaths.filter(
      route => !this.registeredRoutes.includes(route)
    );

    this.registeredRoutes = [...this.registeredRoutes, ...routesToAdd];
  }

  public unregisterRoute(routePath: string) {
    this.registeredRoutes = this.registeredRoutes.filter(
      route => route !== routePath
    );
  }
}
