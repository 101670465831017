import {
  Component,
  EventEmitter,
  forwardRef,
  inject,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, filter } from 'rxjs';

import { LocationSearchService } from 'libs/services';
import { LocationSearchResultEntry } from 'libs/queries/location-search.queries';
import { AppFormFieldControl } from 'libs/components/legacy/form/form-field/form-field-control/form-field-control';
import { BaseControl } from 'libs/components/legacy/form';
import { CustomerLocation } from '@ui/shared/models';
import { AutoCompleteFieldComponent } from 'libs/components/molecules/form/controls/auto-complete-field/auto-complete-field.component';
import { TranslateModule } from '@ngx-translate/core';
import { AppInputDirective } from '../../../../legacy/form/controls/input/input.directive';
import { AutoCompleteFieldComponent as AutoCompleteFieldComponent_1 } from '../auto-complete-field/auto-complete-field.component';

@UntilDestroy()
@Component({
  selector: 'app-location-search-field',
  templateUrl: './location-search-field.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LocationSearchFieldComponent),
      multi: true
    },
    {
      provide: AppFormFieldControl,
      useExisting: forwardRef(() => LocationSearchFieldComponent)
    }
  ],
  standalone: true,
  imports: [
    AutoCompleteFieldComponent_1,
    AppInputDirective,
    FormsModule,
    TranslateModule
  ]
})
export class LocationSearchFieldComponent
  extends BaseControl<string>
  implements OnInit
{
  private locationSearch = inject(LocationSearchService);
  @ViewChild(NgControl, { static: true }) ngControl: NgControl;
  @Input() error = false;
  @Input() removeLocality = false;
  @Input() placeholder = 'general.location_search';
  @Input() country: CustomerLocation = CustomerLocation.DE;
  @Input() appendCountryToSearch = false;
  @Output() locationData = new EventEmitter<LocationSearchResultEntry>();

  @ViewChild(AutoCompleteFieldComponent)
  autoCompleteFieldComponent: AutoCompleteFieldComponent;

  public loading = false;
  public locationSearchSelector$ = new BehaviorSubject<any>(null);

  private recentlySelected = false;

  public get getLocationSearchControlObservable() {
    return this.ngControl.statusChanges.pipe(
      filter(() => {
        if (this.recentlySelected) {
          this.recentlySelected = false;
          return false;
        }
        return !this.ngControl.pristine;
      })
    );
  }

  ngOnInit(): void {
    this.ngControl.statusChanges.subscribe(() => this.stateChanges.next());
  }

  public onNewInput() {
    let value = this.value;
    if (!this.recentlySelected) {
      if (this.country && this.appendCountryToSearch) {
        // We append the country to apiGeoSearch to get results from DE/AT
        // For this we have to use german names for the countries
        switch (this.country) {
          case CustomerLocation.DE:
            value = value?.concat(' Deutschland');
            break;
          case CustomerLocation.AT:
            value = value?.concat(' Österreich');
            break;
          default:
            break;
        }
      }
      this.loading = true;
      this.locationSearch
        .getLocations$(value)
        .pipe(untilDestroyed(this))
        .subscribe(
          data => {
            const features = data.data.searchLocations.features;
            const results = this.removeLocality
              ? features.filter(
                  feature => feature.properties?.type !== 'locality'
                )
              : features;

            this.locationSearchSelector$.next(results);
            this.loading = false;
          },
          error => {
            this.error = error;
          }
        );
    }
    this.recentlySelected = false;
  }

  public onSelect(event: LocationSearchResultEntry) {
    this.value = this.locationSearch.getLocationLabel(event.properties, true);
    this.loading = false;
    this.recentlySelected = true;
    this.locationData.emit(event);
  }

  public onFocusOut() {
    if (this.ngControl?.control?.errors) {
      this.stateChanges.next();
    }
  }

  // Should silently reset the value, without opening the dropdown
  public resetValue() {
    this.ngControl.reset(undefined);
  }
}
