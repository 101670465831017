import { Component, Input } from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[appFieldsetDisabled]',
  template: `
    <fieldset [class.disable-pointer]="disabled" [disabled]="disabled">
      <ng-content></ng-content>
    </fieldset>
  `,
  styleUrls: ['fieldset-disabled.component.scss'],
  standalone: true
})
export class FieldsetDisabledComponent {
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('appFieldsetDisabled') disabled: boolean;
}
