import { NameValue } from './helpers';

export const INTERNATIONAL_OPTIONS: NameValue[] = [
  { name: 'Germany', value: '+49' },
  { name: 'Norway', value: '+47' },
  { name: 'Poland', value: '+48' },
  { name: 'Belgium', value: '+32' },
  { name: 'Luxemburg', value: '+352' },
  { name: 'France', value: '+33' },
  { name: 'Spain', value: '+34' },
  { name: 'Switzerland', value: '+41' },
  { name: 'Austria', value: '+43' },
  { name: 'Italy', value: '+39' },
  { name: 'Slowakia', value: '+421' },
  { name: 'Hungary', value: '+36' },
  { name: 'Greece', value: '+30' },
  { name: 'Bulgary', value: '+359' }
];

export const INTERNATIONAL_QES_OPTIONS: NameValue[] = [
  ...INTERNATIONAL_OPTIONS,
  { name: 'Denmark', value: '+45' },
  { name: 'Estonia', value: '+372' },
  { name: 'Finland', value: '+358' },
  { name: 'Ireland', value: '+353' },
  { name: 'Croatia', value: '+385' },
  { name: 'Iceland', value: '+354' },
  { name: 'Latvia', value: '+371' },
  { name: 'Liechtenstein', value: '+423' },
  { name: 'Lithuania', value: '+370' },
  { name: 'Malta', value: '+356' },
  { name: 'Netherlands', value: '+31' },
  { name: 'Portugal', value: '+351' },
  { name: 'Romania', value: '+40' },
  { name: 'Sweden', value: '+46' },
  { name: 'Slovenia', value: '+386' },
  { name: 'Czech', value: '+420' },
  { name: 'Cyprus', value: '+357' }
];
