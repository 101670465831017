import { inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { INFRASTRUCTURE_CONFIG } from 'libs/infrastructure/infrastructure-config.token';
import { LocalStorageService } from 'libs/infrastructure/storage/local-storage.service';
import { WINDOW_REF } from 'libs/infrastructure/browser/window-ref.token';
import { User } from '@ui/shared/models';

@Injectable()
export class FreshchatWidgetService {
  private doc = inject(DOCUMENT);
  private infrastructureConfig = inject(INFRASTRUCTURE_CONFIG);
  private windowRef = inject(WINDOW_REF);
  private storageService = inject(LocalStorageService);

  private userData: User;
  private widget: any;
  private isInitialized: boolean;

  public init(userData: User) {
    if (!this.isInitialized) {
      this.userData =
        userData ||
        this.getLoggedOutUser({
          email: '',
          profile: {
            firstname: 'unregistered user',
            name: 'unknown'
          }
        });
      this.widgetInit();
      this.isInitialized = true;
    }
  }

  public getLoggedOutUser(user: User) {
    const { email = '', customer = null, profile = null } = user;
    return {
      email,
      customer: customer || { id: null },
      profile: profile || { firstname: '', name: '', phone: '' }
    };
  }

  private widgetInit() {
    this.widget = (this.windowRef as any).fcWidget;
    if (!this.widget) {
      console.error('[App] Freshchat Widget initialisation failed');
      return;
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    this.widget.init({
      token: this.infrastructureConfig.environment.freshchat_token,
      host: 'https://wchat.freshchat.com',
      externalId: this.userData?.customer?.id,
      restoreId: this.storageService.getItem('restoreId'),
      firstName: this.userData.profile.firstname,
      lastName: this.userData.profile.name,
      phone: this.userData.profile.phone,
      email: this.userData.email,
      config: {
        cssNames: {
          widget: 'fc_frame'
        }
      }
    });

    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    this.widget.user.get(resp => {
      const status = resp && resp.status;

      if (status !== 200) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        this.widget.user.setProperties({
          externalId: this.userData?.customer?.id,
          firstName: this.userData.profile.firstname,
          lastName: this.userData.profile.name,
          phone: this.userData.profile.phone,
          email: this.userData.email
        });
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        this.widget.on('user:created', response => {
          const statusCreated = response && response.status;
          const dataCreated = response && response.data;
          if (statusCreated === 200) {
            if (dataCreated.restoreId) {
              this.storageService.setItem('restoreId', dataCreated.restoreId);
            }
          }
        });
      }
    });
  }

  // @TODO when we implementing MULTILANGUAGE on LL use this to change language of Freshchat widget
  // public changeLanguage(selectedLanguage) {
  //   if (!this.isInitialized) return;
  //   // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  //   this.widget.user.setLocale(selectedLanguage);
  // }

  public hideWidget() {
    if (!this.isInitialized) return;
    /* eslint-disable @typescript-eslint/no-unsafe-call */
    this.widget.close();
    this.widget.hide();
    /* eslint-enable @typescript-eslint/no-unsafe-call */
  }

  public showWidget() {
    if (!this.isInitialized) return;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    this.widget.show();
  }
}
