<div
  class="context-menu-item"
  (click)="click($event)"
  [ngClass]="{
    'context-menu-item--active': active && !disabled,
    'context-menu-item--hover': hover && !disabled,
    'context-menu-item--disabled': disabled,
    'context-menu-item--with-top-spacing': withTopSpacing,
    'highlight-bg-red': highlightTextRed
  }"
>
  @if (iconLeft) {
    <div
      class="icon icon--{{ iconLeft }} d-flex me-2 justify-content-center"
    ></div>
  }
  @if (iconSpacing && !iconLeft) {
    <div class="icon me-2"></div>
  }

  <div
    class="context-menu-item__content"
    [ngClass]="{ 'highlight-text-red': highlightTextRed }"
  >
    <ng-content></ng-content>
  </div>

  @if (iconRight) {
    <div
      class="icon icon--{{ iconRight }} d-flex ms-2 justify-content-center"
    ></div>
  }
</div>
