import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-lockable-data',
  templateUrl: './lockable-data.component.html',
  styleUrls: ['./lockable-data.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true
})
export class LockableDataComponent {
  @Input() locked = false;
}
