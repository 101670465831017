import { ApolloError, ApolloQueryResult } from '@apollo/client/core';

export const getResponseValidator = <T>(checkErrors = true) => {
  return (response: ApolloQueryResult<T>) => {
    if (!response || !response.data) {
      throw new ApolloError({ errorMessage: `missing 'data' property` });
    }
    Object.keys(response.data).forEach(key => {
      if (response.data[key] === null || response.data[key] === undefined) {
        throw new ApolloError({
          errorMessage: `missing property '${key}' of 'data'`
        });
      }
    });

    if (checkErrors && response?.errors?.length) {
      throw new ApolloError({
        graphQLErrors: response.errors,
        errorMessage: response.errors[0].message
      });
    }
  };
};
