export enum SystemTag {
  ANONYMOUS = 'ANONYMOUS',
  GUEST = 'GUEST',
  EXCLUSIVE = 'EXCLUSIVE',
  TENANT = 'TENANT',
  OFFLINE = 'OFFLINE',
  SALES_PROSPECT = 'SALES_PROSPECT',
  BUYER = 'BUYER',
  MEMBER = 'MEMBER',
  SELF_REGISTERED = 'SELF_REGISTERED',
  MERGE_CASE = 'MERGE_CASE'
}
