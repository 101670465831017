import { Address } from './address.model';
import { SortDirection, SortOptions } from './data-access';
import { MarketingWorkflowStateFilter, PropertyPortalStates } from './enums';
import { PropertyCommercialOnlyFeaturesEnum } from './property-commercial-only-features.enum';
import { PropertyGeneralFeaturesEnum } from './property-general-features.enum';
import {
  MarketingType,
  PropertyDelegationFilter,
  PropertyType
} from './property.model';
import { SearchPredicate } from './search-predicate.model';
import { PropertyStatusFilter } from './property-status-filter.enum';

export interface Range {
  from: number;
  to: number;
}

export interface PropertyFilterList extends PropertiesFilterList {
  id?: string;
  name?: string;
  global?: boolean;
}

export interface PropertyFilterListOverviewResponse {
  propertyFilterListOverview: PropertyFilterListOverview;
}

export type PropertyFilterListOverview = FilterListOverviewItem[];
export interface FilterListOverviewItem {
  id: string;
  name: string;
  global: boolean;
}

export interface PageableFilterList {
  page?: number;
  size?: number;
  filterList?: PropertiesFilterList;
}

export interface PropertiesFilterList {
  sortBy: string;
  sortOrder: SortDirection;
  groups: PropertySearchFilterGroup[];
}

export interface PropertyFilterListDetailsResponse {
  propertyFilterList: PropertySavedFilterList;
}
export interface CreatePropertyFilterListResponse {
  createPropertyFilterList: PropertySavedFilterList;
}
export interface PropertySavedFilterList
  extends Omit<PropertiesFilterList, 'groups'> {
  id: string;
  name: string;
  global: boolean;
  filterGroups: PropertySearchFilterGroup[];
}

export interface PropertySearchFilterGroup {
  searchPredicate: string;
  filters: PropertyFilterGroupItem[];
}

export interface PropertyFilterGroupItemBase {
  excluded: boolean;
}

export type PropertyFilterGroupItem =
  | PropertyStringFilterValue
  | PropertyBooleanFilterValue
  | PropertyIDFilterValue
  | PropertyRangeFilterValue;

export interface PropertyStringFilterValue extends PropertyFilterGroupItemBase {
  filterKey: PropertyStringFilterKey;
  stringValue: string;
}
export enum PropertyStringFilterKey {
  EXTERNAL_ID = 'EXTERNAL_ID',
  NAME = 'NAME',
  STREET = 'STREET',
  HOUSE_NUMBER = 'HOUSE_NUMBER',
  ZIP_CODE = 'ZIP_CODE',
  CITY = 'CITY',
  REGION = 'REGION',
  STATUS = 'STATUS',
  PORTAL_STATE = 'PORTAL_STATE',
  MARKETING_PHASE = 'MARKETING_PHASE',
  MARKETING_TYPE = 'MARKETING_TYPE',
  DELEGATION_TYPE = 'DELEGATION_TYPE',
  FEATURE_TYPE = 'FEATURE_TYPE',
  TYPE = 'TYPE'
}

export interface PropertyBooleanFilterValue
  extends PropertyFilterGroupItemBase {
  filterKey: PropertyBooleanFilterKey;
  booleanValue: boolean;
}
export enum PropertyBooleanFilterKey {
  WBS = 'WBS',
  RENTED = 'RENTED'
}

export interface PropertyIDFilterValue extends PropertyFilterGroupItemBase {
  filterKey: PropertyIDFilterKey;
  referenceId: string;
}
export enum PropertyIDFilterKey {
  AGENT = 'AGENT',
  LABEL = 'LABEL',
  DELEGATION_PARTNER = 'DELEGATION_PARTNER',
  ADMINISTRATION_UNIT = 'ADMINISTRATION_UNIT',
  PROJECT = 'PROJECT',
  PROPERTY_MANAGER = 'PROPERTY_MANAGER',
  TEMPLATE = 'TEMPLATE'
}

export interface PropertyRangeFilterValue extends PropertyFilterGroupItemBase {
  filterKey: PropertyRangeFilterKey;
  rangeFrom?: number;
  rangeTo?: number;
}
export enum PropertyRangeFilterKey {
  ROOMS = 'ROOMS',
  SIZE = 'SIZE',
  BASE_RENT = 'BASE_RENT',
  TOTAL_RENT = 'TOTAL_RENT',
  PURCHASE_PRICE = 'PURCHASE_PRICE'
}

export interface LegacyPropertiesFilters {
  name?: string;
  agents?: string[];
  address?: Address;
  externalId?: string;
  rented?: boolean;
  propertyPortal?: PropertyPortalFilter;
  wbs?: boolean;
  roomNumber?: Range;
  basePrice?: Range;
  totalPriceGross?: Range;
  propertySize?: Range;
  purchasePrice?: Range;
  marketingType?: MarketingType;
  propertyFilter?: PropertyFilter;
  delegationFilter?: PropertyDelegationFilter;
  projectFilter?: ProjectFilter;
  statuses?: {
    [key in PropertyStatusFilter]: boolean | null;
  };
  searchTerm?: string;
  administrationUnitIds?: string[];
  sort?: SortOptions;
  marketingWorkflowStates?: MarketingWorkflowStateFilter[];
  tagFilter?: {
    tagIds?: string[];
    searchPredicate?: SearchPredicate;
  };
  features?: {
    [key in PropertyGeneralFeaturesEnum | PropertyCommercialOnlyFeaturesEnum]:
      | boolean
      | null;
  };
  withCurrentProcess?: boolean;
}

interface PropertyFilter {
  types?: PropertyType[];
  marketingTypes?: MarketingType[];
}

interface ProjectFilter {
  projectIds: number[];
}

export interface PropertyPortalFilter {
  states?: PropertyPortalStates[] | string;
  includeWithoutPortals?: boolean;
  predicate?: string;
}

export interface FilterRange {
  from?: number;
  to?: number;
}
