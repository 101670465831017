import { Component, Input, OnInit, inject } from '@angular/core';

import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { TranslateService } from '@ngx-translate/core';

const DEFAULT_LINK_PLACEHOLDER = 'link';

@Component({
  selector: 'app-embedded-link',
  templateUrl: './embedded-link.component.html',
  styleUrls: ['./embedded-link.component.scss'],
  standalone: true
})
export class EmbeddedLinkComponent implements OnInit {
  private sanitizer = inject(DomSanitizer);
  private translate = inject(TranslateService);

  @Input() contentKey = '';
  @Input() linkTarget = '';
  @Input() linkTextKey = '';
  @Input() linkPlaceholder = DEFAULT_LINK_PLACEHOLDER;

  public value: SafeHtml = '';

  public ngOnInit() {
    if (!this.linkTextKey || !this.contentKey) {
      return;
    }

    const linkText = this.translate.instant(this.linkTextKey);

    const link = `<a href="${this.linkTarget}" target="_blank">${String(
      linkText
    )}</a>`;

    const result = this.translate.instant(this.contentKey, {
      [this.linkPlaceholder]: link
    });

    this.value = this.sanitizer.bypassSecurityTrustHtml(result);
  }
}
