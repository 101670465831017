import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { Comment } from '@ui/shared/models';
import { TranslateModule } from '@ngx-translate/core';
import { DateTimePipe } from '../../../pipes/date-time.pipe';
import { ButtonComponent } from '../../atoms/button/button.component';
import { AppInputDirective } from '../form/controls/input/input.directive';
import { ContextMenuItemComponent } from '../../molecules/context-menu/context-menu-item/context-menu-item.component';
import { ContextMenuComponent } from '../../molecules/context-menu/context-menu.component';
interface EditableComment extends Comment {
  editMode?: boolean;
}

@Component({
  selector: 'app-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ContextMenuComponent,
    ContextMenuItemComponent,
    AppInputDirective,
    ButtonComponent,
    TranslateModule,
    DateTimePipe
  ]
})
export class CommentsComponent {
  @Input() get comments(): Comment[] {
    return this.editableComments;
  }
  set comments(comments) {
    this.editableComments = comments.map(comment => ({
      ...comment,
      editMode: false
    }));
  }

  @Input() updatingComment: boolean;
  @Input() userId: string;
  @Input() allowCommentEdit = true;

  @Output() showProperty = new EventEmitter<string>();
  @Output() updateComment = new EventEmitter<Comment>();
  @Output() deleteComment = new EventEmitter<string>();

  public editableComments: EditableComment[];

  public onShowProperty(id: string) {
    this.showProperty.emit(id);
  }

  public editComment(index: number) {
    this.editableComments[index].editMode = true;
  }

  public onDeleteComment(id: string) {
    this.deleteComment.emit(id);
  }

  public cancelEdit(index: number) {
    this.editableComments[index].editMode = false;
  }

  public onUpdateComment(editableComment: EditableComment, comment: string) {
    this.updateComment.emit({ id: editableComment?.id, comment } as Comment);
  }
}
