export enum EmploymentType {
  SELF_EMPLOYED = 'SELF_EMPLOYED',
  EMPLOYED_UNLIMITED = 'EMPLOYED_UNLIMITED',
  EMPLOYED_LIMITED = 'EMPLOYED_LIMITED',
  STUDENT = 'STUDENT',
  LOOKING_FOR_WORK = 'LOOKING_FOR_WORK',
  RETIRED = 'RETIRED',
  APPRENTICE = 'APPRENTICE',
  HOUSEHOLD_MANAGER = 'HOUSEHOLD_MANAGER',
  CIVIL_SERVANT = 'CIVIL_SERVANT'
}
