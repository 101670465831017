import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  inject
} from '@angular/core';
import { TagOwnershipType } from '@ui/shared/models';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { NgClass, UpperCasePipe } from '@angular/common';
import { TAG_CONFIGS, TagColor, TagConfig } from './tag-config';

@Component({
  selector: 'app-tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, NgbTooltip, UpperCasePipe, TranslateModule]
})
export class TagComponent implements OnInit {
  private translate = inject(TranslateService);

  @Input() name: string;
  @Input() isSystemTag: boolean;
  @Input() tagType: TagOwnershipType;
  @Input() disableTooltip: boolean;
  @Input() nowrap: boolean;
  public truncate = false;
  public tooltip = '';
  protected TRUNCATE_LENGTH = 15;

  ngOnInit(): void {
    this.truncate =
      (this.name || this.tagConfig?.label)?.length > this.TRUNCATE_LENGTH;
    this.manageTooltip();
  }

  private manageTooltip(): void {
    this.tooltip = this.truncate
      ? this.name || this.tagConfig?.label
      : this.tagConfig?.tooltip
        ? String(this.translate.instant(this.tagConfig.tooltip))
        : '';
  }

  public get tagConfig(): TagConfig {
    let config;
    if (this.isSystemTag) {
      config = this.name;
    } else if (this.tagType === TagOwnershipType.PERSONAL) {
      config = TagColor.PERSONAL;
    } else if (this.tagType === TagOwnershipType.DYNAMIC) {
      this.name.startsWith('PROJEKT-')
        ? (config = TagColor.PROJECT)
        : (config = TagColor.DYNAMIC);
    } else {
      config = TagColor.SHARED;
    }

    return TAG_CONFIGS[config];
  }
}
