export function coerceBooleanProperty(value: any): boolean {
  return value != null && String(value) !== 'false';
}

export function isValueNotNullAndUndefined(...value: unknown[]): boolean {
  return value.every(v => v !== null && v !== undefined);
}

export function isAnyValueNotNullAndUndefined(...value: unknown[]): boolean {
  return value.some(v => v !== null && v !== undefined);
}

export function isValueNullOrUndefined(value: any): boolean {
  return value === null || value === undefined;
}
