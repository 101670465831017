import { Attachment } from './attachment.model';

export interface ResidentAttachment extends Attachment {
  residentFileType?: ResidentFileType;
  location?: string;
  atlasMeta?: CustomerSpecificData[];
}

export interface CustomerSpecificData {
  key: string;
  value: string;
}

export enum ResidentFileType {
  TICKET_ATTACHMENT = 'TICKET_ATTACHMENT',
  RENTAL_CONTRACT = 'RENTAL_CONTRACT',
  CONSUMPTION_DOCUMENT = 'CONSUMPTION_DOCUMENT',
  SERVICE_CHARGE_STATEMENT = 'SERVICE_CHARGE_STATEMENT',
  REQUESTED_DOCUMENT = 'REQUESTED_DOCUMENT'
}
