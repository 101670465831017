<div class="available-hierarchical-question">
  <app-hierarchical-questions-display-root
    [hierarchicalQuestionContainer]="hierarchicalQuestion"
    [tags]="tags"
    [appCheckTemplateRef]="appCheckTemplate"
  >
  </app-hierarchical-questions-display-root>

  <ng-template #appCheckTemplate>
    <app-check
      [(ngModel)]="selected"
      (ngModelChange)="onSelectedChange($event)"
    >
    </app-check>
  </ng-template>
</div>
