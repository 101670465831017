import { NameValue } from './helpers';

export const COUNTRY_OPTIONS: NameValue[] = [
  { name: 'Germany', value: 'DE' },
  { name: 'Norway', value: 'NO' },
  { name: 'Poland', value: 'PL' },
  { name: 'Belgium', value: 'BE' },
  { name: 'Luxemburg', value: 'LU' },
  { name: 'France', value: 'FR' },
  { name: 'Spain', value: 'ES' },
  { name: 'Switzerland', value: 'CH' },
  { name: 'Austria', value: 'AT' },
  { name: 'Italy', value: 'IT' },
  { name: 'Slovakia', value: 'SK' },
  { name: 'Hungary', value: 'HU' },
  { name: 'Greece', value: 'GR' },
  { name: 'Estonia', value: 'EE' },
  { name: 'Denmark', value: 'DK' },
  { name: 'Finland', value: 'FI' },
  { name: 'Ireland', value: 'IR' },
  { name: 'Croatia', value: 'CR' },
  { name: 'Iceland', value: 'IS' },
  { name: 'Latvia', value: 'LV' },
  { name: 'Liechtenstein', value: 'LI' },
  { name: 'Lithuania', value: 'LT' },
  { name: 'Malta', value: 'MT' },
  { name: 'Netherlands', value: 'NL' },
  { name: 'Portugal', value: 'PT' },
  { name: 'Romania', value: 'RO' },
  { name: 'Sweden', value: 'SE' },
  { name: 'Slovenia', value: 'SI' },
  { name: 'Czech', value: 'CZ' },
  { name: 'Cyprus', value: 'CY' }
];

export const DE_AT_CONFIG: NameValue[] = [
  { name: 'germany.search_profile_selection_l', value: 'DE' },
  { name: 'austria.search_profile_selection_l', value: 'AT' }
];
