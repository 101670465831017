import { Directive, OnInit, inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router, NavigationEnd } from '@angular/router';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { filter } from 'rxjs/operators';

import { ScrollerService } from 'libs/infrastructure/browser/scroller.service';

@UntilDestroy()
@Directive({
  selector: '[appScroller]',
  standalone: true
})
export class ScrollerDirective implements OnInit {
  private router = inject(Router);
  private scrollerService = inject(ScrollerService);
  private documentRef = inject(DOCUMENT);

  ngOnInit() {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        filter((event: NavigationEnd) =>
          this.scrollerService.isRouteRegistered(event.url)
        ),
        untilDestroyed(this)
      )
      .subscribe(() => {
        // Appears as if NavigateEnd event is sometimes fired too soon. So that scrolling to top doesn't
        // work e.g. on object edit flow. Setting scrollPositionRestoration alone does not do the trick either.
        setTimeout(() => {
          this.scrollToTop();
        });
      });
  }

  private scrollToTop() {
    this.documentRef.body.scrollTo(0, 0);
  }
}
