import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'appFileSize',
  standalone: true
})
export class FileSizePipe implements PipeTransform {
  transform(size = 0): string {
    return (size / 1024 / 1024).toFixed(2) + ' MB';
  }
}
