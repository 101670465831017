import { Component, EventEmitter, Input, Output } from '@angular/core';

import { Attachment } from '@ui/shared/models';
import { NgClass } from '@angular/common';

@Component({
  selector: 'app-attachment-preview-v2',
  templateUrl: './attachment-preview-v2.component.html',
  styleUrls: ['./attachment-preview-v2.component.scss'],
  standalone: true,
  imports: [NgClass]
})
export class AttachmentPreviewV2Component {
  @Input() public imgSrc: string;
  @Input() public isDocument = false;
  @Input() public attachment: Attachment;

  @Output() public preview = new EventEmitter<Attachment>();
  @Output() public download = new EventEmitter<Attachment>();

  public previewOpen = false;

  public togglePreview() {
    this.previewOpen = !this.previewOpen;
  }

  public openPreview() {
    this.previewOpen = true;
  }

  public closePreview() {
    this.previewOpen = false;
  }

  public isPdf(attachment: Attachment) {
    return (
      attachment.type === 'PDF' ||
      (attachment.title && attachment.title.toLowerCase().endsWith('.pdf'))
    );
  }

  public clickOnPdf() {
    if (this.attachment.url) {
      this.preview.emit(this.attachment);
    }
  }

  public clickOnImage() {
    if (this.attachment.url) {
      this.download.emit(this.attachment);
    }
  }
}
