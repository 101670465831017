import {
  Component,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  inject
} from '@angular/core';
import { DOCUMENT, NgClass } from '@angular/common';
import {
  trigger,
  state,
  animate,
  transition,
  style,
  AnimationEvent
} from '@angular/animations';

import { Toast } from './model/toast.model';

@Component({
  selector: 'app-toast',
  templateUrl: './toast-container.component.html',
  styleUrls: ['./toast-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('inOut', [
      state('fade', style({ opacity: 1 })),
      transition(':enter', [
        style({
          opacity: 0
        }),
        animate('0.3s ease-in')
      ]),
      transition(':leave', [
        animate(
          '0.3s ease-out',
          style({
            opacity: 0
          })
        )
      ])
    ])
  ],
  standalone: true,
  imports: [NgClass]
})
export class ToastContainerComponent {
  private cdr = inject(ChangeDetectorRef);
  private doc = inject(DOCUMENT);

  animate = 'fade';
  toasts: Toast[] = [];

  onEmpty: () => void;
  onToastClick: (toast: Toast) => void;

  public addToast(toast: Toast) {
    this.toasts.push(toast);
    this.cdr.detectChanges();
  }

  public removeToast(toast: Toast) {
    this.toasts = this.toasts.filter(t => t.id !== toast.id);

    this.cdr.detectChanges();
  }

  public toastClick(toast: Toast) {
    if (this.onToastClick) {
      this.onToastClick(toast);
    }
  }

  public onAnimationEnd(event: AnimationEvent) {
    if (event.toState === 'void' && !this.toasts.length && this.onEmpty) {
      this.onEmpty();
    }
  }

  private _getToastElementById(id: number): HTMLElement {
    return this.doc.getElementById(`toast-${String(id)}`);
  }

  public calcPosition(toast: Toast) {
    const toastElement = this._getToastElementById(toast.id);
    if (toast.id === 1 || !toastElement) return { bottom: '74px' };
    let index = toast.id - 1;
    let toastElementPrevious = this._getToastElementById(index);
    while (!toastElementPrevious && index--) {
      toastElementPrevious = this._getToastElementById(index);
    }
    if (toastElementPrevious) {
      return {
        bottom:
          String(
            Number(this.doc.body.clientHeight) -
              toastElementPrevious.offsetTop -
              toastElement.clientHeight +
              10 +
              toastElement.clientHeight
          ) + 'px'
      };
    }
  }
}
