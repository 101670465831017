<div class="set-password-form__content">
  <form [formGroup]="form" autocomplete="off">
    <div class="col-12 mb20 p0">
      <app-form-field>
        <app-password
          [label]="'general.password_l'"
          [showRequiredBox]="true"
          placeholder="{{ 'forms.password_placeholder' | translate }}"
          formControlName="password"
        >
        </app-password>
      </app-form-field>
    </div>
    <div class="col-12 mb20 p0">
      <app-form-field>
        <app-password
          [label]="'general.confirm_password_l'"
          placeholder="{{ 'forms.confirm_password_placeholder' | translate }}"
          formControlName="confirmedPassword"
        >
        </app-password>
      </app-form-field>
    </div>
    <div class="col-12 p0">
      <hr class="mb15" />
      <div class="d-flex justify-content-end">
        <app-button
          id="save-btn"
          [loading]="isSaving"
          [disabled]="!form?.valid || isSaving"
          (clickEvent)="setPassword()"
        >
          {{ ctaButtonLabel || 'general.save_a' | translate }}
        </app-button>
      </div>
    </div>
  </form>
</div>
