export enum DateRangeFilter {
  TODAY = 'TODAY',
  YESTERDAY = 'YESTERDAY',
  LAST_WEEK = 'LAST_WEEK',
  THIS_WEEK = 'THIS_WEEK',
  THIS_MONTH = 'THIS_MONTH',
  LAST_MONTH = 'LAST_MONTH',
  LAST_YEAR = 'LAST_YEAR',
  THIS_YEAR = 'THIS_YEAR',
  LAST_QUARTER = 'LAST_QUARTER',
  THIS_QUARTER = 'THIS_QUARTER',
  LAST_SEVEN_DAYS = 'LAST_SEVEN_DAYS',
  LAST_THIRTY_DAYS = 'LAST_THIRTY_DAYS',
  LAST_X_DAYS = 'LAST_X_DAYS'
}

export interface DateRange {
  start: string;
  end: string;
}

export interface ExportDateRange {
  dateFrom: string;
  dateUntil: string;
}
