export interface ServiceCard {
  cardType: ServiceCardType;
  activationCode: string;
  registrationLink: string;
  headline: string;
  description: string;
}

export enum ServiceCardType {
  RESIDENT_APP_CARD = 'RESIDENT_APP_CARD',
  MOVING_AUCTION_CARD = 'MOVING_AUCTION_CARD',
  MILES_CARD = 'MILES_CARD',
  MOVING_CHECKLIST_CARD = 'MOVING_CHECKLIST_CARD'
}
