import { DialogConfig } from '@ui/shared/models';

export const removeCustomQuestionFromPrioSetDialogConfig: DialogConfig = {
  message: 'custom_questions.remove_from_property_confirmation_message',
  titleMessage: 'custom_questions.remove_from_property_confirmation_title'
};

export const deleteHierarchicalQuestionDialogConfig: DialogConfig = {
  titleMessage: 'hierarchical_questions.delete_confirmation_title_l',
  message: 'hierarchical_questions.delete_confirmation_message'
};
