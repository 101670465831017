<div
  ngbDropdown
  class="dropdown-multiselect__container"
  [autoClose]="'outside'"
  (openChange)="setShowFilters($event)"
>
  <button
    type="button"
    class="d-flex btn align-items-center"
    id="dropdown-multiselect"
    ngbDropdownToggle
    [disabled]="disabled"
  >
    @if (!value || !value.length) {
      <div class="dropdown-toggle__inner">
        <ng-content select="[dropdown-button]"></ng-content>
      </div>
    } @else {
      @if (!showBadges) {
        <div class="dropdown-toggle__inner">
          @switch (value?.length) {
            @case (1) {
              <ng-template
                [ngTemplateOutlet]="templateRef"
                [ngTemplateOutletContext]="{
                  $implicit: selectedItems[0] || {}
                }"
              ></ng-template>
            }
            @default {
              <ng-content select="[dropdown-button-multi]"></ng-content>
            }
          }
        </div>
      }
      @if (showBadges) {
        <div class="badges-container">
          @if (items?.length !== selectedItems?.length) {
            @for (
              item of selectedItems | slice: 0 : numberOfBadgesToShow;
              track item.name
            ) {
              <app-badge
                [clickable]="true"
                [withCloseButton]="true"
                (closeEvent)="removeItem($event, item)"
                (clickEvent)="$event.stopPropagation()"
              >
                {{ item.name }}
              </app-badge>
            }
            @if (selectedItems.length > numberOfBadgesToShow) {
              <app-badge (clickEvent)="$event.stopPropagation()"
                >+ {{ selectedItems.length - numberOfBadgesToShow }}
              </app-badge>
            }
          } @else {
            <app-badge
              [clickable]="true"
              [withCloseButton]="true"
              (closeEvent)="removeAllItems($event)"
              (clickEvent)="$event.stopPropagation()"
              >{{ 'mutli_select.all_items_selected_l' | translate }}
            </app-badge>
          }
        </div>
      }
    }

    <div
      class="dropdown-arrow__container ms-auto pr0 d-flex align-items-center"
    >
      @if (selectedItems.length || predicate === true) {
        <i class="icon icon--close ms-2" (click)="clear($event)"></i>
      }
      <i
        [ngClass]="isDropdownOpened ? 'chevron-rotated' : 'chevron-non-rotated'"
        class="icon icon--chevron-down ms-2"
      ></i>
    </div>
  </button>

  <div
    cdkDropListGroup
    ngbDropdownMenu
    aria-labelledby="dropdown-multiselect"
    [cdkDropListGroupDisabled]="!draggable"
    [class.relative-positioned]="relativelyPositioned"
  >
    <div
      class="dropdown__items"
      cdkDropList
      [cdkDropListData]="items"
      (cdkDropListDropped)="drop($event)"
    >
      <form [formGroup]="dropdownForm">
        @if (showToggleAll) {
          <div class="toggle-all-item">
            {{ 'dropdown_multi-select.select_all_l' | translate }}
            <app-check
              class="dropdown__check"
              [ngModel]="toggle"
              (ngModelChange)="toggleAll($event)"
              [ngModelOptions]="{ standalone: true }"
            >
            </app-check>
          </div>
        }
        @if (showAndOrFilter) {
          <div (click)="onPredicateChange(!predicate)" class="toggle-all-item">
            {{ 'ps_filter.checkbox_label' | translate }}
            <app-check
              class="dropdown__check"
              [ngModel]="predicate"
              (ngModelChange)="onPredicateChange($event)"
              [ngModelOptions]="{ standalone: true }"
            ></app-check>
          </div>
        }
        @if (showAndOrFilter) {
          <hr />
        }
        @for (item of items; track item[itemValueKey]; let i = $index) {
          <div
            class="dropdown__item"
            cdkDragLockAxis="y"
            [cdkDragDisabled]="!dropdownForm.value[item[itemValueKey]]"
            [ngClass]="{
              'cursor-grab highlight':
                draggable && dropdownForm.value[item[itemValueKey]]
            }"
            (click)="onOptionClick(i)"
            cdkDrag
          >
            @if (item.isDivider) {
              <div class="w-100">
                <hr />
              </div>
            } @else {
              <ng-template
                [ngTemplateOutlet]="templateRef"
                [ngTemplateOutletContext]="{ $implicit: item }"
              ></ng-template>
              <app-check
                class="dropdown__check"
                formControlName="{{ item[itemValueKey] }}"
              ></app-check>
            }
          </div>
        }
        @if (items.length === 0 && emptyPlaceholder) {
          <div class="dropdown__item dropdown__item--disabled">
            {{ emptyPlaceholder | translate }}
          </div>
        }
      </form>
    </div>
    @if (showApplyButton) {
      <div class="dropdown__actions">
        <button
          class="btn btn-apply"
          [disabled]="items.length === 0"
          (click)="apply()"
        >
          {{ 'APPLY_A' | translate }}
        </button>
      </div>
    }
  </div>
</div>
