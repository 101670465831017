import { ActionStateCreator, ActionState } from 'libs/state-utils';
import { SearchCity, SearchDistrict } from '@ui/shared/models';

import * as fromActions from './search-profiles.actions';

export interface SearchProfilesState {
  cities: SearchCity[];
  districtsMap: Map<string, SearchDistrict[]>;
  searchForCityActionState: ActionState;
  loadCityDistrictsActionState: ActionState;
}

export const initialState: SearchProfilesState = {
  cities: [],
  districtsMap: new Map<string, SearchDistrict[]>(),
  searchForCityActionState: ActionStateCreator.create(),
  loadCityDistrictsActionState: ActionStateCreator.create()
};

export function reducer(
  state = initialState,
  action: fromActions.SearchProfilesAction
): SearchProfilesState {
  switch (action.type) {
    case fromActions.SEARCH_FOR_CITIES: {
      return {
        ...state,
        searchForCityActionState: ActionStateCreator.onStart()
      };
    }

    case fromActions.SEARCH_FOR_CITIES_FAIL: {
      return {
        ...state,
        searchForCityActionState: ActionStateCreator.onError(action.error)
      };
    }

    case fromActions.SEARCH_FOR_CITIES_SUCCESS: {
      return {
        ...state,
        cities: action.cities,
        searchForCityActionState: ActionStateCreator.onSuccess()
      };
    }

    case fromActions.LOAD_CITY_DISTRICTS: {
      return {
        ...state,
        loadCityDistrictsActionState: ActionStateCreator.onStart()
      };
    }

    case fromActions.LOAD_CITY_DISTRICTS_FAIL: {
      return {
        ...state,
        loadCityDistrictsActionState: ActionStateCreator.onError(action.error)
      };
    }

    case fromActions.LOAD_CITY_DISTRICTS_SUCCESS: {
      return {
        ...state,
        districtsMap: new Map(state.districtsMap).set(
          action.cityId,
          action.districts
        ),
        loadCityDistrictsActionState: ActionStateCreator.onSuccess()
      };
    }

    default:
      return state;
  }
}

export const getSearchCities = (state: SearchProfilesState) => state.cities;
export const getCityDistrictsMap = (state: SearchProfilesState) =>
  state.districtsMap;
export const isCitySearching = (state: SearchProfilesState) =>
  state.searchForCityActionState.pending;
export const isDistrictLoading = (state: SearchProfilesState) =>
  state.loadCityDistrictsActionState.pending;
