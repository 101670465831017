import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { NgbPagination } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgbPagination]
})
export class PaginationComponent {
  @Input() pageSize: number;
  @Input() disabled: boolean;
  @Input() collectionSize: number;
  @Input() page: number;
  @Input() forceDisplay: boolean;
  @Output() pageChange = new EventEmitter<number>();

  onPageChange(pageNumber: number) {
    this.pageChange.emit(pageNumber);
  }
}
