@if (forceDisplay || pageSize < collectionSize) {
  <ngb-pagination
    [pageSize]="pageSize"
    [disabled]="disabled"
    [collectionSize]="collectionSize"
    [page]="page"
    [maxSize]="5"
    [rotate]="true"
    [boundaryLinks]="true"
    (pageChange)="onPageChange($event)"
  >
  </ngb-pagination>
}
