<div
  class="avatar"
  [class.avatar--tiny]="size === avatarSizeEnum.TINY"
  [class.avatar--small]="size === avatarSizeEnum.SMALL"
  [class.avatar--medium]="size === avatarSizeEnum.MEDIUM"
  [class.avatar--large]="size === avatarSizeEnum.LARGE"
>
  @if (imgSrc && !hasLoadError && !hideImage) {
    <img
      [class.rounded-circle]="rounded"
      class="avatar__image"
      [src]="imgSrc"
      (error)="onLoadError()"
    />
  } @else {
    <div class="avatar__placeholder">{{ userInitials }}</div>
    <div
      class="avatar__placeholder-mask"
      [class.rounded-circle]="rounded"
    ></div>
  }
</div>
