import {
  Language,
  CookiePreference,
  AvailableLanguageCodesEnum
} from '@ui/shared/models';
import * as fromAppActions from './app.actions';

export interface AppState {
  currentLocale: AvailableLanguageCodesEnum;
  availableLanguages: Language[];
  defaultLanguageCode: AvailableLanguageCodesEnum;
  viewportSize: string;
  cookiesPreference: CookiePreference;
  performanceWarningConfirmed: boolean;
  redirected: boolean;
  version: { version: string; prevVersion: string };
  loadingVersion: boolean;
}

export const initialState: AppState = {
  currentLocale: null,
  availableLanguages: [],
  defaultLanguageCode: null,
  viewportSize: 'S',
  cookiesPreference: null,
  performanceWarningConfirmed: false,
  redirected: false,
  version: undefined,
  loadingVersion: false
};

export function reducer(
  state: AppState = initialState,
  action: fromAppActions.AppAction
): AppState {
  switch (action.type) {
    case fromAppActions.SET_CURRENT_LOCALE: {
      return {
        ...state,
        currentLocale: action.locale
      };
    }

    case fromAppActions.SET_AVAILABLE_LANGUAGES: {
      return {
        ...state,
        availableLanguages: action.languages
      };
    }

    case fromAppActions.SET_DEFAULT_LANGUAGE_CODE: {
      return {
        ...state,
        defaultLanguageCode: action.languageCode
      };
    }

    case fromAppActions.SET_VIEWPORT_SIZE: {
      return {
        ...state,
        viewportSize: action.viewportSize
      };
    }

    case fromAppActions.SET_COOKIES_PREFERENCE_SUCCESS: {
      return {
        ...state,
        cookiesPreference: action.cookiePreference
      };
    }

    case fromAppActions.SET_PERFORMANCE_WARNING_CONFIRMATION: {
      return {
        ...state,
        performanceWarningConfirmed: action.confirmation
      };
    }

    case fromAppActions.SET_REDIRECTED: {
      return {
        ...state,
        redirected: true
      };
    }

    case fromAppActions.GET_VERSION: {
      return {
        ...state,
        loadingVersion: true
      };
    }

    case fromAppActions.GET_VERSION_SUCCESS: {
      return {
        ...state,
        version: action.version,
        loadingVersion: false
      };
    }

    default: {
      return state;
    }
  }
}

export const getCurrentLocale = (state: AppState) => state.currentLocale;
export const getAvailableLanguages = (state: AppState) =>
  state.availableLanguages;
export const getDefaultLanguageCode = (state: AppState) =>
  state.defaultLanguageCode;
export const getViewportSize = (state: AppState) => state.viewportSize;
export const getCookiesPreference = (state: AppState) =>
  state.cookiesPreference;
export const getPerformanceWarningConfirmed = (state: AppState) =>
  state.performanceWarningConfirmed;
export const getRedirected = (state: AppState) => state.redirected;
export const getVersion = (state: AppState) => state.version;
export const getLoadingVersion = (state: AppState) => state.loadingVersion;
