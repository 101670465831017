import {
  Directive,
  ElementRef,
  HostBinding,
  HostListener,
  Input,
  inject
} from '@angular/core';

@Directive({
  selector: '[appClickArea]',
  exportAs: 'appClickArea',
  standalone: true
})
export class AppClickAreaDirective {
  private elRef = inject(ElementRef);

  @Input() disabledClickArea = false;

  @HostBinding('class.app-click-area') private mainClass = true;

  @HostListener('click', ['$event.target'])
  onClick(target: HTMLElement) {
    if (target.tagName !== 'INPUT' && !this.disabledClickArea) {
      const nativeElement = this.elRef.nativeElement as HTMLElement;
      const checkbox: HTMLElement = nativeElement.querySelector(
        'input[type=checkbox], input[type=radio]'
      );
      if (checkbox) checkbox.click();
    }
  }
}
