export enum Statuses {
  OPEN = 'OPEN',
  OVERDUE = 'OVERDUE',
  PENDING = 'PENDING',
  CHECK_MANUALLY = 'CHECK_MANUALLY',
  PAID = 'PAID',
  PAID_OUT = 'PAID_OUT',
  ERROR = 'ERROR',
  CANCELED = 'CANCELED',
  REFUNDED = 'REFUNDED',
  PENDING_PAY_OUT = 'PENDING_PAY_OUT',
  REVOKED = 'REVOKED',
  CONNECTED = 'CONNECTED',
  IMPORTED = 'IMPORTED',
  RESERVED = 'RESERVED',
  DEFAULT = 'DEFAULT',
  RENTED = 'RENTED',
  EXTERNAL = 'EXTERNAL'
}
