import { Directive, Input } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { WizardStep } from 'libs/components/legacy/wizard/wizard-step/wizard-step';
import { Step } from 'libs/components/legacy/wizard/models';

@Directive({
  standalone: true
})
export class BaseStep extends WizardStep {
  @Input() form: FormGroup;
  @Input() formArray: FormArray;
  @Input() stepIndex: number;
  @Input() steps: Step[];

  nextStep() {
    const next = this.stepIndex + 1;
    const stepName = this.steps[next].name;
    this.onNextStep.emit(stepName);
  }

  public onSubmit() {
    this.form?.valid ? this.nextStep() : this.form.markAllAsTouched();
  }

  public onCompleteStep() {
    this.form?.valid ? this.complete() : this.form.markAllAsTouched();
  }
}
