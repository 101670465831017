import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  inject
} from '@angular/core';
import { BodyModifierClass, BodyService } from 'libs/infrastructure';
import { PageSizeSelectionComponent } from './components/page-size-selection/page-size-selection.component';
import { PaginationComponent } from './components/pagination/pagination.component';

@Component({
  selector: 'app-list-controls',
  templateUrl: './list-controls.component.html',
  styleUrls: ['./list-controls.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [PaginationComponent, PageSizeSelectionComponent]
})
export class ListControlsComponent implements OnInit, OnDestroy {
  private bodyService = inject(BodyService);

  @Input() pageSize = 25;
  @Input() disabled = false;
  @Input() forceDisplay = false;
  @Input() totalCount: number;
  @Input() page: number;
  @Input() selectableSizes = [10, 25, 50, 100];
  @Input() hidePageSizeSelection: boolean;
  @Output() pageChange = new EventEmitter<number>();
  @Output() sizeChange = new EventEmitter<number>();

  ngOnInit(): void {
    this.bodyService.setBodyModifierClass(BodyModifierClass.PAGINATION_VISIBLE);
  }

  ngOnDestroy(): void {
    this.bodyService.unsetBodyModifierClass(
      BodyModifierClass.PAGINATION_VISIBLE
    );
  }

  public onPageChange(page: number) {
    this.pageChange.emit(page);
  }

  public onSizeChange(size: number) {
    this.sizeChange.emit(size);
  }
}
