export const buildBEMClassNamesByGivenBaseClassAndFlags = (
  baseClass: string,
  flags: { [key: string]: boolean }
): string => {
  return [
    baseClass,
    ...Object.keys(flags)
      .filter(key => {
        return flags[key];
      })
      .map(key => {
        return `${baseClass}--${key}`;
      })
  ].join(' ');
};
