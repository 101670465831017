import { Injectable, Renderer2, RendererFactory2, inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { NotificationService } from 'libs/infrastructure/notification/notification.service';

@Injectable()
export class ClipboardService {
  private documentRef = inject(DOCUMENT);
  private rendererFactory = inject(RendererFactory2);
  private notificationService = inject(NotificationService);

  private renderer: Renderer2;

  constructor() {
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  public selectCopyInput(input: HTMLInputElement | HTMLTextAreaElement) {
    if (!input) return;

    input.select();
    this.documentRef.execCommand('Copy');
    this.notificationService.showInfoToast('general.text_copied_l');
  }

  public copyToClipboard(text: string) {
    const body = this.documentRef.body;
    const element = this.renderer.createElement('textarea');

    element.value = text;

    this.renderer.appendChild(body, element);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    element.select();
    this.documentRef.execCommand('copy');
    this.renderer.removeChild(body, element);
    this.notificationService.showInfoToast('general.text_copied_l');
  }
}
