<div
  class="app-form-field-wrapper"
  [class.app-form-field-wrapper--disabled]="disabled"
  [class.app-form-field-wrapper--disabled-click-area]="
    disabled || disabledClickArea
  "
  appClickArea
  [disabledClickArea]="disabled || disabledClickArea"
>
  @if (!labelBelowInput) {
    <ng-template [ngTemplateOutlet]="labelWrapper"></ng-template>
  }

  <div class="app-input-wrapper">
    <ng-content selector="input app-flat-select app-date"></ng-content>
    @if (markCompleted && fieldControl?.touched && !errors.length) {
      <span class="completed-mark">
        <img src="/assets/images/icons/icon-check.svg" />
      </span>
    }
  </div>

  <div class="input-messages">
    <div class="app-form-field-information">
      <ng-content select="app-form-field-info"></ng-content>
    </div>

    @if (fieldControl?.touched && !disableErrorMessages) {
      <div
        class="app-form-field-errors"
        [class.app-form-field-errors--below]="labelBelowInput"
      >
        <ng-content select="app-error"></ng-content>
        @for (error of errors; track error) {
          <app-form-field-error>{{ error | translate }}</app-form-field-error>
        }
      </div>
    }
    @if (fieldControl?.count) {
      <div class="input-counter">
        {{ fieldControl?.counter }} {{ 'general.input_counter_l' | translate }}
      </div>
    }
  </div>

  @if (
    labelBelowInput &&
    !(fieldControl?.touched && !disableErrorMessages && errors.length > 0)
  ) {
    <ng-template [ngTemplateOutlet]="labelWrapper"></ng-template>
  }
</div>

<ng-template #labelWrapper>
  @if (projectedLabelChild() || label) {
    <div
      class="app-input-label-wrapper"
      [class.app-input-label-wrapper--below]="labelBelowInput"
    >
      @switch (!!projectedLabelChild()) {
        @case (true) {
          <ng-content select="app-form-field-label"></ng-content>
        }
        @case (false) {
          <app-form-field-label
            [smallText]="labelBelowInput"
            [idFor]="controlId"
            [showRequiredMarker]="showRequiredMarker"
            [disabled]="disabled"
          >
            {{ label | translate }}
          </app-form-field-label>
        }
      }
      <ng-content select="app-hint"></ng-content>
    </div>
  }
</ng-template>
