@if (projectData) {
  <app-card
    [class]="getClassName()"
    (click)="!pageView ? click($event) : null"
    [elevation]="elevation"
    [clickable]="!pageView"
    [padding]="'none'"
  >
    <ng-container>
      <div [class]="baseClass + '__cover'">
        @if (!pageView) {
          <ng-container *ngTemplateOutlet="badges"></ng-container>
        }
        <div [class]="baseClass + '__cover-wrapper'">
          <app-image
            [defaultSrc]="
              hasPictures
                ? projectData?.data?.titleImage?.url
                : imagePlaceholder
            "
            alt="{{ hasPictures && ('property.fallback_image_l' | translate) }}"
          ></app-image>
          @if (hasPictures) {
            <div
              [class]="baseClass + '__cover-background'"
              [style.background-image]="getBackgroundImage"
            ></div>
          }
        </div>
        @if (projectData?.status && !pageView) {
          <app-project-status-pipeline
            [class]="baseClass + '__pipeline'"
            [status]="projectData?.status"
            [pageView]="pageView"
          ></app-project-status-pipeline>
        }
      </div>
      @if (!limitInformation) {
        <div [class]="baseClass + '__content'">
          @if (pageView) {
            <ng-container *ngTemplateOutlet="badges"></ng-container>
          }
          @if (!pageView) {
            <div class="semibold" [ngStyle]="{ 'font-size': titleFontSize }">
              {{ projectData?.data.name }}
            </div>
          }
          <ng-container *ngTemplateOutlet="details"></ng-container>
        </div>
      }
    </ng-container>
  </app-card>
}

<!-- PROJECT BADGES + CONTEXT MENU TEMPLATE -->
<ng-template #badges>
  @if (!limitInformation) {
    <div [class]="baseClass + '__cover-badges row'">
      <div class="col-10 d-flex flex-wrap pe-0 position-static">
        @if (pageView) {
          <app-badge [color]="'primary-accent'" [icon]="'clipboard'">{{
            projectData.status | translate
          }}</app-badge>
        }
        <app-badge [color]="'light-blue'" [icon]="'object'">
          {{
            projectData?.propertyInformation?.housingInformation?.rentedCount ||
              0
          }}
          /
          {{
            projectData?.propertyInformation?.housingInformation?.totalCount ||
              0
          }}
        </app-badge>
        <app-badge [color]="'light-blue'" [icon]="'parking'">
          {{
            projectData?.propertyInformation?.parkingInformation?.rentedCount ||
              0
          }}
          /
          {{
            projectData?.propertyInformation?.parkingInformation?.totalCount ||
              0
          }}
        </app-badge>
        <app-badge [color]="'light-blue'" [icon]="'building'">
          {{
            projectData?.propertyInformation?.commercialInformation
              ?.rentedCount || 0
          }}
          /
          {{
            projectData?.propertyInformation?.commercialInformation
              ?.totalCount || 0
          }}
        </app-badge>
      </div>
      <div class="col-2 d-flex justify-content-end position-static">
        @if (enableContextMenu) {
          <app-context-menu
            placement="left"
            [ghostButton]="pageView"
            [closeOnItemClick]="true"
            [allowSideNavForMobileView]="false"
          >
            <div menu-content class="d-flex flex-column">
              <app-context-menu-item
                [iconLeft]="'edit'"
                (click)="editClick(projectData?.id); $event.stopPropagation()"
                >{{ 'projects.edit' | translate }}</app-context-menu-item
              >
              @if (allowedToDeleteProjects) {
                <app-context-menu-item
                  [iconLeft]="'delete'"
                  (click)="
                    deleteClick(projectData?.id); $event.stopPropagation()
                  "
                  >{{ 'projects.delete' | translate }}</app-context-menu-item
                >
              }
              @if (!pageView) {
                <app-context-menu-item
                  [iconLeft]="'copy'"
                  (click)="copyClick(projectData?.id); $event.stopPropagation()"
                  >{{ 'projects.copy' | translate }}</app-context-menu-item
                >
              }
              <app-context-menu-item
                [hover]="false"
                [stickToBottom]="true"
                [withTopSpacing]="true"
              >
                <app-button
                  [size]="'small'"
                  [iconLeft]="'copy'"
                  (click)="
                    copyProjectRegistrationLinkClick(
                      projectData?.registrationUrl
                    );
                    $event.stopPropagation()
                  "
                >
                  {{ 'projects.copy_project_registration_link_a' | translate }}
                </app-button>
              </app-context-menu-item>
            </div>
          </app-context-menu>
        }
      </div>
    </div>
  }
</ng-template>

<!-- PROJECT DETAILS TEMPLATE -->
<ng-template #details>
  @if (pageView) {
    <div class="semibold" [ngStyle]="{ 'font-size': titleFontSize }">
      {{ projectData?.data.name }}
    </div>
    @if (pageView) {
      <div class="title-l">{{ projectData?.data.slogan }}</div>
    }
  }

  <div [class]="baseClass + '__content-date'">
    @if (projectData?.data?.availableFrom) {
      <div class="icon icon--calendar d-flex align-items-center">
        <span class="ms-2">{{ 'projects.date_available' | translate }}</span>
      </div>
    }
    <div>{{ projectData?.data?.availableFrom | appDateTime }}</div>
  </div>
  <div class="d-flex flex-wrap mx-0">
    <!-- Price -->
    @if (projectData?.propertyInformation?.countInformation) {
      <ng-container
        [ngTemplateOutlet]="section"
        [ngTemplateOutletContext]="{
          icon: 'money-bag',
          data: projectData?.propertyInformation?.countInformation?.totalRent,
          additional: '€',
          range: true
        }"
      >
      </ng-container>
    }
    <!-- Area size -->
    @if (projectData?.propertyInformation?.countInformation) {
      <ng-container
        [ngTemplateOutlet]="section"
        [ngTemplateOutletContext]="{
          icon: 'area-size',
          data: projectData?.propertyInformation?.countInformation?.size,
          additional: '㎡',
          range: true
        }"
      >
      </ng-container>
    }
    <!-- Number of rooms -->
    @if (projectData?.propertyInformation?.countInformation) {
      <ng-container
        [ngTemplateOutlet]="section"
        [ngTemplateOutletContext]="{
          icon: 'rooms',
          data: projectData?.propertyInformation?.countInformation?.rooms,
          additional: 'ROOMS_L',
          range: true
        }"
      >
      </ng-container>
    }
    @if (projectData?.applyProcessInformation) {
      <ng-container
        [ngTemplateOutlet]="section"
        [ngTemplateOutletContext]="{
          icon: 'eye-open',
          data: projectData.applyProcessInformation.prospects
        }"
      >
      </ng-container>
    }
    <!-- Applicants -->
    @if (projectData?.applyProcessInformation) {
      <ng-container
        [ngTemplateOutlet]="section"
        [ngTemplateOutletContext]="{
          icon: 'user',
          data: projectData.applyProcessInformation?.applicants
        }"
      >
      </ng-container>
    }
  </div>
  <ng-template
    #section
    let-icon="icon"
    let-data="data"
    let-additional="additional"
    let-range="range"
    let-translate="translate"
  >
    <div [class]="baseClass + '__content-section p-0'">
      <div [class]="'icon icon--' + icon"></div>
      @if (range) {
        <span>
          {{
            icon === 'money-bag'
              ? formatCurrencyRange(data)
              : icon === 'area-size'
                ? formatAreaRange(data)
                : getFromToRange(data)
          }}
          @if (additional) {
            {{ additional | translate }}
          }
        </span>
      }
      @if (!range) {
        <span>{{ data }}</span>
      }
    </div>
  </ng-template>
</ng-template>
