import { Injectable } from '@angular/core';
import { Person, User } from '@ui/shared/models';

@Injectable({
  providedIn: 'root'
})
export class DigitalContractService {
  public getUser(signer: Person) {
    return this.getPsUserFromSigner(signer) as User;
  }

  private getPsUserFromSigner(signer: Person) {
    if (!signer) return;
    const { id, email, firstname, lastname, gender, phone } = signer;
    return {
      email,
      created: null,
      profile: {
        phone,
        gender,
        firstname,
        name: lastname
      },
      customer: { id } // required for freshchat
    };
  }
}
