import { Injectable, signal } from '@angular/core';

type SearchOptions = {
  page: number;
  size: number;
};

@Injectable()
export class PaginationService<T = SearchOptions> {
  #options = signal<T>(undefined);

  public get options() {
    return this.#options.asReadonly();
  }

  public init(value: T = { page: 0, size: 10 } as T) {
    this.#options.set(value);
  }

  public updateOptions(options: Partial<T>) {
    this.#options.update(value => ({ ...value, ...options }));
  }
}
