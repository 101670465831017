export interface CustomerDefinedFieldDefinition {
  id: string;
  type: CustomerDefinedFieldType;
  label: string;
  order: number;
}

export interface CustomerDefinedFieldValue {
  definition: CustomerDefinedFieldDefinition;
  value: CustomerDefinedValue;
}

export interface CustomerDefinedValue {
  type: CustomerDefinedFieldType;
  value: CustomerDefinedValueOptions;
}

export type CustomerDefinedValueOptions = boolean | number | string | Date;

export interface CustomerDefinedFieldValuesForm extends CustomerDefinedValue {
  fieldDefinitionId: string;
  label: string;
}

export interface CustomerDefinedValuesPayload {
  fieldDefinitionId: string;
  value: CustomerDefinedValue;
}

export enum CustomerDefinedFieldType {
  BOOLEAN = 'BOOLEAN',
  DECIMAL_NUMBER = 'DECIMAL_NUMBER',
  WHOLE_NUMBER = 'WHOLE_NUMBER',
  STRING = 'STRING',
  DATE = 'DATE'
}
