import { Component, Input, inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NameValue } from '@ui/shared/models';
import { TranslateModule } from '@ngx-translate/core';
import { ModalComponent } from '../modal/modal.component';
import { ModalFooterComponent } from '../modal/modal-footer/modal-footer.component';
import { ButtonComponent } from '../../atoms/button/button.component';
import { ModalContentComponent } from '../modal/modal-content/modal-content.component';
import { ModalV2Component } from '../modal-v2/modal-v2.component';
import { ConfirmReasonComponent } from './confirm-reason/confirm-reason.component';

@Component({
  selector: 'app-confirm-reason-modal',
  templateUrl: './confirm-reason-modal.component.html',
  styleUrls: ['./confirm-reason-modal.component.scss'],
  standalone: true,
  imports: [
    ModalV2Component,
    ModalContentComponent,
    ButtonComponent,
    ConfirmReasonComponent,
    ModalFooterComponent,
    ModalComponent,
    TranslateModule
  ]
})
export class ConfirmReasonModalComponent {
  private ngbActiveModal = inject(NgbActiveModal);

  @Input() reasons: NameValue[];
  @Input() message: string;
  @Input() titleMessage: string;
  @Input() okButtonMessage = 'general.ok';
  @Input() cancelButtonMessage = 'general.cancel_a';
  @Input() showV2 = true;

  public reasonTypeModel: string;
  public otherReasonText: string;

  public get isInvalid() {
    return (
      !this.reasonTypeModel ||
      (this.reasonTypeModel === 'OTHER_REASON' && !this.otherReasonText)
    );
  }

  public get showText() {
    return this.reasonTypeModel === 'OTHER_REASON';
  }

  public cancelAction() {
    this.ngbActiveModal.dismiss();
  }

  public okAction() {
    this.ngbActiveModal.close({
      reasonType: this.reasonTypeModel,
      otherReasonText: this.otherReasonText
    });
  }
}
