<ul class="social-items flex-row">
  <li class="social-item">
    <a
      class="social-link pr0"
      target="_blank"
      href="https://de-de.facebook.com/immomio/"
      ><img src="/assets/images/icons/icon-facebook.svg" alt="facebook-icon"
    /></a>
  </li>
  <li class="social-item">
    <a
      class="social-link pr0"
      target="_blank"
      href="https://twitter.com/immomio"
      ><img src="/assets/images/icons/icon-twitter.svg" alt="twitter-icon"
    /></a>
  </li>
</ul>
