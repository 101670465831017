<mat-slider [min]="min()" [max]="max()" [step]="step()" discrete>
  <input
    matSliderStartThumb
    (dragEnd)="dragEnd($event)"
    [formControl]="minFormControl()"
    [attr.aria-label]="minAriaLabel()"
  />
  <input
    matSliderEndThumb
    (dragEnd)="dragEnd($event)"
    [formControl]="maxFormControl()"
    [attr.aria-label]="maxAriaLabel()"
  />
  <div class="slider__label-container">
    <div class="slider__label-min">{{ minFormControl().value || min() }}</div>
    <div class="slider__label-max">
      {{ maxFormControl().value || max() }}
      @if (maxFormControl().value === max() || !maxFormControl().value) {
        +
      }
    </div>
  </div>
</mat-slider>
