import { HousingPermissionType } from './enums/housing-permission-type';
import { DropdownStepperParent } from './dropdown-stepper.model';
import { NameValue } from './helpers';
import { StateOfGermany } from './state-of-germany.enum';

export const getWbsOptions = (
  germanyStates: NameValue<StateOfGermany>[]
): DropdownStepperParent<HousingPermissionType>[] => {
  const options: DropdownStepperParent<HousingPermissionType>[] = [];

  germanyStates.forEach(v => {
    const state = v.value;
    switch (state) {
      case StateOfGermany.BADEN_WUERTTEMBERG:
        options.push(
          getWbsItems(state, [
            HousingPermissionType.BADEN_WUERTTEMBERG_WOSU_EMERGENCY_CERTIFICATE
          ])
        );
        break;
      case StateOfGermany.BAVARIA:
        options.push(
          getWbsItems(state, [
            HousingPermissionType.BAVARIA_EOF_INCOME_GROUP_1,
            HousingPermissionType.BAVARIA_EOF_INCOME_GROUP_2,
            HousingPermissionType.BAVARIA_EOF_INCOME_GROUP_3
          ])
        );
        break;
      case StateOfGermany.BERLIN:
        options.push(
          getWbsItems(state, [
            HousingPermissionType.WBS_100,
            HousingPermissionType.WBS_140,
            HousingPermissionType.WBS_160,
            HousingPermissionType.WBS_180,
            HousingPermissionType.WBS_240,
            HousingPermissionType.WBS_SPECIAL_HOUSING_NEEDS,
            HousingPermissionType.URGENCY_CERTIFICATE,
            HousingPermissionType.URGENCY_CONFIRMATION
          ])
        );
        break;
      case StateOfGermany.HAMBURG:
        options.push(
          getWbsItems(state, [
            HousingPermissionType.URGENCY_CERTIFICATE,
            HousingPermissionType.URGENCY_CONFIRMATION,
            HousingPermissionType.HAMBURG_SIXTEENTH_PARAGRAPH
          ])
        );
        break;
      case StateOfGermany.SCHLESWIG_HOLSTEIN:
        options.push(
          getWbsItems(state, [
            HousingPermissionType.SCHLESWIG_HOLSTEIN_EIGHTH_PARAGRAPH_STANDARD,
            HousingPermissionType.SCHLESWIG_HOLSTEIN_EIGHTH_PARAGRAPH_PLUS_20,
            HousingPermissionType.SCHLESWIG_HOLSTEIN_EIGHTH_PARAGRAPH_PLUS_40,
            HousingPermissionType.SCHLESWIG_HOLSTEIN_EIGHTY_EIGHTH_PARAGRAPH_D_2
          ])
        );
        break;
      default:
        options.push(getWbsItems(state, []));
        break;
    }
  });

  return options;
};

const getWbsItems = (
  state: StateOfGermany,
  housingPermissionTypes: HousingPermissionType[]
): DropdownStepperParent<HousingPermissionType> => {
  const nationwideTypes: HousingPermissionType[] = [
    HousingPermissionType.WBS,
    HousingPermissionType.SIXTH_PARAGRAPH_SECOND_SUPPORT_PATH
  ];
  const types: HousingPermissionType[] = [
    ...nationwideTypes,
    ...housingPermissionTypes
  ];
  return {
    name: `states.germany.${state.toLowerCase()}_l`,
    items: types.map(housingPermissionType => ({
      name: `HOUSINGPERMISSIONTYPES_${housingPermissionType.toUpperCase()}`,
      value: housingPermissionType
    }))
  };
};
