import { Pipe, PipeTransform, inject } from '@angular/core';
import { DateTimeService } from 'libs/services';

@Pipe({
  name: 'appMessageTime',
  standalone: true
})
export class MessageTimePipe implements PipeTransform {
  private dateTimeService = inject(DateTimeService);

  transform(date: Date | string) {
    if (!date) return '';
    return this.dateTimeService.getDateCalenderInFormat(date);
  }
}
