<div
  ngbDropdown
  #dropdown="ngbDropdown"
  class="auto-complete-field"
  [ngClass]="{ 'show-clear-button': showClearButton }"
  autoClose="outside"
  (click)="hasUserInteractedWithComponent = true"
>
  <div (click)="!readonly && handleDropdownClick()">
    <ng-content></ng-content>

    <div
      class="auto-complete-field__dropdown-arrow-container dropdown-toggle--frameless align-items-center"
      [ngClass]="{ disabled: readonly }"
    >
      @if (loading) {
        <app-loading-spinner
          class="auto-complete-field__spinner"
        ></app-loading-spinner>
      }
      @if (!loading && showClearButton) {
        <i
          class="auto-complete-field__dropdown-arrow icon icon--close me-2"
          (click)="clearValue.emit()"
        ></i>
      }
      @if (!loading) {
        <i
          class="auto-complete-field__dropdown-arrow icon icon--arrow-dropdown"
        ></i>
      }
    </div>

    <div
      class="auto-complete-field__dropdown-menu dropdown-menu"
      ngbDropdownMenu
    >
      @if (selector$ | async; as selector) {
        <div
          class="auto-complete-field__dropdown-menu-inner"
          (scroll)="onScroll($event)"
        >
          @if (selector?.length > 0) {
            @for (value of selector; track value; let i = $index) {
              <div
                class="auto-complete-field__dropdown-menu-item"
                (click)="setFormControlValue(value)"
              >
                <ng-template
                  [ngTemplateOutlet]="itemTemplate"
                  [ngTemplateOutletContext]="{ $implicit: value }"
                ></ng-template>
                @if (selector?.length > 0 && selector?.length - 1 !== i) {
                  <hr class="mt10 mb0" />
                }
              </div>
            }
          } @else {
            <div class="auto-complete-field__dropdown-menu-no-results">
              {{ 'general.no_search_results_m' | translate }}
            </div>
          }
        </div>
      }
    </div>
  </div>
</div>
