import { inject, Injectable } from '@angular/core';
import { INFRASTRUCTURE_CONFIG } from '../infrastructure-config.token';

export type TokenEncodingFunction = (token: string) => string;

export interface TokenEncodingStrategies {
  [key: string]: TokenEncodingFunction;
}

export interface GenerateDownloadUrlArgs {
  token: string;
  fileURL: string;
  encrypted: boolean;
  filename?: string;
}

export interface GetPDFExposeUrlArgs {
  token: string;
  propertyID: string;
}

export interface GetSelfDisclosurePDFUrlArgs {
  token: string;
  id: number;
}

export interface GetDigitalContractUrlArgs {
  token: string;
  identifier: string;
}

export const generatorErrors = {
  unrecognizedHeader: header =>
    `UNRECOGNIZED_AUTH_HEADER_TYPE ${String(header)}`
};

@Injectable()
export class DownloadUrlGeneratorService {
  private config = inject(INFRASTRUCTURE_CONFIG);

  private tokenEncodingStrategies: TokenEncodingStrategies = {
    'X-Auth-Token': token => encodeURI(`Bearer ${token}`)
  };

  public generateDownloadUrl(args: GenerateDownloadUrlArgs): string {
    const { token, fileURL, encrypted, filename } = args;
    const tokenHeader = this.config.environment.file_download_auth_name;
    const filerAddress = this.config.environment.filer_url;

    if (!this.tokenEncodingStrategies[tokenHeader]) {
      throw Error(generatorErrors.unrecognizedHeader(tokenHeader));
    }

    const encodedToken = this.tokenEncodingStrategies[tokenHeader](token);
    const encodedFileURL = encodeURI(fileURL);

    const tokenPart = `${tokenHeader}=${encodedToken}`;
    const fileURLPart = `&url=${encodedFileURL}`;
    const encryptedPart = `&encrypted=${String(encrypted)}`;
    const filenamePart = filename ? `&filename=${filename}` : '';
    return `${filerAddress}/download?${tokenPart}${fileURLPart}${encryptedPart}${filenamePart}`;
  }

  public getSelfDisclosurePDFUrl(args: GetSelfDisclosurePDFUrlArgs): string {
    return this.getUrl(
      '/self-disclosure/pdf/generate/',
      args.id.toString(),
      args.token
    );
  }

  public getDigitalContractUrl(args: GetDigitalContractUrlArgs): string {
    const { filer_url } = this.config.environment;
    return `${filer_url}/dmv/download?token=${args.token}&identifier=${args.identifier}`;
  }

  private getUrl(urlPart: string, id: string, token: string) {
    const tokenHeader = 'X-Auth-Token';
    const { filer_url } = this.config.environment;
    const encodedToken = this.tokenEncodingStrategies[tokenHeader](token);

    const tokenPart = `${tokenHeader}=${encodedToken}`;
    return `${filer_url}${urlPart}${id}?${tokenPart}`;
  }
}
