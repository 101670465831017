<form [formGroup]="form">
  <ng-content></ng-content>

  <app-form-field>
    <app-checkbox
      [label]="'messenger.setting_notify_me_l' | translate"
      formControlName="notifyWhenReceivingMessage"
    >
    </app-checkbox>
  </app-form-field>
</form>
