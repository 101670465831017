<app-form-field>
  <div class="d-flex flex-row justify-content-center">
    <app-form-field-label>{{
      'general.select_amount_of_items_l' | translate
    }}</app-form-field-label>
    <app-select
      [items]="selectableSizes"
      [ngModel]="pageSize"
      [showAdditionalItem]="true"
      (ngModelChange)="onSizeChange($event)"
    ></app-select>
  </div>
</app-form-field>
