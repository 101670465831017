export interface Storage {
  getItem: (key: string) => any;
  setItem: (key: string, value: any) => any;
  removeItem: (key: string) => any;
  clear: () => void;
}

export abstract class StorageService {
  storage: Storage;

  public getItem<T = any>(key: string): T {
    const value = this.storage.getItem(key);
    try {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return JSON.parse(value);
    } catch (e) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return value;
    }
  }

  public setItem(key: string, value: any) {
    try {
      const val = typeof value === 'object' ? JSON.stringify(value) : value;
      this.storage.setItem(key, val);
      return true;
    } catch (e) {
      return false;
    }
  }

  public setItems(items: Record<string, unknown>) {
    Object.entries(items).forEach(([key, value]) => this.setItem(key, value));
  }

  public removeItem(key: string) {
    try {
      this.storage.removeItem(key);
      return true;
    } catch (e) {
      return false;
    }
  }

  public removeItems(...items: string[]) {
    return items.map(key => this.removeItem(key));
  }

  public clear() {
    this.storage.clear();
    return true;
  }
}
