import { Injectable, inject } from '@angular/core';
import { FileUploadService } from 'libs/infrastructure';

import { Attachment } from '@ui/shared/models';
import { PropertyAttachmentsTypes } from '@ui/shared/models';
import { FinalPropertyPayload } from 'libs/properties';

import { Observable, of, zip } from 'rxjs';

import { map } from 'rxjs/operators';

@Injectable()
export class AttachmentService {
  private fileUploadService = inject(FileUploadService);

  public parseAttachmentToSave(payload: FinalPropertyPayload) {
    const attachments = payload.data.attachments || [];
    const documents = payload.data.documents || [];
    const expose = payload.data.expose || {};

    const existingAttachments = attachments.filter(
      attachment => !(attachment.file instanceof Blob)
    );
    const attachmentsToUpload = attachments.filter(
      attachment => attachment.file instanceof Blob
    );
    const floorPlanToUpload = documents.filter(
      attachment =>
        attachment.type === PropertyAttachmentsTypes.FLOOR_PLAN &&
        attachment.file instanceof Blob
    );
    const documentsToUpload = documents.filter(
      attachment =>
        attachment.type !== PropertyAttachmentsTypes.FLOOR_PLAN &&
        attachment.file instanceof Blob
    );

    return zip(
      this.uploadImages(attachmentsToUpload),
      this.uploadDocuments(documentsToUpload),
      this.uploadFloorPlan(floorPlanToUpload),
      this.uploadDocument(expose)
    ).pipe(
      map(
        ([
          uploadedAttachments,
          uploadedDocuments,
          uploadedFloorPlan,
          uploadedExpose
        ]) => {
          const documentsToSave = [
            ...documents,
            ...uploadedDocuments,
            ...uploadedFloorPlan
          ].filter(
            attachment => attachment && !(attachment.file instanceof Blob)
          );

          const attachmentsToSave = [
            ...existingAttachments,
            ...uploadedAttachments
          ]
            .sort((a, b) => a.index - b.index)
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return
            .map((attachment, index) => ({
              ...attachment,
              index
            }));

          return {
            ...payload,
            data: {
              ...payload.data,
              documents: documentsToSave,
              attachments: attachmentsToSave,
              expose: uploadedExpose
            }
          };
        }
      )
    );
  }
  public parseDocumentsToSave(
    attachments: Attachment[],
    options?: Record<string, unknown>
  ) {
    const documentsToUpload = attachments.filter(
      attachment => attachment.type === 'PDF'
    );
    const imagesToUpload = attachments.filter(
      attachment => attachment.type === 'IMG'
    );

    return zip(
      this.uploadImages(imagesToUpload, options),
      this.uploadDocuments(documentsToUpload, options)
    ).pipe(
      map(([uploadedImages, uploadedDocuments]) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return [...uploadedImages, ...uploadedDocuments];
      })
    );
  }

  public uploadImages(
    attachments: Attachment[],
    options?: Record<string, unknown>
  ) {
    const toUpload = attachments.filter(
      attachment =>
        attachment.file instanceof Blob &&
        (attachment.identifier ? 'rotate' in attachment : true)
    );

    const rotations = toUpload.map(attachment => attachment.rotate);
    const files = toUpload.map(attachment => attachment.file) as File[];

    if (!toUpload.length) return of([]) as Observable<Attachment[]>;

    return this.fileUploadService.uploadImages(files, rotations, options).pipe(
      map(response =>
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        response.map((attachment, i) => ({
          ...toUpload[i],
          ...attachment,
          index: toUpload[i].index,
          title: toUpload[i].title || ''
        }))
      )
    );
  }

  public uploadDocuments(
    attachments: Attachment[],
    options?: Record<string, unknown>
  ) {
    const toUpload = attachments.filter(
      attachment => attachment.file instanceof Blob
    );
    if (!toUpload.length) return of([]) as Observable<Attachment[]>;

    return this.fileUploadService.uploadDocuments(toUpload, 'PDF', options);
  }

  public uploadFloorPlan(attachments: Attachment[]) {
    const toUpload = attachments.filter(
      attachment => attachment.file instanceof Blob
    );
    if (!toUpload.length) return of([]) as Observable<Attachment[]>;

    return this.fileUploadService.uploadDocuments(
      toUpload,
      PropertyAttachmentsTypes.FLOOR_PLAN
    );
  }

  public uploadDocument(attachment: Attachment) {
    if (!(attachment.file instanceof Blob))
      return of(null) as Observable<Attachment>;

    return this.fileUploadService.uploadDocument(attachment, 'PDF');
  }
}
