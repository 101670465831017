import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  inject,
  ChangeDetectionStrategy
} from '@angular/core';
import {
  FormArray,
  FormControl,
  FormGroup,
  Validators,
  FormsModule,
  ReactiveFormsModule
} from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import * as fromBaseState from 'libs/infrastructure/base-state';

import {
  CityPacket,
  CustomerLocation,
  DE_AT_CONFIG,
  NameValue,
  PropertyType,
  SearchProfileType
} from '@ui/shared/models';
import { filter, startWith } from 'rxjs/operators';
import { atLeastOneControlHasValueInsideValidator } from 'libs/components/legacy/form/controls/validation';
import { isPropertyTypeFlat, isPropertyTypeWithDistrict } from 'libs/utils';
import {
  NgbAccordionDirective,
  NgbAccordionItem,
  NgbAccordionHeader,
  NgbAccordionToggle,
  NgbCollapse,
  NgbAccordionCollapse,
  NgbAccordionBody
} from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { SvgIconComponent } from 'angular-svg-icon';
import { AsyncPipe } from '@angular/common';
import { CheckboxComponent } from '../../form/controls/checkbox/checkbox.component';
import { ButtonComponent } from '../../../atoms/button/button.component';
import { AppInputDirective } from '../../form/controls/input/input.directive';
import { DropdownMultiselectComponent } from '../../form/controls/dropdown-multiselect/dropdown-multiselect.component';
import { CityAutocompleteComponent } from '../city-autocomplete/city-autocomplete.component';
import { SearchProfileLandlordDistrictsComponent } from '../search-profile-landlord-districts/search-profile-landlord-districts.component';
import { FlatSelectComponent } from '../../form/controls/flat-select/flat-select.component';
import { DropdownSelectComponent } from '../../form/controls/dropdown-select/dropdown-select.component';
import { FormFieldLabelComponent } from '../../form/form-field/form-field-label/form-field-label.component';
import { FormFieldComponent } from '../../form/form-field/form-field.component';

@UntilDestroy()
@Component({
  selector: 'app-search-profile-address',
  templateUrl: './search-profile-address.component.html',
  styleUrls: ['./search-profile-address.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    FormFieldComponent,
    FormFieldLabelComponent,
    DropdownSelectComponent,
    FlatSelectComponent,
    SearchProfileLandlordDistrictsComponent,
    NgbAccordionDirective,
    NgbAccordionItem,
    NgbAccordionHeader,
    NgbAccordionToggle,
    SvgIconComponent,
    NgbCollapse,
    NgbAccordionCollapse,
    NgbAccordionBody,
    CityAutocompleteComponent,
    DropdownMultiselectComponent,
    AppInputDirective,
    ButtonComponent,
    CheckboxComponent,
    TranslateModule,
    AsyncPipe
  ]
})
export class SearchProfileAddressComponent implements OnInit {
  private store = inject<Store<fromBaseState.BaseState>>(Store);

  @Input() form: FormGroup;
  @Input() locationError: string;
  @Input() landlordCityPacket: CityPacket;
  @Input() onlyShowConfiguredCityPartsToUser: boolean;
  @Input() isRegistration: boolean;
  @Input() countries: string[];
  @Input() inInternalPool: boolean;
  @Output() addCity = new EventEmitter();
  @ViewChild('citiesAccordion') accordion: NgbAccordionDirective;
  public districtsMap$ = this.store.select(fromBaseState.getCityDistrictsMap);
  public countriesConfig: NameValue[] = [];

  public selectOptions = [
    {
      name: 'register.location_type.district_l',
      value: SearchProfileType.DISTRICT
    },
    { name: 'register.location_type.radius_l', value: SearchProfileType.RADIUS }
  ];

  public radiusOptions = [];
  public isExactAddress: boolean;

  public get isRadius() {
    return this.getTypeControl.value === SearchProfileType.RADIUS;
  }

  public get isDistrict() {
    return this.getTypeControl.value === SearchProfileType.DISTRICT;
  }

  public get getDistrictsFormArray() {
    return this.form.get('districts') as FormArray;
  }

  public get cityControl() {
    return this.addressForm.get('city');
  }

  public get getTypeControl() {
    return this.form.get('type');
  }

  public get radiusControl() {
    return this.form.get('radius');
  }

  public get propertyType() {
    return this.form.get('propertyType') as FormControl;
  }

  public get isFlat() {
    return isPropertyTypeFlat(this.propertyType.value);
  }

  public get showDistrictSelection() {
    return isPropertyTypeWithDistrict(this.propertyType.value);
  }

  private get isNewSP() {
    return !this.form.get('id').value;
  }

  private get addressForm() {
    return this.form.get('address') as FormGroup;
  }

  private get zipCodeControl() {
    return this.addressForm.get('zipCode');
  }

  private get radiusGarageOptions() {
    return [
      { name: 'radius.0.2_km_l', value: 200 },
      { name: 'radius.0.4_km_l', value: 400 },
      { name: 'radius.0.6_km_l', value: 600 },
      { name: 'radius.0.8_km_l', value: 800 },
      { name: 'radius.greater_than_0.8_km_l', value: 1000 },
      {
        name: 'radius.1.2_km_l',
        value: 1200,
        hide: !(this.radiusControl.value >= 1000)
      },
      {
        name: 'radius.1.4_km_l',
        value: 1400,
        hide: !(this.radiusControl.value >= 1000)
      },
      {
        name: 'radius.1.6_km_l',
        value: 1600,
        hide: !(this.radiusControl.value >= 1000)
      },
      {
        name: 'radius.1.8_km_l',
        value: 1800,
        hide: !(this.radiusControl.value >= 1000)
      },
      {
        name: 'radius.2_km_l',
        value: 2000,
        hide: !(this.radiusControl.value >= 1000)
      }
    ];
  }

  private get radiusFlatOptions() {
    return [
      { name: 'radius.0.5_km_l', value: 500 },
      { name: 'radius.1_km_l', value: 1000 },
      { name: 'radius.2_km_l', value: 2000 },
      { name: 'radius.greater_than_2_km_l', value: 2500 },
      {
        name: 'radius.3_km_l',
        value: 3000,
        hide: !(this.radiusControl.value >= 2500)
      },
      {
        name: 'radius.5_km_l',
        value: 5000,
        hide: !(this.radiusControl.value >= 2500)
      },
      {
        name: 'radius.8_km_l',
        value: 8000,
        hide: !(this.radiusControl.value >= 2500)
      },
      {
        name: 'radius.13_km_l',
        value: 13000,
        hide: !(this.radiusControl.value >= 2500)
      }
    ];
  }

  private get landlordDistrictsFormArray() {
    return this.form.get('landlordDistricts') as FormArray;
  }

  ngOnInit() {
    if (this.landlordCityPacket) {
      this.handleLandlordDistricts();
    }
    this.handleDistrictByType(this.getTypeControl.value);
    this.radiusOptions = this.showDistrictSelection
      ? this.radiusFlatOptions
      : this.radiusGarageOptions;

    this.getTypeControl.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(value => this.handleDistrictByType(value));

    if (this.showDistrictSelection) {
      if (this.onlyShowConfiguredCityPartsToUser) {
        this.selectOptions = this.resolveSelectOptions([
          SearchProfileType.DISTRICT
        ]);
      } else {
        this.selectOptions = this.resolveSelectOptions([
          SearchProfileType.DISTRICT,
          SearchProfileType.RADIUS
        ]);
      }
    } else {
      this.getTypeControl.patchValue(SearchProfileType.RADIUS);
      this.selectOptions = this.resolveSelectOptions([
        SearchProfileType.RADIUS
      ]);
    }

    this.isExactAddress = !this.radiusControl.value;

    this.radiusControl.valueChanges
      .pipe(
        filter(value => {
          const radius = this.showDistrictSelection ? 2500 : 1000;
          return value >= radius;
        }),
        untilDestroyed(this)
      )
      .subscribe(
        () =>
          // eslint-disable-next-line @typescript-eslint/no-unsafe-return
          (this.radiusOptions = this.radiusOptions.map(option => ({
            ...option,
            hide: 'hide' in option ? false : undefined
          })))
      );

    this.form
      .get('propertyType')
      .valueChanges.pipe(untilDestroyed(this))
      .subscribe((value: PropertyType) => {
        this.radiusControl.patchValue(this.showDistrictSelection ? 2000 : 200);
        this.radiusOptions = this.showDistrictSelection
          ? this.radiusFlatOptions
          : this.radiusGarageOptions;

        if (isPropertyTypeWithDistrict(value)) {
          if (this.onlyShowConfiguredCityPartsToUser) {
            this.selectOptions = this.resolveSelectOptions([
              SearchProfileType.DISTRICT
            ]);
          } else {
            this.selectOptions = this.resolveSelectOptions([
              SearchProfileType.DISTRICT,
              SearchProfileType.RADIUS
            ]);
          }
        } else {
          this.getTypeControl.patchValue(SearchProfileType.RADIUS);
          this.selectOptions = this.resolveSelectOptions([
            SearchProfileType.RADIUS
          ]);
        }
      });

    if (
      this.countries?.length > 0 &&
      !this.landlordCityPacket &&
      !this.inInternalPool
    ) {
      this.countries.map(country => {
        this.countriesResolver(country);
      });
    } else if (
      this.landlordCityPacket &&
      (this.inInternalPool || this.isRegistration)
    ) {
      this.countriesResolver(this.landlordCityPacket.country);
    } else {
      this.countriesConfig = [];
      this.countriesResolver(CustomerLocation.DE);
      this.countriesResolver(CustomerLocation.AT);
    }

    if (this.countriesConfig.length === 1) {
      this.addressForm.get('country').patchValue(this.countriesConfig[0].value);
    }

    if (this.countriesConfig.length > 1 && this.isRegistration) {
      DE_AT_CONFIG.map(option => {
        if (option.value === CustomerLocation.DE) {
          this.addressForm.get('country').patchValue(option.value);
        }
      });
    }

    this.modifyZipCodeValidationBasedOnCountry(
      this.addressForm.get('country')?.value as CustomerLocation
    );

    this.addressForm
      .get('country')
      ?.valueChanges.pipe(untilDestroyed(this))
      .subscribe((value: CustomerLocation) => {
        this.modifyZipCodeValidationBasedOnCountry(value);
      });
  }

  public modifyZipCodeValidationBasedOnCountry(value: CustomerLocation) {
    const countriesMap = new Map([
      [CustomerLocation.DE, 5],
      [CustomerLocation.AT, 4]
    ]);

    if (countriesMap.has(value)) {
      this.zipCodeControl.setValidators([
        Validators.pattern(`^[0-9]{${countriesMap.get(value)}}$`)
      ]);
    } else {
      this.zipCodeControl.clearValidators();
    }

    this.zipCodeControl.updateValueAndValidity();
  }

  public resetRadius() {
    this.radiusControl.patchValue(
      this.isExactAddress ? 0 : this.showDistrictSelection ? 2000 : 200
    );
  }

  public addNewCity() {
    this.accordion.collapseAll();
    this.addCity.emit();
  }

  public removeCity(index: number) {
    this.getDistrictsFormArray.removeAt(index);
  }

  private handleDistrictByType(value: string) {
    if (value === SearchProfileType.DISTRICT) {
      this.zipCodeControl.disable();
      this.cityControl.disable();
      this.getDistrictsFormArray.enable();
      this.landlordDistrictsFormArray?.enable();
    } else {
      this.zipCodeControl.enable();
      this.cityControl.enable();
      this.getDistrictsFormArray.disable();
      this.landlordDistrictsFormArray?.disable();
    }
  }

  private handleLandlordDistricts() {
    this.form.addControl(
      'landlordDistricts',
      new FormArray([], {
        validators: [atLeastOneControlHasValueInsideValidator]
      })
    );
    this.landlordCityPacket.citiesMap.forEach(v => {
      for (let i = 0; i < v.length; i++) {
        this.landlordDistrictsFormArray.push(new FormControl([]));
      }
    });

    if (this.isNewSP) this.getDistrictsFormArray.clear();

    this.getDistrictsFormArray.statusChanges
      .pipe(startWith(this.getDistrictsFormArray.status), untilDestroyed(this))
      .subscribe(status => {
        status === 'VALID'
          ? this.landlordDistrictsFormArray.clearValidators()
          : this.landlordDistrictsFormArray.setValidators(
              atLeastOneControlHasValueInsideValidator
            );
        this.landlordDistrictsFormArray.updateValueAndValidity({
          emitEvent: false
        });
      });

    this.landlordDistrictsFormArray.statusChanges
      .pipe(
        startWith(this.landlordDistrictsFormArray.status),
        untilDestroyed(this)
      )
      .subscribe(status => {
        status === 'VALID'
          ? this.getDistrictsFormArray.clearValidators()
          : this.getDistrictsFormArray.setValidators(Validators.required);
        this.getDistrictsFormArray.updateValueAndValidity({
          emitEvent: false
        });
      });
  }

  public countriesResolver(value: string) {
    DE_AT_CONFIG.map(option => {
      if (option.value === value) {
        this.countriesConfig.push(option);
      }
    });
  }

  public resolveSelectOptions(searchProfileType: SearchProfileType[]) {
    if (
      searchProfileType.includes(SearchProfileType.DISTRICT) &&
      !searchProfileType.includes(SearchProfileType.RADIUS)
    ) {
      return [
        {
          name: 'register.location_type.district_l',
          value: SearchProfileType.DISTRICT
        }
      ];
    } else if (
      !searchProfileType.includes(SearchProfileType.DISTRICT) &&
      searchProfileType.includes(SearchProfileType.RADIUS)
    ) {
      return [
        {
          name: 'register.location_type.radius_l',
          value: SearchProfileType.RADIUS
        }
      ];
    } else {
      return [
        {
          name: 'register.location_type.district_l',
          value: SearchProfileType.DISTRICT
        },
        {
          name: 'register.location_type.radius_l',
          value: SearchProfileType.RADIUS
        }
      ];
    }
  }
}
