import { createSelector } from '@ngrx/store';

import * as fromReducers from '../reducers';
import * as fromConstantsReducers from './constants.reducers';

export const getConstantState = createSelector(
  fromReducers.getBaseState,
  (state: fromReducers.BaseState) => state.constants
);

export const getConstantsLoading = createSelector(
  getConstantState,
  fromConstantsReducers.getLoading
);

export const getConstantsLoaded = createSelector(
  getConstantState,
  fromConstantsReducers.getLoaded
);

export const getConstants = createSelector(
  getConstantState,
  fromConstantsReducers.getConstants
);

export const getConstantsError = createSelector(
  getConstantState,
  fromConstantsReducers.getError
);

export const getConstantsRaw = createSelector(getConstants, data => {
  if (!data) return null;

  const rawData = {};
  Object.keys(data).forEach(
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    key => (rawData[key] = data[key].map(element => element.value))
  );

  return rawData;
});
