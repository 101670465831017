export const paginationQuery = `
  cursor
  hasNext
  hasPrev
  next
  page
  prev
  size
  totalElements
  totalPages
`;
