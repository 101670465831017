import { Component, OnInit, inject } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { CustomQuestion } from '@ui/shared/models';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonComponent } from '../../../atoms/button/button.component';
import { ModalFooterComponent } from '../../modal/modal-footer/modal-footer.component';
import { CustomQuestionFormComponent } from '../custom-question-form/custom-question-form.component';
import { ModalContentComponent } from '../../modal/modal-content/modal-content.component';
import { ModalComponent } from '../../modal/modal.component';

@Component({
  selector: 'app-custom-question-preview-modal',
  templateUrl: './custom-question-preview-modal.component.html',
  styleUrls: ['./custom-question-preview-modal.component.scss'],
  standalone: true,
  imports: [
    ModalComponent,
    ModalContentComponent,
    CustomQuestionFormComponent,
    FormsModule,
    ReactiveFormsModule,
    ModalFooterComponent,
    ButtonComponent,
    TranslateModule
  ]
})
export class CustomQuestionPreviewModalComponent implements OnInit {
  private ngbActiveModal = inject(NgbActiveModal);

  public questionControl = new FormControl();
  public customQuestion: CustomQuestion;
  public global: boolean;

  public ngOnInit() {
    if (this.customQuestion) {
      this.global = this.global ?? this.customQuestion.global;
      this.questionControl.patchValue(this.customQuestion);
    }
  }

  public dismiss() {
    this.ngbActiveModal.dismiss();
  }
}
