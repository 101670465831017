import { Component, ViewEncapsulation, Input, inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SystemTag } from '@ui/shared/models';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonComponent } from '../../../atoms/button/button.component';
import { ModalFooterComponent } from '../modal-footer/modal-footer.component';
import { ModalContentComponent } from '../modal-content/modal-content.component';
import { ModalComponent } from '../modal.component';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    ModalComponent,
    ModalContentComponent,
    ModalFooterComponent,
    ButtonComponent,
    TranslateModule
  ]
})
export class ConfirmationDialogComponent {
  private ngbActiveModal = inject(NgbActiveModal);

  @Input() message: string;
  @Input() acknowledge = false;
  @Input() innerHTML = false;
  @Input() titleMessage: string;
  @Input() okButtonMessage = 'general.ok';
  @Input() cancelButtonMessage = 'general.cancel_a';
  @Input() titleValue = null;
  @Input() messageValue = null;
  @Input() systemTags: SystemTag[];

  okAction() {
    this.ngbActiveModal.close();
  }

  cancelAction() {
    this.ngbActiveModal.dismiss();
  }

  public get isProspectOfflineSales() {
    return this.systemTags?.includes(
      SystemTag.OFFLINE && SystemTag.SALES_PROSPECT
    );
  }
}
