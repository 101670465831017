import { Component, Input } from '@angular/core';
import { buildBEMClassNamesByGivenBaseClassAndFlags } from 'libs/utils';
import { NgClass } from '@angular/common';
import {
  ImageSrcSet,
  ImageSources,
  ImageDefaultConfig,
  ImageBorderRadiusEnum,
  ImageObjectFitEnum,
  ImageBorderStyleEnum
} from './image.model';

@Component({
  selector: 'app-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss'],
  standalone: true,
  imports: [NgClass]
})
export class ImageComponent {
  @Input() sources: ImageSources = ImageDefaultConfig.sources;
  @Input() defaultSrc: string;
  @Input() alt: string;
  @Input() lazyLoading = ImageDefaultConfig.lazyLoading;
  @Input() maxHeightInPx: number;
  @Input() objectFit: ImageObjectFitEnum = ImageDefaultConfig.objectFit;
  @Input() borderRadius: ImageBorderRadiusEnum =
    ImageDefaultConfig.borderRadius;
  @Input() borderStyle: ImageBorderStyleEnum = ImageDefaultConfig.borderStyle;
  @Input() centerImage = false;
  @Input() dataTableCellPicture = false;

  public get setMaxHeight(): boolean {
    return !isNaN(this.maxHeightInPx) && Number.isInteger(+this.maxHeightInPx);
  }

  public baseClass = 'image';

  public getClassName(): string {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-return
    return buildBEMClassNamesByGivenBaseClassAndFlags(this.baseClass, {
      [`object-fit-${this.objectFit}`]: !!this.objectFit,
      [`border-radius-${this.borderRadius}`]: !!this.borderRadius,
      [`border-style-${this.borderStyle}`]: !!this.borderStyle
    });
  }

  public getMediaQuery(
    minScreenWidth?: number,
    maxScreenWidth?: number
  ): string | null {
    let ret = '';
    if (minScreenWidth !== undefined) {
      ret += `(min-width: ${minScreenWidth}px)`;
    }
    if (maxScreenWidth !== undefined) {
      if (ret !== '') ret += ' and ';
      ret += `(max-width: ${maxScreenWidth}px)`;
    }
    return ret || null;
  }

  public getSrcSet(srcSet: ImageSrcSet): string | null {
    if (!srcSet) return null;
    let ret = '';
    let value;
    for (let i = 1; i <= 3; i++) {
      value = srcSet[`x${i}`];
      if (value) {
        if (ret !== '') ret += ', ';
        ret += `${String(value)} ${i}x`;
      }
    }
    return ret || null;
  }
}
