import { Address } from './address.model';

export enum MandateStatus {
  CREATED = 'CREATED', // created but form not filled out
  SUBMITTED = 'SUBMITTED', // success
  ACTIVE = 'ACTIVE', // success
  FAILED = 'FAILED', // fail
  EXPIRED = 'EXPIRED' // fail
}

export enum PaymentType {
  SEPA = 'SEPA',
  PAYPAL = 'PAYPAL',
  BANK_WIRE = 'BANK_WIRE'
}

export enum PaymentCategory {
  DEPOSIT = 'DEPOSIT',
  ENTRY_FEE = 'ENTRY_FEE',
  OTHER = 'OTHER',
  OTHER_RESIDENT = 'OTHER_RESIDENT'
}

export enum PaymentStatus {
  OPEN = 'OPEN',
  OVERDUE = 'OVERDUE',
  PENDING = 'PENDING',
  CHECK_MANUALLY = 'CHECK_MANUALLY',
  PAID = 'PAID',
  PAID_OUT = 'PAID_OUT',
  ERROR = 'ERROR',
  CANCELED = 'CANCELED',
  REFUNDED = 'REFUNDED',
  PENDING_PAY_OUT = 'PENDING_PAY_OUT',
  REVOKED = 'REVOKED' // not used anywhere (incl. BE)
}

export enum SmartDepositStatus {
  INVITED = 'INVITED',
  DECLINED = 'DECLINED',
  APPLIED = 'APPLIED',
  GUARANTEE = 'GUARANTEE', // finished
  GUARANTEE_TERMINATED = 'GUARANTEE_TERMINATED',
  ERROR = 'ERROR' // FE ONLY, DOESNT EXIST IN BE
}

export enum SmartDepositApplicationStatus {
  OFFER_REQUESTED = 'OFFER_REQUESTED', // PS has sent form to Bank11
  OFFER = 'OFFER', // Bank11 has aggreed, PS can choose between products
  APPLICATION_SUBMITTED = 'APPLICATION_SUBMITTED', // PS has chosen a product
  DOCUMENTS_READY = 'DOCUMENTS_READY', // Contract can be downloaded / viewed
  LEGITIMATION = 'LEGITIMATION', // PS has signed the contract
  BILLING = 'BILLING', // Guarantee will be created
  NO_OFFER = 'NO_OFFER', // Rejected because of reasons
  REJECTED = 'REJECTED',
  CANCELED = 'CANCELED',
  FINISHED = 'FINISHED', // Guarantee is created
  ERROR = 'ERROR' // FE ONLY, DOESNT EXIST IN BE
}

export enum SmartDepositLegitimationStatus {
  STARTED = 'STARTED',
  CANCELED = 'CANCELED',
  FINISHED = 'FINISHED'
}

export enum MemberPaymentTabFormTypes {
  ADD = 'ADD',
  EDIT = 'EDIT'
}

export type PaymentPosition = {
  id: number;
  subject: string;
  amount: number;
  status: PaymentStatus;
};

export type PaymentRequest = {
  positions: PaymentPosition[];
  allowedPaymentMethods: PaymentType[];
  customerName: string;
};

export type PaymentMandate = {
  id: string;
  status: MandateStatus;
  creationDate: Date;
};

export type ManualPaymentInput = {
  token: string;
  positionIds: number[];
};

export type PayInInput = {
  type: PaymentType;
  positionIds: number[];
};

export type PaymentPayPalPayInInput = PayInInput;

export type PaymentDirectDebitPayInInput = PayInInput & {
  fullName: string;
  iban: string;
  address: Address;
};

export type PaymentExecutionInput = {
  token: string;
  payInData: PaymentDirectDebitPayInInput | PaymentPayPalPayInInput;
};

export type PaymentPayInResponse = {
  redirectUrl?: string;
};

export type Payment = {
  id: string;
  category: PaymentCategory;
  data: PaymentData;
  active: boolean;
};

export type PaymentsTransactionQuota = {
  usedTransactions: number;
};

export type PaymentData = {
  name: string;
  subject: string;
  iban: string;
  value: number;
  dueDays: number;
};

export type PaymentDataPayload = Omit<PaymentData, 'name' | 'dueDays'> & {
  category: PaymentCategory;
  dueDate: Date;
};

// # SmartDeposit

// ## SmartDeposit Inputs

export type SmartDepositRequestOfferInput = {
  applicant: SmartDepositApplicantInput;
  rentalProperty: SmartDepositRentalPropertyInput;
  deposit: number;
};

export type SmartDepositApplicantInput = {
  salutation: number;
  givenName: string;
  familyName: string;
  birthName?: string;
  birthDate: string; // DD-MM-YYY
  maritalStatus: number;
  numberOfChildren: number;
  nationality: string;
  wayOfLiving: number;
  address: Address;
  previousAddress?: Address;
  totalIncome: SmartDepositTotalIncomeInput;
  bankAccount: SmartDepositBankAccountInput;
  contact: SmartDepositContactInput;
  consent: SmartDepositConsentInput;
  deposit: number;
};

export type SmartDepositTotalIncomeInput = {
  employment: SmartDepositEmploymentInput;
  otherIncome: number;
};

export type SmartDepositEmploymentInput = {
  income: number;
  since: string; // DD-MM-YYY
  occupationalGroup: number;
};

export type SmartDepositBankAccountInput = {
  iban: string;
};

export type SmartDepositContactInput = {
  email: string;
  telephone: string;
};

export type SmartDepositConsentInput = {
  dataProcessing: boolean;
  advertising: boolean;
  ownAccount: boolean;
  schufaQuery: boolean;
};

export type SmartDepositRentalPropertyInput = {
  startOfRental: string; // DD-MM-YYY
  startOfGuarantee: string; // DD-MM-YYY
  basicRent: number;
  address: Address;
  landlord: SmartDepositLandlordInput;
};

export type SmartDepositLandlordInput = {
  name: string;
  address: Address;
};

export type SmartDepositLegitimationInput = {
  contactData: SmartDepositContactInput;
  consentDataProcessing: boolean;
};

// ## SmartDeposit types

export type PaymentTokenBean = {
  token?: string;
};

export type SmartDepositOverview = {
  id: number;
  status: SmartDepositStatus;
  preFillData: SmartDepositPreFillData;
  smartDepositApplication: SmartDepositApplicationOverview;
};

export type SmartDepositPreFillData = {
  landlordName: string;
  landlordAddress: Address;
  propertyAddress: Address;
  depositAmount: number;
  baseRent: number;
  startOfContract: string; // dd-mm-yyy
};

export type SmartDepositApplicationOverview = {
  id: number;
  currentState: SmartDepositApplicationState;
  offer: SmartDepositGuaranteeProduct;
  applicantContact: SmartDepositApplicantContact;
  legitimationContact: SmartDepositApplicantContact;
};

export type SmartDepositApplicantContact = {
  email: string;
  telephone: string;
};

export type SmartDepositApplicationState = {
  applicationStatus: SmartDepositApplicationStatus;
  legitimationStatus: SmartDepositLegitimationStatus;
};

export type SmartDepositGuaranteeProduct = {
  commission: number;
  guaranteeFee: number;
  commissionAmount: number;
};

export class KeyValuePair<K, V> {
  key: K;
  value: V;
}

export class KeyValuePairTranslated<K, V> extends KeyValuePair<K, V> {
  translate: string;
}

export type SmartDepositDictionary = {
  salutation: KeyValuePairTranslated<number, string>[];
  numberOfChildren: KeyValuePairTranslated<number, string>[];
  commercialSector: KeyValuePairTranslated<number, string>[];
  occupation: KeyValuePairTranslated<number, string>[];
  maritalStatus: KeyValuePairTranslated<number, string>[];
  country: KeyValuePairTranslated<string, string>[];
  postcode: KeyValuePair<string, string[]>[];
  wayOfLiving: KeyValuePairTranslated<number, string>[];
};
