import { Constants } from '@ui/shared/models';
import * as fromConstantsActions from './constants.actions';

export interface ConstantsState {
  constants: Constants;
  loading: boolean;
  loaded: boolean;
  error: any;
}

export const initialState: ConstantsState = {
  constants: null,
  loading: false,
  loaded: false,
  error: null
};

export function reducer(
  state = initialState,
  action: fromConstantsActions.ConstantsActions
): ConstantsState {
  switch (action.type) {
    case fromConstantsActions.FETCH_CONSTANTS: {
      return {
        ...state,
        loading: true
      };
    }

    case fromConstantsActions.FETCH_CONSTANTS_FAIL: {
      return {
        ...state,
        loaded: false,
        loading: false,
        error: action.error
      };
    }

    case fromConstantsActions.FETCH_CONSTANTS_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: null,
        constants: action.data
      };
    }

    default:
      return state;
  }
}

export const getConstants = (state: ConstantsState) => state.constants;
export const getLoading = (state: ConstantsState) => state.loading;
export const getLoaded = (state: ConstantsState) => state.loaded;
// eslint-disable-next-line @typescript-eslint/no-unsafe-return
export const getError = (state: ConstantsState) => state.error;
