export const stripTypenameProperty = (obj: { [key: string]: any }) => {
  /* eslint-disable @typescript-eslint/no-unsafe-return */

  if (!obj) {
    return obj;
  }
  const omitTypename = (key, value) =>
    key === '__typename' ? undefined : value;
  return JSON.parse(JSON.stringify(obj), omitTypename);
  /* eslint-enable @typescript-eslint/no-unsafe-return */
};
