import { createSelector } from '@ngrx/store';

import * as fromReducers from '../reducers';
import * as fromTranslationReducers from './translation.reducers';

export const getTranslationState = createSelector(
  fromReducers.getBaseState,
  (state: fromReducers.BaseState) => state.translations
);

export const getTranslationLoaded = createSelector(
  getTranslationState,
  fromTranslationReducers.getLoaded
);

export const getTranslationLoadedInformal = createSelector(
  getTranslationState,
  fromTranslationReducers.getLoadedInformal
);

export const getTranslationLoading = createSelector(
  getTranslationState,
  fromTranslationReducers.getLoading
);
