import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BaseStep } from 'libs/components/legacy/wizard/wizard-step/base-step';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-qualified-electroinic-signature-confirm-terms-and-conditions',
  templateUrl:
    './qualified-electronic-signature-confirm-terms-and-conditions.component.html',
  styleUrls: [
    './qualified-electronic-signature-confirm-terms-and-conditions.component.scss'
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TranslateModule]
})
export class QualifiedElectronicSignatureConfirmTermsAndConditionsComponent extends BaseStep {}
