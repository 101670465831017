import { createAction, props } from '@ngrx/store';
import { DistrictForAddress } from '@ui/shared/models';
import { Address } from '@ui/shared/models';

export const loadDistrictsByAddress = createAction(
  '[District] Load Districts',
  props<{ address: Address }>()
);

export const loadDistrictsByAddressSuccess = createAction(
  '[District] Load Districts Success',
  props<{ data: DistrictForAddress[] }>()
);

export const loadDistrictsByAddressFailure = createAction(
  '[District] Load Districts Failure',
  props<{ error: Error }>()
);

export const resetDistricts = createAction('[District] Reset Districts');
