import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import {
  AvailableLanguageCodesEnum,
  BaseNavigationItem as NavigationItem,
  LandlordUser,
  Language,
  PropertySearcherUser
} from '@ui/shared/models';
import { TranslateModule } from '@ngx-translate/core';
import { SvgIconComponent } from 'angular-svg-icon';
import {
  NgbDropdown,
  NgbDropdownToggle,
  NgbDropdownMenu
} from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { FullNamePipe } from '../../../pipes/full-name.pipe';
import { ImageSizePipe } from '../../../pipes/image-size.pipe';
import { ButtonComponent } from '../../atoms/button/button.component';
import { NavigationItemV2Component } from '../navigation-item-v2/navigation-item-v2.component';
import { AvatarComponent } from '../avatar/avatar.component';
import { LangPickerComponent } from '../../molecules/lang-picker/lang-picker.component';
import { LogoComponent } from '../logo/logo.component';

@Component({
  selector: 'app-header-v2',
  templateUrl: './header-v2.component.html',
  styleUrls: ['./header-v2.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    LogoComponent,
    LangPickerComponent,
    FormsModule,
    NgbDropdown,
    NgbDropdownToggle,
    SvgIconComponent,
    NgbDropdownMenu,
    AvatarComponent,
    NavigationItemV2Component,
    ButtonComponent,
    TranslateModule,
    ImageSizePipe,
    FullNamePipe
  ]
})
export class HeaderV2Component {
  @Input() navigationUserDropdownItems: NavigationItem[];
  @Input() user: LandlordUser | PropertySearcherUser;
  @Input() tenantInInternalPool: boolean;
  @Input() showSearchIcon: boolean;
  @Input() showLanguagePicker = false;
  @Input() availableLanguages: Language[];
  @Input() defaultLanguageCode: AvailableLanguageCodesEnum;
  @Input() currentLanguageCode: AvailableLanguageCodesEnum;

  public visibleItems: NavigationItem[];

  @Output() openSearch = new EventEmitter();
  @Output() hasPermission = new EventEmitter();
  @Output() languageChange = new EventEmitter<string>();
  @Output() logout = new EventEmitter<string>();

  public sidenavOpened = false;

  public onLanguageChange(value: string) {
    this.languageChange.emit(value);
  }

  public onLogout() {
    this.logout.emit();
  }
}
