// eslint-disable-next-line @typescript-eslint/ban-types
export function isObject(candidate: any): candidate is object {
  return (
    typeof candidate === 'object' &&
    candidate !== null &&
    !Array.isArray(candidate)
  );
}

export function isBoolean(candidate: any): candidate is boolean {
  return typeof candidate === 'boolean';
}

export function isString(candidate: any): candidate is string {
  return typeof candidate === 'string';
}

export function isNumber(candidate: any): candidate is number {
  return typeof candidate === 'number';
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function isFunction(candidate: any): candidate is Function {
  return typeof candidate === 'function';
}
