import { inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { DOCUMENT } from '@angular/common';
import { take, tap } from 'rxjs/operators';

import { Attachment } from '@ui/shared/models';
import { INFRASTRUCTURE_CONFIG } from 'libs/infrastructure/infrastructure-config.token';
import { WINDOW_REF } from '../browser';

import { DownloadUrlGeneratorService } from '../download-url-generator';

import { AuthTokenService } from '../authentication';
import { NotificationService } from '../notification';

@Injectable()
export class DownloadService {
  private documentRef = inject(DOCUMENT);
  private windowRef = inject(WINDOW_REF);
  private rendererFactory = inject(RendererFactory2);
  private authTokenService = inject(AuthTokenService);
  private downloadUrlGeneratorService = inject(DownloadUrlGeneratorService);
  private http = inject(HttpClient);
  private notificationService = inject(NotificationService);
  private infrastructureConfig = inject(INFRASTRUCTURE_CONFIG);

  private renderer: Renderer2;

  private isIOS: boolean;
  private graphQLPsFiles: string;
  private proxyDownload: string;

  constructor() {
    this.renderer = this.rendererFactory.createRenderer(null, null);
    this.isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
    this.graphQLPsFiles =
      this.infrastructureConfig.environment.graphql_host +
      '/property-searcher-files';
    this.proxyDownload =
      this.infrastructureConfig.environment.graphql_host + '/download-s3-file';
  }

  public downloadFileFromEndpoint(endpoint: string, body: any) {
    const url = this.infrastructureConfig.environment.graphql_host + endpoint;
    const token = this.authTokenService.getToken().access_token;

    const headers = new HttpHeaders().append(
      'Content-Type',
      'application/json'
    );

    return this.http.request('POST', url, {
      headers,
      responseType: 'blob',
      body: {
        token,
        ...body
      }
    });
  }

  public downloadByUrl(attachment: Attachment, token?: string) {
    if (!attachment.url) {
      return;
    }

    const url = this.getUrl(attachment, token);
    if (this.isIOS) {
      this.windowRef.open(url, '_blank');
    } else {
      this.downloadFileByProxy(url, attachment.title);
    }
  }

  public getByteArraysFromBase64(base64: string) {
    const decodedString = atob(base64);

    const bytes = new Uint8Array(decodedString.length);
    for (let i = 0; i < decodedString.length; i++) {
      bytes[i] = decodedString.charCodeAt(i);
    }
    return bytes;
  }

  private getUrl(attachment: Attachment, token?: string) {
    return this.downloadUrlGeneratorService.generateDownloadUrl({
      fileURL: attachment.url,
      encrypted: attachment?.encrypted,
      token: token || this.authTokenService.getToken().access_token,
      filename: this.isIOS ? attachment?.title : null
    });
  }

  public downloadPropertyExpose(id: string, responseType?: any, ps?: boolean) {
    const token = this.authTokenService.getToken().access_token;
    const headers = new HttpHeaders().append(
      'Content-Type',
      'application/json'
    );
    const PATH = this.infrastructureConfig.environment.graphql_host + '/pdf';

    return this.http.post(
      PATH,
      { id, token, ps },
      {
        headers,
        responseType: responseType || 'blob'
      }
    );
  }

  public downloadSelfDisclosurePdf(id: number) {
    const URLToOpen = this.downloadUrlGeneratorService.getSelfDisclosurePDFUrl({
      token: this.authTokenService.getToken().access_token,
      id
    });

    this.windowRef.open(URLToOpen);
  }

  public downloadDigitalContract(token: string, identifier: string) {
    const URLToOpen = this.downloadUrlGeneratorService.getDigitalContractUrl({
      token,
      identifier
    });
    this.windowRef.open(URLToOpen);
  }

  public openPdfInNewTab(attachment: Attachment) {
    if (this.isIOS) {
      return this.downloadByUrl(attachment);
    }

    const fileURL = this.getUrl(attachment);
    return this.downloadFileViaGraphQL(fileURL, this.graphQLPsFiles)
      .pipe(
        tap(file => {
          const type =
            attachment.extension === 'pdf' ? 'application/pdf' : 'image/jpeg';
          const blob = new Blob([file], { type });
          const objectURL = URL.createObjectURL(blob);
          this.windowRef.open(objectURL, 'about:blank');
        })
      )
      .subscribe();
  }

  public openPdfBlobInNewTab(pdfBlob: Blob) {
    const objectURL = URL.createObjectURL(pdfBlob);
    this.windowRef.open(objectURL, '_blank');
  }

  public downloadCSVFile(csv: string, fileName: string) {
    const a = document.createElement('a');
    a.href = 'data:text/csv,' + csv;
    a.setAttribute('download', fileName + '.csv');
    this.documentRef.body.appendChild(a);
    a.click();
    this.documentRef.body.removeChild(a);
  }

  public openWithBearerInNewTab(
    fileURL: string,
    token: string,
    fileFormat?: string
  ) {
    return this.downloadFileWithBearer(fileURL, token)
      .pipe(
        tap(file => {
          const type = fileFormat || 'application/pdf';
          const blob = new Blob([file], { type });
          const objectURL = URL.createObjectURL(blob);
          this.windowRef.open(objectURL, 'about:blank');
        })
      )
      .subscribe();
  }

  public downloadFileByProxy(fileURL: string, fileName: string) {
    return this.downloadFileViaGraphQL(fileURL, this.graphQLPsFiles)
      .pipe(
        tap(file => this.download(file, fileName)),
        take(1)
      )
      .subscribe();
  }

  public download(blob: Blob, fileName: string) {
    const body = this.documentRef.body;
    const downloadUrl = URL.createObjectURL(blob);
    const downloadLink = this.renderer.createElement('a') as HTMLAnchorElement;
    downloadLink.target = '_blank';
    downloadLink.download = fileName;
    downloadLink.href = downloadUrl;
    this.renderer.appendChild(body, downloadLink);
    downloadLink.click();
    this.renderer.removeChild(body, downloadLink);
    URL.revokeObjectURL(downloadUrl);
  }

  public downloadArrayBuffer(arrayBuffer: ArrayBuffer, filename: string) {
    // Create a Blob from the ArrayBuffer
    const blob = new Blob([arrayBuffer], { type: 'application/pdf' });

    // Create a URL for the Blob
    const url = URL.createObjectURL(blob);

    // Create an anchor element
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;

    // Trigger a click event on the anchor element to start the download
    document.body.appendChild(a);
    a.click();

    // Clean up by removing the anchor element and revoking the Blob URL
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  }

  public downloadFileFromS3(fileURL: string) {
    return this.downloadFileViaGraphQL(fileURL, this.proxyDownload);
  }

  public downloadUviControlPdf(fileId: number) {
    const token = this.authTokenService.getToken().access_token;
    const { filer_url } = this.infrastructureConfig.environment;
    const url = `${filer_url}/file/download/${fileId}`;

    return this.downloadFileWithBearer(url, token).pipe(
      tap(blob => this.download(blob, `uvi_${fileId}.pdf`))
    );
  }

  private downloadFileViaGraphQL(fileURL: string, proxyUri: string) {
    const headers = new HttpHeaders().append(
      'Content-Type',
      'application/json'
    );

    return this.http
      .post(proxyUri, { fileURL }, { headers, responseType: 'blob' })
      .pipe(
        tap({
          error: error => {
            this.notificationService.showErrorToast(error?.message, {});
          }
        })
      );
  }

  private downloadFileWithBearer(fileURL: string, token: string) {
    const headers = new HttpHeaders().append(
      'Authorization',
      `Bearer ${token}`
    );

    return this.http.get(fileURL, { headers, responseType: 'blob' });
  }
}
