const MB_MULTIPLIER = 1024 * 1024;
const PDF_TYPE = 'application/pdf';

export const defaultDocumentSize = MB_MULTIPLIER * 20;
export const defaultAttachmentSize = MB_MULTIPLIER * 10; // 10 MB

export const imagesTypes = [
  'image/png',
  'image/gif',
  'image/jpeg',
  'image/jpg'
];

export const docXType =
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
export const csvType = '.csv';

export const imageExtension = ['png', 'gif', 'jpeg', 'jpg'];

export const documentsTypes = [PDF_TYPE];

export const digitalContractTypes = [PDF_TYPE, docXType];

export const defaultDocumentsConfig = {
  acceptedTypes: PDF_TYPE,
  allAcceptedTypes: [PDF_TYPE, ...imagesTypes].join(','),
  acceptedDocumentSize: defaultDocumentSize,
  acceptedAttachmentSize: defaultAttachmentSize
};
