<form [formGroup]="form" class="hierarchical-root-questions">
  <div formArrayName="hierarchicalRootQuestions">
    @for (
      hierarchicalQuestion of hierarchicalRootQuestionsForm.controls;
      track hierarchicalQuestion;
      let i = $index
    ) {
      <div class="mb16">
        <div [formGroupName]="i">
          <div formGroupName="questions">
            @for (
              subQuestion of getQuestionsControl(i).controls;
              track subQuestion;
              let j = $index
            ) {
              <div>
                @if (j === 0 || questionDisplayIndices.get(i)[j]) {
                  <app-hierarchical-question-form
                    [formControl]="subQuestion"
                    [hierarchicalQuestion]="subQuestion.value"
                    [hierarchicalQuestions]="getAllHierarchicalQuestions(i)"
                    [currentLanguage]="currentLanguage$ | async"
                    [defaultLanguage]="defaultLanguage$ | async"
                    (answerChange)="onAnswerChange($event, i)"
                    (validityChange)="onFormValidityChange($event)"
                  ></app-hierarchical-question-form>
                }
              </div>
            }
          </div>
        </div>
      </div>
    }
  </div>
</form>
