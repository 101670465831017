export enum SelfDisclosureQuestionType {
  ADDRESS = 'ADDRESS',
  BOOLEAN = 'BOOLEAN',
  CHILDREN = 'CHILDREN',
  DOCUMENT = 'DOCUMENT',
  EMPLOYMENT = 'EMPLOYMENT',
  FLAT = 'FLAT',
  PERSON = 'PERSON',
  PERSONS = 'PERSONS',
  SELECT = 'SELECT',
  SALUTATION = 'SELF_DISCLOSURE_SALUTATION_L'
}

export enum SelfDisclosureErrorMessage {
  SELF_DISCLOSURE_RESPONSE_EXISTS_L = 'SELF_DISCLOSURE_RESPONSE_EXISTS_L'
}
