import { Action } from '@ngrx/store';
import { SearchCity, SearchDistrict } from '@ui/shared/models';

export const SEARCH_FOR_CITIES = '[SearchProfile] Search for Cities';
export const SEARCH_FOR_CITIES_FAIL = '[SearchProfile] Search for Cities Fail';
export const SEARCH_FOR_CITIES_SUCCESS =
  '[SearchProfile] Search for Cities Success';

export class SearchForCities implements Action {
  readonly type = SEARCH_FOR_CITIES;
  constructor(
    public country: string,
    public name: string
  ) {}
}

export class SearchForCitiesSuccess implements Action {
  readonly type = SEARCH_FOR_CITIES_SUCCESS;
  constructor(public cities: SearchCity[]) {}
}

export class SearchForCitiesFail implements Action {
  readonly type = SEARCH_FOR_CITIES_FAIL;
  constructor(public error: Error) {}
}

export const LOAD_CITY_DISTRICTS = '[SearchProfile] Load City Districts';
export const LOAD_CITY_DISTRICTS_FAIL =
  '[SearchProfile] Load City Districts Fail';
export const LOAD_CITY_DISTRICTS_SUCCESS =
  '[SearchProfile] Load City Districts Success';

export class LoadCityDistricts implements Action {
  readonly type = LOAD_CITY_DISTRICTS;
  constructor(public cityId: string) {}
}

export class LoadCityDistrictsSuccess implements Action {
  readonly type = LOAD_CITY_DISTRICTS_SUCCESS;
  constructor(
    public districts: SearchDistrict[],
    public cityId: string
  ) {}
}

export class LoadCityDistrictsFail implements Action {
  readonly type = LOAD_CITY_DISTRICTS_FAIL;
  constructor(public error: Error) {}
}

export type SearchProfilesAction =
  | SearchForCities
  | SearchForCitiesFail
  | SearchForCitiesSuccess
  | LoadCityDistricts
  | LoadCityDistrictsFail
  | LoadCityDistrictsSuccess;
