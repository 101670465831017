export enum CommercialType {
  OFFICES_MEDICAL = 'OFFICES_MEDICAL',
  RETAIL = 'RETAIL',
  GASTRONOMY_HOTELS = 'GASTRONOMY_HOTELS',
  HALL_PRODUCTION = 'HALL_PRODUCTION'
}

export const commercialSubTypes = {
  [CommercialType.OFFICES_MEDICAL]: [
    'OFFICE',
    'OFFICE_BUILDING',
    'OFFICE_CENTRE',
    'LOFT_ATELIER',
    'PRACTICE',
    'PRACTICE_AREA',
    'PRACTICE_BUILDING',
    'EXHIBITION_AREA',
    'COWORKING',
    'SHARED_OFFICE'
  ],
  [CommercialType.RETAIL]: [
    'STORE',
    'RETAIL_STORE',
    'CONSUMER_MARKET',
    'SHOPPING_MALL',
    'SHOP',
    'FACTORY_OUTLET',
    'KIOSK',
    'SALES_AREA',
    'EXHIBITION_AREA_STORE'
  ],
  [CommercialType.GASTRONOMY_HOTELS]: [
    'GASTRONOMY',
    'GASTRONOMY_AND_FLAT',
    'PENSIONS',
    'HOTELS',
    'FURTHER_ACCOMMODATION_FACILITIES',
    'BAR',
    'CAFE',
    'DISCOTHEQUE',
    'RESTAURANT',
    'SMOKING_LOCAL',
    'ONE_ROOM_LOCAL'
  ],
  [CommercialType.HALL_PRODUCTION]: [
    'HALL',
    'INDUSTRIAL_HALL',
    'WAREHOUSE',
    'STORAGE_AREA',
    'WAREHOUSE_WITH_OPEN_SPACE',
    'HIGH_BAY_WAREHOUSE',
    'SHIPPING_DEPARTMENT',
    'PRODUCTION',
    'WORKSHOP',
    'SERVICE',
    'OPEN_AREAS',
    'COLDHOUSE'
  ]
};
