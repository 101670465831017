import { Pagination } from './data-access';
import { User } from './user.model';

export interface CustomerCooperationList extends Pagination {
  content: CustomerCooperationListData[];
}

export interface CustomerCooperationListData {
  id: string;
  receiverCustomer?: User;
  receiverUser?: User;
  status?: string;
}

export interface CustomerCooperationListResponse {
  customerCooperationGetOverviewList?: CustomerCooperationList;
}

// -----

export interface CustomerCooperationListContent extends Pagination {
  content: CustomerCooperationListItem[];
}

export interface CustomerCooperationGetReceiversListResponse {
  customerCooperationGetReceiversList: CustomerCooperationListContent;
}

export interface CustomerCooperationGetSendersListResponse {
  customerCooperationGetSendersList: CustomerCooperationListContent;
}

export interface CustomerCooperationListItem {
  id: string;
  customer?: CustomerCooperationCustomer;
}

// -----

export interface CustomerCooperationIsReceivingPossibleResponse {
  customerCooperationIsReceivingPossible: {
    value: boolean;
  };
}

export interface CustomerCooperationRemainingSlotsResponse {
  customerCooperationCheckRemainingSlots: {
    value: number;
  };
}

export interface CustomerCooperationCustomer {
  id: string;
  name?: string;
  logo?: string;
}

export enum OverviewListStatusesEnum {
  PENDING = 'PENDING',
  CONNECTED = 'CONNECTED',
  REVOKED = 'REVOKED',
  ALL_STATUSES = 'ALL_STATUSES'
}

export const CUSTOMER_COOPERATION_ERRORS = [
  'CUSTOMER_COOPERATION_MODULE_NOT_ALLOWED_L',
  'CUSTOMER_COOPERATION_PROPERTY_NOT_OFFLINE_L',
  'CUSTOMER_COOPERATION_RECEIVER_SALES_NOT_ALLOWED_L',
  'CUSTOMER_COOPERATION_EXTERNAL_ID_ALREADY_IN_USE_BY_RECEIVER_L',
  'CUSTOMER_COOPERATION_PROPERTY_ALREADY_SENT_L',
  'CUSTOMER_COOPERATION_PROPERTY_ALREADY_RECEIVED_L',
  'CUSTOMER_COOPERATION_NO_SLOT_AVAILABLE_L',
  'CUSTOMER_COOPERATION_EMAIL_INVALID_L',
  'CUSTOMER_COOPERATION_USER_NOT_FOUND_OR_AUTHORIZED_L',
  'CUSTOMER_COOPERATION_RECEIVER_SAME_CUSTOMER_L',
  'CUSTOMER_COOPERATION_CONNECTION_ALREADY_EXISTS_L',
  'CUSTOMER_COOPERATION_TOKEN_ALREADY_USED_L',
  'CUSTOMER_COOPERATION_ACCEPTANCE_NOT_ALLOWED_L',
  'CUSTOMER_COOPERATION_NOT_THE_RECEIVER_L',
  'CUSTOMER_COOPERATION_NOT_THE_SENDER_L',
  'CUSTOMER_COOPERATION_REVOCATION_NOT_ALLOWED_L',
  'CUSTOMER_COOPERATION_RESEND_NOT_ALLOWED_L',
  'CUSTOMER_COOPERATION_NOT_CONNECTED_L',
  'CUSTOMER_COOPERATION_TOKEN_NOT_FOUND_L',
  'CUSTOMER_COOPERATION_CONNECTION_NOT_FOUND_L'
];
