export const notificationConfig = {
  searchProfile: {
    searchCities: {
      error: 'search_profile.search_cities_error_m'
    },
    getDistricts: {
      error: 'search_profile.get_districts_error_m'
    },
    delete: {
      message: 'search_profile.delete_confirmation_message',
      titleMessage: 'search_profile.delete_confirmation_title'
    }
  }
};
