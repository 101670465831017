<div
  ngbTooltip="{{ bannerInfo?.tooltip | translate }}"
  class="property-banner__content"
  [ngClass]="bannerInfo?.color"
>
  @if (bannerInfo?.icon === 'check') {
    <i class="icon icon--check-framed"></i>
  }
  {{ bannerInfo?.label | translate }}
</div>
