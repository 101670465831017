<div class="tag" [ngClass]="tagConfig?.color">
  <span
    class="tag__name"
    [class.tag__name--truncated]="truncate"
    [class.nowrap]="nowrap"
    [ngbTooltip]="!disableTooltip ? tooltip : null"
    >{{ tagConfig?.label || name | translate | uppercase }}</span
  >
  <div class="tag__icon">
    <ng-content select="[icon]"></ng-content>
  </div>
</div>
