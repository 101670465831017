export const stripProperties = (obj: any, properties: string[]) => {
  /* eslint-disable @typescript-eslint/no-unsafe-return */
  if (!obj) {
    return obj;
  }
  const omitTypename = (key, value) =>
    properties.indexOf(key) > -1 ? undefined : value;
  return JSON.parse(JSON.stringify(obj), omitTypename);
  /* eslint-enable @typescript-eslint/no-unsafe-return */
};
