<app-modal>
  <div class="modal-m">
    <app-modal-content>
      <div class="modal-body custom-cookie-settings__body">
        <div class="custom-cookie-settings__header">
          <div class="d-flex justify-content-between">
            <h2 class="title custom-cookie-settings__title">
              {{ 'general.cookies_settings_modal.title_l' | translate }}
            </h2>
            <div class="custom-cookie-settings__close-button">
              <img
                src="/assets/images/icons/icon-close.svg"
                (click)="dismiss()"
              />
            </div>
          </div>
        </div>

        <nav ngbNav #nav="ngbNav" [activeId]="'cookies'" class="nav-pills">
          <ng-container ngbNavItem="cookies">
            <a ngbNavLink>{{
              'general.cookies_settings_modal.tab_cookies_l' | translate
            }}</a>
            <ng-template ngbNavContent>
              <h3 class="title-s custom-cookie-settings__subtitle mt20">
                {{ 'general.cookies_settings_modal.sub_title_l' | translate }}
              </h3>

              <div class="custom-cookie-settings__content">
                <form [formGroup]="form">
                  <!-- Required cookies -->
                  <div class="privacy-option">
                    <div
                      class="d-flex privacy-option__header align-item-center"
                    >
                      <svg-icon
                        src="/assets/images/icons/icon-question.svg"
                        [applyClass]="true"
                        [svgStyle]="{ 'height.px': 20, 'width.px': 20 }"
                        class="icon-show-more secondary-svg-color circle"
                        (click)="toggleMoreRequired()"
                      ></svg-icon>
                      <h3
                        class="uppercase-m semibold custom-cookie-settings__option-title"
                      >
                        {{
                          'general.cookies_settings_modal.required_content_title_l'
                            | translate
                        }}
                      </h3>
                    </div>
                    <div class="ml4">
                      <app-form-field>
                        <app-checkbox
                          [disabled]="true"
                          [innerHTML]="
                            (showMoreRequired
                              ? 'general.cookies_settings_modal.required_content_more_l'
                              : 'general.cookies_settings_modal.required_text_l'
                            ) | translate
                          "
                          formControlName="allowRequired"
                        >
                        </app-checkbox>
                      </app-form-field>
                    </div>
                  </div>

                  <!-- Functional -->
                  <div class="privacy-option">
                    <div
                      class="d-flex privacy-option__header align-item-center"
                    >
                      <svg-icon
                        src="/assets/images/icons/icon-question.svg"
                        [applyClass]="true"
                        [svgStyle]="{ 'height.px': 20, 'width.px': 20 }"
                        class="secondary-svg-color circle"
                        (click)="toggleMoreFunctional()"
                      ></svg-icon>
                      <h3
                        class="uppercase-m semibold custom-cookie-settings__option-title"
                      >
                        {{
                          'general.cookies_settings_modal.functional_content_title_l'
                            | translate
                        }}
                      </h3>
                    </div>
                    <div class="ml4">
                      <app-form-field>
                        <app-checkbox
                          [innerHTML]="
                            (showMoreFunctional
                              ? 'general.cookies_settings_modal.functional_content_hint_l'
                              : 'general.cookies_settings_modal.functional_text_l'
                            ) | translate
                          "
                          formControlName="allowFunctional"
                        >
                        </app-checkbox>
                      </app-form-field>
                    </div>
                  </div>

                  <!-- Performance -->
                  <div class="privacy-option">
                    <div
                      class="d-flex privacy-option__header align-item-center"
                    >
                      <svg-icon
                        src="/assets/images/icons/icon-question.svg"
                        [applyClass]="true"
                        [svgStyle]="{ 'height.px': 20, 'width.px': 20 }"
                        class="secondary-svg-color circle"
                        (click)="toggleMorePerformance()"
                      ></svg-icon>
                      <h3
                        class="uppercase-m semibold custom-cookie-settings__option-title"
                      >
                        {{
                          'general.cookies_settings_modal.performance_content_title_l'
                            | translate
                        }}
                      </h3>
                    </div>
                    <div class="ml4">
                      <app-form-field>
                        <app-checkbox
                          [innerHTML]="
                            (showMorePerformance
                              ? 'general.cookies_settings_modal.performance_content_more_l'
                              : 'general.cookies_settings_modal.performance_text_l'
                            ) | translate
                          "
                          formControlName="allowPerformance"
                        >
                        </app-checkbox>
                      </app-form-field>
                    </div>
                  </div>
                </form>
              </div>
            </ng-template>
          </ng-container>

          <ng-container ngbNavItem="cookie-list">
            <a ngbNavLink>{{
              'general.cookies_settings_modal.tab_cookie_list_l' | translate
            }}</a>
            <ng-template ngbNavContent>
              <div class="custom-cookie-list__content">
                <div class="custom-cookie-settings__scroll-container">
                  <div class="privacy-links mt20">
                    @switch (isTenant) {
                      @case (true) {
                        <p
                          [innerHTML]="
                            'general.cookies_settings_modal.cookie_list_content_tenant_l'
                              | translate
                          "
                        ></p>
                      }
                      @default {
                        <p
                          [innerHTML]="
                            'general.cookies_settings_modal.cookie_list_content_landlord_l'
                              | translate
                          "
                        ></p>
                      }
                    }
                  </div>
                </div>
              </div>
            </ng-template>
          </ng-container>

          <ng-container ngbNavItem="guidelines">
            <a ngbNavLink>{{
              'general.cookies_settings_modal.tab_policy_l' | translate
            }}</a>
            <ng-template ngbNavContent>
              <div class="custom-cookie-policy__content">
                <div class="custom-cookie-settings__scroll-container">
                  <p
                    class="mt20"
                    [innerHTML]="
                      'general.cookies_settings_modal.policy_text_l' | translate
                    "
                  ></p>
                </div>
              </div>
            </ng-template>
          </ng-container>
        </nav>

        <div [ngbNavOutlet]="nav"></div>
      </div>
    </app-modal-content>

    <app-modal-footer class="custom-cookie-settings__footer">
      <div class="privacy-links">
        @switch (isTenant) {
          @case (true) {
            <p
              [innerHTML]="
                'general.cookies_settings_modal.privacy_policy_tenant_l'
                  | translate
              "
            ></p>
          }
          @default {
            <p
              [innerHTML]="
                'general.cookies_settings_modal.privacy_policy_landlord_l'
                  | translate
              "
            ></p>
          }
        }
      </div>
      <div class="modal-footer">
        <app-button [type]="'light-bordered'" (clickEvent)="save()">
          {{ 'general.save_a' | translate }}</app-button
        >
        <app-button (clickEvent)="acceptAll()">
          {{
            'general.cookies_settings_modal.accept_all' | translate
          }}</app-button
        >
      </div>
    </app-modal-footer>
  </div>
</app-modal>
