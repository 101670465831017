import {
  Directive,
  ElementRef,
  Input,
  AfterViewInit,
  ChangeDetectorRef,
  inject
} from '@angular/core';

import { coerceBooleanProperty } from 'libs/utils/coercion';

@Directive({
  selector: '[appAutofocus]',
  standalone: true
})
export class AutofocusDirective implements AfterViewInit {
  private elementRef = inject(ElementRef);
  private changeDetectorRef = inject(ChangeDetectorRef);

  private _appAutofocus = true;

  @Input() forceAutofocus = false;
  @Input()
  set appAutofocus(value: boolean) {
    this._appAutofocus = coerceBooleanProperty(value);
  }

  ngAfterViewInit() {
    if (this._appAutofocus) {
      const el = this.elementRef.nativeElement as HTMLInputElement;

      if ((el && !el.value) || this.forceAutofocus) {
        el.focus();
        this.changeDetectorRef.detectChanges();
      }
    }
  }
}
