import {
  AutoActivateConfig,
  AutoDeactivateConfig,
  AutomatedRentalConfigData
} from './automated-rental';
import { MarketingWorkflowState } from './enums';
import { AgentInfo } from './landlord.model';
import { PortalType } from './portals';
import { EventType, PublishPropertyState } from './property.model';

export interface EventLog {
  created: string;
  status: PublishPropertyState;
  eventType: EventType;
  message: string;
  recipient: string;
  portals: PortalType[];
  rentalProcessConfig: AutomatedRentalConfigData;
  settings: SettingsEventLog;
  editor: AgentInfo;
  delegationPartnerName?: string;
  timestamp?: string;
  proposal?: {
    user?: {
      profile?: {
        firstname?: string;
        name?: string;
      };
    };
  };
  marketingWorkflowState?: MarketingWorkflowState;
  tenantContractStart?: string;
}

export interface SettingsEventLog {
  autoDeactivateConfig: AutoDeactivateConfig;
  autoActivateConfig: AutoActivateConfig;
}

export enum EventLogEditor {
  MIGRATION = 'MIGRATION',
  BROKER = 'BROKER',
  IMPORTED = 'IMPORTED',
  AUTOMATIC = 'AUTOMATIC',
  CRAWLER = 'CRAWLER'
}
