import { NgbDateAdapter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment';
import { Injectable } from '@angular/core';

@Injectable()
export class DateStringAdapter extends NgbDateAdapter<string> {
  private dateFormat = 'YYYY-MM-DD';

  fromModel(dateString: string): NgbDateStruct {
    const date = moment(dateString, this.dateFormat);

    return dateString && date.isValid()
      ? { year: date.year(), month: date.month() + 1, day: date.date() }
      : null;
  }

  toModel(date: NgbDateStruct): string {
    return date
      ? moment(
          `${date.year}-${date.month}-${date.day}`,
          this.dateFormat
        ).format(this.dateFormat)
      : null;
  }
}
