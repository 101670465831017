import { inject, Injectable } from '@angular/core';

import { CookiePreference } from '@ui/shared/models';
import { INFRASTRUCTURE_CONFIG } from '../infrastructure-config.token';
import { DOCUMENT_REF } from '../browser';
import { LocalStorageService } from './local-storage.service';

@Injectable()
export class CookieService {
  private localStorageService = inject(LocalStorageService);
  private config = inject(INFRASTRUCTURE_CONFIG);
  private documentRef = inject(DOCUMENT_REF);

  public get getPreference(): CookiePreference {
    return this.localStorageService.getItem(
      this.config.storageKeys.cookiePreference
    );
  }

  public get(key: string): string {
    const ca = this.documentRef.cookie.split('; ');

    const cLen = ca.length;

    const cookieName = `${key}=`;
    let cookie = '';

    for (let i = 0; i < cLen; i += 1) {
      cookie = ca[i].replace(/^\s+/g, '');

      if (cookie.indexOf(cookieName) === 0) {
        return cookie.substring(cookieName.length, cookie.length);
      }
    }

    return '';
  }

  public set(key: string, value: string, expireHours?: number): void {
    const expirePart = expireHours
      ? `expires=${String(
          new Date(new Date().getTime() + expireHours * 1000 * 60 * 60)
        )};`
      : '';

    this.documentRef.cookie = `${key}=${value};${expirePart} path=/`;
  }

  public delete(key: string) {
    this.documentRef.cookie = `${key}=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/`;
  }

  public savePreference(cookiePreference: CookiePreference) {
    this.localStorageService.setItem(
      this.config.storageKeys.cookiePreference,
      cookiePreference
    );
  }

  public isFunctionalAllowed() {
    return !!this.getPreference?.allowFunctional;
  }

  public isPerformanceAllowed() {
    return !!this.getPreference?.allowPerformance;
  }
}
