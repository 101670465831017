import { EmploymentType, HouseholdType, Prioset } from '@ui/shared/models';
import {
  defaultHousingCondition,
  defaultResidentsCondition,
  defaultSearchPeriodCondition,
  withHousingCondition
} from '@ui/shared/models';

const wbs: Prioset = {
  id: 'wbs',
  name: 'PRESET_PROFILE_WBS_NAME',
  description: 'PRESET_PROFILE_WBS_DESCRIPTION',
  template: true,
  data: {
    conditions: [
      withHousingCondition,
      defaultSearchPeriodCondition,
      defaultResidentsCondition()
    ]
  }
};

const family: Prioset = {
  id: 'family',
  name: 'PRESET_PROFILE_FAMILY_NAME',
  description: 'PRESET_PROFILE_FAMILY_DESCRIPTION',
  template: true,
  data: {
    conditions: [
      defaultHousingCondition,
      defaultSearchPeriodCondition,
      defaultResidentsCondition(0, 5)
    ],
    monthlyIncome: {
      value: {
        lowerBound: 1,
        upperBound: 5,
        value: 10
      },
      knockout: false
    },
    employmentType: {
      value: {
        choice: [
          EmploymentType.SELF_EMPLOYED,
          EmploymentType.EMPLOYED_LIMITED,
          EmploymentType.EMPLOYED_UNLIMITED,
          EmploymentType.CIVIL_SERVANT
        ],
        value: 7
      },
      knockout: false
    },
    householdType: {
      value: {
        choice: [HouseholdType.SINGLE_WITH_CHILDREN, HouseholdType.FAMILY],
        value: 10
      },
      knockout: false
    },
    smoking: {
      value: 5,
      knockout: false
    }
  }
};

const single: Prioset = {
  id: 'single',
  name: 'PRESET_PROFILE_SINGLE_NAME',
  description: 'PRESET_PROFILE_SINGLE_DESCRIPTION',
  template: true,
  data: {
    conditions: [
      defaultHousingCondition,
      defaultSearchPeriodCondition,
      defaultResidentsCondition(1, 10)
    ],
    monthlyIncome: {
      value: {
        lowerBound: 1,
        upperBound: 5,
        value: 10
      },
      knockout: false
    },
    employmentType: {
      value: {
        choice: [
          EmploymentType.SELF_EMPLOYED,
          EmploymentType.EMPLOYED_LIMITED,
          EmploymentType.EMPLOYED_UNLIMITED,
          EmploymentType.CIVIL_SERVANT
        ],
        value: 7
      },
      knockout: false
    },
    householdType: {
      value: {
        choice: [HouseholdType.SINGLE],
        value: 10
      },
      knockout: false
    },
    children: {
      value: 10,
      knockout: false
    }
  }
};

const childlessCouple: Prioset = {
  id: 'childlessCouple',
  name: 'PRESET_PROFILE_CHILDLESS_COUPLE_NAME',
  description: 'PRESET_PROFILE_CHILDLESS_COUPLE_DESCRIPTION',
  template: true,
  data: {
    conditions: [
      defaultHousingCondition,
      defaultSearchPeriodCondition,
      defaultResidentsCondition(0, 2)
    ],
    monthlyIncome: {
      value: {
        lowerBound: 1,
        upperBound: 5,
        value: 10
      },
      knockout: false
    },
    employmentType: {
      value: {
        choice: [
          EmploymentType.SELF_EMPLOYED,
          EmploymentType.EMPLOYED_LIMITED,
          EmploymentType.EMPLOYED_UNLIMITED,
          EmploymentType.CIVIL_SERVANT
        ],
        value: 7
      },
      knockout: false
    },
    householdType: {
      value: {
        choice: [HouseholdType.COUPLE_WITHOUT_CHILDREN],
        value: 10
      },
      knockout: false
    },
    children: {
      value: 10,
      knockout: false
    }
  }
};

const flatShare: Prioset = {
  id: 'flatShare',
  name: 'PRESET_PROFILE_FLAT_SHARE_NAME',
  description: 'PRESET_PROFILE_FLAT_SHARE_DESCRIPTION',
  template: true,
  data: {
    conditions: [
      defaultHousingCondition,
      defaultSearchPeriodCondition,
      defaultResidentsCondition()
    ],
    monthlyIncome: {
      value: {
        lowerBound: 1,
        upperBound: 5,
        value: 10
      },
      knockout: false
    },
    employmentType: {
      value: {
        choice: [
          EmploymentType.SELF_EMPLOYED,
          EmploymentType.EMPLOYED_LIMITED,
          EmploymentType.EMPLOYED_UNLIMITED,
          EmploymentType.CIVIL_SERVANT,
          EmploymentType.STUDENT
        ],
        value: 5
      },
      knockout: false
    },
    householdType: {
      value: {
        choice: [HouseholdType.FLATSHARE],
        value: 10
      },
      knockout: false
    },
    children: {
      value: 6,
      knockout: false
    }
  }
};

const pensioner: Prioset = {
  id: 'pensioner',
  name: 'PRESET_PROFILE_PENSIONER_NAME',
  description: 'PRESET_PROFILE_PENSIONER_DESCRIPTION',
  template: true,
  data: {
    conditions: [
      defaultHousingCondition,
      defaultSearchPeriodCondition,
      defaultResidentsCondition(0, 2)
    ],
    monthlyIncome: {
      value: {
        lowerBound: 1,
        upperBound: 5,
        value: 10
      },
      knockout: false
    },
    employmentType: {
      value: {
        choice: [EmploymentType.RETIRED],
        value: 10
      },
      knockout: false
    },
    householdType: {
      value: {
        choice: [HouseholdType.SINGLE, HouseholdType.COUPLE_WITHOUT_CHILDREN],
        value: 10
      },
      knockout: false
    },
    age: {
      value: {
        lowerBound: 60,
        upperBound: 110,
        value: 10
      },
      knockout: false
    }
  }
};

const defaultPrioset: Prioset = {
  id: 'default',
  name: 'DEFAULT_PRIOSET_TITLE_L',
  description: 'DEFAULT_PRIOSET_DESCRIPTION_L',
  template: true,
  data: {
    age: {
      value: {
        value: 0,
        lowerBound: 1,
        upperBound: 99
      },
      knockout: false
    },
    employmentType: {
      value: {
        value: 0,
        choice: []
      },
      knockout: false
    },
    householdType: {
      value: {
        value: 0,
        choice: []
      },
      knockout: false
    },
    monthlyIncome: {
      value: {
        value: 0,
        lowerBound: 1,
        upperBound: 5
      },
      knockout: false
    },
    animals: {
      value: 0,
      knockout: false
    },
    children: {
      value: 0,
      knockout: false
    },
    smoking: {
      value: 0,
      knockout: false
    },
    conditions: [
      defaultHousingCondition,
      defaultSearchPeriodCondition,
      defaultResidentsCondition(0, 5)
    ]
  }
};

export const presetProfiles = [
  defaultPrioset,
  childlessCouple,
  family,
  flatShare,
  pensioner,
  single,
  wbs
];

export const presetProfilesIds = presetProfiles.map(({ id }) => id);
