<header
  class="header ios-padding-left ios-padding-right"
  [elevation]="4"
  appElevation
>
  <app-button
    class="header__navigation-toggle"
    type="context-menu"
    ghost
    (click)="onNavigationToggleClick($event)"
    [iconLeft]="navigationOpen ? 'close' : 'context'"
  >
  </app-button>

  <app-logo></app-logo>

  <nav class="header__nav">
    <div class="header__nav-item header__nav-item--search">
      <ng-content select="[search-field]"></ng-content>
    </div>

    <div class="header__nav-item header__nav-item--user-menu">
      @if (user && user.profile) {
        <app-avatar
          class="header__profile-settings-toggle"
          [imgSrc]="user?.profile?.portrait | appImageSize: 'S'"
          name="{{ user?.profile?.firstname }} {{ user?.profile?.name }}"
          [size]="'small'"
          (click)="onProfileSettingsToggleClick($event)"
        ></app-avatar>
      } @else {
        <app-button
          class="header__profile-settings-toggle"
          type="context-menu"
          ghost
          (click)="onProfileSettingsToggleClick($event)"
          [iconLeft]="profileSettingsOpen ? 'close' : 'user'"
        >
        </app-button>
      }
    </div>
  </nav>
</header>
