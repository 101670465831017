import {
  CustomQuestionScore,
  HierarchicalRootQuestionResponse
} from './custom-questions';
import { PropertyApplicationStatus } from './enums';
import { GuestUserProfile, PropertySearcher } from './property-searcher.model';
import { Property } from './property.model';

export interface IApplication {
  id?: string;
  score?: number;
  status?: string;
  customQuestionScore?: CustomQuestionScore;
  portal?: string;
  text?: string;
  marked?: boolean;
  state?: string;
  property?: Property;
  propertySearcher?: PropertySearcher;
  created?: Date;
  updated?: Date;
  applicantStatus?: PropertyApplicationStatus;
  applicationBlocked?: boolean;
  deleted?:
    | PropertyApplicationStatus.DELETED
    | PropertyApplicationStatus.DELETED_RECENTLY;
  deletedByPs?: Date;
}

export interface Application extends IApplication {
  propertySearcher?: PropertySearcher;
}

export interface GuestApplication extends IApplication {
  userProfile?: GuestUserProfile;
  questionResponses?: HierarchicalRootQuestionResponse[];
  askForViewingAppointmentAttendance?: boolean;
}

export interface AnonymousApplicationPayload {
  propertyId: string;
  email: string;
  shortUrlToken: string;
  phone?: string;
  firstname?: string;
  name?: string;
}

export enum GuestApplicationErrorState {
  USER_PROFILE_NOT_GUEST_L = 'USER_PROFILE_NOT_GUEST_L',
  APPLICATION_NOT_FOUND_L = 'APPLICATION_NOT_FOUND_L',
  ERROR_PARSING_JWT_TOKEN_L = 'ERROR_PARSING_JWT_TOKEN_L',
  TOKEN_IS_EXPIRED_L = 'TOKEN_IS_EXPIRED_L'
}

export enum ApplicantStatus {
  ALL_APPOINTMENTS_DECLINED = 'ALL_APPOINTMENTS_DECLINED',
  ATTENDING_TO_VIEWING = 'ATTENDING_TO_VIEWING',
  AUTO_OFFERED = 'AUTO_OFFERED',
  DECLARE_INTENT = 'DECLARE_INTENT',
  DENIEDBYLL = 'DENIEDBYLL',
  DENIEDBYPS = 'DENIEDBYPS',
  INTENT = 'INTENT',
  INVITED_TO_VIEWING = 'INVITED_TO_VIEWING',
  NEW_MATCH = 'NEW_MATCH',
  NO_INTENT = 'NO_INTENT',
  NO_OPEN_SLOTS = 'NO_OPEN_SLOTS',
  NO_REPLY = 'NO_REPLY',
  OFFERED = 'OFFERED',
  PROSPECT = 'PROSPECT',
  REJECTED = 'REJECTED',
  TENANT = 'TENANT',
  UNANSWERED = 'UNANSWERED',
  WAITING_FOR_LANDLORD = 'WAITING_FOR_LANDLORD',
  ACCEPTED = 'ACCEPTED'
}
