import {
  AfterViewInit,
  ChangeDetectorRef,
  Directive,
  HostBinding,
  inject,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges
} from '@angular/core';

import { NgControl } from '@angular/forms';

import { Subject } from 'rxjs';
import { coerceBooleanProperty } from 'libs/utils';
import { AppFormFieldControl } from '../form-field/form-field-control/form-field-control';
import { BaseValueAccessor } from './base-value-accesor';

@Directive({
  standalone: true
})
export abstract class BaseControl<T>
  extends BaseValueAccessor<T>
  implements AppFormFieldControl<T>, OnChanges, OnDestroy, AfterViewInit
{
  protected injector = inject(Injector);

  protected cdr = inject(ChangeDetectorRef);
  protected ngControl: NgControl;
  protected _required = false;
  protected _disabled = false;
  protected _readOnly = false;

  stateChanges: Subject<void> = new Subject();

  @Input()
  get required() {
    return this._required;
  }

  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
  }

  @Input()
  get disabled() {
    return this._disabled;
  }

  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
  }

  @Input()
  get readOnly() {
    return this._readOnly;
  }

  set readOnly(value: boolean) {
    this._readOnly = coerceBooleanProperty(value);
  }

  @Input() id: string;
  @Input() placeholder: string;

  // TODO: remove them, as they are probably too specific for the general BaseControl
  @Input() count: boolean;
  @Input() counter: number;

  /**
   * temporary workaround. Angular shows warnings when [disabled] is set on an element
   * with already has [formControl] or formControlName attribute. Because those two directives
   * are part of ReactiveForms, Angular suggests to disable controls via FormControl objects, not in the
   * template itself (which is normal for TemplateDrivenForms).
   * We could use FormControl passed from the parent element, but we cannot inject it right now,
   * because of NG_VALUE_ACCESSOR - related FormControl tries to inject this class, and when this class
   * tries to inject FormControl (NgControl) in the same time, we got cyclic dependency.
   *
   * In order to fix it properly, we need to find a way to inject related FormControl (NgControl) into our
   * custom form control, and then we can use it directly.
   */
  @Input()
  forceDisabled = false;

  get errors() {
    return this.ngControl?.errors;
  }

  public hasErrors(error: string) {
    return this.ngControl?.hasError(error);
  }

  get touched() {
    return this.ngControl?.touched;
  }

  get valid() {
    return this.ngControl?.valid;
  }

  get invalid() {
    return this.ngControl?.invalid;
  }

  // Some classes implementing this class won't
  // update their ng classes. FormFieldComponent
  // requires the ng-touched class for styling.
  @HostBinding('class.ng-touched') ngTouched = false;

  // this is needed because of the following issues:
  // https://github.com/angular/angular/issues/17736
  // https://github.com/angular/angular/issues/10887
  ngAfterViewInit(): void {
    // some components implement this class incorrectly,
    // that's why we need to check if ngControl is set
    if (this.ngControl) {
      const outerControl = this.injector.get(NgControl).control;
      if (outerControl) {
        outerControl.markAsTouched = () => {
          if (!this.ngTouched) {
            this.ngControl?.control.markAsTouched();
            this.ngControl?.control.updateValueAndValidity();
            this.ngTouched = true;
          }
        };
      }
    }
  }

  setDisabledState(isDisabled: boolean) {
    this._disabled = isDisabled;
    this.cdr.markForCheck();
  }

  ngOnChanges(changes: SimpleChanges) {
    Object.keys(changes).forEach(key => {
      if (changes[key].currentValue !== changes[key].previousValue) {
        this.stateChanges.next();
      }
    });
  }

  ngOnDestroy() {
    this.stateChanges.complete();
  }
}
