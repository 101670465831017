// Ref: https://ng-bootstrap.github.io/#/guides/positioning

export enum ContextMenuTooltipPlacementEnum {
  AUTO = 'auto',
  TOP = 'top',
  TOP_START = 'top-start',
  TOP_LEFT = 'top-left',
  TOP_END = 'top-end',
  TOP_RIGHT = 'top-right',

  BOTTOM = 'bottom',
  BOTTOM_START = 'bottom-start',
  BOTTOM_LEFT = 'bottom-left',
  BOTTOM_END = 'bottom-end',
  BOTTOM_RIGHT = 'bottom-right',

  LEFT = 'left',
  START_TOP = 'start-top',
  LEFT_TOP = 'left-top',
  START_BOTTOM = 'start-bottom',
  LEFT_BOTTOM = 'left-bottom',
  END = 'end',
  RIGHT = 'right',
  END_TOP = 'end-top',
  RIGHT_TOP = 'right-top',
  END_BOTTOM = 'end-bottom',
  RIGHT_BOTTOM = 'right-bottom'
}
