<form [formGroup]="form">
  <div class="appointment-list__scroll-container">
    @if (exclusiveAppointments && exclusiveAppointments?.length) {
      <div class="appointment-list exclusive-appointment-list mt10 mb10">
        <p class="title-m mb8">
          {{ 'appointment.exclusive_slots_l' | translate }}
        </p>
        <app-radio-group formControlName="newActiveAppointmentId">
          @for (
            appointment of exclusiveAppointments;
            track appointment;
            let i = $index
          ) {
            <div class="appointment-item__container">
              <div
                class="appointment-item__inner-container"
                [ngClass]="{
                  full: appointment.full,
                  canceled: getAppointmentCanceled(appointment),
                  exclusive: appointment?.application
                }"
              >
                <app-radio-button
                  class="header-element header-check"
                  [value]="appointment.id"
                  [disabled]="getAppointmentCanceled(appointment)"
                >
                  <div class="pr10 d-flex align-items-center">
                    <svg-icon
                      src="/assets/images/icons/icon-calendar.svg"
                      [applyClass]="true"
                      [svgStyle]="{ 'width.px': 15 }"
                      class="primary-text-svg-color path rect"
                    ></svg-icon>
                    <span class="pl10">{{
                      appointment.date | appDateTime
                    }}</span>
                  </div>
                  <div class="pr10 pl10 d-flex align-items-center">
                    <svg-icon
                      src="/assets/images/icons/icon-clock.svg"
                      [applyClass]="true"
                      [svgStyle]="{ 'width.px': 15 }"
                      class="primary-text-svg-color path circle polyline"
                    ></svg-icon>
                    <span class="pl10">{{
                      appointment.date
                        | appDateTime: { withDate: false, withTime: true }
                    }}</span>
                  </div>
                </app-radio-button>
              </div>
            </div>
          }
        </app-radio-group>
      </div>
    }
    @if (appointments?.length) {
      <div class="appointment-list mt10 mb5">
        <p class="title-m mb8">
          {{ 'appointment.appointment_slots_l' | translate }}
        </p>
        <app-radio-group formControlName="newActiveAppointmentId">
          @for (
            appointment of appointments;
            track appointment;
            let i = $index
          ) {
            <div class="appointment-item__container">
              <div
                class="appointment-item__inner-container"
                [ngClass]="{
                  full: appointment.full,
                  canceled: getAppointmentCanceled(appointment),
                  exclusive: appointment?.application
                }"
              >
                <app-radio-button
                  class="header-element header-check"
                  [value]="appointment.id"
                  [disabled]="getAppointmentCanceled(appointment)"
                >
                  <div class="pr10 d-flex align-items-center">
                    <svg-icon
                      src="/assets/images/icons/icon-calendar.svg"
                      [applyClass]="true"
                      [svgStyle]="{ 'width.px': 15 }"
                      class="primary-text-svg-color path rect"
                    ></svg-icon>
                    <span class="pl10">{{
                      appointment.date | appDateTime
                    }}</span>
                  </div>
                  <div class="pr10 pl10 d-flex align-items-center">
                    <svg-icon
                      src="/assets/images/icons/icon-clock.svg"
                      [applyClass]="true"
                      [svgStyle]="{ 'width.px': 15 }"
                      class="primary-text-svg-color path circle polyline"
                    ></svg-icon>
                    <span class="pl10">{{
                      appointment.date
                        | appDateTime: { withDate: false, withTime: true }
                    }}</span>
                  </div>
                </app-radio-button>
              </div>
            </div>
          }
          @if (showNotFittingOption) {
            <div
              class="appointment-item__container"
              [class.active]="
                nextActiveAppointmentIdControl.value === 'non_fitting'
              "
            >
              <div
                class="appointment-item__inner-container appointment-item__non-fitting"
              >
                <app-radio-button
                  class="header-element header-check"
                  [value]="'non_fitting'"
                >
                  <svg-icon
                    src="/assets/images/icons/icon-no.svg"
                    [applyClass]="true"
                    [svgStyle]="{ 'width.px': 15 }"
                    class="primary-text-svg-color path"
                    [class.white-svg-color]="
                      nextActiveAppointmentIdControl.value === 'non_fitting'
                    "
                  ></svg-icon>
                  <span class="pl10">
                    {{ 'appointment.appointment_not_fitting' | translate }}
                  </span>
                </app-radio-button>
              </div>
            </div>
          }
        </app-radio-group>
      </div>
    }
  </div>
</form>
