<app-modal>
  <div class="modal-lg">
    <app-modal-content>
      <div class="update-banner-container">
        <div class="update-banner-container__title">
          <p class="title-xl pb8">{{ 'update_banner.title_l' | translate }}</p>
        </div>
        <div class="update-banner-container__body">
          <div class="update-banner-container__gif text-center">
            <img
              src="/assets/images/gifs/updateBanner.gif"
              alt="update banner"
            />
          </div>
          <div class="d-flex update-banner-container__message">
            <span>{{ 'update_banner.message_l' | translate }}</span>
          </div>
          <div class="d-flex update-banner-container__button pb16">
            <app-button (clickEvent)="onUpdate()">{{
              'update_banner.confirm_a' | translate
            }}</app-button>
          </div>
        </div>
      </div>
    </app-modal-content>
  </div>
</app-modal>
