export enum PropertyGeneralFeaturesEnum {
  BALCONY = 'BALCONY',
  TERRACE = 'TERRACE',
  LOGGIA = 'LOGGIA',
  FURNISHED = 'FURNISHED',
  GUEST_TOILETTE = 'GUEST_TOILETTE',
  KITCHENETTE = 'KITCHENETTE',
  TV_SAT_CABLE = 'TV_SAT_CABLE',
  GARDEN = 'GARDEN',
  ELEVATOR = 'ELEVATOR',
  BARRIER_FREE = 'BARRIER_FREE',
  WHEELCHAIR_ACCESSIBLE = 'WHEELCHAIR_ACCESSIBLE',
  SHUTTER = 'SHUTTER',
  BASEMENT = 'BASEMENT',
  FIREPLACE = 'FIREPLACE',
  GARDEN_USE = 'GARDEN_USE',
  WASH_DRY_ROOM = 'WASH_DRY_ROOM',
  STORE_ROOM = 'STORE_ROOM',
  BICYCLE_ROOM = 'BICYCLE_ROOM',
  ATTIC = 'ATTIC',
  SENIORS = 'SENIORS',
  HISTORIC_BUILDING = 'HISTORIC_BUILDING',
  WASHING_MACHINE_CONNECTION = 'WASHING_MACHINE_CONNECTION',
  BATHROOM_SHOWER = 'BATHROOM_SHOWER',
  BATHROOM_BATHTUB = 'BATHROOM_BATHTUB',
  BATHROOM_WINDOW = 'BATHROOM_WINDOW',
  BATHROOM_BIDET = 'BATHROOM_BIDET',
  BATHROOM_URINAL = 'BATHROOM_URINAL'
}
