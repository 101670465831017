import Color from 'color';
import { hexToHSL, HSLToHex } from 'libs/utils/colors';

/* eslint-disable @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access */

export const getColorPrimaryAccentLight = (baseColor: string): string => {
  // TODO: consider switching to the custom lightness adjustment
  //  e.g. like adjustColorLightness(baseColor, 0.4);
  return Color(baseColor).lighten(0.4).hex();
};

export const getColorSecondaryAccentLight = (baseColor: string): string => {
  // TODO: consider switching to the custom lightness adjustment
  //  e.g. like adjustColorLightness(baseColor, 0.4);
  return Color(baseColor).lighten(0.5).desaturate(0.6).hex();
};

/* eslint-enable @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access */

const clamp = (num, min, max) => Math.min(Math.max(num, min), max);

export const adjustColorLightness = (
  hexColor: string,
  percentage: number
): string => {
  const hsl = hexToHSL(hexColor);
  const lighten = percentage > 0;
  hsl.l = clamp(hsl.l + (lighten ? 100 - hsl.l : hsl.l) * percentage, 0, 100);
  return HSLToHex(hsl).toUpperCase();
};

export const getColorDark = (baseColor: string): string => {
  return adjustColorLightness(baseColor, -0.35);
};

export const getColorLight = (baseColor: string): string => {
  return adjustColorLightness(baseColor, 0.7);
};

export const getColorUltraLight = (baseColor: string): string => {
  return adjustColorLightness(baseColor, 0.9);
};

export const getHexColorWithAlpha = (
  baseColor: string,
  alpha: number
): string => {
  return baseColor + Math.floor(alpha * 255).toString(16);
};

export const getContrastingBWColor = (baseColor: string): string => {
  if (hexToHSL(baseColor.substr(0, 7)).l > 70) {
    return '#000';
  } else {
    return '#fff';
  }
};
