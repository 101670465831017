import { createSelector } from '@ngrx/store';

import * as fromReducers from '../reducers';
import * as fromAppReducers from './app.reducers';

export const getAppState = createSelector(
  fromReducers.getBaseState,
  (state: fromReducers.BaseState) => state.app
);

export const getCurrentLocale = createSelector(
  getAppState,
  fromAppReducers.getCurrentLocale
);
export const getAvailableLanguages = createSelector(
  getAppState,
  fromAppReducers.getAvailableLanguages
);
export const getDefaultLanguageCode = createSelector(
  getAppState,
  fromAppReducers.getDefaultLanguageCode
);
export const getViewportSize = createSelector(
  getAppState,
  fromAppReducers.getViewportSize
);
export const getCookiesPreference = createSelector(
  getAppState,
  fromAppReducers.getCookiesPreference
);

export const getRedirected = createSelector(
  getAppState,
  fromAppReducers.getRedirected
);
export const getPerformanceWarningConfirmed = createSelector(
  getAppState,
  fromAppReducers.getPerformanceWarningConfirmed
);

export const getVersion = createSelector(
  getAppState,
  fromAppReducers.getVersion
);
export const getLoadingVersion = createSelector(
  getAppState,
  fromAppReducers.getLoadingVersion
);
