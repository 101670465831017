import {
  Directive,
  Input,
  OnChanges,
  TemplateRef,
  ViewContainerRef,
  inject
} from '@angular/core';

@Directive({
  selector: '[appHide]',
  exportAs: 'appHide',
  standalone: true
})
export class HideDirective implements OnChanges {
  private view = inject(ViewContainerRef);
  private template = inject<TemplateRef<any>>(TemplateRef);

  @Input() appHide: boolean;

  ngOnChanges() {
    if (this.appHide) {
      this.view.clear();
    } else {
      this.view.createEmbeddedView(this.template);
    }
  }
}
