import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { ContextMenuTooltipPlacementEnum } from 'libs/components/molecules/context-menu/context-menu.enum';
import { ContactTag, PropertiesTag, SystemTag } from '@ui/shared/models';
import { TranslateModule } from '@ngx-translate/core';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { NgClass, SlicePipe } from '@angular/common';
import { TagComponent } from '../tag/tag.component';
import { ContextMenuItemComponent } from '../context-menu/context-menu-item/context-menu-item.component';
import { BadgeComponent } from '../../atoms/badge/badge.component';
import { ContextMenuComponent } from '../context-menu/context-menu.component';
import { LabelListTagComponent } from './label-list-tag/label-list-tag.component';

@Component({
  selector: 'app-label-list',
  templateUrl: './label-list.component.html',
  styleUrls: ['./label-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgClass,
    LabelListTagComponent,
    ContextMenuComponent,
    BadgeComponent,
    ContextMenuItemComponent,
    NgbTooltip,
    TagComponent,
    SlicePipe,
    TranslateModule
  ]
})
export class LabelListComponent {
  @Input() assignedTags: Array<ContactTag | PropertiesTag | SystemTag>;
  @Input() assignableTags: Array<ContactTag | PropertiesTag | SystemTag>;
  @Input() isSystemTag = false;
  @Input() amountBeforeTruncation = 2;
  @Input() truncateLabels = true;
  @Input() readonly = false;
  @Input() multilineMode = false;
  @Input() isAddLabelObject = false;

  @Output() assignTag = new EventEmitter<
    ContactTag | PropertiesTag | SystemTag
  >();
  @Output() unassignTag = new EventEmitter<
    ContactTag | PropertiesTag | SystemTag
  >();

  public onAssignTag(tag: ContactTag | PropertiesTag | SystemTag): void {
    this.assignTag.emit(tag);
  }

  public onUnassignTag(tag: ContactTag | PropertiesTag | SystemTag): void {
    this.unassignTag.emit(tag);
  }

  protected readonly ContextMenuTooltipPlacementEnum =
    ContextMenuTooltipPlacementEnum;
}
