export enum ConversationSenderTypes {
  PROPERTYSEARCHER = 'PROPERTYSEARCHER',
  LANDLORD = 'LANDLORD'
}

export enum ConversationSourceTypes {
  APP = 'APP',
  EMAIL = 'EMAIL'
}

export const DEFAULT_CONVERSATION_RESTRICTION_CONFIG = {
  forbidden: false,
  allowed: true,
  replyOnly: false,
  whenInvited: false
};
