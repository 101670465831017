import * as fromTypeGuards from 'libs/utils/type-guards';

export class SelectOption {
  value: any = null;
  label = '';
  selected = false;
  disabled = false;

  // eslint-disable-next-line @typescript-eslint/ban-types
  constructor(origin: object | string | number, labelKey = '', valueKey = '') {
    if (fromTypeGuards.isString(origin)) this.value = this.label = origin;

    if (fromTypeGuards.isNumber(origin)) {
      this.value = origin;
      this.label = origin.toString();
    }

    if (fromTypeGuards.isObject(origin)) {
      this.value = this.useProperty(origin, valueKey)
        ? origin[valueKey]
        : origin;
      this.label = this.useProperty(origin, labelKey) ? origin[labelKey] : '';
      this.disabled = this.useProperty(origin, 'disabled')
        ? origin['disabled']
        : false;
    }
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  private useProperty(source: object, key: string) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return (
      source &&
      key &&
      key.length &&
      Object.prototype.hasOwnProperty.call(source, key)
    );
  }
}
