<div
  ngbDropdown
  [ngModel]="value"
  [container]="container"
  [required]="required"
  ngDefaultControl
  autoClose="outside"
  class="dropdown-select__container"
  (openChange)="setShowFilters($event)"
>
  <button
    [attr.id]="this.uniqueElementId"
    class="btn"
    type="button"
    [class]="toggleClass"
    ngbDropdownToggle
    [disabled]="disabled"
  >
    <div class="d-flex justify-content-between">
      @if (!isValue) {
        <div class="dropdown-select__inner">
          <ng-content select="[dropdown-button]"></ng-content>
        </div>
      } @else {
        <span class="dropdown-toggle__inner">
          <ng-template
            [ngTemplateOutlet]="templateRef"
            [ngTemplateOutletContext]="{ $implicit: selectedItem || {} }"
          ></ng-template>
        </span>
      }

      <div class="d-flex align-items-center">
        @if (clearable && value) {
          <i class="icon icon--close ms-2" (click)="reset($event)"></i>
        }
        <i
          [ngClass]="
            isDropdownOpened ? 'chevron-rotated' : 'chevron-non-rotated'
          "
          class="icon icon--chevron-down ms-2"
        ></i>
      </div>
    </div>
  </button>

  <div
    [ngClass]="{ menuClass: !!menuClass, 'dropdown-menu': true }"
    ngbDropdownMenu
    [attr.aria-labelledby]="this.uniqueElementId"
    [class.relative-positioned]="relativePositioned"
  >
    <div class="dropdown__items">
      @if (items?.length) {
        <app-infinite-scroll (scrolled)="onScroll()">
          @for (item of items; track item[trackId]; let i = $index) {
            <div
              class="dropdown__item"
              [ngClass]="{ active: item?.value === value }"
              (click)="onItemClick(item)"
            >
              <ng-template
                [ngTemplateOutlet]="templateRef"
                [ngTemplateOutletContext]="{ $implicit: item }"
              ></ng-template>
              @if (
                showSeperator && items?.length > 0 && items?.length - 1 !== i
              ) {
                <hr />
              }
            </div>
          }
        </app-infinite-scroll>
      }
    </div>
  </div>
</div>
