<footer
  class="footer container-fluid"
  [class.footer--sidenav]="sideNavMode"
  [class.footer--dark]="dark"
  [class.ios-padding-left]="!sideNavMode"
  [class.ios-padding-right]="!sideNavMode"
>
  <div
    class="footer__inner"
    [class.flex-column]="sideNavMode || (mobileView$ | async)"
  >
    <nav
      class="footer__section align-items-center"
      [class.justify-content-center]="!sideNavMode || (mobileView$ | async)"
      [class.order-md-2]="!sideNavMode"
    >
      <ul
        class="footer__items flex-wrap"
        [class.align-items-center]="!sideNavMode || (mobileView$ | async)"
        [class.justify-content-center]="!sideNavMode || (mobileView$ | async)"
        [class.flex-column]="sideNavMode && (mobileView$ | async) !== true"
      >
        @for (link of links; track link.name) {
          <li class="footer__item">
            <a class="footer__link" href="{{ link.url }}" target="_blank">{{
              link.name | translate
            }}</a>
          </li>
        }
      </ul>
    </nav>

    <div
      class="footer__section align-items-center"
      [class.justify-content-center]="mobileView$ | async"
      [class.justify-content-end]="
        !sideNavMode && (mobileView$ | async) !== true
      "
      [class.order-md-3]="!sideNavMode"
    >
      @if (showLanguagePicker) {
        <app-lang-picker
          class="me-2"
          [availableLanguages]="availableLanguages"
          [ngModel]="currentLanguageCode"
          [ghostButton]="true"
          (ngModelChange)="onLanguageChange($event)"
        ></app-lang-picker>
      }
      <app-socials></app-socials>
    </div>

    <div
      class="footer__section align-items-center"
      [class.justify-content-center]="mobileView$ | async"
      [class.order-md-1]="!sideNavMode"
    >
      <span class="footer__text">&copy; {{ currentYear }} Immomio GmbH</span>
    </div>
  </div>
</footer>
