import { Component, Input, inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { WINDOW_REF } from 'libs/infrastructure';
import { TranslateModule } from '@ngx-translate/core';
import { ModalFooterComponent } from '../modal/modal-footer/modal-footer.component';
import { ButtonComponent } from '../../atoms/button/button.component';
import { ModalContentComponent } from '../modal/modal-content/modal-content.component';
import { ModalComponent } from '../modal/modal.component';

@Component({
  selector: 'app-update-modal',
  templateUrl: './update-modal.component.html',
  styleUrls: ['./update-modal.component.scss'],
  standalone: true,
  imports: [
    ModalComponent,
    ModalContentComponent,
    ButtonComponent,
    ModalFooterComponent,
    TranslateModule
  ]
})
export class UpdateModalComponent {
  private ngbActiveModal = inject(NgbActiveModal);
  private windowRef = inject(WINDOW_REF);

  @Input() isStillValid: boolean;

  public storeURL: string;

  close() {
    this.ngbActiveModal.close();
  }

  dismiss() {
    this.ngbActiveModal.dismiss();
  }

  public openStore() {
    this.windowRef.open('https://tenant.immomio.com', '_system');
  }
}
