import { Attachment } from './index';

export enum TooltipTrigger {
  ONLY_FOR_NEW_USERS = 'ONLY_FOR_NEW_USERS'
}

export enum TooltipApp {
  PROPERTY_SEARCHER = 'PROPERTY_SEARCHER',
  LANDLORD = 'LANDLORD'
}

export interface TooltipAnchorPoint {
  id: number;
  anchorPoint?: string;
  path?: string;
  app?: TooltipApp;
}

export interface Tooltip {
  id: number;
  title?: string;
  description?: string;
  trigger?: TooltipTrigger;
  active?: boolean;
  onlyForNewUsers?: boolean;
  images?: Attachment;
  anchorPoint?: TooltipAnchorPoint;
}

export interface TooltipTourElement {
  id: number;
  tooltip: Tooltip;
  step: number;
}

export interface TooltipTour {
  id?: number;
  title?: string;
  tourElements: TooltipTourElement[];
}

export interface TooltipProgress {
  currentTour: TooltipTour;
  currentTourElement: TooltipTourElement;
}
