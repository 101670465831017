import {
  Injectable,
  ComponentRef,
  ApplicationRef,
  ViewContainerRef,
  NgZone,
  inject
} from '@angular/core';

import { ToastOptions } from './model/toast-options';
import { Toast } from './model/toast.model';
import { ToastContainerComponent } from './toast-container.component';

@Injectable()
export class ToastService {
  private applicationRef = inject(ApplicationRef);
  private ngZone = inject(NgZone);

  private rootContainerRef: ViewContainerRef;
  private toastContainer: ComponentRef<ToastContainerComponent>;

  private toastIndex = 0;

  public setRootViewContainerRef(ref: ViewContainerRef) {
    this.rootContainerRef = ref;
  }

  private show(toast: Toast) {
    if (!this.toastContainer) {
      this.toastIndex = 0;

      if (!this.rootContainerRef) {
        try {
          this.rootContainerRef = this.applicationRef.components[0].instance
            .viewContainerRef as ViewContainerRef;
        } catch (e) {
          throw new Error('Inject ViewContainerRef into root component');
        }
      }

      this.toastContainer = this.rootContainerRef.createComponent(
        ToastContainerComponent
      );

      this.toastContainer.instance.onEmpty = () => this.destroyToastContainer();
      this.toastContainer.instance.onToastClick = (t: Toast) =>
        this.onToastClick(t);

      this.rootContainerRef.insert(this.toastContainer.hostView);
    }

    return this.addToast(toast);
  }

  private addToast(toast: Toast) {
    let timeoutId;
    toast.id = ++this.toastIndex;

    this.ngZone.runOutsideAngular(
      () =>
        (timeoutId = setTimeout(
          () => this.ngZone.run(() => this.clearToast(toast)),
          toast.duration
        ))
    );

    toast.timeoutId = timeoutId;
    this.toastContainer.instance.addToast(toast);

    return toast;
  }

  private clearToast(toast: Toast) {
    if (this.toastContainer) {
      clearTimeout(toast.timeoutId);
      this.toastContainer.instance.removeToast(toast);
    }
  }

  private destroyToastContainer() {
    if (this.toastContainer) {
      this.toastContainer.destroy();
      this.toastContainer = null;
    }
  }

  private onToastClick(toast: Toast) {
    if (toast.dismissOnClick) {
      this.clearToast(toast);
    }
  }

  public success(message: string, options: ToastOptions = {}) {
    const toast = new Toast('success', message, options);
    return this.show(toast);
  }

  public info(message: string, options: ToastOptions = {}) {
    const toast = new Toast('info', message, options);
    return this.show(toast);
  }

  public error(message: string, options: ToastOptions = {}) {
    const toast = new Toast('error', message, options);
    return this.show(toast);
  }

  public warning(message: string, options: ToastOptions = {}) {
    const toast = new Toast('warning', message, options);
    return this.show(toast);
  }
}
