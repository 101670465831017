import { Injectable } from '@angular/core';
import moment from 'moment';

import { PropertySearcherUser, User } from '@ui/shared/models';

@Injectable()
export class UserConversionService {
  public convertPsUser(propertySearcherUser: PropertySearcherUser): User {
    const { id, created, profile } = propertySearcherUser;
    return {
      id,
      created: moment.utc(created).format('YYYY-MM-DD'),
      profile,
      customer: { id }
    };
  }

  public convertUserData(userData): User {
    return {
      id: userData.id,
      profile: {
        firstname: userData.firstName,
        name: userData.lastName
      },
      customer: { id: userData.id }
    };
  }
}
