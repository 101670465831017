import { Attachment } from './attachment.model';
import { Pagination } from './data-access';

export type DocumentManagementBean = {
  folderId: string;
  folderName: string;
  contentPage: PageDocumentManagementContent;
  breadcrumbs: DocumentManagementBreadCrumb[];
};

export type DocumentManagementContent = {
  id: string;
  name: string;
  updatedAt: Date;
  type: DocumentManagementContentType;
  sharedWith: DocumentSharedWith;
};

export type PageDocumentManagementContent = {
  nodes: DocumentManagementContent[];
  page: Pagination;
};

export type DocumentManagementBreadCrumb = {
  id: string;
  name: string;
};

export type DocumentManagementInput = {
  attachments: Attachment[];
  sharing?: DocumentManagementSharingSettings[];
};

export type DocumentManagementSharingSettings = {
  type?: DocumentManagementSharingType;
  contractId?: string;
  administrationUnitId?: string;
  role?: DocumentManagementSharingRole;
};

export type SearchBean = {
  id?: string;
  externalId?: string;
  name?: string;
  value?: string;
  type?: DocumentManagementSharingType;
};

export type PageSearchBean = {
  nodes: SearchBean[];
  page: Pagination;
};

export type DocumentSearchInput = {
  id?: string;
  page?: number;
};

export enum DocumentManagementContentType {
  FOLDER = 'FOLDER',
  DOCUMENT = 'DOCUMENT'
}

export enum DocumentManagementSharingRole {
  RENTER = 'RENTER',
  OWNER = 'OWNER',
  MEMBER = 'MEMBER',
  BOARD_MEMBER = 'BOARD_MEMBER',
  REPRESENTATIVE = 'REPRESENTATIVE',
  INVESTOR = 'INVESTOR'
}

export enum DocumentManagementSharingType {
  CONTRACT = 'CONTRACT',
  ADMINISTRATION_UNIT = 'ADMINISTRATION_UNIT',
  ROLE = 'ROLE',
  ALL_USERS = 'ALL'
}

export type DocumentSharedWith = {
  all: boolean;
  administrationUnits: number;
  contracts: number;
  roles: number;
};

export type DocumentDetails = {
  title: string;
  sharing: SearchBean[];
};
