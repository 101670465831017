export enum ExpireConfigUnitType {
  HOURS = 'HOURS',
  DAYS = 'DAYS'
}

export enum CancelStrategyType {
  WHEN_RENTED = 'WHEN_RENTED',
  MAX_INTENT_DECLARATIONS = 'MAX_INTENT_DECLARATIONS'
}

export enum AutoDeactivateType {
  PUBLISHED_DAYS = 'PUBLISHED_DAYS',
  APPLICATIONS = 'APPLICATIONS'
}
