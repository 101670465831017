import { BreakpointObserver } from '@angular/cdk/layout';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  inject
} from '@angular/core';

import { NgbPopover, NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { mobileBreakingPoints } from 'libs/components/legacy/messenger/model/interface';
import { Elevation, ElevationType } from 'libs/directives';
import { ContextMenuTooltipPlacementEnum } from 'libs/components/molecules/context-menu/context-menu.enum';
import {
  ButtonBorderRadiusEnum,
  ButtonTypeEnum
} from 'libs/components/atoms/button/button.enum';
import { IconTypeEnum } from '@ui/shared/models';
import { TranslateModule } from '@ngx-translate/core';
import { NgClass, NgTemplateOutlet } from '@angular/common';
import { OffcanvasService } from '../../../services/offcanvas.service';
import { ButtonComponent } from '../../atoms/button/button.component';

@UntilDestroy()
@Component({
  selector: 'app-context-menu',
  templateUrl: './context-menu.component.html',
  styleUrls: ['./context-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgbPopover,
    ButtonComponent,
    NgbTooltip,
    NgClass,
    NgTemplateOutlet,
    TranslateModule
  ]
})
export class ContextMenuComponent implements OnInit {
  private observer = inject(BreakpointObserver);
  private offcanvasService = inject(OffcanvasService);

  @Input() buttonElevation: ElevationType = Elevation.ONE;
  @Input() buttonMenuIcon = IconTypeEnum.Context;
  @Input() buttonUseFullContainerSize = false;
  @Input() ghostButton = false;

  @Input() tooltipPlacement = ContextMenuTooltipPlacementEnum.BOTTOM;
  @Input() closeOnItemClick = true;
  @Input() sideNavMenuTitle = '';
  @Input() allowSideNavForMobileView = true;
  @Input() disabled = false;

  @Input() tooltip = '';
  @Input() zeroPadding: boolean;
  @Input() container: 'body' | null = null;
  @Input() disableHover: boolean;
  @Input() borderRadius: ButtonBorderRadiusEnum = ButtonBorderRadiusEnum.BIG;

  @Output() openMenuEvent = new EventEmitter<any>();
  @Output() clickCloseSideNavEvent = new EventEmitter<any>();
  // ^ will either be triggered, when clicking the close button or the overlay of the side nav view

  @ViewChild(NgbPopover) popover: NgbPopover;

  @HostBinding('class.side-nav-opened') get openSideNav() {
    return this.sideNavOpened;
  }

  protected readonly ButtonTypeEnum = ButtonTypeEnum;
  protected readonly IconTypeEnum = IconTypeEnum;

  public openMenuInSideNav = false;
  public sideNavOpened = false;

  public ngOnInit() {
    if (this.allowSideNavForMobileView) {
      this.observer
        .observe(mobileBreakingPoints)
        .pipe(untilDestroyed(this))
        .subscribe(result => {
          this.openMenuInSideNav = result.matches;
        });
    }
  }

  public handleInsideClick($event: Event) {
    if (!this.closeOnItemClick) {
      $event.stopPropagation();
    }
  }

  public openContextMenu($event: Event, content: TemplateRef<any>) {
    $event.stopPropagation();
    if (this.disabled) return;

    if (this.openMenuInSideNav) {
      if (this.sideNavOpened) {
        this.closeContextMenu();
        return;
      }

      this.sideNavOpened = true;
      this.openMenuEvent.emit($event);
      this.open(content);
    } else if (this.popover && !this.popover.isOpen()) {
      this.popover.open();
      this.openMenuEvent.emit($event);
    }
  }

  open(content: TemplateRef<any>) {
    this.offcanvasService
      .open(content, {
        ariaLabelledBy: 'offcanvas-context-menu',
        position: 'end'
      })
      .result.then(
        () => {
          this.closeContextMenu();
        },
        () => {
          this.closeContextMenu();
        }
      );
  }

  public closeContextMenu() {
    if (!this.sideNavOpened) {
      return;
    }
    if (this.popover) this.popover.close();
    this.sideNavOpened = false;
    this.offcanvasService.dismiss();
    this.clickCloseSideNavEvent.emit();
  }
}
