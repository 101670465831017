import { createSelector } from '@ngrx/store';
import * as fromReducers from '../reducers';
import * as fromSearchProfileReducer from './search-profiles.reducers';

export const getSearchProfilesState = createSelector(
  fromReducers.getBaseState,
  (state: fromReducers.BaseState) => state.searchProfiles
);

export const getSearchCities = createSelector(
  getSearchProfilesState,
  fromSearchProfileReducer.getSearchCities
);
export const getCityDistrictsMap = createSelector(
  getSearchProfilesState,
  fromSearchProfileReducer.getCityDistrictsMap
);
export const isCitySearching = createSelector(
  getSearchProfilesState,
  fromSearchProfileReducer.isCitySearching
);
export const isDistrictLoading = createSelector(
  getSearchProfilesState,
  fromSearchProfileReducer.isDistrictLoading
);
