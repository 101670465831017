import { ConversationRestrictionConfig } from 'libs/components/legacy/messenger/model/interface';
import { Address } from './address.model';
import { Attachment } from './attachment.model';
import { Contact } from './contact.model';
import {
  AvailableLanguageCodesEnum,
  MultiLanguageString
} from './language.model';
import { ObjectNote } from './object-note.model';
import { PortalCredential, PropertyPortal } from './portals';
import { Prioset } from './prioset.model';
import { AgentInfo, LandlordUser } from './landlord.model';
import { Profile } from './profile.model';
import { Customer } from './customer.model';
import { CustomerBranding } from './property-searcher.model';
import { EventLog } from './publish-log.model';
import { BrandingTheme } from './branding.model';
import {
  CustomerDefinedFieldDefinition,
  CustomerDefinedFieldValue
} from './customer-defined-fields.model';
import { SalesLegalText } from './sales-legal-text.model';
import { District } from './search-profile.model';
import { PropertyStatus } from './enums/property-status';
import { AdministrationUnit } from './administration-unit.model';
import {
  MarketingWorkflowState,
  PropertyPortalStates,
  PropertyProposalStatus,
  PropertyStatuses
} from './enums';
import { PropertiesTag } from './tag.model';
import { CommercialType } from './commercial-type.model';
import { AutoActivateConfig, AutoDeactivateConfig } from './automated-rental';
import { ContactPropertySearcherType } from './internal-list.model';
import { QuestionContainer } from './custom-questions';
import { RentDepositInfo } from './rent-deposit';

export enum PropertyTypeState {
  ONLINE = 'ONLINE',
  OFFLINE = 'OFFLINE',
  RENTED = 'RENTED'
}

export enum EventType {
  IDLE = 'IDLE',
  PROPERTY_CREATED = 'PROPERTY_CREATED',
  PROPERTY_UPDATED = 'PROPERTY_UPDATED',
  PROPERTY_SOLD = 'PROPERTY_SOLD',
  PROPERTY_PUBLISHED = 'PROPERTY_PUBLISHED',
  PROPERTY_UNPUBLISHED = 'PROPERTY_UNPUBLISHED',
  PROPERTY_AUTO_PUBLISHED = 'PROPERTY_AUTO_PUBLISHED',
  PROPERTY_AUTO_UNPUBLISHED = 'PROPERTY_AUTO_UNPUBLISHED',
  PROPERTY_RENTED = 'PROPERTY_RENTED',
  PROPERTY_DELETED = 'PROPERTY_DELETED',
  PROPERTY_EXPOSE_SENT = 'PROPERTY_EXPOSE_SENT',
  RENTAL_PROCESS_AUTO_ACTIVATED = 'RENTAL_PROCESS_AUTO_ACTIVATED',
  RENTAL_PROCESS_MANUAL_ACTIVATED = 'RENTAL_PROCESS_MANUAL_ACTIVATED',
  RENTAL_PROCESS_AUTO_DEACTIVATED = 'RENTAL_PROCESS_AUTO_DEACTIVATED',
  RENTAL_PROCESS_MANUAL_DEACTIVATED = 'RENTAL_PROCESS_MANUAL_DEACTIVATED',
  APPLIED_BY_LL = 'APPLIED_BY_LL',

  CUSTOMER_COOPERATION_PROPERTY_SENT = 'CUSTOMER_COOPERATION_PROPERTY_SENT',
  CUSTOMER_COOPERATION_PROPERTY_WITHDRAWN = 'CUSTOMER_COOPERATION_PROPERTY_WITHDRAWN',
  CUSTOMER_COOPERATION_PROPERTY_RETURNED = 'CUSTOMER_COOPERATION_PROPERTY_RETURNED',
  CUSTOMER_COOPERATION_PROPERTY_RENTED = 'CUSTOMER_COOPERATION_PROPERTY_RENTED',
  CUSTOMER_COOPERATION_PROPERTY_SOLD = 'CUSTOMER_COOPERATION_PROPERTY_SOLD',
  CUSTOMER_COOPERATION_PROPERTY_RECEIVED = 'CUSTOMER_COOPERATION_PROPERTY_RECEIVED',

  PROPOSAL_MANUALLY_CREATED = 'PROPOSAL_MANUALLY_CREATED',
  PROPOSAL_OFFERED = 'PROPOSAL_OFFERED',
  PROPOSAL_AUTO_OFFERED = 'PROPOSAL_AUTO_OFFERED',
  PROPOSAL_DENIED_BY_PS = 'PROPOSAL_DENIED_BY_PS',
  PROPOSAL_DENIED_BY_LL = 'PROPOSAL_DENIED_BY_LL',
  PROPOSAL_ACCEPTED = 'PROPOSAL_ACCEPTED',
  PS_NO_REPLY = 'PS_NO_REPLY',
  PDF_PRINTED = 'PDF_PRINTED',

  MARKETING_WORKFLOW_STATE_CHANGED = 'MARKETING_WORKFLOW_STATE_CHANGED',
  NEW_PROPERTY_PROCESS_STARTED = 'NEW_PROPERTY_PROCESS_STARTED',
  PROPERTY_RESET = 'PROPERTY_RESET'
}

export enum PublishPropertyState {
  SUCCESS = 'SUCCESS',
  PENDING = 'PENDING',
  ERROR = 'ERROR'
}

export enum ParkingType {
  GARAGE = 'GARAGE',
  UNDERGROUND_CAR_PARK = 'UNDERGROUND_CAR_PARK',
  CARPORT = 'CARPORT',
  FREE_SPACE = 'FREE_SPACE',
  CAR_PARK = 'CAR_PARK',
  DUPLEX = 'DUPLEX'
}

export enum FollowupState {
  ENDS_IN_FUTURE = 'ENDS_IN_FUTURE',
  ENDS_TODAY = 'ENDS_TODAY',
  ENDED_ALREADY = 'ENDED_ALREADY'
}

export enum FollowupProcessed {
  UNPROCESSED = 'UNPROCESSED',
  PROCESSED = 'PROCESSED'
}

export enum PropertyType {
  FLAT = 'FLAT',
  GARAGE = 'GARAGE',
  COMMERCIAL = 'COMMERCIAL'
}

export enum FurnishingType {
  FULL = 'FULL',
  PARTLY = 'PARTLY'
}

export enum MarketingType {
  SALES = 'SALES',
  RENT = 'RENT'
}

export enum GarageType {
  DUPLEX = 'DUPLEX',
  CARPORT = 'CARPORT',
  GARAGE = 'GARAGE',
  SPACE_ELECTRICITY = 'SPACE_ELECTRICITY',
  BOATYARD = 'BOATYARD',
  NO_INFORMATION = 'NO_INFORMATION',
  CAR_PARK = 'CAR_PARK',
  UNDERGROUND_GARAGE = 'UNDERGROUND_GARAGE',
  UNDERGROUND_GARAGE_SPACE = 'UNDERGROUND_GARAGE_SPACE',
  DOUBLE_GARAGE = 'DOUBLE_GARAGE'
}

export enum IntercomType {
  STANDARD = 'STANDARD',
  VIDEO = 'VIDEO'
}

export enum ExternalResourceType {
  VIRTUAL_TOUR = 'VIRTUAL_TOUR'
}

export interface CustomQuestionAssociation {
  importance: number;
  id: string;
  knockout: boolean;
}

export interface EnergyCertificate {
  energyCertificateType?: string;
  yearOfConstruction?: number;
  primaryEnergyProvider?: string;
  creationDate?: string;
  usageCertificate?: UsageCertificate;
  demandCertificate?: DemandCertificate;
  primaryEnergyConsumption?: number;
  austrianEnergyCertificate?: AustrianEnergyCertificate;
}

export interface AustrianEnergyCertificate {
  dateOfExpiry?: Date;
  heatingEnergyDemand?: string;
  heatingEnergyDemandClass: string;
  overallEnergyEfficiencyFactor: string;
  overallEnergyEfficiencyFactorClass: string;
}

export interface UsageCertificate {
  energyConsumptionParameter?: string;
  energyConsumption?: number;
  energyEfficiencyClass?: string;
  includesHeatConsumption?: boolean;
}

export interface DemandCertificate {
  endEnergyConsumption?: number;
  energyEfficiencyClass?: string;
}

export interface ParkingSpace {
  purchasePrice: number;
  type: ParkingType;
  price: number;
  count: number;
}

export interface BathroomEquipment {
  shower?: boolean;
  bathtub?: boolean;
  window?: boolean;
  bidet?: boolean;
  urinal?: boolean;
}

export interface PropertyFilterBean {
  id?: string;
  name?: string;
  externalId?: string;
  address?: Address;
  titleImage?: Attachment;
}

export interface Property {
  id?: string;
  created?: Date;
  updated?: Date;

  wbs?: boolean;
  address?: Address;
  attachments?: Attachment[];
  availableFrom?: AvailableFrom;
  constructionYear?: number;
  bailment?: number;
  balcony?: boolean;
  barrierFree?: boolean;
  basementAvailable?: boolean;
  basementSize?: number;
  basePrice?: number;
  totalRentGross?: number;
  totalRentGrossManuallySet?: boolean;
  totalRent?: number;
  bathRooms?: number;
  buildingCondition?: string;
  contact?: Contact;
  documents?: Attachment[];
  expose?: Attachment;
  elevator?: boolean;
  energyCertificate?: EnergyCertificate;
  externalId?: string;
  flatShare?: boolean;
  flatType?: string;
  floor?: number;
  garden?: boolean;
  ground?: string;
  guestToilette?: boolean;

  houseType?: string;
  objectType?: string;
  numberOfFloors?: number;
  fireplace?: boolean;
  gardenUse?: boolean;
  washDryRoom?: boolean;
  storeRoom?: boolean;
  bicycleRoom?: boolean;
  attic?: boolean;
  seniors?: boolean;
  hasBalconyOrTerrace?: boolean;
  landArea?: number;
  parkingSpaces?: ParkingSpace[];
  parkingPriceIncludedToAdditionalCosts?: boolean;
  totalParkingPrice?: number;
  pricesWriteProtected?: boolean;
  heater?: string;
  heatingCost?: number;
  heatingCostIncluded?: boolean;
  otherCosts?: number;
  historicBuilding?: boolean;
  kitchenette?: boolean;
  lastRefurbishment?: Date;
  name?: string;
  objectDescription?: MultiLanguageString;
  furnishingDescription?: MultiLanguageString;
  objectLocationText?: MultiLanguageString;
  objectMiscellaneousText?: MultiLanguageString;
  note?: ObjectNote;
  portals?: PropertyPortal[];
  prioset?: Prioset;
  referenceId?: string;
  rooms?: number;
  halfRooms?: number;
  totalRooms?: number;
  serviceCharge?: number;
  shortDescription?: string;
  showAddress?: boolean;
  showContact?: boolean;
  size?: number;
  user?: LandlordUser;
  tenantInfo?: Profile;
  writeProtection?: string;
  rented?: boolean;
  dataPrivacyUrl?: string;
  portalStates?: { [key: string]: string };
  tvSatCable?: boolean;

  // verify - or remove
  customer?: Customer;
  viewings?: any;
  state?: any;
  task?: any;
  settings?: PropertyConfigBean;

  deposit?: any;
  additionalCosts?: any;

  applyLink?: string;
  sizeOfProposals?: number;
  sizeOfApplications?: number;
  newApplications?: number;
  appointmentsCount?: number;
  hasOpenSlots?: boolean;
  hasActiveAppointments?: boolean;
  hasCustomQuestions?: boolean;
  sizeOfUpcomingAppointments?: number;

  selfDisclosureId?: string;
  allowContinueAsGuest?: boolean;
  branding?: CustomerBranding;

  customQuestions?: CustomQuestionAssociation[];
  inactive?: boolean;
  active?: boolean;

  agentInfo?: AgentInfo;
  eventLogs?: EventLog[];

  customerName?: string;
  customerLogo?: string;
  customerId?: string;
  theme?: BrandingTheme;
  previousTenantAppointmentEnabled?: boolean;
  previousTenant?: TenantData;
  propertyManagerName?: string;
  propertyManagerAgentInfo?: PropertyManagerAgentInfo;

  followups?: PropertyFollowup[];
  titleImage?: Attachment;
  type?: PropertyType;
  garageData?: GarageDatatype;
  commercialData?: CommercialData;

  numberOfBalconies?: number;
  numberOfTerraces?: number;
  numberOfLoggias?: number;
  balconyTerraceArea?: number;
  numberOfBedrooms?: number;
  bathroomEquipment?: BathroomEquipment;
  furnishingType?: FurnishingType;
  washingMachineConnection?: boolean;
  washingMachineConnectionPlaces?: WashingMachineConnectionPlace[];
  shutter?: boolean;
  intercomType?: string;
  wheelchairAccessible?: boolean;
  automatedRentalProcessActive?: boolean;
  version?: number;
  credentials?: PortalCredential[];
  entryPrice?: number;
  externalResources?: ExternalResource[];
  allowRuvDeposit?: boolean;
  customerDefinedFieldValues?: CustomerDefinedFieldValue[];
  marketingType?: MarketingType;
  salesData?: SalesData;
  salesLegalTexts?: SalesLegalText[];
  disableEntranceFee?: boolean;
  district?: District;
  status?: PropertyStatus;

  propertyTemplateBean?: PropertyTemplateBean;
  administrationUnit?: AdministrationUnit;
  administrationUnitId?: number;
  externalIdPart?: string;
  propertyFlowState?: PropertyStatuses;
  propertyPortalState?: PropertyPortalStates;
  administrationUnitProjectBean?: AdministrationUnit;
  alreadyAssigned?: boolean;
  notesAmount?: number;
  projectId?: string;
  propertyProposalState?: PropertyProposalStatus;
  imported?: boolean;
  delegationOverview?: PropertyDelegationOverview;
  courtageData?: CourtageData;

  tags?: PropertiesTag[];

  currentPropertyProcessBean?: {
    marketingWorkflowState?: MarketingWorkflowState;
  };
}

export interface SalesData {
  price: number;
  serviceCharge: number;
}

export interface CourtageData {
  courtage: number;
  courtageInPercent: boolean;
  plusVAT: boolean;
  text: string;
}

export interface ExternalResource {
  name: string;
  url: string;
  type: ExternalResourceType;
}

export interface PropertyManagerAgentInfo {
  id: string;
  name: string;
  firstName: string;
  usertype: string;
  email: string;
}

export interface GarageDatatype {
  garageType: GarageType;
  availableUntil: Date;
  freeUntil: Date;
  length: number;
  width: number;
  height: number;
}

export interface CommercialData {
  commercialType: CommercialType;
  commercialSubType: string;
  hasCanteen: boolean;
  lanCables: boolean;
  highVoltage: boolean;
  airConditioning: boolean;
  distanceToTrainStation: number;
  distanceToHighway: number;
  distanceToPublicTransport: number;
  distanceToAirport: number;
  pricePerSquareMeter: number;
  vatNotIncludedInPrice: boolean;
}

export interface PropertyFollowup {
  id?: string;
  date?: string;
  time?: string;
  processed?: boolean;
  reason?: string;
  notifications?: PropertyFollowUpNotification[];
  currentFollowupStatus?: FollowupState;
}

export interface CreatePropertyFollowUp {
  property?: string;
  propertyFollowup?: PropertyFollowup;
  setReserved?: boolean;
}

export interface PropertyFollowUpNotification {
  date?: string;
  time?: string;
}

export interface PropertyBean {
  id?: string;
  externalId?: string;
  externalIdPart?: string;
  status?: PropertyStatus;
  titleImage?: Attachment;
  data?: PropertyData;
  customer?: Customer;
  hasOpenSlots?: boolean;
  applyLink?: string;
  downloadExposeAllowed?: boolean;
  downloadExposeUrl?: string;
  shareable?: boolean;
  totalRentGross?: number;
  size?: number;
  selfDisclosureId?: string;
  wbs?: boolean;
  type?: PropertyType;
  entryPrice?: number;
  customerLogo?: string;
  customerName?: string;
  credentials?: PortalCredential[];
  customerDefinedFieldValues?: CustomerDefinedFieldValue[];
  customerDefinedFieldDefinitions?: CustomerDefinedFieldDefinition[];
  marketingType?: MarketingType;
  salesLegalTexts?: SalesLegalText[];
  settings?: PropertyConfigBean;
  rented?: boolean;
  dataPrivacyUrl?: string;
}

export interface PropertyConfigBean {
  conversationConfig?: ConversationRestrictionConfig;
  autoDeactivateConfig?: AutoDeactivateConfig;
  autoActivateConfig?: AutoActivateConfig;
  allowRuvDeposit?: boolean;
  showLegalTextAndCheckboxes?: boolean;
  disableEntranceFee?: boolean;
}

export interface TenantData {
  firstname?: string;
  name?: string;
  email?: string;
  phone?: string;
  type?: ContactPropertySearcherType;
  preferredLanguage?: AvailableLanguageCodesEnum;
}

export interface SendExposeFormInput {
  recipients?: string[];
  template: string;
  body?: string;
  attachments?: Attachment[];
  setReserved?: boolean;
}

export interface AvailableFrom {
  dateAvailable?: string;
  stringAvailable?: string;
}

// this should be the data object inside the property after the BE changes
// to the property object.
// TODO: adapt the interfaces to the BE models
export interface PropertyData {
  address: Address;
  attachments: Attachment[];
  availableFrom: AvailableFrom;
  constructionYear: number;
  bailment: number;
  barrierFree: boolean;
  basePrice: number;
  otherCosts?: number;
  totalRentGross?: number;
  totalRentGrossManuallySet?: boolean;
  basementAvailable?: boolean;
  basementSize: number;
  bathRooms: number;
  buildingCondition: string;
  documents: Attachment[];
  elevator: boolean;
  energyCertificate: EnergyCertificate;
  flatShare: boolean;
  flatType: string;
  floor: number;
  garden: boolean;
  ground: string;
  guestToilette: boolean;

  houseType: string;
  objectType: string;
  numberOfFloors: number;
  fireplace: boolean;
  gardenUse: boolean;
  washDryRoom: boolean;
  storeRoom: boolean;
  bicycleRoom: boolean;
  attic: boolean;
  seniors: boolean;
  landArea: number;
  parkingSpaces: [ParkingSpace];
  totalParkingPrice: number;
  totalParkingPurchasePrice?: number;
  heater: string;
  heatingCost: number;
  heatingCostIncluded: boolean;
  historicBuilding: boolean;
  kitchenette: boolean;
  name: string;
  objectDescription: MultiLanguageString;
  furnishingDescription: MultiLanguageString;
  objectLocationText: MultiLanguageString;
  objectMiscellaneousText: MultiLanguageString;
  rooms: number;
  halfRooms?: number;
  serviceCharge: number;
  showAddress: boolean;
  showContact: boolean;
  size: number;

  tvSatCable: boolean;

  preferences: any;

  numberOfBalconies?: number;
  numberOfTerraces?: number;
  numberOfLoggias?: number;
  balconyTerraceArea?: number;
  numberOfBedrooms?: number;
  bathroomEquipment?: BathroomEquipment;
  furnishingType?: FurnishingType;
  washingMachineConnection?: boolean;
  shutter?: boolean;
  intercomType?: string;
  wheelchairAccessible?: boolean;
  garageData?: GarageDatatype;
  commercialData?: CommercialData;
  salesData?: SalesData;
}

enum WashingMachineConnectionPlace {
  KITCHEN = 'KITCHEN',
  BATHROOM = 'BATHROOM',
  BASEMENT = 'BASEMENT'
}

export interface TitleImage {
  title?: string;
  name: string;
  type?: string;
  identifier?: string;
  extension?: string;
  encrypted?: boolean;
  url?: string;
  index?: number;
}

export interface PropertyTemplateBean {
  propertyTemplateData?: PropertyTemplateData;
}

export interface PropertyTemplateData {
  name?: string;
  propertyType?: PropertyType;
}

export interface PropertyDelegationOverview {
  id?: string;
  name?: string;
  logo?: string;
  type?: PropertyDelegationOverviewTypes;
}

export enum PropertyDelegationOverviewTypes {
  SENDER = 'SENDER',
  RECEIVER = 'RECEIVER'
}

export interface PropertyDelegationFilter {
  types?: PropertyDelegationFilterType[];
  partnerIds?: string[];
}

export enum PropertyDelegationFilterType {
  WITHOUT = 'WITHOUT',
  SENT = 'SENT',
  RECEIVED = 'RECEIVED',
  WITHOUT_RECEIVED = 'WITHOUT_RECEIVED'
}

export interface PropertyMatchBean {
  id?: string;
  type?: string;
  status?: string;
  property?: PropertyBean;
  appointmentSlotsAvailable?: boolean;
  upcomingAppointmentDate?: Date;
  upcomingAppointmentId?: string;
  applicationBlocked?: boolean;
  hasQuestions?: boolean;
  hasUnansweredQuestions?: boolean;
  questionContainer?: QuestionContainer;
  rentDepositInfo?: RentDepositInfo;
  conversationExists?: boolean;
  allowedToSendMessage?: boolean;
  askForViewingAppointmentAttendance?: boolean;
  isProposal?: boolean;
}

export interface PropertyOfferEvent {
  events: any;
  searchAfter: string[];
}
