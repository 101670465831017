import {
  Directive,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';

import { Subject, Subscription } from 'rxjs';
import { Step, StepChange } from 'libs/components/legacy/wizard/models';

@Directive({
  standalone: true
})
export abstract class WizardStep implements OnInit, OnDestroy {
  @Input() stepChange: Subject<StepChange>;

  /* eslint-disable @angular-eslint/no-output-on-prefix */
  @Output() onNextStep = new EventEmitter();
  @Output() onPreviousStep = new EventEmitter();
  @Output() onSelectStep = new EventEmitter<Step>();
  @Output() onComplete = new EventEmitter();
  @Output() onCancel = new EventEmitter();
  /* eslint-enable @angular-eslint/no-output-on-prefix */

  private stepChangeSubscriber: Subscription;

  ngOnInit() {
    this.stepChangeSubscriber = this.stepChange?.subscribe(
      (change: StepChange) => {
        switch (change.action) {
          case 'next': {
            this.nextStep();
            break;
          }

          case 'back': {
            this.previousStep();
            break;
          }

          case 'complete': {
            this.complete();
            break;
          }

          case 'cancel': {
            this.cancel();
            break;
          }

          case 'select': {
            this.selectStep(change.step);
            break;
          }
        }
      }
    );
  }

  ngOnDestroy() {
    this.stepChangeSubscriber?.unsubscribe();
  }

  nextStep() {
    this.onNextStep.emit();
  }

  previousStep() {
    this.onPreviousStep.emit();
  }

  selectStep(step: Step) {
    this.onSelectStep.emit(step);
  }

  complete() {
    this.onComplete.emit();
  }

  cancel() {
    this.onCancel.emit();
  }
}
