<app-modal-v2>
  <div class="modal-m">
    <app-modal-content>
      <div class="modal-body">
        <ng-container>
          <div class="d-flex">
            <app-badge class="mr5" [color]="selectedMethod.label?.color">{{
              selectedMethod.label?.text | translate
            }}</app-badge>
            <app-badge [color]="'primary-accent-light'">{{
              'digital_contract.qes_modal_badge_verify_identity_l' | translate
            }}</app-badge>
          </div>

          <p class="semibold title-m mt10">
            {{ selectedMethod.header | translate }}
          </p>
          <div class="d-flex align-items-center">
            <svg-icon
              src="/assets/images/icons/icon-clock.svg"
              [svgStyle]="{ 'width.px': 30 }"
            ></svg-icon>
            <span>{{ 'digital_contract.process_duration_l' | translate }}</span>
          </div>

          <div class="d-flex align-items-center">
            <svg-icon
              src="/assets/images/icons/icon-calendar.svg"
              [svgStyle]="{ 'width.px': 30 }"
            ></svg-icon>
            <span>{{ 'digital_contract.process_validity_l' | translate }}</span>
          </div>
        </ng-container>

        <hr />

        <ng-container>
          <div class="d-flex align-items-center mt10">
            <svg-icon
              src="/assets/images/icons/icon-phone.svg"
              [svgStyle]="{ 'width.px': 20 }"
            ></svg-icon>
            @if (!selectedMethod.secondHowItWorksText) {
              <svg-icon
                src="/assets/images/icons/icon-computer.svg"
                [svgStyle]="{ 'width.px': 20 }"
              ></svg-icon>
            }
            <span class="semibold title-m">
              {{
                (selectedMethod.secondHowItWorksText
                  ? 'digital_contract.qes_with_phone_header_l'
                  : 'digital_contract.qes_with_phone_and_pc_header_l'
                ) | translate
              }}
            </span>
          </div>

          <div class="needed-documents-container">
            <svg-icon
              class="mr5"
              src="/assets/images/icons/icon-hint.svg"
              [svgStyle]="{ 'width.px': 20 }"
            ></svg-icon>
            <div
              [innerHTML]="
                selectedMethod.defaultNeededDocumentsText | translate
              "
            ></div>
          </div>

          @if (selectedMethod.notSupportedBanks) {
            <div class="not-supported-banks">
              <svg-icon
                class="mr5"
                src="/assets/images/icons/icon-attention-red.svg"
                [svgStyle]="{ 'width.px': 20, 'height.px': 20 }"
              ></svg-icon>
              <a target="_blank" href="/assets/pdf/SRS-Bank-en.pdf">
                <strong>
                  {{ selectedMethod.notSupportedBanks | translate }}
                </strong>
              </a>
            </div>
          }

          <p class="semibold title-s mt24 mb5">
            {{ 'digital_contract.qes_how_it_works_header_l' | translate }}
          </p>
          <p
            class="mb24"
            [innerHTML]="selectedMethod.defaultHowItWorksText | translate"
          ></p>

          <ng-template
            [ngTemplateOutlet]="images"
            [ngTemplateOutletContext]="{ isSecondIdImage: false }"
          ></ng-template>
        </ng-container>

        <hr />

        @if (selectedMethod.secondNeededDocumentsText) {
          <div class="d-flex align-items-center mt10">
            <svg-icon
              src="/assets/images/icons/icon-computer.svg"
              [svgStyle]="{ 'width.px': 20 }"
            ></svg-icon>
            <span class="semibold title-m">
              {{ 'digital_contract.qes_with_pc_header_l' | translate }}
            </span>
          </div>
          <div class="needed-documents-container">
            <svg-icon
              class="mr5"
              src="/assets/images/icons/icon-hint.svg"
              [svgStyle]="{ 'width.px': 20 }"
            ></svg-icon>
            <div
              [innerHTML]="selectedMethod.secondNeededDocumentsText | translate"
            ></div>
          </div>
          <p class="semibold title-s mt24 mb5">
            {{ 'digital_contract.qes_how_it_works_header_l' | translate }}
          </p>
          <p
            class="mb24"
            [innerHTML]="selectedMethod.secondHowItWorksText | translate"
          ></p>
          <ng-template
            [ngTemplateOutlet]="images"
            [ngTemplateOutletContext]="{ isSecondIdImage: true }"
          ></ng-template>
          <hr />
          <p class="id-pin-explanation semibold title-s mt16 mb24">
            {{ 'digital_contract.qes_id_pin_explanation_l' | translate }}
          </p>
          <hr />
        }
        @if (qesMethod === qesMethods.E_ID || qesMethod === qesMethods.BANK) {
          <app-form-field>
            <app-form-field-label [showRequiredMarker]="true">{{
              'general.birthdate_l' | translate
            }}</app-form-field-label>
            <app-date
              [formControl]="dateOfBirthControl"
              [maxDate]="maxBirthDate"
            >
            </app-date>
          </app-form-field>
          <hr />
        }
      </div>
    </app-modal-content>

    <app-modal-footer>
      <div class="modal-footer">
        <app-button [type]="'light-bordered'" (clickEvent)="dismiss()">{{
          'general.cancel_a' | translate
        }}</app-button>
        <app-button
          [type]="'primary'"
          [disabled]="dateOfBirthControl.invalid"
          (clickEvent)="close()"
          >{{ 'digital_contract.qes_start_check_a' | translate }}</app-button
        >
      </div>
    </app-modal-footer>
  </div>
</app-modal-v2>

<ng-template #images let-isSecondIdImage="isSecondIdImage">
  @switch (qesMethod) {
    @case (qesMethods.ROBO_IDENT) {
      <ng-template
        [ngTemplateOutlet]="image"
        [ngTemplateOutletContext]="{
          sources: [
            '/assets/images/digitalContract/qes/qes_selfie_1.png',
            '/assets/images/digitalContract/qes/qes_selfie_2.png',
            '/assets/images/digitalContract/qes/qes_selfie_3.png'
          ]
        }"
      ></ng-template>
    }
    @case (qesMethods.VIDEO) {
      <ng-template
        [ngTemplateOutlet]="image"
        [ngTemplateOutletContext]="{
          sources: ['/assets/images/digitalContract/qes/qes_video_1.png']
        }"
      ></ng-template>
    }
    @case (qesMethods.BANK) {
      <ng-template
        [ngTemplateOutlet]="image"
        [ngTemplateOutletContext]="{
          sources: [
            '/assets/images/digitalContract/qes/qes_bank_1.png',
            '/assets/images/digitalContract/qes/qes_bank_2.png',
            '/assets/images/digitalContract/qes/qes_bank_3.png',
            '/assets/images/digitalContract/qes/qes_bank_4.png'
          ]
        }"
      ></ng-template>
    }
    @case (qesMethods.E_ID) {
      @if (!isSecondIdImage) {
        <ng-container
          [ngTemplateOutlet]="image"
          [ngTemplateOutletContext]="{
            sources: [
              '/assets/images/digitalContract/qes/qes_id_phone_1.png',
              '/assets/images/digitalContract/qes/qes_id_phone_2.png',
              '/assets/images/digitalContract/qes/qes_id_phone_3.png'
            ]
          }"
        ></ng-container>
      }
      @if (isSecondIdImage) {
        <ng-container
          [ngTemplateOutlet]="image"
          [ngTemplateOutletContext]="{
            sources: ['/assets/images/digitalContract/qes/qes_id_pc_1.png']
          }"
        ></ng-container>
      }
    }
  }
</ng-template>

<ng-template #image let-sources="sources">
  @for (source of sources; track source) {
    <app-image [defaultSrc]="source"></app-image>
  }
</ng-template>
