import { Injectable } from '@angular/core';
import { Params } from '@angular/router';

import { isString, isBoolean, isNumber } from 'libs/utils';

import { SortOptions } from '@ui/shared/models';

@Injectable()
export class SearchParamsSerializerService {
  public serialize<_T>(params: Params, additionalFields: string[] = []) {
    const sort = params.sort || ({} as SortOptions);

    const filters = Object.keys(params.filters).reduce((total, key) => {
      const value = params.filters[key];
      if (
        value === undefined ||
        value === null ||
        (Array.isArray(value) && !value.length)
      ) {
        return {
          ...total,
          [key]: undefined
        };
      }

      if (
        isString(value) ||
        isBoolean(value) ||
        isNumber(value) ||
        Array.isArray(value)
      ) {
        return {
          ...total,
          [key]: value
        };
      }

      return {
        ...total,
        ...this.flatten(value, key)
      };
    }, {});

    const additional = additionalFields.reduce(
      (total, current) => ({
        ...total,
        [current]: params[current]
      }),
      {}
    );

    return {
      ...filters,
      page: params.page,
      size: params.size,
      sort:
        sort.field && sort.direction
          ? [sort.field, sort.direction].join(',')
          : '',
      ...additional
    } as Params;
  }

  public deserialize(params: Params) {
    return Object.keys(params).reduce((total, key) => {
      const value = params[key];
      if (value === undefined || value === null) {
        return total;
      }

      if (key === 'sort') {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        const parts = value.split(',') || [];
        const field = parts[0];
        const direction = parts[1];

        const sort = field && direction ? { field, direction } : {};

        return {
          ...total,
          sort
        };
      }

      const keyParts = key.split('.');
      if (keyParts[0] && keyParts[1]) {
        const existing = total[keyParts[0]] || {};
        return {
          ...total,
          [keyParts[0]]: {
            ...existing,
            [keyParts[1]]: value
          }
        };
      }

      return {
        ...total,
        [key]: value
      };
    }, {});
  }

  private flatten(param: unknown, parentKey: string) {
    return Object.keys(param).reduce(
      (total, currentKey) => ({
        ...total,
        [`${parentKey}.${currentKey}`]: param[currentKey]
      }),
      {}
    );
  }
}
