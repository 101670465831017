<div
  class="attachment-item d-flex col-12 p0 flex-row"
  (click)="action()"
  [class.preview]="!disableDownload && attachment.url"
  [class.attachment-item--clickable]="!disableDownload && attachment.url"
>
  <div class="attachment-item__info col-9 p0">
    @if (orderable) {
      <div class="attachment-item__order-menu">
        <i class="icon icon--chevron-up" (click)="onMoveUp()"></i>
        <i class="icon icon--chevron-down" (click)="onMoveDown()"></i>
      </div>
    }
    @if (getFileExtension) {
      <div class="attachment-item__preview mr10">{{ getFileExtension }}</div>
    }
    <div class="attachment-item__details">
      @if (editable) {
        <app-simple-text-input
          [formControl]="nameControl"
          [hideIcon]="hideIcons"
        ></app-simple-text-input>
      }
      @if (!editable) {
        <p class="default-s attachment-details__name">{{ name }}</p>
      }
      <p class="default-s attachment-details__size">
        @if (attachment?.size) {
          <span>{{ attachment?.size | appFileSize }}</span>
        }
        @if (hasExifMetadata) {
          <span class="exif-metadata-info">{{
            'attachments.exif_metadata_info' | translate
          }}</span>
        }
      </p>
    </div>
  </div>
  <div class="attachment-item__actions upload-progress col-3 p0">
    @if (showDownload && !hasFile) {
      <div class="download-attachment-button" [ngClass]="{ mr12: showRemove }">
        @if (!blockDownload && !disableDownload) {
          <a class="download-icon" (click)="onDownload()">
            <!-- {{attachment?.title || attachment?.name}} -->
            <svg-icon
              src="/assets/images/icons/icon-download.svg"
              [applyClass]="true"
              [svgStyle]="{ 'width.px': 15 }"
              class="white-svg-color path rect download"
            ></svg-icon>
          </a>
        }
        <span class="download-icon disabled">
          @if (!blockDownload && disableDownload) {
            <svg-icon
              src="/assets/images/icons/icon-download.svg"
              [applyClass]="true"
              [svgStyle]="{ 'width.px': 15 }"
              class="default-svg-color path rect"
            ></svg-icon>
          }
          @if (blockDownload) {
            <svg-icon
              src="/assets/images/icons/lock-icon.svg"
              [applyClass]="true"
              [svgStyle]="{ 'width.px': 15 }"
              class="default-svg-color path rect"
            ></svg-icon>
          }
        </span>
      </div>
    }
    @if (showRemove) {
      <div class="remove-attachment-button">
        <svg-icon
          src="/assets/images/icons/icon-bin.svg"
          [applyClass]="true"
          [svgStyle]="{ 'width.px': 15 }"
          class="secondary-svg-color path rect"
          (click)="onRemove()"
        ></svg-icon>
      </div>
    }
    @if (editable) {
      <div>
        <app-context-menu>
          <div menu-content>
            <app-context-menu-item (clickEvent)="editAttachment()">{{
              'general.edit_attachment_a' | translate
            }}</app-context-menu-item>
          </div>
        </app-context-menu>
      </div>
    }
  </div>
</div>
