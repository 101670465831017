export interface ImageSrcSet {
  x1?: string;
  x2?: string;
  x3?: string;
}

export interface ImageSource {
  minScreenWidth?: number;
  maxScreenWidth?: number;
  srcSet: ImageSrcSet;
}

export type ImageSources = ImageSource[];

export enum ImageObjectFitEnum {
  DEFAULT = 'default',
  COVER = 'cover',
  CONTAINS = 'contain'
}

export enum ImageBorderRadiusEnum {
  NONE = 'none',
  SMALL = 'small',
  BIG = 'big',
  ROUND = 'round',
  MEDIUM = 'medium'
}

export enum ImageBorderStyleEnum {
  NONE = 'none',
  NEUTRAL = 'neutral'
}

export const ImageDefaultConfig = {
  sources: [],
  lazyLoading: true,
  objectFit: ImageObjectFitEnum.DEFAULT,
  borderRadius: ImageBorderRadiusEnum.NONE,
  borderStyle: ImageBorderStyleEnum.NONE
};
