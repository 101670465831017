import { inject, Injectable } from '@angular/core';
import { NgbOffcanvas, NgbOffcanvasOptions } from '@ng-bootstrap/ng-bootstrap';

type SideSheetOptions<
  DataOptions extends Record<string, unknown> = Record<string, never>
> = NgbOffcanvasOptions & {
  data?: DataOptions;
};

@Injectable({
  providedIn: 'root'
})
export class OffcanvasService {
  #offCanvasOptions: NgbOffcanvasOptions = {
    position: 'end'
  };
  private ngbOffCanvasService = inject(NgbOffcanvas);

  open<T, DataOptions extends Record<string, unknown>>(
    content: T,
    options?: SideSheetOptions<DataOptions>
  ) {
    const { data, ...offCanvasOptions } = options || {};
    const ref = this.ngbOffCanvasService.open(content, {
      ...this.#offCanvasOptions,
      ...offCanvasOptions
    });
    if (data) {
      Object.assign(ref.componentInstance, data);
    }

    return ref;
  }

  dismiss(reason?: any) {
    this.ngbOffCanvasService.dismiss(reason);
  }
}
