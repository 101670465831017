import { Component, Input, inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';

import { PropertySearcher } from '@ui/shared/models';
import { ActionState } from 'libs/state-utils';
import { TranslateModule } from '@ngx-translate/core';
import { AsyncPipe, CurrencyPipe } from '@angular/common';
import { ButtonComponent } from '../../../../atoms/button/button.component';
import { ModalFooterComponent } from '../../../modal/modal-footer/modal-footer.component';
import { LoadingSpinnerComponent } from '../../../loading-spinner/loading-spinner.component';
import { ModalContentComponent } from '../../../modal/modal-content/modal-content.component';
import { ModalComponent } from '../../../modal/modal.component';

@Component({
  selector: 'app-chat-create-modal',
  templateUrl: './chat-create-modal.component.html',
  styleUrls: ['./chat-create-modal.component.scss'],
  standalone: true,
  imports: [
    ModalComponent,
    ModalContentComponent,
    LoadingSpinnerComponent,
    ModalFooterComponent,
    ButtonComponent,
    AsyncPipe,
    CurrencyPipe,
    TranslateModule
  ]
})
export class ChatCreateModalComponent {
  private ngbActiveModal = inject(NgbActiveModal);

  @Input() userData: PropertySearcher;
  @Input() availableForNewConversation: Observable<PropertySearcher[]>;
  @Input() availableForNewConversationActionState: Observable<ActionState>;

  public close(application: PropertySearcher) {
    this.ngbActiveModal.close(application);
  }

  public dismiss() {
    this.ngbActiveModal.dismiss();
  }
}
