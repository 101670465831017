export function arrayMove<T = any>(array: T[], from: number, to: number) {
  const length = array.length;
  if (from < 0 || from >= length || to < 0 || to >= length) {
    return array;
  }

  const fromTmp = array[from];
  const toTmp = array[to];

  /* eslint-disable @typescript-eslint/no-unsafe-return */
  return array.reduce((total, current, index) => {
    if (index === from) {
      return [...total, toTmp];
    }

    if (index === to) {
      return [...total, fromTmp];
    }

    return [...total, current];
  }, []);
  /* eslint-enable @typescript-eslint/no-unsafe-return */
}
