import { PropertyApplicationStatus } from '@ui/shared/models';

export interface BannerConfig {
  color: BannerColor;
  label: string;
  icon?: string;
  tooltip?: string;
  explanation?: string;
}

export interface BannerConfigs {
  [type: string]: BannerConfig;
}

export enum BannerColor {
  BLUE = 'blue',
  GREY = 'grey',
  GREEN = 'green',
  ORANGE = 'orange',
  RED = 'red'
}

export const BANNER_CONFIGS: BannerConfigs = {
  [PropertyApplicationStatus.NEW_MATCH]: {
    color: BannerColor.BLUE,
    label: 'properties.banner.new_match_l',
    tooltip: 'properties.banner.new_match_tooltip_l',
    explanation: 'properties.banner.new_match_explanation_l'
  },
  [PropertyApplicationStatus.WAITING_FOR_LANDLORD]: {
    color: BannerColor.GREY,
    label: 'properties.banner.waiting_for_landlord_l',
    tooltip: 'properties.banner.waiting_for_landlord_tooltip_l',
    explanation: 'properties.banner.waiting_for_landlord_explanation_l'
  },
  [PropertyApplicationStatus.ALL_APPOINTMENTS_DECLINED]: {
    color: BannerColor.RED,
    label: 'properties.banner.no_fitting_appointment_l',
    tooltip: 'properties.banner.no_fitting_appointment_tooltip_l',
    explanation: 'properties.banner.no_fitting_appointment_explanation_l'
  },
  [PropertyApplicationStatus.ATTENDING_TO_VIEWING]: {
    color: BannerColor.GREEN,
    label: 'properties.banner.attending_to_viewing_l',
    tooltip: 'properties.banner.attending_to_viewing_tooltip_l',
    explanation: 'properties.banner.attending_to_viewing_explanation_l'
  },
  [PropertyApplicationStatus.INVITED_TO_VIEWING]: {
    color: BannerColor.ORANGE,
    label: 'properties.banner.invited_to_viewing_l',
    tooltip: 'properties.banner.invited_to_viewing_tooltip_l',
    explanation: 'properties.banner.invited_to_viewing_explanation_l'
  },
  [PropertyApplicationStatus.NO_OPEN_SLOTS]: {
    color: BannerColor.GREY,
    label: 'properties.banner.no_open_slots_available_l',
    tooltip: 'properties.banner.no_open_slots_available_tooltip_l',
    explanation: 'properties.banner.no_open_slots_available_explanation_l'
  },
  [PropertyApplicationStatus.REJECTED]: {
    color: BannerColor.RED,
    label: 'properties.banner.rejected_l',
    tooltip: 'properties.banner.rejected_tooltip_l',
    explanation: 'properties.banner.rejected_explanation_l'
  },
  [PropertyApplicationStatus.DECLARE_INTENT]: {
    color: BannerColor.GREEN,
    label: 'properties.banner.declare_intent_l',
    tooltip: 'properties.banner.declare_intent_tooltip_l',
    explanation: 'properties.banner.declare_intent_explanation_l'
  },
  [PropertyApplicationStatus.INTENT]: {
    color: BannerColor.GREEN,
    label: 'properties.banner.intent_l',
    tooltip: 'properties.banner.intent_tooltip_l',
    explanation: 'properties.banner.intent_explanation_l',
    icon: 'check'
  },
  [PropertyApplicationStatus.NO_INTENT]: {
    color: BannerColor.RED,
    label: 'properties.banner.no_intent_l',
    tooltip: 'properties.banner.no_intent_tooltip_l',
    explanation: 'properties.banner.no_intent_explanation_l'
  },
  [PropertyApplicationStatus.TENANT]: {
    color: BannerColor.GREEN,
    label: 'properties.banner.accepted_as_tenant_l',
    tooltip: 'properties.banner.accepted_as_tenant_tooltip_l',
    explanation: 'properties.banner.accepted_as_tenant_explanation_l'
  }
};
