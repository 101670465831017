import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule
} from '@angular/forms';

import { NgScrollbar } from 'ngx-scrollbar';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter } from 'rxjs/operators';

import moment from 'moment';

import {
  Conversation,
  ConversationDetails,
  ConversationMessage,
  ConversationMessagesCSSCLass,
  MessageTemplate,
  PaneType,
  SendMessageInput
} from 'libs/components/legacy/messenger/model/interface';
import { ActionState } from 'libs/state-utils';
import {
  Attachment,
  ContactType,
  filteredOutFromList
} from '@ui/shared/models';
import {
  FileUploadChange,
  FileUploadComponent
} from 'libs/components/legacy/attachment';
import { defaultDocumentsConfig } from 'libs/config';
import { TranslateModule } from '@ngx-translate/core';
import { SvgIconComponent } from 'angular-svg-icon';
import { NgClass } from '@angular/common';
import { FileUploadComponent as FileUploadComponent_1 } from '../../../attachment/file-upload/file-upload.component';
import { AppInputDirective } from '../../../form/controls/input/input.directive';
import { AttachmentsComponent } from '../../../form/controls/attachment/attachments.component';
import { FormFieldLabelComponent } from '../../../form/form-field/form-field-label/form-field-label.component';
import { FormFieldComponent } from '../../../form/form-field/form-field.component';
import { NoContentComponent } from '../../../no-content/no-content.component';
import { ChatTextMessageComponent } from '../chat-text-message/chat-text-message.component';
import { LoadingSpinnerComponent } from '../../../loading-spinner/loading-spinner.component';
import { DropdownSelectComponent } from '../../../form/controls/dropdown-select/dropdown-select.component';
import { ButtonComponent } from '../../../../atoms/button/button.component';

@UntilDestroy()
@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ButtonComponent,
    NgClass,
    FormsModule,
    ReactiveFormsModule,
    DropdownSelectComponent,
    SvgIconComponent,
    NgScrollbar,
    LoadingSpinnerComponent,
    ChatTextMessageComponent,
    NoContentComponent,
    FormFieldComponent,
    FormFieldLabelComponent,
    AttachmentsComponent,
    AppInputDirective,
    FileUploadComponent_1,
    TranslateModule
  ]
})
export class ChatComponent implements OnInit, AfterViewInit, OnChanges {
  private fb = inject(FormBuilder);

  @Input() sender: ContactType;
  @Input() activeConversation: Conversation;
  @Input() conversationDetails: ConversationDetails;
  @Input() templates: MessageTemplate[];
  @Input() messages: ConversationMessage[];
  @Input() messagesAttach: boolean;
  @Input() isPropertySearcher: boolean;
  @Input() isLandlord: boolean;
  @Input() messagesActionState: ActionState;
  @Input() canAnswer: boolean;
  @Input() isMobile: boolean;
  @Input() isTablet: boolean;
  @Input() hasOldMessages: boolean;
  @Input() allowAttachmentUpload: boolean;
  @Input() showMessageTemplates: boolean;
  @Output() sendMessage = new EventEmitter<SendMessageInput>();
  @Output() fetchOldMessages = new EventEmitter();
  @Output() download = new EventEmitter<Attachment>();
  @Output() preview = new EventEmitter<Attachment>();
  @Output() setActivePane = new EventEmitter<PaneType>();
  @Output() createMessageAsTemplate = new EventEmitter<MessageTemplate>();
  @Output() scrollBottom = new EventEmitter();
  @Output() calcHeight = new EventEmitter();

  @ViewChild('chatContainerArea') chatContainerArea: ElementRef;
  @ViewChild('fileInput') fileInput: FileUploadComponent;
  @ViewChild(NgScrollbar, { static: true }) ngScrollbar: NgScrollbar;
  @ViewChild('chatHistory') chatHistory: ElementRef;
  @ViewChild('chatInput') chatInput: ElementRef;

  public form = this.fb.group({
    message: null,
    attachments: null
  });
  public selectTemplateForm: FormGroup;
  public paneTypes = PaneType;
  public dragEvent: boolean;
  public acceptedFileTypes = defaultDocumentsConfig.allAcceptedTypes;
  public documentsMaxSize = defaultDocumentsConfig.acceptedDocumentSize;

  private count = 0;
  private xssRegEx = new RegExp(
    // eslint-disable-next-line no-control-regex
    '(\b)(onS+)(s*)=|javascript|(<s*)(/*)script',
    'gi'
  );

  private get messageControl() {
    return this.form.get('message');
  }

  public get attachmentControl() {
    return this.form.get('attachments');
  }

  public get isLoading() {
    return this.messagesActionState.pending && !!this.activeConversation;
  }

  public get isApplicationBlocked() {
    return this.conversationDetails?.applicationDetails?.applicationBlocked;
  }

  public get areMessageAndAttachmentsEmpty() {
    const message = (this.form.get('message').getRawValue() as string) || '';
    return message.trim().length === 0 && !this.attachmentControl.getRawValue();
  }

  public get isDeleted() {
    return filteredOutFromList.includes(
      this.conversationDetails?.applicationDetails?.status
    );
  }

  public ngOnInit() {
    this.selectTemplateForm = this.fb.group({
      template: null
    });

    this.selectTemplateForm.valueChanges
      .pipe(
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        filter(value => value.template),
        untilDestroyed(this)
      )
      .subscribe(value => this.templateSelected(value.template));
  }

  public ngAfterViewInit(): void {
    void this.ngScrollbar.scrollTo({ bottom: 0 });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (
      this.messages &&
      changes.messages &&
      !(
        (changes.messagesAttach && changes.messagesAttach.currentValue) ||
        this.messagesAttach
      )
    ) {
      const { currentValue, previousValue } = changes.messages;
      const curValue = currentValue ? currentValue.length : 0;
      const prevValue = previousValue ? previousValue.length : 0;
      if (curValue > prevValue) {
        setTimeout(() => {
          void this.ngScrollbar.scrollTo({
            bottom: 0,
            duration: prevValue === 0 ? 0 : 500
          });
        });
      }
    }

    if (this.isDeleted || !this.canAnswer || this.isApplicationBlocked) {
      this.form.get('message').disable();
      this.form.get('attachments').disable();
    } else {
      this.form.get('message').enable();
      this.form.get('attachments').enable();
    }

    if (
      changes.activeConversation &&
      !changes.activeConversation.isFirstChange()
    ) {
      this.form.reset();
    }
  }

  public groupMessages(index: number) {
    if (!this.messages) return;
    this.count++;
    let durToNext = false;
    let cssClass = ConversationMessagesCSSCLass.SINGLE;

    if (this.messages.length - 1 >= index + 1) {
      const currentSender = this.messages[index].sender;
      const nextSender = this.messages[index + 1].sender;
      durToNext =
        moment
          .duration(
            moment(this.messages[index + 1].messageSent).diff(
              this.messages[index].messageSent
            )
          )
          .asMinutes() < 1 &&
        currentSender.firstname === nextSender.firstname &&
        currentSender.name === nextSender.name &&
        currentSender.type === nextSender.type;
    }

    if (this.count === 1 && durToNext) {
      cssClass = ConversationMessagesCSSCLass.FIRST;
    }

    if (this.count > 1 && durToNext) {
      cssClass = ConversationMessagesCSSCLass.CONTENT;
    }

    if (this.count > 1 && !durToNext) {
      cssClass = ConversationMessagesCSSCLass.LAST;
    }

    if (this.count > 0 && !durToNext) {
      this.count = 0;
    }
    return cssClass;
  }

  public onSendMessage(key?: KeyboardEvent) {
    if (this.isPropertySearcher && !this.canAnswer) {
      return;
    }

    if (this.isApplicationBlocked) {
      this.form.get('message').reset();
      return;
    }

    if (key && key.code === 'Enter' && key.ctrlKey) {
      if (key) {
        key.preventDefault();
      }
      return this.messageControl.patchValue(
        `${String(this.messageControl.value)}\n`
      );
    }

    if (!key || (key && key.code === 'Enter' && !key.ctrlKey)) {
      if (key) {
        key.preventDefault();
      }
      if (this.areMessageAndAttachmentsEmpty) return;

      const messageValue = this.messageControl.value as string;
      const attachmentValue = Array.isArray(this.attachmentControl.value)
        ? this.attachmentControl.value
        : [(this.attachmentControl.value as Attachment)?.file];

      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      if (messageValue?.match(this.xssRegEx)?.length > 1) return;

      this.calcHeight.emit();
      this.sendMessage.emit({
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        message: messageValue?.replace(/\n/gi, ` <br /> `) || '',
        attachments: attachmentValue
      });
      this.messageControl.patchValue(null);
      this.attachmentControl.patchValue(null);
      this.hideUploadArea();
    }
  }

  public scrolled(event: Event) {
    if (
      this.hasOldMessages &&
      (event.target as HTMLElement).scrollTop === 0 &&
      !this.messagesActionState.pending
    ) {
      this.fetchOldMessages.emit();
    }
  }

  public onDragOverFile(event: DragEvent) {
    if (!this.activeConversation || !this.allowAttachmentUpload) return;
    if (event.type === 'dragenter') {
      this.showUploadArea();
    }
    if (
      event.type === 'dragleave' &&
      !(this.chatContainerArea.nativeElement as HTMLElement).contains(
        event.relatedTarget as HTMLElement
      )
    ) {
      this.hideUploadArea();
    }
  }

  public hideUploadArea() {
    this.dragEvent = false;
  }

  public showUploadArea() {
    this.dragEvent = true;
  }

  public selectFile() {
    const fileInput = (
      ((this.fileInput as any).elementRef as ElementRef)
        .nativeElement as HTMLElement
    ).getElementsByTagName('input');
    fileInput[0].click();
  }

  public onChange(file: FileUploadChange) {
    this.showUploadArea();
    this.attachmentControl.patchValue(file);
  }

  public onDownload(attachment: Attachment) {
    this.download.emit(attachment);
  }

  public onPreview(attachment: Attachment) {
    this.preview.emit(attachment);
  }

  public setActivePanel(pane: PaneType) {
    this.setActivePane.emit(pane);
  }

  public onCreateMessageAsTemplate(template: MessageTemplate) {
    this.createMessageAsTemplate.emit(template);
  }

  public scrollingToBottom() {
    this.scrollBottom.emit();
  }

  private templateSelected(id: string) {
    if (!id) return;
    const { attachments, content: message } = this.templates.find(
      t => t.id === id
    );
    this.form.patchValue({
      message,
      attachments
    });
    const lines = message.split('\n').length;
    const height = 35 * lines;
    (this.chatInput.nativeElement as HTMLInputElement).getElementsByTagName(
      'textarea'
    )[0].style.height = `${String(height > 100 ? 100 : height)}px`;
    this.selectTemplateForm.patchValue({ template: null });
    this.calcHeight.emit();
  }
}
