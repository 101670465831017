import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  inject,
  Input,
  OnChanges,
  OnInit,
  Output
} from '@angular/core';

import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { mixinAttachmentHelper } from 'libs/components/legacy/attachment/attachment-component-helper.mixin';

import { checkExifOrientation } from 'libs/utils';
import { Attachment } from '@ui/shared/models';
import { ModalService } from 'libs/components/legacy/modal';
import { EditAttachmentComponent } from 'libs/components/legacy/attachment/edit-attachment/edit-attachment.component';
import { TranslateModule } from '@ngx-translate/core';
import { SvgIconComponent } from 'angular-svg-icon';
import { NgClass } from '@angular/common';
import { FileSizePipe } from '../../../../pipes/file-size.pipe';
import { ContextMenuItemComponent } from '../../../molecules/context-menu/context-menu-item/context-menu-item.component';
import { ContextMenuComponent } from '../../../molecules/context-menu/context-menu.component';
import { SimpleTextInputComponent } from '../../form/controls/simple-text-input/simple-text-input.component';

class AttachmentItemComponentBase {}

const _AttachmentItemComponentMixinBase = mixinAttachmentHelper(
  AttachmentItemComponentBase
);

@Component({
  selector: 'app-attachment-item-v2',
  templateUrl: './attachment-item-v2.component.html',
  styleUrls: ['./attachment-item-v2.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    SimpleTextInputComponent,
    FormsModule,
    ReactiveFormsModule,
    NgClass,
    SvgIconComponent,
    ContextMenuComponent,
    ContextMenuItemComponent,
    TranslateModule,
    FileSizePipe
  ]
})
export class AttachmentItemV2Component
  extends _AttachmentItemComponentMixinBase
  implements OnChanges, OnInit
{
  private sanitizer = inject(DomSanitizer);
  private modalService = inject(ModalService);
  private cdr = inject(ChangeDetectorRef);

  @Input() public attachment: Attachment;
  @Input() public index: number;
  @Input() public showDownload = true;
  @Input() public disableDownload = false;
  @Input() public blockDownload = false;
  @Input() public showRemove = false;
  @Input() public isDocument = false;
  @Input() public orderable = false;
  @Input() public editable = false;
  @Input() public directDownload = false;

  @Output() public remove = new EventEmitter<number>();
  @Output() public download = new EventEmitter<Attachment>();
  @Output() public preview = new EventEmitter<Attachment>();
  @Output() public moveUp = new EventEmitter<Attachment>();
  @Output() public moveDown = new EventEmitter<Attachment>();
  public progress: number;
  public isLoading: boolean;
  public imgSrc: SafeUrl | string;

  public hideIcons = true;

  public hasExifMetadata = false;

  public nameControl = new FormControl('');

  @HostListener('mouseenter')
  public onEnter() {
    this.hideIcons = false;
  }

  @HostListener('mouseleave')
  public onLeave() {
    this.hideIcons = true;
  }

  get isPDF() {
    return this.attachment.extension === 'pdf';
  }

  get getFileExtension() {
    return this.attachment?.extension?.toUpperCase();
  }

  public ngOnInit() {
    this.nameControl.patchValue(this.name);

    checkExifOrientation(this.attachment.file, orientation => {
      this.hasExifMetadata = orientation > -1;
      this.cdr.markForCheck();
    });
  }

  public ngOnChanges() {
    if (!this.isDocument) {
      this.imgSrc = this.hasFile
        ? this.getSafeUrl(this.attachment.file || (this.attachment as Blob))
        : this.attachment.url;
    }
  }

  public isPdf(attachment: Attachment) {
    return (
      attachment.type === 'PDF' ||
      (attachment.title && attachment.title.toLowerCase().endsWith('.pdf'))
    );
  }

  public onRemove() {
    this.remove.emit(this.index);
  }

  public onDownload() {
    this.download.emit(this.attachment);
  }

  public onPreview() {
    this.preview.emit(this.attachment);
  }

  public getSafeUrl(file: Blob) {
    return this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(file));
  }

  public onMoveUp() {
    if (this.orderable) {
      this.moveUp.emit(this.attachment);
    }
  }

  public onMoveDown() {
    if (this.orderable) {
      this.moveDown.emit(this.attachment);
    }
  }

  public editAttachment() {
    this.modalService
      .open<EditAttachmentComponent>(EditAttachmentComponent, {
        data: {
          attachment: { ...this.attachment },
          removeFn: () => this.remove.emit(this.index)
        }
      })
      .onClose()
      .subscribe((result: Attachment) => {
        this.attachment.title = result.title || '';
        this.nameControl.patchValue(this.attachment.title);

        this.attachment.rotate = result.rotate || 0;
        this.attachment.file = this.attachment.file || result.file;
      });
  }

  public action() {
    if (!this.disableDownload && this.attachment.url) {
      this.onPreview();
    }
    if (this.directDownload) {
      this.onDownload();
    }
  }
}
