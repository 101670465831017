export interface SalesLegalText {
  id?: string;
  type: SalesLegalTextType;
  text: string;
}

export enum SalesLegalTextType {
  INTRODUCTION = 'INTRODUCTION',
  CHECKBOX = 'CHECKBOX',
  CONCLUSION = 'CONCLUSION'
}
