import {
  ChangeDetectorRef,
  Component,
  forwardRef,
  inject,
  Input,
  OnInit,
  ViewChild
} from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NgClass } from '@angular/common';
import { AppFormFieldControl } from '../../form-field/form-field-control/form-field-control';
import { BaseControl } from '../base-control';
import { AppClickAreaDirective } from '../input/click-area.directive';

@UntilDestroy()
@Component({
  selector: 'app-check-intermediate',
  templateUrl: './check-intermediate.component.html',
  styleUrls: ['./check-intermediate.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckIntermediateComponent),
      multi: true
    },
    {
      provide: AppFormFieldControl,
      useExisting: forwardRef(() => CheckIntermediateComponent)
    }
  ],
  standalone: true,
  imports: [AppClickAreaDirective, NgClass, FormsModule]
})
export class CheckIntermediateComponent
  extends BaseControl<boolean>
  implements OnInit
{
  private cd = inject(ChangeDetectorRef);

  public trueValue: boolean;
  public falseValue: boolean;

  @ViewChild(NgControl, { static: true }) ngControl: NgControl;
  @Input() disableUncheck = false;
  @Input() alwaysShow = false;

  public ngOnInit() {
    this.ngControl.statusChanges
      .pipe(untilDestroyed(this))
      .subscribe(() => this.stateChanges.next());
  }

  public writeValue(value: any) {
    this.trueValue = value === true;
    this.falseValue = value === false;
    super.writeValue(value);
  }

  public onTrueChange(value) {
    if (value) {
      this.value = value;
      this.falseValue = false;
    } else {
      this.value = null;
    }
    this.cd.detectChanges();
  }

  public onFalseChange(value) {
    if (value) {
      this.value = !value;
      this.trueValue = false;
    } else {
      this.value = null;
    }
    this.cd.detectChanges();
  }
}
