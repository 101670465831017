import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AvailableLanguageCodesEnum, Language } from '@ui/shared/models';
import { FormsModule } from '@angular/forms';
import { LocaleFlagComponent } from '../../../../atoms/image/locale-flag/locale-flag.component';
import { LangPickerComponent } from '../../../../molecules/lang-picker/lang-picker.component';

@Component({
  selector: 'app-form-field-lang-picker',
  templateUrl: './form-field-lang-picker.component.html',
  styleUrls: ['./form-field-lang-picker.component.scss'],
  standalone: true,
  imports: [LangPickerComponent, FormsModule, LocaleFlagComponent]
})
export class FormFieldLangPickerComponent {
  @Input() availableLanguages: Language[];
  @Input() defaultLanguageCode: AvailableLanguageCodesEnum;
  @Input() currentLanguageCode: AvailableLanguageCodesEnum;
  @Output() languageChangeEvent =
    new EventEmitter<AvailableLanguageCodesEnum>();

  public onLanguageChange(langCode: AvailableLanguageCodesEnum) {
    this.languageChangeEvent.emit(langCode);
  }
}
