import { Injectable, inject } from '@angular/core';

import { Store } from '@ngrx/store';

import { tap, map } from 'rxjs/operators';

import * as fromBaseState from '../base-state';

@Injectable()
export class ConstantsGuard {
  private store = inject<Store<fromBaseState.AppState>>(Store);

  canActivate() {
    return this.store.select(fromBaseState.getConstantsLoaded).pipe(
      tap(loaded => {
        if (!loaded) this.store.dispatch(new fromBaseState.FetchConstants());
      }),
      map(() => true)
    );
  }
}
