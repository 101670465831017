import { Attachment } from './attachment.model';
import { ContentSharingInput } from './customer-portal';
import { TargetGroup } from './faq.model';

export interface OffersOverview {
  id?: number;
  uuid?: string;
  data?: OfferData;
  publishedAt?: string;
  publishedUntil?: string;
  sharing?: ContentSharingInput;
  _links?: AdditionalProps;
  sendNotification?: boolean;
}
export interface AdditionalProp {
  href: string;
  hreflang: string;
  title: string;
  type: string;
  deprecation: string;
  profile: string;
  name: string;
  templated: boolean;
}

export interface OfferData {
  title?: string;
  showInHeader?: boolean;
  excerpt?: string;
  image?: Attachment;
  imageUrl?: string;
  body?: string;
  href?: string;
}
export interface AdditionalProps {
  additionalProp1: AdditionalProp;
  additionalProp2: AdditionalProp;
  additionalProp3: AdditionalProp;
}

export interface OffersInput {
  data: OfferData;
  publishedAt?: string | Date;
  publishedUntil?: string | Date;
  targetGroups: TargetGroup[];
  sendNotification?: boolean;
}

export enum TargetGroupType {
  TENANT,
  OWNER,
  ADMINISTRATION_UNIT
}

export interface OfferFiles {
  document: Attachment;
}

export interface BulkDeleteInput {
  contentDeleteIds: number[];
}
