import { inject, Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders
} from '@angular/common/http';
import { from, Observable, of, throwError, zip } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import imageCompression from 'browser-image-compression';

import { Attachment } from '@ui/shared/models';
import { INFRASTRUCTURE_CONFIG } from '../infrastructure-config.token';
import { AuthTokenService } from '../authentication';

@Injectable()
export class FileUploadService {
  private http = inject(HttpClient);
  private authTokenService = inject(AuthTokenService);
  private config = inject(INFRASTRUCTURE_CONFIG);

  private IMG_COMPRESSION_OPTION = {
    maxWidthOrHeight: 1080,
    useWebWorker: false
  };

  public uploadFileWithToken(
    files: Blob[],
    filesType: string,
    applicationFileCategory: string,
    token: string
  ) {
    const formData = new FormData();
    files.forEach(file => formData.append('files', file, (file as any).name));
    formData.append('applicationFileCategory', applicationFileCategory);
    formData.append('token', token);
    formData.append('filesType', filesType);

    return this.doUploadFiles(
      files,
      formData,
      this.config.environment.file_upload_path
    );
  }

  public uploadFiles(
    files: Blob[],
    type: string,
    rotations: number[] = [],
    options?: Record<string, unknown>
  ): Observable<Attachment[]> {
    const formData = this.getFormData(files, type, rotations, options);

    return this.doUploadFiles(
      files,
      formData,
      this.config.environment.file_upload_path
    );
  }

  private getFormData(
    files: Blob[],
    type: string,
    rotations: number[] = [],
    options?: Record<string, unknown>
  ) {
    const formData = new FormData();
    files.forEach(file => formData.append('files', file, (file as any).name));
    formData.append('filesType', type);
    formData.append('rotations', JSON.stringify(rotations));
    if (options) formData.append('options', JSON.stringify(options));

    return formData;
  }

  private doUploadFiles(files: Blob[], formData: FormData, url: string) {
    /**
     * We need to add ngsw-bypass to skip service worker for file upload
     * We need to do that due because of bug present in Chromium itself
     * Files larger than 1MB wasn't able to be processed by service worker
     * https://github.com/angular/angular/issues/29629
     * https://bugs.chromium.org/p/chromium/issues/detail?id=988468
     */
    const authorization =
      this.authTokenService.getToken().access_token ||
      // eslint-disable-next-line @typescript-eslint/no-base-to-string
      formData?.get('token')?.toString();
    const headers = new HttpHeaders({
      authorization,
      'ngsw-bypass': 'true'
    });

    return this.http
      .post<Attachment[]>(url, formData, { headers })
      .pipe(
        map(attachments =>
          this.parseUploadResponse(attachments, files as File[])
        )
      );
  }

  public uploadFileAndGetPDF(
    files: Blob[],
    type: string,
    options?: Record<string, unknown>,
    responseType: 'json' | 'arraybuffer' = 'arraybuffer'
  ) {
    const formData = this.getFormData(files, type, [], {
      ...options,
      parseBody: false
    });
    /**
     * We need to add ngsw-bypass to skip service worker for file upload
     * We need to do that due because of bug present in Chromium itself
     * Files larger than 1MB wasn't able to be processed by service worker
     * https://github.com/angular/angular/issues/29629
     * https://bugs.chromium.org/p/chromium/issues/detail?id=988468
     */
    const authorization =
      this.authTokenService.getToken().access_token ||
      // eslint-disable-next-line @typescript-eslint/no-base-to-string
      formData?.get('token')?.toString();
    const headers = new HttpHeaders({
      authorization,
      'ngsw-bypass': 'true'
    });

    switch (responseType) {
      case 'arraybuffer':
        return this.http.post(
          this.config.environment.file_upload_path,
          formData,
          {
            headers,
            responseType
          }
        );

      case 'json':
        return this.http.post(
          this.config.environment.file_upload_path,
          formData,
          {
            headers,
            responseType
          }
        );
    }
  }

  private getImagesAndRotations(
    files: Blob[],
    rotations: number[] = [],
    predicate
  ) {
    const container = {
      images: [],
      rotations: []
    };
    files.forEach((file, index) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      if (predicate(file)) {
        container.images.push(file);
        container.rotations.push(rotations[index] || 0);
      }
    });
    return container;
  }

  public uploadImages(
    files: Blob[],
    rotations: number[] = [],
    options?: Record<string, unknown>
  ) {
    const compress = this.getImagesAndRotations(files, rotations, file =>
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-return
      file.type.startsWith('image')
    );
    const other = this.getImagesAndRotations(
      files,
      rotations,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      file => !file.type.startsWith('image')
    );

    const compressedObservable =
      compress.images.length > 0
        ? this.compressBeforeUpload(
            compress.images,
            compress.rotations,
            options
          )
        : of([]);
    const otherObservable =
      other.images.length > 0
        ? this.uploadFiles(other.images, 'IMG', other.rotations, options)
        : of([]);
    return zip(compressedObservable, otherObservable).pipe(
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      map(([compressedUploaded, otherUploaded]) => [
        ...compressedUploaded,
        ...otherUploaded
      ])
    );
  }

  private compressBeforeUpload(
    files: Blob[],
    rotations: number[] = [],
    options?: Record<string, unknown>
  ): Observable<Attachment[]> {
    return from(
      Promise.all(
        files.map((file: File) =>
          imageCompression(file, this.IMG_COMPRESSION_OPTION)
        )
      )
    ).pipe(
      switchMap((compressedBlobs: Blob[]) =>
        this.uploadFiles(compressedBlobs, 'IMG', rotations, options)
      ),
      catchError((error: HttpErrorResponse) => {
        console.error(error);
        throw throwError(() => error);
      })
    );
  }

  public uploadImage(
    attachment: Attachment,
    options?: Record<string, unknown>
  ): Observable<Attachment> {
    if (!(attachment?.file instanceof File)) return of(attachment);

    return this.uploadImages([attachment.file], undefined, options).pipe(
      map(response => this.parseSingleFilerResponse(response, attachment))
    );
  }

  public uploadDocuments(
    attachments: Attachment[],
    type: any = 'PDF',
    options?: Record<string, unknown>
  ): Observable<Attachment[]> {
    const existingAttachments = (attachments || []).filter(
      attachment => !(attachment?.file instanceof File)
    );
    const attachmentsToUpload = (attachments || []).filter(
      attachment => attachment?.file instanceof File
    );

    if (!attachmentsToUpload.length) {
      return of(attachments);
    }

    return this.uploadFiles(
      attachmentsToUpload.map(attachment => attachment.file),
      type,
      [],
      options
    ).pipe(
      map(response => {
        const uploadedAttachments = response.map((attachment, index) => {
          const originalAttachment = attachmentsToUpload[index];

          return this.parseSingleAttachment(
            attachment,
            originalAttachment,
            type
          );
        });

        return [...existingAttachments, ...uploadedAttachments];
      }),
      catchError((error: HttpErrorResponse) => {
        console.error(error);
        throw throwError(() => error);
      })
    );
  }

  public uploadDocument(
    attachment: Attachment,
    type: any,
    options?: Record<string, unknown>
  ): Observable<Attachment> {
    if (!(attachment?.file instanceof File)) return of(attachment);

    return this.uploadDocuments([attachment], type, options).pipe(
      map(response => this.parseSingleFilerResponse(response, attachment, type))
    );
  }

  private parseSingleFilerResponse(
    response: Attachment[],
    originalAttachment: Attachment,
    type = ''
  ) {
    return response.map(attachment =>
      this.parseSingleAttachment(attachment, originalAttachment, type)
    )[0];
  }

  private parseSingleAttachment(
    attachment: Attachment,
    originalAttachment: Attachment,
    type = ''
  ) {
    const { file, ...originalAttachmentRest } = originalAttachment;
    return {
      ...originalAttachmentRest,
      ...attachment,
      title: (file as File).name || type || ''
    };
  }

  private parseUploadResponse(
    attachments: Attachment[],
    files: File[]
  ): Attachment[] {
    return attachments.map((attachment, index) => ({
      ...attachment,
      title: attachment.title || files[index].name
    }));
  }
}
