<div
  class="circle__container"
  [ngStyle]="{ height: outerRadius + 'px', width: outerRadius + 'px' }"
>
  <div class="circle-wrapper" [ngClass]="!isAnonymous ? circleClass : null">
    @if (path) {
      <svg
        class="svg-element circle-svg"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        xml:space="preserve"
        [attr.viewBox]="viewBox"
      >
        <path [attr.d]="path"></path>
        @if (hasRestPath) {
          <path class="circle-path" [attr.d]="restPath"></path>
        }
      </svg>
    }
    @if (!isAnonymous) {
      @if (!scoreObscured) {
        @if (!showPercentage) {
          <div class="circle-number">
            {{ displayedValue | number: '1.1-1' }}
          </div>
        }
        @if (showPercentage) {
          <div class="circle-number cirlce-number--percentage">
            {{ value | number: '1.0-0' }} %
          </div>
        }
      } @else {
        <div class="circle-number">{{ scoreObscured }}</div>
      }
    } @else {
      <svg-icon
        src="/assets/images/icons/sanduhr.svg"
        [applyClass]="true"
        [svgStyle]="{ 'width.px': 30 }"
        class="default-svg-color rect path unlock-icon"
      ></svg-icon>
    }
  </div>
</div>
