import { Action } from '@ngrx/store';

export const LOAD_TRANSLATION = '[Translations] Load Translation';
export const LOAD_TRANSLATION_SUCCESS =
  '[Translations] Load Translation Success';
export const LOAD_TRANSLATION_FAIL = '[Translations] Load Translation Fail';

export const LOAD_DEFAULT_TRANSLATION =
  '[Translations] Load Default Translation';

export const CHECK_TRANSLATIONS = '[Translations] Check Translations';

export const REMOVE_TRANSLATIONS = '[Translations] Remove Translations';

export class LoadTranslation implements Action {
  readonly type = LOAD_TRANSLATION;

  constructor(
    public appName: string,
    public langCode: string,
    public informal: boolean
  ) {}
}

export class LoadTranslationSuccess implements Action {
  readonly type = LOAD_TRANSLATION_SUCCESS;

  constructor(
    public langCode: string,
    public informal: boolean
  ) {}
}

export class LoadTranslationFail implements Action {
  readonly type = LOAD_TRANSLATION_FAIL;

  constructor(public error: string) {}
}

export class LoadDefaultTranslation implements Action {
  readonly type = LOAD_DEFAULT_TRANSLATION;
}

export class CheckTranslations implements Action {
  readonly type = CHECK_TRANSLATIONS;

  constructor(
    public appName: string,
    public langCode: string,
    public informal: boolean
  ) {}
}

export class RemoveTranslations implements Action {
  readonly type = REMOVE_TRANSLATIONS;
}

export type TranslationAction =
  | LoadTranslation
  | LoadTranslationSuccess
  | LoadTranslationFail
  | LoadDefaultTranslation
  | CheckTranslations
  | RemoveTranslations;
