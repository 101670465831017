@if (!showV2) {
  <app-modal>
    <div class="modal-s">
      <app-modal-content>
        <div class="modal-body">
          <p class="title-s sub-headline">
            {{
              'appointment.slot_available_l'
                | translate: { value: numberOfAppointments }
            }}
          </p>
          @if (hasAppointments) {
            <div>
              <p class="title-m modal-headline">
                {{ 'appointment.select_slot_l' | translate }}
              </p>
              <p class="default-s confirmation-dialog__message">
                {{ 'appointment.select_description_l' | translate }}
              </p>
            </div>
          }
          @if (!hasAppointments) {
            <div>
              <p class="title-m modal-headline">
                {{ 'appointment.no_slot_l' | translate }}
              </p>
              <p class="default-s confirmation-dialog__message">
                {{ 'appointment.no_slot_description_l' | translate }}
              </p>
            </div>
          }
          <div class="property-info">
            <div>
              @if (picture) {
                <img
                  src="{{ picture | appImageSize: 'S' }}"
                  alt="{{ property?.name }}"
                />
              }
            </div>
            <div>
              <div class="property-name">{{ property?.name }}</div>
              <div class="property-address">
                {{ property?.address | appAddress }}
              </div>
            </div>
          </div>
          <app-appointment-selection
            [appointments]="appointments"
            [exclusiveAppointments]="exclusiveAppointments"
            [(ngModel)]="newActiveAppointmentId"
            [showNotFittingOption]="showNotFittingOption"
          >
          </app-appointment-selection>
        </div>
      </app-modal-content>
      <app-modal-footer>
        <div class="modal-footer confirmation-dialog__footer">
          <app-button [type]="'light-bordered'" (clickEvent)="dismiss()">
            {{ 'general.close_a' | translate }}
          </app-button>
          @if (hasAppointments) {
            <app-button
              [disabled]="
                !newActiveAppointmentId ||
                newActiveAppointmentId === currentActiveAppointmentId
              "
              (clickEvent)="close()"
            >
              {{ okButtonMessage | translate }}
            </app-button>
          }
        </div>
      </app-modal-footer>
    </div>
  </app-modal>
}
@if (showV2) {
  <app-modal-v2>
    <div class="modal-s">
      <app-modal-content>
        <div class="modal-body">
          <p class="title-s sub-headline">
            {{
              'appointment.slot_available_l'
                | translate: { value: numberOfAppointments }
            }}
          </p>
          @if (hasAppointments) {
            <div>
              <p class="title-m modal-headline">
                {{ 'appointment.select_slot_l' | translate }}
              </p>
              <p class="default-s confirmation-dialog__message">
                {{ 'appointment.select_description_l' | translate }}
              </p>
            </div>
          }
          @if (!hasAppointments) {
            <div>
              <p class="title-m modal-headline">
                {{ 'appointment.no_slot_l' | translate }}
              </p>
              <p class="default-s confirmation-dialog__message">
                {{ 'appointment.no_slot_description_l' | translate }}
              </p>
            </div>
          }
          <div class="property-info">
            <div>
              @if (picture) {
                <img
                  src="{{ picture | appImageSize: 'S' }}"
                  alt="{{ property?.name }}"
                />
              }
            </div>
            <div>
              <div class="property-name">{{ property?.name }}</div>
              <div class="property-address">
                {{ property?.address | appAddress }}
              </div>
            </div>
          </div>
          <app-appointment-selection
            [appointments]="appointments"
            [applicationStatus]="applicationStatus"
            [exclusiveAppointments]="exclusiveAppointments"
            [showNotFittingOption]="showNotFittingOption"
            [(ngModel)]="newActiveAppointmentId"
          >
          </app-appointment-selection>
        </div>
      </app-modal-content>
      <app-modal-footer>
        <div class="modal-footer confirmation-dialog__footer">
          <app-button [type]="'light-bordered'" (clickEvent)="dismiss()">
            {{ 'general.close_a' | translate }}
          </app-button>
          @if (hasAppointments) {
            <app-button
              [disabled]="
                !newActiveAppointmentId ||
                newActiveAppointmentId === currentActiveAppointmentId
              "
              [type]="'primary'"
              (clickEvent)="close()"
            >
              @if (newActiveAppointmentId !== 'non_fitting') {
                {{ okButtonMessage | translate }}
              } @else {
                {{ 'appointment.not_fitting_a_l' | translate }}
              }
            </app-button>
          }
        </div>
      </app-modal-footer>
    </div>
  </app-modal-v2>
}
