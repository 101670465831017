import { Address } from '../address.model';
import { Attachment } from '../attachment.model';
import { Property } from '../property.model';
import { Appointment } from './appointment.model';

export enum AppointmentAcceptanceStates {
  ACTIVE = 'ACTIVE',
  CANCELED = 'CANCELED'
}

export interface SelectAppointmentInput {
  appointmentId?: string;
  appointmentAcceptanceId?: string;
  applicationId?: string;
}

export interface AppointmentSelection {
  applicationId?: string;
  appointmentAcceptanceId?: string;
  property?: Property;
  appointment?: Appointment;
}

export interface AppointmentsBundles {
  applicationId?: string;
  applicationStatus?: string;
  property?: AppointmentPropertyShortInfo;
  appointments?: Appointment[];
  appointmentAcceptances?: AppointmentAcceptanceShortInfo[];
  appointmentInvitations?: AppointmentInvitations[];
}

export interface AppointmentPropertyShortInfo {
  propertyId?: string;
  address?: Address;
  name?: string;
  image?: Attachment;
  customerName?: string;
  customerLogo?: string;
}

export interface AppointmentAcceptanceShortInfo {
  id?: string;
  state?: string;
  appointmentId?: string;
}

export interface AppointmentInvitations {
  id: string;
  appointmentId: string;
}

export interface CancelAppointmentData {
  appointmentId?: string;
  applicationId?: string;
  reasonType?: string;
  otherReasonText?: string;
}
