import { Component, Input } from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule
} from '@angular/forms';

import { CustomQuestion, RootQuestion } from '@ui/shared/models';
import { BaseStep } from 'libs/components/legacy/wizard/wizard-step/base-step';
import { TranslateModule } from '@ngx-translate/core';
import { HierarchicalRootQuestionFormComponent } from '../custom-questions/hierarchical-root-question-form/hierarchical-root-question-form.component';
import { CustomQuestionsFormComponent } from '../custom-questions/custom-questions-form/custom-questions-form.component';

@Component({
  selector: 'app-global-custom-questions',
  templateUrl: './global-custom-questions.component.html',
  styleUrls: ['./global-custom-questions.component.scss'],
  standalone: true,
  imports: [
    CustomQuestionsFormComponent,
    FormsModule,
    ReactiveFormsModule,
    HierarchicalRootQuestionFormComponent,
    TranslateModule
  ]
})
export class GlobalCustomQuestionsComponent extends BaseStep {
  @Input() form: FormGroup;
  @Input() customQuestions: CustomQuestion[];
  @Input() rootQuestions: RootQuestion[];
  @Input() isProcessing: boolean;
  @Input() showHierarchicalRootQuestions = true;
  @Input() showErrorMessage = false;

  public get customQuestionResponsesControl() {
    return this.form.get('customQuestionResponses') as FormControl;
  }

  public get hierarchicalRootQuestionsControl() {
    return this.form.get('hierarchicalRootQuestions') as FormControl;
  }

  public onFormValidityChange(isValid: boolean) {
    this.customQuestionResponsesControl.setErrors(
      isValid ? null : { missingFields: true }
    );
  }

  public onHierarchicalFormValidityChange(isValid: boolean) {
    this.hierarchicalRootQuestionsControl.setErrors(
      isValid ? null : { missingFields: true }
    );
  }
}
