export interface MovingChecklist {
  moveInDate: string;
  doNotAskForMoveInDate: boolean;
  checklist: MovingChecklistTimeSlot;
}

export interface MovingChecklistTimeSlot {
  [key: string]: MovingChecklistContent;
}

export interface MovingChecklistContent {
  daysBeforeMoving: number;
  elements: MovingChecklistTodo[];
}

export interface MovingChecklistTodo {
  type: string;
  timeSlot: string;
  status: string;
  days?: number;
}

export interface MovingChecklistMoveInDate {
  moveInDate: string;
}

export interface MovingChecklistSetMoveInDatePayload {
  doNotAskForMoveInDate: boolean;
  moveInDate: string;
}
export interface MovingChecklistChangeElementPayload {
  propertyID: string;
  element: MovingChecklistTodo;
}

export enum MovingChecklistStatus {
  NOT_SET = 'NOT_SET',
  DONE = 'DONE',
  NOT_NEEDED = 'NOT_NEEDED'
}

export enum MovingChecklistTimeSlotEnum {
  AFTER_ACCEPTANCE = 'AFTER_ACCEPTANCE',
  THREE_MONTHS_BEFORE_MOVING = 'THREE_MONTHS_BEFORE_MOVING',
  SIX_WEEKS_BEFORE_MOVING = 'SIX_WEEKS_BEFORE_MOVING',
  FOUR_WEEKS_BEFORE_MOVING = 'FOUR_WEEKS_BEFORE_MOVING',
  THREE_WEEKS_BEFORE_MOVING = 'THREE_WEEKS_BEFORE_MOVING',
  TWO_WEEKS_BEFORE_MOVING = 'TWO_WEEKS_BEFORE_MOVING',
  ONE_WEEK_BEFORE_MOVING = 'ONE_WEEK_BEFORE_MOVING',
  HANDOVER_OLD_FLAT = 'HANDOVER_OLD_FLAT',
  AFTER_MOVING = 'AFTER_MOVING'
}
